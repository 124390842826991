import React, { useState, useEffect, Suspense, lazy } from "react";
import { useHistory } from "react-router";
import Header from "../header/Header";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card } from "reactstrap";
import CrmTestimonials from "./CrmTestimonials";

const CrmTestimonailsReport = (props) => {


  const [opencount, setOpenCount] = useState(0);
  const [showMenu, setshowMenu] = useState(true);
  const [myId, setmyId] = useState(0);
  const [month, setMonth] = useState(48);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const history = useHistory();

  const projectManagers = [
    { id: 1, name: "Project Manager 1" },
    { id: 2, name: "Project Manager 2" },
    { id: 3, name: "Project Manager 3" },
  ];
  const projectLeads = [
    { id: 1, name: "Project Lead 1" },
    { id: 2, name: "Project Lead 2" },
    { id: 3, name: "Project Lead 3" },
  ];
  
  const comOptions = [
    { id: 1, name: "Com 1" },
    { id: 2, name: "Com 2" },
    { id: 3, name: "Com 3" },
  ];
  
  const opsOptions = [
    { id: 1, name: "Ops 1" },
    { id: 2, name: "Ops 2" },
    { id: 3, name: "Ops 3" },
  ];

  const initialData = [
    {
      id: "ERP Nova",
      request_platform: "Google Reviews",
      requested_by: "Kushal",
      requested_on: "15 Mar 2024, 9:30 AM",
      received_on: "15 Mar 2024, 2:20 PM",
      status: "Yes",
      rating_received: 4,
      board_360_id: 5001,
      board_hub_id: 6001,
      project_maanger_id: "Kushal",
      project_lead_id: "Aanisha",
      com_id: "Krishna Kumar",
      ops_id: "Hasthik"
    },
    {
      id: "CRM Plus",
      request_platform: "Trustpilot",
      requested_by: "Dinali",
      requested_on: "14 Mar 2024, 10:15 AM",
      received_on: "14 Mar 2024, 4:45 PM",
      status: "No",
      rating_received: 5,
      board_360_id: 5002,
      board_hub_id: 6002,
      project_maanger_id: "Dinali",
      project_lead_id: "Trupti",
      com_id: "Kshitiz",
      ops_id: "Suhas"
    },
    {
      id: "HR Core",
      request_platform: "Capterra",
      requested_by: "Ishan",
      requested_on: "13 Mar 2024, 11:20 AM",
      received_on: "13 Mar 2024, 5:30 PM",
      status: "Yes",
      rating_received: 3,
      board_360_id: 5003,
      board_hub_id: 6003,
      project_maanger_id: "Ishan",
      project_lead_id: "Aaron",
      com_id: "Sabrina",
      ops_id: "Hasthik"
    },
    {
      id: "Sales Hub",
      request_platform: "G2",
      requested_by: "Divya",
      requested_on: "12 Mar 2024, 2:25 PM",
      received_on: "12 Mar 2024, 6:15 PM",
      status: "Yes",
      rating_received: 5,
      board_360_id: 5004,
      board_hub_id: 6004,
      project_maanger_id: "Divya",
      project_lead_id: "Nishan",
      com_id: "Krishna Kumar",
      ops_id: "Suhas"
    },
    {
      id: "Finance Pro",
      request_platform: "Google Reviews",
      requested_by: "Kushal",
      requested_on: "11 Mar 2024, 9:45 AM",
      received_on: "11 Mar 2024, 3:30 PM",
      status: "No",
      rating_received: 2,
      board_360_id: 5005,
      board_hub_id: 6005,
      project_maanger_id: "Kushal",
      project_lead_id: "Trupti",
      com_id: "Kshitiz",
      ops_id: "Hasthik"
    },
    {
      id: "Tech Sync",
      request_platform: "Trustpilot",
      requested_by: "Dinali",
      requested_on: "10 Mar 2024, 1:10 PM",
      received_on: "10 Mar 2024, 7:20 PM",
      status: "Yes",
      rating_received: 4,
      board_360_id: 5006,
      board_hub_id: 6006,
      project_maanger_id: "Dinali",
      project_lead_id: "Aaron",
      com_id: "Sabrina",
      ops_id: "Suhas"
    },
    {
      id: "Data Core",
      request_platform: "G2",
      requested_by: "Ishan",
      requested_on: "09 Mar 2024, 10:30 AM",
      received_on: "09 Mar 2024, 4:45 PM",
      status: "Yes",
      rating_received: 5,
      board_360_id: 5007,
      board_hub_id: 6007,
      project_maanger_id: "Ishan",
      project_lead_id: "Nishan",
      com_id: "Krishna Kumar",
      ops_id: "Hasthik"
    },
    {
      id: "Dev Ops",
      request_platform: "Capterra",
      requested_by: "Divya",
      requested_on: "08 Mar 2024, 3:20 PM",
      received_on: "08 Mar 2024, 8:10 PM",
      status: "No",
      rating_received: 3,
      board_360_id: 5008,
      board_hub_id: 6008,
      project_maanger_id: "Divya",
      project_lead_id: "Aanisha",
      com_id: "Kshitiz",
      ops_id: "Suhas"
    },
    {
      id: "AI Lab",
      request_platform: "Google Reviews",
      requested_by: "Kushal",
      requested_on: "07 Mar 2024, 11:15 AM",
      received_on: "07 Mar 2024, 5:25 PM",
      status: "Yes",
      rating_received: 5,
      board_360_id: 5009,
      board_hub_id: 6009,
      project_maanger_id: "Kushal",
      project_lead_id: "Trupti",
      com_id: "Sabrina",
      ops_id: "Hasthik"
    },
    {
      id: "Cloud Hub",
      request_platform: "Trustpilot",
      requested_by: "Dinali",
      requested_on: "06 Mar 2024, 9:50 AM",
      received_on: "06 Mar 2024, 2:30 PM",
      status: "Yes",
      rating_received: 4,
      board_360_id: 5010,
      board_hub_id: 6010,
      project_maanger_id: "Dinali",
      project_lead_id: "Aaron",
      com_id: "Krishna Kumar",
      ops_id: "Suhas"
    }
  ];


  useEffect(() => {
    checkUserPrivilege();
  }, []);

  const checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      setmyId(login.uid);
    }
  };

  function toggleSidebar() {
    setshowMenu(!showMenu);
  }

  function hideMenu() {
    setshowMenu(!showMenu);
  }

  const toggleFeedbackFormTrigger = () => {
    setShowFeedbackForm(!showFeedbackForm);
  };

  const testimonialType = props.match.params.type;

  return (
    <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className="">
        <Row>
          <div className="theme_header">
            <Header
              ncount={opencount}
              {...props}
              className="tick-timer-header"
              quickactionblock={false}
            />
          </div>
          <div className="SideNavbar">
            <SideMenu
              activeitem="report"
              showmenu={showMenu}
              hidemenu={hideMenu}
            />
          </div>
          <div className="themeContentBody performance-dashboard">
            <div className="p-6 bg-gray-50 min-h-screen">
            <h1 className="page-title text-left">
              Client Testimonials{' '}
              {testimonialType !== '' &&
                ` - ${testimonialType.charAt(0).toUpperCase() + testimonialType.slice(1)}`}
            </h1>
              <CrmTestimonials
              projectManagers={projectManagers}
              projectLeads={projectLeads}
              comOptions={comOptions}
              opsOptions={opsOptions}
              data={initialData}
              />
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default CrmTestimonailsReport;
