import React, { useState, useEffect } from 'react';
import * as LucideIcons from 'lucide-react';
import {
    Clock,
    AlertTriangle,
    Users,
    MessageSquare,
    CheckCircle,
    XCircle,
    Mail
  } from 'lucide-react';
import { useHistory } from "react-router";
import Header from "../header/Header";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, Modal, Form, Button } from "reactstrap";
import { urls } from "../../services/UrlList";
import "./PerformanceIssues.css";
import MeetingScheduleDrawer  from './MeetingScheduleDrawer';
import WarningIssueDrawer from './WarningIssueDrawer';
import FeedbackRequestDrawer from './FeedbackRequestDrawer';
import FeedbackSubmissionDrawer from './FeedbackSubmissionDrawer';
import TerminationDrawer from './TerminationDrawer';
import {useGetIssueTrackerSteps, useGetIssueTimeline} from './usePerformanceIssueHooks';



const PerformanceTimeline = (props) => {
  const [currentUserRole] = useState('PROJECT_LEAD'); 

   const [opencount, setOpenCount] = useState(0);
    const [showMenu, setshowMenu] = useState(true);
    const [myId, setmyId] = useState(0);
    const history = useHistory();
    const [issueSteps, setIssueSteps] = useState({});
    const [issue, setIssue] = useState({});
  
    useEffect(() => {
      checkUserPrivilege();
    }, []);
    
   const {isLoading: stepsLoading, data: stepsData} = useGetIssueTrackerSteps();
   const {isLoading: timelineLoading, data: timelineData} = useGetIssueTimeline(1);

   useEffect(() => {
    if(stepsData?.data.steps){
       const workflowsData = JSON.parse(stepsData?.data.steps[0].workflow_json);
       setIssueSteps(workflowsData.workflows);
    }
    if(timelineData?.data){
        console.log(timelineData?.data.timeline);
        setIssue(timelineData?.data);
     }

    }, [stepsData?.data, timelineData?.data]);

  
    const checkUserPrivilege = () => {
      if (localStorage.getItem("user") !== null) {
        var login = JSON.parse(localStorage.getItem("user"));
        setmyId(login.uid);
      }
    };
  
    function toggleSidebar() {
      setshowMenu(!showMenu);
    }
  
    function hideMenu() {
      setshowMenu(!showMenu);
    }

    
    const [issue1, setIssue1] = useState({
        issueMakerName: "Fariz Muhammed",
        issueMakerID: 357,
        issueMakerRole: "DESIGNER",
        status: "IN_PROGRESS",
        issueID: "PI-123",
        currentStep: "SCHEDULE_MEETING",
        assignedTo: "PROJECT_LEAD",
        assignedToID: 123,
        timeline: [
          {
            type: "ISSUE_CREATION",
            title: "Performance Issue Identified",
            description: "Multiple deadline missed in past month",
            createdBy: "Project Manager",
            status: "COMPLETED",
            createdAt: "2025-01-10",
          },
          {
            type: "TICKET_ACKNOWLEDGMENT",
            createdAt: "2025-01-10 14:30",
            title: "Ticket Acknowledged",
            description: "Project Lead acknowledged the performance issue ticket",
            createdBy: "Project Lead",
            status: "COMPLETED"
          },
          {
            type: "INITIAL_DISCUSSION",
            createdAt: "2025-01-11",
            title: "Initial Discussion",
            description: "Discussion with PM/COM/TL about performance concerns",
            participants: ["Project Lead", "Project Manager", "COM", "Team Lead"],
            status: "COMPLETED"
          },
          {
            type: "MEETING",
            createdAt: "2025-01-13",
            title: "Performance Review Meeting",
            description: "Detailed discussion with designer and team",
            participants: ["Designer", "COM", "TL", "OM", "QA"],
            status: "COMPLETED",
            meetingNotes: "Discussed performance issues and improvement plan"
          },
          {
            type: "FINDINGS_DOCUMENTATION",
            createdAt: "2025-01-14",
            title: "Meeting Findings Documented",
            description: "Project Lead documented meeting outcomes",
            createdBy: "Project Lead",
            status: "COMPLETED",
            assignedTo: "HR_MANAGER",
            assignmentDeadline: "2025-01-15"
          },
          {
            type: "WARNING",
            createdAt: "2025-01-15",
            title: "First Warning Issued",
            description: "Formal warning issued via email",
            createdBy: "HR Manager",
            status: "ACTIVE",
            warningType: "FIRST_WARNING",
            deadline: "2025-02-14", 
            ccList: ["TL", "COM", "PM", "PL", "OM", "COL", "Senthu", "Prem"],
            assignedTo: "OPERATIONS_MANAGER"
          },
          {
            type: "FEEDBACK_REQUEST",
            createdAt: "2025-02-14",
            title: "Feedback Collection Initiated",
            description: "Request sent to PL & Creative Operations Lead for feedback",
            createdBy: "Operations Manager",
            status: "COMPLETED",
            assignedTo: ["PROJECT_LEAD", "CREATIVE_OPERATIONS_LEAD"],
            feedbackDeadline: "2025-02-15"
          },
          {
            type: "FEEDBACK_RECEIVED",
            createdAt: "2025-02-15",
            title: "Performance Feedback Received",
            description: "Feedback collected from all stakeholders",
            status: "COMPLETED",
            assignedTo: "OPERATIONS_MANAGER"
          },
          {
            type: "EVALUATION",
            createdAt: "2025-02-16",
            title: "Performance Evaluation Completed",
            description: "Issues remain unresolved after evaluation",
            createdBy: "Operations Manager",
            status: "COMPLETED",
            outcome: "UNRESOLVED"
          },
          {
            type: "SECOND_CHANCE_DECISION",
            createdAt: "2025-02-17",
            title: "Second Chance Denied",
            description: "Decision made to proceed with termination",
            createdBy: "Operations Manager",
            status: "COMPLETED",
            assignedTo: "PREM"
          },
        //   {
        //     id: 11,
        //     type: "TERMINATION_APPROVAL",
        //     createdAt: "2025-02-18",
        //     title: "Termination Approved",
        //     description: "PREM approved the termination request",
        //     createdBy: "PREM",
        //     status: "COMPLETED",
        //     assignedTo: "HR_MANAGER"
        //   },
        //   {
        //     id: 12,
        //     type: "TERMINATION_PROCESSED",
        //     createdAt: "2025-02-19",
        //     title: "Termination Completed",
        //     description: "Accounts disabled and passwords reset",
        //     createdBy: "HR Manager",
        //     status: "COMPLETED",
        //     finalActions: {
        //       accountsDisabled: true,
        //       passwordsReset: true,
        //       terminationDate: "2025-02-19"
        //     }
        //   }
        ]
      });



  const getWorkflowSteps = (currentStep, userRole) => {
    const stepWorkflow = issueSteps[currentStep];
    if (!stepWorkflow) return null;
    if (stepWorkflow.ALLOWED_ROLES && !stepWorkflow.ALLOWED_ROLES.includes(userRole)) {
      return null;
    }
    return stepWorkflow[userRole] || null;
  };

  const handleActionClick = (actionType) => {
    console.log(actionType);
    switch(actionType) {
    //   case 'CREATE_HUB_TICKET':
    //     window.open('/create-ticket', '_blank');
    //     break;

     case 'TICKET_ACKNOWLEDGED':
        handleTicketAcknowledgment();
     break;

      case 'SCHEDULE_MEETING':
      case 'SCHEDULE_DISCUSSION':
        setActiveDrawer('meeting');
        break;

      case 'DOCUMENT_FINDINGS':
        setActiveDrawer('feedbackSubmission');
        break;

      case 'ISSUE_WARNING':
        setActiveDrawer('warning');
        break;

      case 'ACKNOWLEDGE_WARNING':
        setActiveDrawer('acknowledge');
        break;

      case 'REQUEST_FEEDBACK':
        setActiveDrawer('feedbackRequest');
        break;

      case 'EVALUATE_ISSUES':
        setActiveDrawer('feedbackSubmission');
        break;

      case 'GRANT_SECOND_CHANCE':
        setActiveDrawer('secondChance');
        break;

      case 'INITIATE_TERMINATION':
        setActiveDrawer('termination');  //termination 
        break;

      case 'APPROVE_TERMINATION':
        setActiveDrawer('termination'); //terminationApproval
        break;

      default:
        console.warn(`No handler for action type: ${actionType}`);
    }
  };


  const ActionPanel = ({ actions, deadline, title, onAction, isLoading }) => (
    <div className="card border-0 shadow-sm mb-4 p-4">
      <div className="card-body">
        <h5 className="text-primary d-flex align-items-center mb-4">
          <Clock className="me-2" size={20} />
          <span className='ml-1'>{title}</span>
        </h5>
        {actions.map((action, index) => (
          <div key={index} className="card border mb-3 p-4">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-start mb-2">
                <h6 className="text-primary mb-0">{action.label}</h6>
                <span className="badge bg-primary rounded-pill">Required</span>
              </div>
              <p className="text-muted medium mb-3">{action.description}</p>
              <div className="d-flex align-items-center">
                <button 
                  className="btn btn-primary me-3"
                  onClick={() => onAction(action.type)}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"/>
                      Processing...
                    </>
                  ) : action.buttonText || 'Take Action'}
                </button>
                {deadline && (
                  <span className="text-muted medium ml-2">
                    Due within: {deadline}
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const ResolutionStatus = ({ status, warningCount }) => (
    <>
      {warningCount > 0 && (
            <>
                <p className="text-muted medium mb-1">Warnings Issued</p>
                <p className="fw-medium mb-0 text-danger">{warningCount}</p>
            </>
          )}
    </>
  );

  const [isLoading, setIsLoading] = useState(false);
  const [activeDrawer, setActiveDrawer] = useState(null);

  const toggleDrawer = () => {
    setActiveDrawer(null);
  };

  // Individual action handlers
  const handleTicketAcknowledgment = () => {
    // Update ticket status
    updateTicketStatus('ACKNOWLEDGED');
  };

  const handleDocumentFindings = () => {
    // Save meeting findings
    saveMeetingFindings();
  };

  const handleIssueEvaluation = () => {
    // Evaluate and update status
    evaluatePerformanceIssues();
  };

  const handleSecondChance = () => {
    // Reset warning process
    resetWarningProcess();
  };

  const handleTerminationApproval = () => {
    // Process approval
    processTerminationApproval();
  };

  const handleTerminationProcessing = () => {
    // Final termination steps
    processTermination();
  };

  // Modal submission handlers
  const handleMeetingSubmit = (formData) => {
    // Schedule meeting
    scheduleMeeting(formData);
    setActiveDrawer(null);
  };

  const handleWarningSubmit = (formData) => {
    // Issue warning
    issueWarning(formData);
    setActiveDrawer(null);
  };

  const handleFeedbackRequestSubmit = (formData) => {
    // Issue warning
    issueWarning(formData);
    setActiveDrawer(null);
  };

  const handleFeedbackSubmit = (formData) => {
    // Request feedback
    requestFeedback(formData);
    setActiveDrawer(null);
  };

  const handleTerminationSubmit = (formData) => {
    // Submit termination request
    submitTerminationRequest(formData);
    setActiveDrawer(null);
  };



  const updateTicketStatus = async (status) => {
    try {
      const response = await fetch(`/api/tickets/${issue.id}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          status,
          updatedBy: currentUserRole,
          timestamp: new Date().toISOString()
        })
      });

      if (response.ok) {
        // Update local state
        setIssue(prev => ({
          ...prev,
          status: status
        }));
        // Show success message
        showNotification('Success', 'Ticket status updated successfully');
      }
    } catch (error) {
      console.error('Error updating ticket status:', error);
      showNotification('Error', 'Failed to update ticket status');
    }
  };

  const saveMeetingFindings = async (findings) => {
    try {
      const response = await fetch(`/api/tickets/${issue.id}/findings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          findings,
          documentedBy: currentUserRole,
          timestamp: new Date().toISOString()
        })
      });

      if (response.ok) {
        // Update timeline
        updateTimeline('FINDINGS_DOCUMENTED');
        showNotification('Success', 'Meeting findings saved successfully');
      }
    } catch (error) {
      console.error('Error saving findings:', error);
      showNotification('Error', 'Failed to save meeting findings');
    }
  };

  const evaluatePerformanceIssues = async (evaluation) => {
    try {
      const response = await fetch(`/api/tickets/${issue.id}/evaluate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          evaluation,
          evaluatedBy: currentUserRole,
          timestamp: new Date().toISOString()
        })
      });

      if (response.ok) {
        updateTimeline('EVALUATION_COMPLETED');
        showNotification('Success', 'Performance evaluation completed');
      }
    } catch (error) {
      console.error('Error evaluating issues:', error);
      showNotification('Error', 'Failed to complete evaluation');
    }
  };

  const resetWarningProcess = async () => {
    try {
      const response = await fetch(`/api/tickets/${issue.id}/reset-warning`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          initiatedBy: currentUserRole,
          timestamp: new Date().toISOString()
        })
      });

      if (response.ok) {
        updateTimeline('WARNING_PROCESS_RESET');
        showNotification('Success', 'Warning process reset successfully');
      }
    } catch (error) {
      console.error('Error resetting warning process:', error);
      showNotification('Error', 'Failed to reset warning process');
    }
  };

  const processTerminationApproval = async (approvalData) => {
    try {
      const response = await fetch(`/api/tickets/${issue.id}/termination-approval`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...approvalData,
          approvedBy: currentUserRole,
          timestamp: new Date().toISOString()
        })
      });

      if (response.ok) {
        updateTimeline('TERMINATION_APPROVED');
        showNotification('Success', 'Termination approved');
      }
    } catch (error) {
      console.error('Error processing termination approval:', error);
      showNotification('Error', 'Failed to process termination approval');
    }
  };

  const processTermination = async () => {
    try {
      const response = await fetch(`/api/tickets/${issue.id}/terminate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          processedBy: currentUserRole,
          timestamp: new Date().toISOString()
        })
      });

      if (response.ok) {
        updateTimeline('TERMINATION_COMPLETED');
        showNotification('Success', 'Termination processed successfully');
      }
    } catch (error) {
      console.error('Error processing termination:', error);
      showNotification('Error', 'Failed to process termination');
    }
  };

  const scheduleMeeting = async (meetingData) => {
    try {
      const response = await fetch(`/api/tickets/${issue.id}/meetings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...meetingData,
          scheduledBy: currentUserRole,
          timestamp: new Date().toISOString()
        })
      });

      if (response.ok) {
        updateTimeline('MEETING_SCHEDULED');
        showNotification('Success', 'Meeting scheduled successfully');
      }
    } catch (error) {
      console.error('Error scheduling meeting:', error);
      showNotification('Error', 'Failed to schedule meeting');
    }
  };

  const issueWarning = async (warningData) => {
    try {
      const response = await fetch(`/api/tickets/${issue.id}/warnings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...warningData,
          issuedBy: currentUserRole,
          timestamp: new Date().toISOString()
        })
      });

      if (response.ok) {
        updateTimeline('WARNING_ISSUED');
        showNotification('Success', 'Warning issued successfully');
      }
    } catch (error) {
      console.error('Error issuing warning:', error);
      showNotification('Error', 'Failed to issue warning');
    }
  };

  const requestFeedback = async (feedbackData) => {
    try {
      const response = await fetch(`/api/tickets/${issue.id}/feedback-requests`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...feedbackData,
          requestedBy: currentUserRole,
          timestamp: new Date().toISOString()
        })
      });

      if (response.ok) {
        updateTimeline('FEEDBACK_REQUESTED');
        showNotification('Success', 'Feedback requested successfully');
      }
    } catch (error) {
      console.error('Error requesting feedback:', error);
      showNotification('Error', 'Failed to request feedback');
    }
  };

  const submitTerminationRequest = async (terminationData) => {
    try {
      const response = await fetch(`/api/tickets/${issue.id}/termination-requests`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...terminationData,
          requestedBy: currentUserRole,
          timestamp: new Date().toISOString()
        })
      });

      if (response.ok) {
        updateTimeline('TERMINATION_REQUESTED');
        showNotification('Success', 'Termination request submitted successfully');
      }
    } catch (error) {
      console.error('Error submitting termination request:', error);
      showNotification('Error', 'Failed to submit termination request');
    }
  };

  // Helper function to update timeline
  const updateTimeline = (newStatus) => {
    setIssue(prev => ({
      ...prev,
      currentStep: newStatus,
      timeline: [
        ...prev.timeline,
        {
          id: prev.timeline.length + 1,
          type: newStatus,
          date: new Date().toISOString(),
          createdBy: currentUserRole,
          status: 'COMPLETED'
        }
      ]
    }));
  };

  // Notification helper
  const showNotification = (title, message) => {
    // Implement your notification system here
    // Could use toast or alert component
    console.log(`${title}: ${message}`);
  };


  const  formatConstantCase = (str) => {
    return str
        .split('_')
        .map(word => word.charAt(0) + word.slice(1).toLowerCase())
        .join(' ');
   }   



  const TimelineEvent = ({ event, isLast }) => {
    //console.log(event);
    const getEventIcon = (type) => {
      switch(type) {
        case 'ISSUE_CREATION': return AlertTriangle;
        case 'MEETING': return Users;
        case 'WARNING': return Clock;
        case 'FEEDBACK': return MessageSquare;
        case 'TERMINATION': return XCircle;
        case 'EMAIL': return Mail;
        default: return CheckCircle;
      }
    };

    const getStatusClass = (status) => {
      switch(status) {
        case 'COMPLETED': return 'success';
        case 'ACTIVE': return 'warning';
        default: return 'secondary';
      }
    };
    
    const statusClass = getStatusClass(event.status);
  
   // const Icon = LucideIcons[event.type] || LucideIcons.CheckCircle;

    const Icon = getEventIcon(event.type);
  
  return (
    <div className="d-flex timeline-event">
      <div className="timeline-icon-wrapper">
        <div className="timeline-line"></div>
        <div className={`timeline-icon-circle ${event.status.toLowerCase()}`}>
        <Icon size={20} />
        </div>
    </div>
      <div className="timeline-content">
        <div className="performanceCard">
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <h5 className="mb-1">{formatConstantCase(event.type)}</h5>
              <small className="text-muted">{event.createdAt}</small>
            </div>
            <span className={`status-badge ${event.status.toLowerCase()}`}>
              {event.status}
            </span>
          </div>
          <p className="timeline-description">{event.title}</p>
          <p className="timeline-description">{event.description}</p>
          {event.deadline && (
            <div className="timeline-deadline">
              <LucideIcons.Clock size={16} className="me-2" />
              <small className='pl-2'>Deadline: {event.deadline}</small>
            </div>
          )}
          {event.participants && (
                <div className="mt-2 flex items-center text-sm text-gray-500">
                {/* <Users className="h-4 w-4 mr-1" /> */}
                Participants: {event.participants.join(", ")}
                </div>
          )}
        </div>
      </div>
    </div>
  );
};



  return (
    <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className="">
        <Row>
          <div className="theme_header">
            <Header
              ncount={opencount}
              {...props}
              className="tick-timer-header"
              quickactionblock={false}
            />
          </div>
          <div className="SideNavbar">
            <SideMenu
              activeitem="report"
              showmenu={showMenu}
              hidemenu={hideMenu}
            />
          </div>
          <div className="themeContentBody performance-dashboard">
          <div className="performance-issue-view py-4">
      
                <div className="card mb-4 pt-2 pb-4">
                    <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6 className="card-title mb-0">Performance Issue Tracking</h6>
                        <span className="badge bg-primary fw-large fs-4">{issue?.issueID>0 && `PI-${issue.issueID}`}</span>
                    </div>
                    </div>
                    <div className="card-body">
                    <div className="row pl-4 pr-4 "> 
                        <div className="col-md-4">
                            <p className="text-muted  medium mb-1">{issue.issueMakerRole}</p>
                            <p className="fw-medium mb-0">{issue.issueMakerName}</p>
                        </div>
                        <div className="col-md-4">
                            <p className="text-muted medium mb-1">Status</p>
                            <p className="fw-medium mb-0">{issue.status}</p>
                        </div>
                        <div className="col-md-4">
                            {/* Resolution Status */}
                            {issue?.timeline?.length > 0 && (
                                <ResolutionStatus 
                                    status={issue.status} 
                                    warningCount={issue.timeline.filter(e => e.type === 'WARNING_ISSUED').length} 
                                />
                            )}
                        </div>
                    </div>
                    </div>
                </div>

                

                {/* Required Actions */}
                {getWorkflowSteps(issue.currentStep, currentUserRole) && (
                        <ActionPanel 
                        {...getWorkflowSteps(issue.currentStep, currentUserRole)}
                        onAction={handleActionClick}
                        isLoading={isLoading}
                        />
                )}

                {/* Timeline */}
                {issue?.timeline?.length > 0 && 
                (
                    <div className="position-relative">
                        {issue.timeline.map((event, index) => (
                        <TimelineEvent 
                            key={event.id} 
                            event={event} 
                            isLast={index === issue.timeline.length - 1} 
                        />
                        ))}
                    </div>
                )}
                
                </div>
          </div>
        </Row>
      </Container>


      {/* <MeetingScheduleModal 
        show={activeModal === 'meeting'}
        onHide={() => setActiveModal(null)}
        onSubmit={handleMeetingSubmit}
      />
      
      <WarningIssueModal
        show={activeModal === 'warning'}
        onHide={() => setActiveModal(null)}
        onSubmit={handleWarningSubmit}
      />

      <FeedbackRequestModal
        show={activeModal === 'feedback'}
        onHide={() => setActiveModal(null)}
        onSubmit={handleFeedbackSubmit}
      />

      <TerminationRequestModal
        show={activeModal === 'termination'}
        onHide={() => setActiveModal(null)}
        onSubmit={handleTerminationSubmit}
      /> */}


      {/* Modals */}
      <MeetingScheduleDrawer 
        isOpen={activeDrawer === 'meeting'}
        toggle={toggleDrawer}
        onSubmit={async (data) => {
          setIsLoading(true);
          try {
            await scheduleMeeting(data);
            toggleDrawer();
          } catch (error) {
            console.error('Failed to schedule meeting:', error);
          } finally {
            setIsLoading(false);
          }
        }}
        isLoading={isLoading}
      />
      
      <WarningIssueDrawer
        isOpen={activeDrawer === 'warning'}
        toggle={() => setActiveDrawer(null)}
        onSubmit={handleWarningSubmit}
        isLoading={isLoading}
        designer={{
            id: issue.id,
            name: issue.designer
        }}
      />

     <FeedbackRequestDrawer
        isOpen={activeDrawer === 'feedbackRequest'}
        toggle={() => setActiveDrawer(null)}
        onSubmit={handleFeedbackRequestSubmit}
        isLoading={isLoading}
      />

     <FeedbackSubmissionDrawer
        isOpen={activeDrawer === 'feedbackSubmission'}
        toggle={() => setActiveDrawer(null)}
        onSubmit={handleFeedbackSubmit}
        isLoading={isLoading}
        feedbackPoints={['Quality of Work', 'Communication', 'Timeline Adherence']}
      />

      <TerminationDrawer
        isOpen={activeDrawer === 'termination'}
        toggle={() => setActiveDrawer(null)}
        onSubmit={handleTerminationSubmit}
        isLoading={isLoading}
      />

    </div>
  );

};

export default PerformanceTimeline;