import React, { useState, useEffect } from "react";
import { Card, Col } from "reactstrap";
import ProfileProgressBar from "./ProfileProgressbar";
import FeedbackDrawer from "./FeedbackForm";
import MaterialIcon from "material-icons-react";

const PerformanceCard = ({ member, storageUrl, availableReport }) => {
  const convertTo10PointScale = (score, maxScore = 60) => {
    const convertedScore = (score / maxScore) * 10;
    return Math.round(convertedScore * 10) / 10;
  };

  const [imageLoaded, setImageLoaded] = useState(false);
  const progressPercentage =
    (convertTo10PointScale(member.performance) / member.maxScore) * 100;
  const progressColor = progressPercentage >= 75 ? "#4ade80" : "#facc15";
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const getInitials = (name) => {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();
  };

  const checkImageExists = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  useEffect(() => {
    const imageUrl = `${storageUrl}storage/profile/image/${member.id}.jpg`;
    checkImageExists(imageUrl).then((exists) => setImageLoaded(exists));
  }, [member.id, storageUrl]);

  const toggleFeedbackForm = () => {
    setShowFeedbackForm(!showFeedbackForm);
  };

  const ProfileImage = () => {
    const imageUrl = `${storageUrl}storage/profile/image/${member.id}.jpg`;
    const initials = getInitials(member.name);

    return (
      <div
        className="profile-image-container position-relative"
        style={{ width: "44px", height: "44px" }}
      >
        {imageLoaded ? (
          <img
            src={imageUrl}
            alt={member.name}
            className="rounded-circle position-absolute top-0 start-0"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        ) : (
          <div
            className="initials-placeholder rounded-circle bg-primary text-white d-flex align-items-center justify-content-center position-absolute top-0 start-0"
            style={{
              width: "100%",
              height: "100%",
              fontSize: "1.2rem",
            }}
          >
            {initials}
          </div>
        )}
      </div>
    );
  };

  return (
    <Col xs={12} sm={12} md={6} lg={6} xl={4}>
      <div className={`performanceCard`}>
        
        {member.currentRatingCount == 6 ?
        (
          <>
            <div className="bg-emerald-600 rounded-full p-4 feedback-completed-icon">
              <MaterialIcon icon={'check_cirle'}   />
              </div>
              
              <div className="text-center space-y-2">
                <h2 className="text-2xl font-semibold" style={{ fontSize: "18px", fontWeight: "300" }}>
                  Thank you for your feedback
                </h2>
                <p className="text-2xl font-semibold">
                  on <span className="feedback-membername">{member.name}</span>
                </p>
              </div>
          </>
        ):
        (
          <>
              <div className="d-flex align-items-center border-bottom border-whitish border-1 pb-3">
                <ProfileImage />
                <div className="ml-2 performance-member" style={{ marginTop: "7px" }}>
                  <h5 className="mb-0">{member.name}</h5>
                  <small className="text-muted member-name">{member.role}</small>
                </div>
              </div>
              <div className="mb-4">
                <div className="d-flex justify-content-between align-items-center mb-2 pt-3">
                  <div className="d-flex ">
                    <div>
                      <span className="past-month-label">Past Month Performance</span>
                      <ProfileProgressBar
                        percentage={progressPercentage}
                        color={progressColor}
                        progresstype="performance"
                      />
                    </div>
                    <span
                      className="ml-2 inline-block"
                      style={{ color: "#666666", marginTop: "18px" }}
                    >
                      {convertTo10PointScale(member.performance)}/{member.maxScore}
                    </span>
                  </div>
                  <div className="d-flex d-flex mt-3 mr-2">
                    <div
                      className="d-flex align-items-center"
                      style={{ marginRight: "-8px" }}
                    >
                      {[...Array(2)].map((_, i) => (
                        <div
                          key={i}
                          className="rounded-circle bg-secondary"
                          style={{
                            width: "27px",
                            height: "27px",
                            marginRight: "-10px",
                            border: "2px solid white",
                          }}
                        />
                      ))}
                      <span
                        className="ms-1 text-white bg-success rounded-circle d-flex align-items-center justify-content-center"
                        style={{
                          width: "27px",
                          height: "27px",
                          fontSize: "11px",
                          fontWeight: "500",
                          border: "2px solid white",
                        }}
                      >
                        +{member.reviewers}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
          </>
        )}
        {availableReport && (
          <button
            className={`btn btn-outline-success w-100 ${member.skip_rating === 1 ? 'rating_skipped' : ''}`}
            style={{
              fontSize: "14px",
              fontWeight: "500",
            }}
            onClick={toggleFeedbackForm}
          >
            {member.skip_rating === 1 ? 'Skipped' : ( member.currentRatingCount == 6 ? "View Feedback" : "Give Feedback")}
          </button>
        )}
        {showFeedbackForm && (
          <FeedbackDrawer
            isOpen={showFeedbackForm}
            onClose={toggleFeedbackForm}
            employeeName={member.name}
            userID={member.id}
            currentRatingCount={member.currentRatingCount}
            skippedRating={member.skip_rating}
          />
        )}
      </div>
    </Col>
  );
};

export default PerformanceCard;
