import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import "./Task.css";
import {Input, Container, Row, Col, Card } from "reactstrap";
import Header from "../../header/Header";
import { urls } from "../../../services/UrlList";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import TaksSingle from "./task-single/TaskSingle";
import PageTitle from "../../page-title/PageTitle";
import Alertlogmsg from "../../messages/Alertlogmsg";
import LogAmend from "./task-single/LogAmend";
import {isMobile} from 'react-device-detect';
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper";
import {motion} from 'framer-motion';
import TrelloCard from "./task-single/TrelloCard";
import ConfirmAction from "../../admincomment/ConfirmAction";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ScrollTop from '../scrollTop'
import { Scrollbars } from 'react-custom-scrollbars';
import toast, { Toaster } from 'react-hot-toast';

const card = "carddetails : ";
const notifyError = () => toast('Something went wrong, please try again.');

export default class Task extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalTaks: [1, 2, 3],
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      myName: "",
      teamId:'',
    
      showProgress: true,
      tasksDueToday: [],
      tasksDueTomorrow: [],
      tasksDueUpcoming: [],
      tasksOverdue: [],
      tasksInternal:[],
      tasksDsmm:[],
      process: "",
     
      runningTaskStatus: 0,
      pauseTaskStatus: 0,
      showRunningMsg: false,
      showLogProgress:false,
      expandViewId: 0,
      runningMsgText:
        "Looks like one task is already running, please end it first to start a new one.",
      runningMsgTextStatus:
        "Looks like the task is still running. Please end the task first, to change assignee or mark as completed.",
      statusChangeMsg: "Status of task has been updated in our system.",
      showLogAmend: false,
      notLoggedinMsg:'You must have an active shift to work on tasks. Please start your shift.',
      breakNotEndedMsg:'Please end your break before starting the task.',
      trelloIdWrong:'Looks like the Trello card id linked with this task is not correct. Please check with your Project Manager.',
      notAuthorizedTask:false,
      notAuthorizedMsg:"Basecamp API linked with Kimphub don't have access to add this task. Please contact administrator to grant access and add task again.",
      trelloWrong:false,
      logAmendId: 0,
      logAmendTId:0,
      logAmendTask:'',
      logAmendProjectId: 0,
      projectAssignees: [],
      taskAssignees: "",
      taskAssigneeArray: "",
      showAssigneefetchStatus: false,
      showStatusChangeMsg: false,
      showAddTaskModalBox: true,
      showNotLoggedinMsg:false,
      taskStillRunning:false,
      breakStillRunning:false,
      showCompletion:false,
      pManager:0,

      cardDisplayNum:0,
      CardDisplayTop:30,
      taskCloseCount:0,
      taskCloseWait:false,
      taskCloseMsg:'You can close a maximum of 5 tasks at a time. Please wait for 10 seconds to close more.',

      cardDetails1: {},
      cardComments1: [],
      cardAttachments1: [], 
      displayTrelloCard1:false,

      cardDetails2: {},
      cardComments2: [],
      cardAttachments2: [], 
      displayTrelloCard2:false,

      cardDetails3: {},
      cardComments3: [],
      cardAttachments3: [], 
      displayTrelloCard3:false,

      cardDetails4: {},
      cardComments4: [],
      cardAttachments4: [], 
      displayTrelloCard4:false,

      cardDetails5: {},
      cardComments5: [],
      cardAttachments5: [], 
      displayTrelloCard5:false,

      cardDetails6: {},
      cardComments6: [],
      cardAttachments6: [], 
      displayTrelloCard6:false,

      cardDetails7: {},
      cardComments7: [],
      cardAttachments7: [], 
      displayTrelloCard7:false,
      showInternalCommentBox:false,
      internalComment:'',
      commenttask_id:'',
      commentproject_id:'',
      commenttask_name:'',
      commentproject_name:'',
      commenttid_cpt:'',
      commentInternalError:false,
      submissionProgress:false,
      showCompletionDialogue:false,
      ctid:'',
      ctidcpt:'',
      cpid:'',
      ctype:'',
      openNCount:0,
      showMenu:true,
    };
  }

  componentDidMount = () => {
    document.title = "HUB - My Assignments ";    
    this.checkUserPrivilege();
    this.renderContentDevice(); 
    //this.dotoTracking();
    const _this = this;
    setTimeout(function() {
      //Start the timer
      _this.showMyTasks();
    }, 1000);
    this.taskinterval = setInterval(() => this.showMyTasks(),360000);
    window.addEventListener("storage", this.onRefreshMessage);
  };

  onRefreshMessage = (ev) => {
      if (localStorage.getItem("enrefresh") == 'refreshplz')
      {
          localStorage.setItem('enrefresh','');
          this.showMyTasks();
      }
  }

  notificationChecker = () =>
  {
    const {myProp, match: {params}} = this.props; 
    if(myProp)
    {
      const _this = this;
      var channel = myProp.subscribe('Hub-Tasks');
      channel.bind('hub-event', function(data) {
        if(data.id === myProp.connection.socket_id)
        {
            _this.setState({
            openNCount: _this.state.openNCount +1
          })
        }
      });
   }
  }
 

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name,
        teamId: login.user_team
      });
      if (login.privileges.includes("mark_task_completed")) {
        this.setState({ showCompletion: true });
      }
    }
  };

  // renderContentDevice = () => {
  //   if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
  //       this.setState({isMobileView:1});
  //   } 
  //   if (isMobile) {
  //     this.setState({showMenu:false});
  //   }  
  // };
  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
    document.title = "HUB - My Assignments"; 
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  componentWillUnmount = () =>
  {
    this.showMyTasks();
    clearInterval(this.taskinterval);
    window.removeEventListener("storage", this.onRefreshMessage);
  }


  componentDidUpdate(prevState) {
    if (
      prevState.showStatusChangeMsg !== this.state.showStatusChangeMsg &&
      this.state.showStatusChangeMsg === true
    ) {
      const _this = this;
      setTimeout(function() {
        _this.setState({ showStatusChangeMsg: false });
      }, 6000);
    }

    if (
      prevState.showRunningMsg !== this.state.showRunningMsg &&
      this.state.showRunningMsg === true
    ) {
      const _this = this;
      setTimeout(function() {
        _this.setState({ showRunningMsg: false });
      }, 6000);
    }

    if (
      prevState.showNotLoggedinMsg !== this.state.showNotLoggedinMsg &&
      this.state.showNotLoggedinMsg === true
    ) {
      const _this = this;
      setTimeout(function() {
        _this.setState({ showNotLoggedinMsg: false });
      }, 6000);
    }

    if (
      prevState.taskStillRunning !== this.state.taskStillRunning &&
      this.state.taskStillRunning === true
    ) {
      const _this = this;
      setTimeout(function() {
        _this.setState({ taskStillRunning: false });
      }, 6000);
    }

    if (
      prevState.trelloWrong !== this.state.trelloWrong &&
      this.state.trelloWrong === true
    ) {
      const _this = this;
      setTimeout(function() {
        _this.setState({ trelloWrong: false });
      }, 6000);
    }

    if (
      prevState.notAuthorizedTask !== this.state.notAuthorizedTask &&
      this.state.notAuthorizedTask === true
    ) {
      const _this = this;
      setTimeout(function() {
        _this.setState({ notAuthorizedTask: false });
      }, 10000);
    }

    if (
      prevState.taskCloseWait !== this.state.taskCloseWait &&
      this.state.taskCloseWait === true
    ) {
      const _this = this;
      setTimeout(function() {
        _this.setState({ taskCloseWait: false, taskCloseCount:0});
      }, 10000);
    }
   
  }

  showMyTasks() {
    fetch(urls.getMyProjectTasks, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {    
        this.setState({
          tasksDueToday: (response['today'] && response['today'][0].project_id)? this.sortMyTasks(response['today']):'',
          tasksDueTomorrow: (response['tomorrow'] && response['tomorrow'][0].project_id)? this.sortMyTasks(response['tomorrow']):'',
          tasksDueUpcoming: (response['upcoming'] && response['upcoming'][0].project_id)? this.sortMyTasks(response['upcoming']):'',
          tasksOverdue: (response['overdue'] && response['overdue'][0].project_id)? this.sortMyTasks(response['overdue']):'',
          tasksInternal: (response['internal'] && response['internal'][0].project_id)? this.sortMyTasks(response['internal']):'',
          tasksDsmm: (response['dsmm'] && response['dsmm'][0].project_id)? this.sortMyTasks(response['dsmm']):'',
          runningTaskStatus: response.task_running,
          pauseTaskStatus: response["task_paused"],
          showProgress: false
        });
      })
      .catch(error => {        
        this.setState({
          tasksDueToday: [],
          tasksDueTomorrow: [],
          tasksDueUpcoming: [],
          tasksOverdue: [],
          runningTaskStatus: "",
          pauseTaskStatus: "",
          showProgress: false
        });
      });
  }

  sortMyTasks = (response) =>
  {
    let myarr = response;
    myarr.sort(function (a, b) {
      return parseInt(b.running_status) - parseInt(a.running_status);
    });
   return myarr;   
  }

  fetchMyNewTasks = () => {
    this.setState({
      showProgress: true
    });
    fetch(urls.getMyNewTasks + this.state.myBaseId, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        this.setState({
          showProgress: true
        });
        this.showMyTasks();
      })
      .catch(error => {
        this.setState({
          showProgress: false
        });
        this.showMyTasks();
      });
  };

  fetchMyNewDotoTasks = () => {
    fetch(urls.getMyDotoTasks + this.state.myBaseId, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        this.showMyTasks();
      })
      .catch(error => {
        this.showMyTasks();
      });
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

  triggerActions = (tidcpt, process, type, taskid, projectid, taskname, projectname) => {
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.setState({
      showProgress: true,
      process: process
    });
    const _this = this;
    setTimeout(function() {
      if (_this.state.process === "start") {
        var url = urls.startProjectTask;
        if (_this.state.runningTaskStatus === 1) {
          _this.setState({
            showRunningMsg: true,
            showProgress: false
          });
          return false;
        }
      } else if (_this.state.process === "end") {
        var url = urls.endProjectTask;
      }

      let opts = {
        task_id:taskid,
        tid_cpt: tidcpt,
        user_id: _this.state.myId,
        mname: _this.state.myName,
        project_id: projectid,
        project_name: projectname,
        task_name:taskname 
      };
      fetch(url, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method: "post",
        body: JSON.stringify(opts)
      })
        .then(response => response.json())
        .then(response => {        
          response.success === 10 && notifyError();
          localStorage.setItem('enrefresh','refreshplz');
          _this.setState({
            showProgress: false,
            showNotLoggedinMsg: response.success === 2 ? true:false,
            breakStillRunning: response.success === 3 ? true:false,
            // tasksDueToday: [],
            // tasksDueTomorrow:[],
            // tasksDueUpcoming: [],
            // tasksOverdue: [],
            // tasksInternal: [],
            // runningTaskStatus: 0,
          });
         _this.showMyTasks();
         if(response.success !== 2 && response.success !== 3)
         {
          _this.setState({
            showProgress: false,
           });
          //window.location.reload();
         }         
          
        })
        .catch(error => {
          _this.setState({
            showProgress: false
          });
        });
    }, 1000);
  };


  triggerActionsInternal = (tidcpt, process, type, taskid, projectid, taskname, projectname) => {
    this.setState({
      process: process
    });
    const _this = this;
    setTimeout(function() {
      if (_this.state.process === "start") {
        var url = urls.startProjectTask;
        if (_this.state.runningTaskStatus === 1) {
          _this.setState({
            showRunningMsg: true,
            showProgress: false
          });
          return false;
        }
        _this.setState({
          showProgress: true
        });
        let opts = {
          task_id:taskid,
          tid_cpt: tidcpt,
          user_id: _this.state.myId,
          mname: _this.state.myName,
          project_id: projectid,
          project_name: projectname,
          task_name:taskname 
        };

        fetch(url, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
          },
          method: "post",
          body: JSON.stringify(opts)
        })
          .then(response => response.json())
          .then(response => { 
            localStorage.setItem('enrefresh','refreshplz');
            _this.showMyTasks();     
            _this.setState({
              showProgress: true,
              showNotLoggedinMsg: response[0].error === 2 ? true:false,
              breakStillRunning: response[0].error === 3 ? true:false,
            });

          //  if(response.success == 1)
          //  {
          //    console.log('entered');
          //   //window.location.reload();
          //   _this.showMyTasks();
          //  }         
            
          })
          .catch(error => {
            _this.setState({
              showProgress: false
            });
          });

      } else if (_this.state.process === "end") {
        //this.endInternalTask(taskid, projectid, taskname);
        _this.setState({
            commentproject_id:projectid,
            commenttask_id: taskid,
            commenttask_name: taskname,
            commentproject_name:projectname,
            commenttid_cpt: tidcpt
        })
        _this.showInternalEndTaskComment();
      }
    }, 1000);
    ReactDOM.findDOMNode(this).scrollIntoView();
  };

  showInternalEndTaskComment = () =>
  {
      this.setState({showInternalCommentBox:true});
  }

  endInternalTask = () => {
   // ReactDOM.findDOMNode(this).scrollIntoView();
    if(this.state.internalComment.trim().length<=0)
    {
        this.setState({commentInternalError:true});
    }else
    {
      this.setState({submissionProgress:true})
      let opts = {
        task_id:this.state.commenttask_id,
        tid_cpt: this.state.commenttid_cpt,
        user_id: this.state.myId,
        mname: this.state.myName,
        project_id: this.state.commentproject_id,
        project_name: this.state.commentproject_name,
        task_name:this.state.commenttask_name ,
        internal_comment: this.state.internalComment
      };

      fetch(urls.endProjectInternalTask, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method: "post",
        body: JSON.stringify(opts)
      })
        .then(response => response.json())
        .then(response => {        
          this.setState({
            showProgress: true,
            showNotLoggedinMsg: response[0].error === 2 ? true:false,
            breakStillRunning: response[0].error === 3 ? true:false,
            commentInternalError:false,
            submissionProgress:false,
            showInternalCommentBox:false,
          });
        if(response[0].error !== 2 && response[0].error !== 3)
        {
          //window.location.reload();
        }         
          this.showMyTasks();
        })
        .catch(error => {
          this.setState({
            showProgress: false,
            submissionProgress:false,
          });
        });
    }
  };


  trigerAmendlog = (tid, id, pid, taskname) => {
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.setState({
      logAmendTId: tid,
      logAmendId: id,
      logAmendProjectId: pid,
      showAssigneefetchStatus: true,
      showLogProgress: true,
      userid: this.state.myId,
      logAmendTask: taskname
    });
    let opts = {
        task_id: tid,
        project_id: pid,
        user_id: this.state.myId,
        task_name: taskname
      };
    fetch(urls.getProjecTaskaccess, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method: "post",
        body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        this.setState({
          projectAssignees: response["project_assignees"],
          taskAssignees: response["task_assignees"],
          showAssigneefetchStatus: false,
          showLogAmend: !this.state.showLogAmend,
          displayTrelloCard:false,
          showLogProgress: false
        });
      })
      .catch(error => {
        this.setState({
          showAssigneefetchStatus: false,
          showLogProgress: false
        });
      });     
  };

  handleassigneeupdate = (assignees, status) => {
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.setState({
      showProgress: true,
      showLogAmend: !this.state.showLogAmend
    });
    let opts = {
      assignees: assignees,
      task_id: this.state.logAmendId,
      tid_cpt: this.state.logAmendTId,
      task_name:this.state.logAmendTask,
      project_id: this.state.logAmendProjectId,
      status: status,
      name:this.state.myName,
      creator: this.state.myId
    };

    console.log(opts);

    fetch(urls.projectTaskLogandReassign,{
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      method: "post",
      body: JSON.stringify(opts)
    })
    .then(response => response.json())
      .then(response => {      
        this.setState({          
          taskStillRunning: response.error === 1? true:false,
          logAmendId: "",
          logAmendTask:"",
          logAmendProjectId: "",
          showAssigneefetchStatus: false,
          showStatusChangeMsg: response.error !== 1? true:false,
          showProgress: false
        });
         this.showMyTasks();       
         //window.location.reload(); 
      })
      .catch(error => {
        this.setState({
          logAmendId: "",
          logAmendTask:"",
          logAmendProjectId: "",
          showAssigneefetchStatus: false,
          showStatusChangeMsg: false,
          showProgress: false
        });
        this.showMyTasks();
       // window.location.reload();
      });;

  };

  handleTextChange = ({ target }) =>
  {
    this.setState({[target.name] : target.value, commentInternalError:false});
  }

  handleQuickCompletion = (tid, tid_cpt, pid, type) => {
      this.setState({
        ctid:tid,
        ctidcpt:tid_cpt,
        cpid:pid,
        ctype:type,
        showCompletionDialogue:true
      })
  }
  handleQuickCompletionAction = () => {
    if(this.state.taskCloseCount<5)
    {
      let opts = {
        tid: this.state.ctid,
        task_id: this.state.ctidcpt,
        project_id: this.state.cpid,
        creator:this.state.myId,
        status:2
      };
      fetch(urls.updateClientTaskStatus,{
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method: "post",
        body: JSON.stringify(opts)
      })
      .then(response => response.json())
        .then(response => {      
  
            
        })
        .catch(error => {
            console.log("error");
        });
        
      if(this.state.ctype=="today")
      {
        let temp = this.state.tasksDueToday;
        var idx = this.state.tasksDueToday.map((el) => el.task_id).indexOf(this.state.ctid);
        temp.splice(idx, 1);
        this.setState({tasksDueToday: temp, taskCloseCount: this.state.taskCloseCount +1});
        this.closeDialogue();
      }else if(this.state.ctype=="tomorrow")
      {
        let temp = this.state.tasksDueTomorrow;
        var idx = this.state.tasksDueTomorrow.map((el) => el.task_id).indexOf(this.state.ctid);
        temp.splice(idx, 1);
        this.setState({tasksDueTomorrow: temp, taskCloseCount: this.state.taskCloseCount +1});
        this.closeDialogue();
      }else if(this.state.ctype=="upcoming")
      {
        let temp = this.state.tasksDueUpcoming;
        var idx = this.state.tasksDueUpcoming.map((el) => el.task_id).indexOf(this.state.ctid);
        temp.splice(idx, 1);
        this.setState({tasksDueUpcoming: temp, taskCloseCount: this.state.taskCloseCount +1});
        this.closeDialogue();
      }else if(this.state.ctype=="overdue")
      {
        let temp = this.state.tasksOverdue;
        var idx = this.state.tasksOverdue.map((el) => el.task_id).indexOf(this.state.ctid);
        temp.splice(idx, 1);
        this.setState({tasksOverdue: temp, taskCloseCount: this.state.taskCloseCount +1});
        this.closeDialogue();
      }
    }else
    {
        this.setState({taskCloseWait:true})
        this.closeDialogue();
    }
  };

  closeDialogue = () => {
    this.setState({
      showCompletionDialogue: false,
      ctid:'',
      cpid:'',
      ctype:''
    });
  };

  closeAmendPopup = () => {
    this.setState({
      projectAssignees: [],
      taskAssignees: [],
      taskAssigneeArray: [],
      showAssigneefetchStatus: false,
      showLogAmend: !this.state.showLogAmend
    });
  };

  closeInternalPopup = () =>
  {
    this.setState({
      showInternalCommentBox: !this.state.showInternalCommentBox
    });
  }
  showAddTaskModal = () => {
    this.setState({ showAddTaskModalBox: true });
  };

  handleTaskUpdate = () => {
    this.setState({ showAddTaskModalBox: true});
    window.location.reload();
  };
  hideTaskAddBox = () =>
  {  
     this.setState({ showAddTaskModalBox: true });
  }



  /* Trello */

  showTrelloCard = (id)=>
  {
    this.setState({
      cardId:id,
       cardDisplayNum: this.state.cardDisplayNum + 1,
       cardDisplayTop: this.state.cardDisplayTop + 10,
    });
    const _this = this;
    setTimeout(function() {
        _this.getCardDetailsById();
    },100);
  }

  getCardDetailsById = () => {        
          fetch(urls.getTrelloBoardCardById +this.state.cardId)
          .then(response => response.json())
          .then(response => {
            response[0].showEditCardDescription = false;
            this.setState({
              ['cardDetails'+this.state.cardDisplayNum]: response[0],
              showProgress: false, 
              ['displayTrelloCard'+this.state.cardDisplayNum]:true,        
            });           
            this.getCardComments();
          })
          .catch(error => {
            this.setState({              
              showProgress: false,
              trelloWrong:true,
            });
          });
  };

  getCardComments = () => {
    fetch(urls.getTrelloBoardCardComments + this.state.cardId)
      .then(response => response.json())
      .then(response => {
        this.setState({
          ['cardComments'+this.state.cardDisplayNum]: response,
          showProgress: false
        });
        this.getCardAttachments();
      })
      .catch(error => {
        this.setState({
          showProgress: false
        });
      });
  };

  getCardAttachments = () => {
    fetch(urls.getTrelloBoardCardAttachments + this.state.cardId)
      .then(response => response.json())
      .then(response => {
        this.setState({
          ['cardAttachments'+this.state.cardDisplayNum]: response,
          showProgress: false
        });
      })
      .catch(error => {
        this.setState({
          showProgress: false
        });
      });
  };

  closeCard = () =>
  {
      this.setState({
        ['displayTrelloCard'+this.state.cardDisplayNum]:false,
        cardDisplayNum: this.state.cardDisplayNum -1,
        cardDisplayTop: this.state.cardDisplayTop - 10,
      });
  }

  render() {
    const list = {
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.11,
        } 
      },
      hidden: { 
        opacity: 0,
        transition: {
          when: "afterChildren",
        } 
      },
    }

    const item = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: -20 },
    }

    return (
      <div className="fullwidth_mobilemenu mobileflex">
      
      <Container fluid className="top-bar-inactive">

      <Row>
        <div className="theme_header">
        <Header
                   ncount={this.state.openNCount}
                   {...this.props} 
                    classname="tick-timer-header"
                    logout={this.signOut.bind(this)}
                    onmenuaction={this.toggleSidebar.bind(this)}
                  />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="tasks" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
        <Toaster position="top-right" containerClassName="notifier"
            toastOptions={{
              // Define default options
              className: '',
              duration: 5000,
              style: {
                background: '#363636',
                color: '#fff',
              },}}
        />
        <Row>
            <Col sm="12">
            <section
                  id="all-tasks"
                  className={this.state.runningTaskStatus !== 1 ? "task-in-active" : "task-active-running"}
                >
                <div className="task-wrapper projects-mytasks myassiments_wrapper">
                <div className="easy_tab_access_task">
                  <PageTitle title="My Assignments" />
                  <ul>
                    {(this.state.tasksOverdue && this.state.tasksOverdue.length>0) &&
                    (
                      <li><AnchorLink href='#overdue'>Overdue </AnchorLink></li>
                    )}
                    {(this.state.tasksDueToday && this.state.tasksDueToday.length>0) &&
                    (
                      <li><AnchorLink href='#due-today'>Today</AnchorLink></li>
                    )}
                    {(this.state.tasksDueTomorrow && this.state.tasksDueTomorrow.length>0) &&
                    (
                      <li><AnchorLink href='#due-tomorrow'>Tomorrow </AnchorLink></li>
                    )}
                    {(this.state.tasksDueUpcoming && this.state.tasksDueUpcoming.length>0) &&
                    (
                      <li><AnchorLink href='#upcoming'>Upcoming </AnchorLink></li>
                    )}
                    <span className="internal-seperator"></span>
                    {(this.state.tasksDsmm && this.state.tasksDsmm.length>0) &&
                    (
                      <li><AnchorLink href='#ictask'>Internal Cards </AnchorLink></li>
                    )}
                    {(this.state.tasksInternal && this.state.tasksInternal.length>0) &&
                    (
                      <li><AnchorLink href='#itask'>Internal Timers </AnchorLink></li>
                    )}
                  </ul>
                </div>
                
                {this.state.showRunningMsg ? (
                  <Alertlogmsg
                    msgtext={this.state.runningMsgText}
                    boxclass="task-running"
                  />
                ) : null}
                {this.state.showStatusChangeMsg ? (
                  <Alertlogmsg
                    msgtext={this.state.statusChangeMsg}
                    boxclass="task-status-changed"
                  />
                ) : null}
                {this.state.taskStillRunning ? (
                  <Alertlogmsg
                    msgtext={this.state.runningMsgTextStatus}
                    boxclass="task-running"
                  />
                ) : null}
                {this.state.showNotLoggedinMsg ? (
                  <Alertlogmsg
                    msgtext={this.state.notLoggedinMsg}
                    boxclass="task-running"
                  />
                ) : null}

                {this.state.breakStillRunning ? (
                  <Alertlogmsg
                    msgtext={this.state.breakNotEndedMsg}
                    boxclass="task-running"
                  />
                ) : null}

                {this.state.trelloWrong ? (
                  <Alertlogmsg
                    msgtext={this.state.trelloIdWrong}
                    boxclass="task-running"
                  />
                ) : null}

                {this.state.notAuthorizedTask ? (
                  <Alertlogmsg
                    msgtext={this.state.notAuthorizedMsg}
                    boxclass="task-running"
                  />
                ) : null}

                  {this.state.taskCloseWait ? (
                  <Alertlogmsg
                    msgtext={this.state.taskCloseMsg}
                    boxclass="task-running"
                  />
                ) : null}
                
                <LoadingWrapper isLoading={this.state.showLogProgress}></LoadingWrapper>
                <div className="d-flex task_full_list">

                <div id="overdue"></div>
                <section className="task-wrapper" onClick={this.hideTaskAddBox.bind(this)}>
                  <LoadingWrapper isLoading={this.state.showProgress}>
                    {this.state.tasksOverdue &&
                    this.state.tasksOverdue.length > 0 ? (
                      <>
                      <div className="head d-flex">
                        <h4>Overdue</h4>
                      </div>
                        <Row
                          initial="hidden"
                          animate="visible"
                          variants={list} >
                          {this.state.tasksOverdue.map((task, index) => {
                            return (
                             
                              <Col xs={12} sm={12} md={12} variants={item}>
                                <TaksSingle
                                  key={index}
                                  slno={index + 1}
                                  classname={
                                      task.running_status === 1
                                        ? "active"
                                        : ""
                                    }
                                  mtask={task}
                                  task_id={task.task_id}
                                  project_id={task.project_id}
                                  project_name={task.project_name}
                                  task_name={task.task_name}
                                  manager={task.manager}
                                  totaltoday={task.today_total}
                                  running_status={task.running_status}
                                  starttimer={task.running_status == 1? task.timmer : 0}
                                  myactions={this.triggerActions}
                                  taskamend={this.trigerAmendlog}
                                  showtrello={this.showTrelloCard}
                                  quickclose = {this.handleQuickCompletion}
                                  type="overdue"
                                  allowclose={this.state.showCompletion}
                                  myId={this.state.myId}
                                />
                              </Col>
                             
                            );
                          })}
                        </Row>
                      </>
                    ) : null}
                  </LoadingWrapper>
                </section>

                <div id="due-today">
                <section className="task-wrapper" onClick={this.hideTaskAddBox.bind(this)}>
                  <LoadingWrapper isLoading={this.state.showProgress}>
                    {this.state.tasksDueToday &&
                    this.state.tasksDueToday.length > 0 ? (
                      <>
                        <div className="head d-flex">
                          <h4>Due Today</h4>
                        </div>
                        <Row
                          initial="hidden"
                          animate="visible"
                          variants={list}>
                        { this.state.tasksDueToday.map((task, index) => {
                          return (
                            <Col xs={12} sm={12} md={12} variants={item}>
                              <TaksSingle
                                key={index}
                                slno={index + 1}
                                classname={
                                  task.running_status === 1
                                    ? "active"
                                    : ""
                                }
                                mtask={task}
                                task_id={task.task_id}
                                project_id={task.project_id}
                                project_name={task.project_name}
                                task_name={task.task_name}
                                manager={task.manager}
                                totaltoday={task.today_total}
                                running_status={task.running_status}
                                starttimer={task.running_status == 1? task.timmer : 0}
                                myactions={this.triggerActions}
                                taskamend={this.trigerAmendlog}
                                showtrello={this.showTrelloCard}
                                quickclose = {this.handleQuickCompletion}
                                type="today"
                                allowclose={this.state.showCompletion}
                                myId={this.state.myId}
                              />
                            </Col>
                          );
                        }) }
                        
                        </Row>
                      </>
                    ) : null}
                  </LoadingWrapper>
                </section>
                </div>
                <div id="due-tomorrow">
                <section onClick={this.hideTaskAddBox.bind(this)}>
                  <LoadingWrapper isLoading={this.state.showProgress}>
                    {this.state.tasksDueTomorrow &&
                    this.state.tasksDueTomorrow.length > 0 ? (
                      <>
                        <div className="head d-flex">
                          <h4>Due Tomorrow</h4>
                        </div>
                        <Row
                          initial="hidden"
                          animate="visible"
                          variants={list}
                        >
                        {this.state.tasksDueTomorrow.map((task, index) => {
                          return (
                            <Col xs={12} sm={12} md={12}  variants={item}>
                              <TaksSingle
                                key={index}
                                slno={index + 1}
                                classname={
                                  task.running_status === 1
                                    ? "active"
                                    : ""
                                }
                                mtask={task}
                                task_id={task.task_id}
                                project_id={task.project_id}
                                project_name={task.project_name}
                                task_name={task.task_name}
                                manager={task.manager}
                                totaltoday={task.today_total}
                                running_status={task.running_status}
                                starttimer={task.running_status == 1? task.timmer : 0}
                                myactions={this.triggerActions}
                                taskamend={this.trigerAmendlog}
                                showtrello={this.showTrelloCard}
                                quickclose = {this.handleQuickCompletion}
                                type="tomorrow"
                                allowclose={this.state.showCompletion}
                                ttt={task.timmer}
                                myId={this.state.myId}
                              />
                            </Col>
                          );
                        })}
                        </Row>
                      </>
                    ) : null}
                  </LoadingWrapper>
                </section>
                </div>
                <div id="upcoming"></div>
                <section onClick={this.hideTaskAddBox.bind(this)}>
                  <LoadingWrapper isLoading={this.state.showProgress}>
                    {this.state.tasksDueUpcoming &&
                    this.state.tasksDueUpcoming.length > 0 ? (
                      <>
                        <div className="head d-flex">
                          <h4>Upcoming</h4>
                        </div>
                        <Row
                            initial="hidden"
                            animate="visible"
                            variants={list}>
                          {this.state.tasksDueUpcoming.map((task, index) => {
                            return (
                              
                              <Col xs={12} sm={12} md={12} variants={item}>
                                <TaksSingle
                                  key={index}
                                  slno={index + 1}
                                  classname={
                                      task.running_status === 1
                                        ? "active"
                                        : ""
                                    }
                                  mtask={task}
                                  task_id={task.task_id}
                                  project_id={task.project_id}
                                  project_name={task.project_name}
                                  task_name={task.task_name}
                                  manager={task.manager}
                                  totaltoday={task.today_total}
                                  running_status={task.running_status}
                                  starttimer={task.running_status == 1? task.timmer : 0}
                                  myactions={this.triggerActions}
                                  taskamend={this.trigerAmendlog}
                                  showtrello={this.showTrelloCard}
                                  quickclose = {this.handleQuickCompletion}
                                  type="upcoming"
                                  allowclose={this.state.showCompletion}
                                  myId={this.state.myId}
                                   />
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    ) : null}
                  </LoadingWrapper>
                </section>
              
                <div id="ictask">
                <section
                className={((this.state.tasksDueUpcoming && this.state.tasksDueUpcoming.length >0) || 
                  (this.state.tasksDueTomorrow && this.state.tasksDueTomorrow.length >0 ) || 
                  (this.state.tasksDueToday && this.state.tasksDueToday.length >0  ) || 
                  (this.state.tasksOverdue && this.state.tasksOverdue.length >0)) ? 'hidden-task-block':''}
                onClick={this.hideTaskAddBox.bind(this)}>
                  <LoadingWrapper isLoading={this.state.showProgress}>
                    {this.state.tasksDsmm &&
                    this.state.tasksDsmm.length > 0 ? (
                      <>
                        <div className="head d-flex">
                          <h4>Internal Cards</h4>
                        </div>
                        <Row
                          initial="hidden"
                          animate="visible"
                          variants={list}
                        >
                        {this.state.tasksDsmm.map((task, index) => {
                          return (
                            <Col xs={12} sm={12} md={12}  variants={item}>
                              <TaksSingle
                                key={index}
                                slno={index + 1}
                                classname={
                                  task.running_status === 1
                                    ? "active"
                                    : ""
                                }
                                mtask={task}
                                task_id={task.task_id}
                                project_id={task.project_id}
                                project_name={task.project_name}
                                task_name={task.task_name}
                                manager={task.manager}
                                totaltoday={task.today_total}
                                running_status={task.running_status}
                                starttimer={task.running_status == 1? task.timmer : 0}
                                myactions={this.triggerActions}
                                taskamend={this.trigerAmendlog}
                                showtrello={this.showTrelloCard}
                                quickclose = {this.handleQuickCompletion}
                                type="dsmm"
                                allowclose={this.state.showCompletion}
                                ttt={task.timmer}
                                myId={this.state.myId}
                              />
                            </Col>
                          );
                        })}
                        </Row>
                      </>
                    ) : null}
                  </LoadingWrapper>
                </section>
                </div>

               <div id="itask">
                <section className="task-wrapper" onClick={this.hideTaskAddBox.bind(this)}>
                  <LoadingWrapper isLoading={this.state.showProgress}>
                    {this.state.tasksInternal &&
                    this.state.tasksInternal.length > 0 ? (
                      <>
                      <div className="head d-flex">
                        <h4>Internal Timers</h4>
                      </div>
                        <Row
                          initial="hidden"
                          animate="visible"
                          variants={list} >
                          {this.state.tasksInternal.map((task, index) => {
                            return (
                              <Col xs={12} sm={12} md={12} variants={item}>
                                <TaksSingle
                                  key={index}
                                  slno={index + 1}
                                  classname={
                                      task.running_status === 1
                                        ? "active"
                                        : ""
                                    }
                                  mtask={task}
                                  task_id={task.task_id}
                                  project_id={task.project_id}
                                  project_name={task.project_name}
                                  task_name={task.task_name}
                                  manager={task.manager}
                                  totaltoday={task.today_total}
                                  running_status={task.running_status}
                                  starttimer={task.running_status == 1? task.timmer : 0}
                                  myactions={this.triggerActionsInternal}
                                  taskamend={this.trigerAmendlog}
                                  showtrello={this.showTrelloCard}
                                  quickclose = {this.handleQuickCompletion}
                                  type="internal"
                                  allowclose={this.state.showCompletion}
                                  myId={this.state.myId}
                                />
                              </Col>
                            );
                          })}
                        </Row>
                      </>
                    ) : null}
                  </LoadingWrapper>
                </section>
                </div>
                </div>
             


                {this.state.showLogAmend ? (
                  <LogAmend
                    myname={this.state.myName}
                    myid={this.state.myBaseId}
                    managerid={this.state.pManager}
                    loading={this.state.showAssigneefetchStatus}
                    passignees={this.state.projectAssignees}
                    tassignees={this.state.taskAssignees}
                    taskassigneearray={this.state.taskAssigneeArray}
                    closeaction={this.closeAmendPopup}
                    onassigneupdate={this.handleassigneeupdate}
                  />
                ) : null}

                {this.state.displayTrelloCard1 ? (
                  <TrelloCard
                    carddetails={this.state.cardDetails1}
                    cardcomments={this.state.cardComments1}
                    cardattachments={this.state.cardAttachments1}
                    closecard={this.closeCard} 
                    cardclass="cardone"   
                    showtrellocard={this.showTrelloCard} 
                    cardtop="30px"                
                  />
                ) : null}

                {this.state.displayTrelloCard2 ? (
                  <TrelloCard
                    carddetails={this.state.cardDetails2}
                    cardcomments={this.state.cardComments2}
                    cardattachments={this.state.cardAttachments2}
                    closecard={this.closeCard}
                    cardclass="cardtwo" 
                    showtrellocard={this.showTrelloCard}
                    cardtop="42px" 
                  />
                ) : null}
                {this.state.displayTrelloCard3 ? (
                  <TrelloCard
                    carddetails={this.state.cardDetails3}
                    cardcomments={this.state.cardComments3}
                    cardattachments={this.state.cardAttachments3}
                    closecard={this.closeCard}
                    cardclass="cardthree" 
                    showtrellocard={this.showTrelloCard}  
                    cardtop="52px"
                  />
                ) : null}
                {this.state.displayTrelloCard4 ? (
                  <TrelloCard
                    carddetails={this.state.cardDetails4}
                    cardcomments={this.state.cardComments4}
                    cardattachments={this.state.cardAttachments4}
                    closecard={this.closeCard}
                    cardclass="cardfour"  
                    showtrellocard={this.showTrelloCard} 
                    cardtop="62px"
                  />
                ) : null}
                {this.state.displayTrelloCard5 ? (
                  <TrelloCard
                    carddetails={this.state.cardDetails5}
                    cardcomments={this.state.cardComments5}
                    cardattachments={this.state.cardAttachments5}
                    closecard={this.closeCard}
                    cardclass="cardfive" 
                    cardtop="72px"
                  />
                ) : null}

                {this.state.displayTrelloCard6 ? (
                  <TrelloCard
                    carddetails={this.state.cardDetails6}
                    cardcomments={this.state.cardComments6}
                    cardattachments={this.state.cardAttachments6}
                    closecard={this.closeCard}
                    cardclass="cardsix"   
                    cardtop="82px"
                  />
                ) : null}

                {this.state.displayTrelloCard7 ? (
                  <TrelloCard
                    carddetails={this.state.cardDetails7}
                    cardcomments={this.state.cardComments7}
                    cardattachments={this.state.cardAttachments7}
                    closecard={this.closeCard}
                    cardclass="cardseven"   
                    cardtop="92px"
                  />
                ) : null}

                {this.state.showInternalCommentBox &&
                (
                  <>
                    <div className="internal-comment container">
                      <div className="internal-comment-overlay" onClick={this.closeInternalPopup}></div>
                        <div className="internal-comment-box">
                        <LoadingWrapper isLoading={this.state.submissionProgress}></LoadingWrapper>
                            <label className="internal-comment-label">Please enter the details about the task done to end this task.</label>
                            <Input type="textarea" placeholder="Enter you comment" name="internalComment" onChange={this.handleTextChange} className={this.state.commentInternalError && 'field-error'} />
                            <div class="col-sm-12">
                              <button type="button" class="project-assignee-submit btn-green btn btn-secondary" onClick={this.endInternalTask}>Submit and Close</button>
                            </div>
                        </div>
                    </div>
                  </>
                )}
                {this.state.showCompletionDialogue ? (
                <ConfirmAction
                  purpose="change"
                  closeDialogue={this.closeDialogue}
                  show={this.state.showCompletionDialogue}
                  note="*This action cannot be undone."
                  content="Are you sure you want to mark this task as completed?"
                  handleCompletionConfirm={this.handleQuickCompletionAction}
                  resolveid=""
                  resolveStatus=""
                  adminid=""
                  confirmBoxAction="markascomplete"
                />
              ) : null}
              </div>
            </section>
            </Col>
        </Row>
        </div>
      </Row>
      <ScrollTop/>
    </Container>
   </div>
    ); 
  }
}
