import React, { Component, Fragment } from 'react';
import './basecampmodule.css' 
import './lightgallery.min.css'
import Header from '../header/Header';
import Homebackicon from '../../images/bc_homeicon.svg'
import {urls} from "../../services/UrlList";
import axios from 'axios';
import {Container, Row, Col, Breadcrumb, BreadcrumbItem, Table, Button, Input, Progress } from 'reactstrap';
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import AccessDenied from "./AccessDenied";
import { AccessCheck, AccessCheckBlock } from './AccessCheck';
import ProjectCurrentStatus from './ProjectCurrentStatus';
import AttachmentIcon from '../svgicon/AttachmentIcon';
import ConfirmAction from '../admincomment/ConfirmAction';
import MaterialIcon from "material-icons-react";
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';
import toast, { Toaster } from 'react-hot-toast';
import Linkify from 'linkifyjs/react';
import ReactHtmlParser from 'react-html-parser';
import { FileUploader } from "react-drag-drop-files";
import Addsubfolders from "./Addsub_folders"
import EditSubFolder from './EditSubFolder'; 
import { saveAs } from "file-saver";
import SideMenu from "../NewSideMenu/NewSideMenu";
import MailboxEmail from './MailboxEmail';
import moment from "moment";

const notifySuccess = () => toast('Asset links updated successfully.');
const notifyError = () => toast('Something went wrong, please try again.');
const notifyRenameSuccess = () => toast('Folder has been renamed successfully');
const notifyDeleteSuccess = () => toast('Folder deleted successfully');

const toolbarOptions = [
  ['bold', 'italic', 'underline'],        
  [{ 'color': [] }, { 'background': [] }],           
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],     
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }] ,
  ['link'],
  ['clean']    
];

const fileTypes = ["ai", "bmp", "gif",'ico','jpg','png','ps','psd','svg','tif','tiff',
					'jpeg','webp','eps','jfif','heic','heif','fnt','fon','otf','ttf',
					'epub', 'indd','zip', 'fig','mp4','xd','mov','aif', 'cda','mid',
					'midi','mp3','mpa','ogg','wav','wma','wpl','doc', 'docx','odt',
					'pdf','rtf','txt','wpd','ods','xls','xlsm','xlsx','key','odp',
					'pps','ppt','pptx', 'raw','webm','CR2','RW2', 'RAF', 'ERF' , 
					'NRW', 'GPR', 'SR2', 'SRF', 'SRW', 'NEF', 'ARW', 'KC2', 'psb','PSB','pfm','ttc'];



export default class Attachfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      addmember:false ,
      orginalUsers:[],
      selectedUser:'', 
      uploadingFiles:false,
      fileList:'',
      setFileList:'',
      myPrivileges: [],
      myId: '',
      myName: '',
      folderAssets:[],
      brandName:'',
      folderName:'',
      subFolderName:'',
      accessDenied:'',
      projectDetails:[],
      progressBar:true,
      uploadProgressLoader:false,
      deletionId:0,
      showDeleteConfirmation:false,
      showFolderDeleteConfirmation:false,
      assetLinks:'',
      editActive:false,
      uploadCount:0,
      uploadedCount:0,
      uploadingProgress:false,
      subFolderId:(this.props.match.params.sfid && this.props.match.params.sfid>0)? this.props.match.params.sfid : 0,
      newFolder:'',
      subFolders:[],
      showAllFolders:false,
      folderEditActive:false,
      folderEditName:'',
      folderEditId:'',
      fieldChanges:[],
      showMenu:true,
      showDownloadConfirmation:false,
      downloadMessage:false,
      activeEditId:0,
      activeEditText:'',
      timezone:'',
      options: {
        attributes: null,
        className: 'linkified',
        defaultProtocol: 'http',
        events: null,
        format: function (value, type) {
          return value;
        },
        formatHref: function (href, type) {
          return href;
        },
        ignoreTags: [],
        nl2br: false,
        tagName: 'a',
        target: {
          url: '_blank'
        },
        validate: true,
      }
};
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.toggleDisplay = this.toggleDisplay.bind(this);
    this.togglememberDisplay = this.togglememberDisplay.bind(this);
    this.reactTags = React.createRef();
    this.keyPress = this._handleKeyDown.bind(this);
  }
//faheem code----------------------------


modules = {
  toolbar: toolbarOptions,
  imageUploader: {
    upload: file => {
      return new Promise((resolve, reject) => {
        const data = new FormData();
        data.append('file', file);
        data.append('filename', file.name);
        data.append('filesize', file.size);
        data.append('filetype', file.type);
        data.append('folder_path', 'editor')
        data.append('user_id', 1);
        axios.post(urls.uploadProjectMediaEditor, data, { 
        })
        .then(res => {
          resolve(res.data.file_name);
        })
        .catch(error => {
            reject("Upload failed");
            console.error("Error:", error);
          });
      });
    }
  }
};

handleInit() {
  console.log("FilePond instance has initialised", this.pond);
}



toggleDisplay() {
  this.setState({
    display: !this.state.display
  });
}
togglememberDisplay() {
  this.setState({
    addmember: !this.state.addmember
  });
}

  componentDidMount () {
    document.title = "KIMPHUB - Brand Files";
    AccessCheck('access_projects_management_module');
    this.checkUserPrivilege();
    const _this = this;
    setTimeout(()=>
    {
      _this.getFolderAssets();
    }, 1000);
  };


  componentWillUnmount() {  
    this.checkUserPrivilege();
    this.getFolderAssets();
  }

  
  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      console.log(login);
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name,
        timezone: login.my_timezone
      });
    }
  };


  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  onFileUpload = (files)=>
  {
    for (let i = 0; i < files.length; i++) {
     console.log(files[i].file);
    }
   
  }

  getFolderAssets = () =>
  {
    const data = {
      project_id:this.props.match.params.pid,
      brand_id: this.props.match.params.bid,
      master_folder_id: this.props.match.params.fid,
      parent_folder_id:this.state.subFolderId,
      user_id: this.state.myId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.getBrandFolderAssets, data, {
        headers: headers
      })
      .then((response) => {
         this.setState({
          accessDenied: response.data.success === 10 ? true : false,
          projectDetails: response.data.success !== 10 && response.data.project_details[0],
          folderAssets: response.data.success !== 10 && response.data.assets,
          assetLinks: (response.data.success !== 10 && response.data.assets_links) ? response.data.assets_links:'',
          brandName: response.data.success !== 10 && response.data.brand_name,
          folderName: response.data.success !== 10 && response.data.folder_name,
          subFolderName:response.data.success !== 10 && response.data.sub_folder_name,
          subFolders: response.data.success !== 10 && response.data.subfolders,
          progressBar:false
         })
      })
      .catch((error) => {
        this.setState({
          accessDenied: true,
          projectDetails: [],
          folderAssets: [],
          brandName: '',
          folderName: '',
          progressBar:false
         })
      })
  }

  downloadAllAssets = () =>
  {
    this.setState({showDownloadConfirmation:false, downloadMessage:true});
    const data = {
      project_id:this.props.match.params.pid,
      brand_id: this.props.match.params.bid,
      master_folder_id: this.props.match.params.fid,
      parent_folder_id:this.state.subFolderId,
      user_id: this.state.myId,
      brandName: this.state.brandName,
      folderName: this.state.folderName,
      subFolderName: this.state.subFolderName
    };
    
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.downloadAllAssets, data, {
        headers: headers
      })
    .then((response) => 
    {
      this.setState({downloadMessage:false});
      if(response.data.filename!=='')
      {
        saveAs(urls.storageUrl+"storage/projects/downloads/"+response.data.filename,response.data.filename);
      }else
      {
        notifyError();
      }
    })
    .catch((error) => {
      this.setState({downloadMessage:false});
    })
  }

  addSubFolders = (name) =>
  {
    const data = {
      project_id:this.props.match.params.pid,
      brand_id: this.props.match.params.bid,
      master_folder_id: this.props.match.params.fid,
      parent_folder_id: this.state.subFolderId,
      folder_name:name,
      user_id: this.state.myId,
      project_name: this.state.projectDetails.board_name,
      brand_name: this.state.brandName,
      mainfolder:this.state.folderName
    };
    
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.addSubFolders, data, {
        headers: headers
      })
      .then((response) => {
        if(response.data.success === 1)
        {
          this.setState({subFolders: response.data.subfolders})
        }
      })
      .catch((error) => {
        
      })
  }

  ShowEditBox = (subfolder)=>
  {
    this.setState({
      folderEditActive: !this.state.folderEditActive,
      folderEditName:subfolder.folder_name,
      folderEditId:subfolder.id
    })
  }

  editFolderName = async (name, folderid, oldname) =>
  {
    if(name != oldname)
    {
      let fields = [];
      fields.push('SubFolder Name: '+oldname+" -> "+name);
     await this.setState({fieldChanges: fields});
    }
    const data = {
      project_id:this.props.match.params.pid,
      brand_id: this.props.match.params.bid,
      master_folder_id: this.props.match.params.fid,
      subfolder_id: this.state.subFolderId,
      editId: folderid,
      folder_name:name,
      user_id: this.state.myId,
      project_name: this.state.projectDetails.board_name,
      brand_name: this.state.brandName,
      mainfolder:this.state.folderName,
      fieldchange: this.state.fieldChanges
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.editSubFolder, data, {
        headers: headers
      })
      .then((response) => {
        if(response.data.success === 1)
        {
          notifyRenameSuccess();
          this.setState({
            subFolders: response.data.subfolders,
            folderEditActive: !this.state.folderEditActive,
            folderEditName:'',
            folderEditId:''
          })
        }
      })
      .catch((error) => {
        
      })
  }

  deleteAssetAsk = async (id) =>
  {
    this.setState({
      showDeleteConfirmation:true,
      deletionId:id
    })
  }
  
  deleteAsset = ()=>
  {
    const data = {
      project_id:this.props.match.params.pid,
      brand_id: this.props.match.params.bid,
      asset_id: this.state.deletionId,
      user_id: this.state.myId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.deleteBrandAsset, data, {
        headers: headers
      })
      .then((response) => {
          if(response.data.delete_response == 1)
          {
            this.reorderList(this.state.deletionId);
            this.setState({
              showDeleteConfirmation:false,
              deletionId:0
            })
          }
      })
      .catch((error) => {
       
      })
  }

  closeDialogue = () => {
    this.setState({
      showDeleteConfirmation: false,
      deletionId:0,
      showFolderDeleteConfirmation:false,
      folderdeletionId:0,
      showDownloadConfirmation:false
    });
  };

  deleteFolderAsk = async (folderid) =>
  {
    this.setState({
      showFolderDeleteConfirmation:true,
      folderdeletionId:folderid
    })
  } 

  downloadAllAssetsAsk = () =>
  {
    this.setState({
      showDownloadConfirmation:true
    })
  }

  deleteSubfolder = ()=>
  {
    const data = {
      project_id:this.props.match.params.pid,
      brand_id: this.props.match.params.bid,
      master_folder_id: this.props.match.params.fid,
      deletefolderid:this.state.folderdeletionId,
      subfolder_id: this.state.subFolderId,
      user_id: this.state.myId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.deleteFolderPlusAssets, data, {
        headers: headers
      })
      .then((response) => {
         this.setState({subFolders: response.data.subfolders});
         notifyDeleteSuccess();
         this.closeDialogue();
      })
      .catch((error) => {
        notifyError();
      })
  }

  reorderList =  (aid)=>
  {
    var assetupdate = [...this.state.folderAssets];
    var itemkey = assetupdate.map(function (item) { return item.asset_id; }).indexOf(aid); // (2D) finding the matching key providing value
    assetupdate.splice(itemkey, 1);
    this.setState({folderAssets:assetupdate});
  }

  goToHome = () =>
  {
    this.props.history.push("/home"+"?uuid="+this.state.myId);
  }

  breadNavigation = (murl)=>
  {
    this.props.history.push(murl+"?uuid="+this.state.myId);
  }
  
  breadNavigationLink = (murl)=>
  {
    window.location.href = murl+"?uuid="+this.state.myId;
  }

  goToProjectsHome = () =>
  {
    this.props.history.push("/boards"+"?uuid="+this.state.myId);
  }

  goToSubFolders = (subid) =>
  {
    //this.props.history.push("/brand/assets/"+this.props.match.params.pid+"/"+this.props.match.params.bid+"/"+this.props.match.params.fid+"/"+subid);
    window.location.href = "/brand/assets/"+this.props.match.params.pid+"/"+this.props.match.params.bid+"/"+this.props.match.params.fid+"/"+subid+"?uuid="+this.state.myId;
  }

  // uploadStarted = () =>
  // {
  //   // this.setState({
  //   //   uploadProgressLoader:true
  //   //  })
  // }

  uploadProgress = () =>
  {
    //this.setState({uploadProgressLoader:false});
    this.getFolderAssets();
  }

  
  handleChangeEditor = (value) =>
  {
    this.setState({
      assetLinks: value
    })
  }

  updateAssetLinks = () =>
  {
    const data = {
      project_id:this.props.match.params.pid,
      brand_id: this.props.match.params.bid,
      folder: this.props.match.params.fid,
      sub_folder_id: this.state.subFolderId,
      user_id:this.state.myId,
      link_details: this.state.assetLinks
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.updateAssetLinks, data, {
        headers: headers
      })
      .then((response) => {
        this.setState({editActive:false});
        notifySuccess();
      })
      .catch((error) => {
        notifyError();
      })

  }

  stripslashes = (str) => {
    if(str.length>0)
    {
      str = str.replace(/\\'/g, '\'');
      str = str.replace(/\\"/g, '"');
      str = str.replace(/\\0/g, '\0');
      str = str.replace(/\\\\/g, '\\');
    }
    return str;
 }

 activateEdit = ()=>
 {
   this.setState({
     editActive:true
   })
 }

 handleChangeFile = async (files) => {
   this.setState({uploadCount: files.length, uploadingProgress:true});
   const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-origin':'*',
  }
   for (let i = 0; i < files.length; i++) {
    const data = new FormData();
    data.append('file', files[i]);
    data.append('filename', files[i].name);
    data.append('filesize', files[i].size);
    data.append('filetype', files[i].type);
    data.append('project_id', this.props.match.params.pid);
    data.append('brand_id', this.props.match.params.bid);
    data.append('folder', this.props.match.params.fid);
    data.append('sub_folder_id', this.state.subFolderId);
    data.append('user_id', this.state.myId);
    data.append('project_name', this.state.projectDetails.board_name);
    data.append('folder_name', this.state.folderName);
    data.append('brand_name', this.state.brandName);
    await axios.post(urls.uploadBrandAssets, data, { 
      headers: headers
    })
    .then(res => {
     this.setState({uploadedCount:i+1});
     if(files.length == i+1)
     {
      this.setState({uploadCount:0,uploadedCount:0,uploadingProgress:false});
      this.getFolderAssets();
     }
    })

  }
};

handleError = (err)=>
{
  console.log(err);
}

showAssetEdit = (id, text) =>
{
  if(AccessCheckBlock('delete_doc_files'))
  this.setState({activeEditId: id, activeEditText: text});
}

handleNoteUpdate = (e)=>
{
  let temp = this.state.folderAssets;
  var itemkey = temp.map(function (item) { return item.asset_id; }).indexOf(this.state.activeEditId);
  temp[itemkey].asset_note = e.target.value;
  this.setState({folderAssets:temp, activeEditText: e.target.value});
}

  _handleKeyDown = (e) => {
    var code = (e.keyCode ? e.keyCode : e.which);
    if(code == 13) { 
      
      const data = {
        asset_id:this.state.activeEditId,
        asset_note: this.state.activeEditText
      };
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
      }
      axios.post(urls.updateAssetNote, data, {
          headers: headers
      })
      .then((response) => {
        this.setState({activeEditId: 0, activeEditText: ''});
        //notifySuccess();
      })
      .catch((error) => {
        //notifyError();
        this.setState({activeEditId: 0, activeEditText: ''});
      })
    }
  }

  render() {
    return (
      <div className="fullwidth_mobilemenu">
      <Container fluid className="top-bar-inactive bc_module_container">
      <Toaster position="top-right" containerClassName="notifier"
            toastOptions={{
              // Define default options
              className: '',
              duration: 5000,
              style: {
                background: '#363636',
                color: '#fff',
              },}}
        />
      <Row>
        <div className="theme_header">
           <Header {...this.props}  className="tick-timer-header" 
            logout={this.signOut.bind(this)} 
            onmenuaction={this.toggleSidebar.bind(this)}
            quickactionblock={false} />
            <div className="SideNavbar">
              <SideMenu  
              activeitem="project" 
              showmenu={this.state.showMenu} 
              hidemenu={this.hideMenu.bind(this)}  /> 
            </div>
        </div>
       <div className="themeContentBody themefullwidthContentBoday">
       <LoadingWrapper isLoading={this.state.progressBar}>
            {this.state.accessDenied === true ?
            (
              <>
                <AccessDenied gotohome={this.goToHome} gotoprojectshome={this.goToProjectsHome} />
              </>
            ):
            (
            <>
                <Col sm="12">
                  <div className="bc_Board_wrapper">
                    <h2 class="section_title d-flex align-items-center justify-content-center">{this.state.projectDetails.board_name}
                    <ProjectCurrentStatus 
                    graphics={this.state.projectDetails.graphics_pack}
                    video={this.state.projectDetails.video_pack}
                    graphics_video={this.state.projectDetails.graphics_video_pack}
                    canva_graphics={this.state.projectDetails.canva_graphics}
                    canva_plus={this.state.projectDetails.canva_plus}
                    whitelabel={this.state.projectDetails.white_label}
                    clientstatus={this.state.projectDetails.client_status} />
                   
                    </h2>
                    <p class="board_discrption">{this.state.projectDetails.short_description}</p>    
                    <MailboxEmail canva_access={this.state.projectDetails.canva_access} />     
                    <h2 className="section_title subtitle">
                      {this.state.subFolderName!=''? this.state.subFolderName: this.state.folderName}
                    </h2>
                    <div className="page_header_wrap">
                      <Row>
                        <Col sm={6}></Col>
                        <Col sm={6}>
                        <div className="search-box">
                              <a title="Back to Boards" className="edit_board_detail_icon" onClick={this.breadNavigation.bind(this,'/boards')} > <MaterialIcon icon="fact_check"  /></a>
                              <a className="home_button_box" onClick={this.goToHome}><img src={Homebackicon} alt="back" /></a>
                        </div>
                        </Col>
                      </Row>
                     

                    </div>
                  </div>
                  <div className="breadcrumb_boards">
                    <Breadcrumb>
                        <BreadcrumbItem><a  href={"/boards"+"?uuid="+this.state.myId} >Boards</a></BreadcrumbItem>
                        <BreadcrumbItem><a href={'/boardsdetails/'+this.props.match.params.pid+"?uuid="+this.state.myId} >Board Details</a></BreadcrumbItem>
                        <BreadcrumbItem><a href={'/docandfiles/'+this.props.match.params.pid+"?uuid="+this.state.myId} >Doc and Files</a></BreadcrumbItem>
                        <BreadcrumbItem><a href={'/brand/'+this.props.match.params.pid+"/"+this.props.match.params.bid+"?uuid="+this.state.myId} >{this.state.brandName}</a></BreadcrumbItem>
                        {this.state.subFolderId>0?
                          (
                            <>
                              <BreadcrumbItem><a href={'/brand/assets/'+this.props.match.params.pid+"/"+this.props.match.params.bid+"/"+this.props.match.params.fid+"?uuid="+this.state.myId} >{this.state.folderName}</a></BreadcrumbItem>
                              <BreadcrumbItem active>{this.state.subFolderName}</BreadcrumbItem>
                            </>
                            )
                          :
                          (<BreadcrumbItem active>{this.state.folderName}</BreadcrumbItem>)
                        }
                        
                      </Breadcrumb>
                      </div>
                  </Col>
                  <Row>
                      <Col sm="7">
                        <Row>
                          <Col sm="12">
                            {AccessCheckBlock('uplaod_doc_files') &&
                            (<div className="attchfiles_wrap">
                              <h4 className="boxtitile">Upload Files</h4>
                              <FileUploader 
                              maxSize={1024}
                              handleChange={this.handleChangeFile} 
                              onTypeError = {(err)=>this.handleError.bind(this, err)}
                              name="files" 
                              types={fileTypes}
                              multiple={true} />
                              {(this.state.uploadingProgress) &&
                              (
                                <div className="upload-progress">
                                  Please wait, uploading in progress ({this.state.uploadedCount+"/"+this.state.uploadCount})
                                  <div className='uploading-bar'>
                                    <Progress striped  color="success" value={((this.state.uploadedCount / this.state.uploadCount) + 0.1) * 100} />
                                  </div>
                                </div>
                              )}
                              <div className="attach_footer">
                                <div className="filesize">Maximum File size 1024MB</div>
                              </div>
                              </div>)
                              }
                          </Col>
                          <Col sm="12">
                              <div className="imag_previewbox">
                                <LightgalleryProvider
                                  onAfterSlide={() => {
                                    console.log("onAfterSlide");
                                  }}
                                >
                                <div className="image_preview_details_bc">
                                  <div className='d-flex justify-content-between align-items-center'>
                                  <h4>Files Uploaded</h4>
                                    {this.state.folderAssets && this.state.folderAssets.length>0 &&
                                    (
                                      <span className='download-asset-files' onClick={this.downloadAllAssetsAsk}>Download All Files</span>
                                    )}
                                  </div>
                                  {this.state.downloadMessage &&
                                  (
                                    <div className='download-wait'>Please wait while we prepare the download file.</div>
                                  )}
                                  <Table responsive className="theme_table">
                                        <thead>
                                          <tr>
                                            <th>File name</th>
                                            <th>Short Note</th>
                                            {/* <th>File Size</th> */}
                                            {/* <th>Download</th>
                                             {AccessCheckBlock('delete_doc_files') &&
                                            (
                                              <th>Delete</th>)
                                            } */}
                                             <th>Actions</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.folderAssets.map((p, idx) => (
                                        <tr key={p.id}>
                                          <td className="d-flex align-items-center"> {p.asset_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpg' || p.asset_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpeg'
                                        || p.asset_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'png' ?
                                        (
                                            <LightgalleryItem group="group1" src={(p.drive_location === 0 ? urls.cdnURL : urls.cdnURL+"storage/")+`projects/brands/`+p.asset_file_location+`/`+p.asset_name}>
                                              <img src={(p.drive_location === 0 ? urls.cdnURL : urls.cdnURL+"storage/")+"projects/brands/"+p.asset_file_location+"/"+p.asset_name+"?class=small"} style={{ width: "auto" }} />
                                            </LightgalleryItem>
                                        ):
                                        (
                                        <div className="attachother_file">
                                        <a href={(p.drive_location === 0 ? urls.cdnURL : urls.cdnURL+"storage/")+"projects/brands/"+p.asset_file_location+"/"+p.asset_name} target="_blank" className="attachment-bg bg-small">
                                            <AttachmentIcon />
                                            <span className="attachment-type">
                                              {p.asset_name.split('.').pop()}
                                            </span>
                                          </a>
                                        </div>
                                        )}
                                        <div className='filessize'>{p.asset_name}
                                        <br/><span>{p.asset_size}</span>
                                        <span>{moment((new Date(p.created_on)).getTime()).tz(this.state.timezone).format('DD MMM, YYYY')}</span>
                                        </div>
                                        </td>
                                        <td onClick={this.showAssetEdit.bind(this, p.asset_id, p.asset_note)}>
                                          {/* {p.created_on} */}

                                          {this.state.activeEditId === p.asset_id ? 
                                          (
                                            <Input type="text" value={p.asset_note}
                                            onKeyDown={this.keyPress}
                                            onChange={this.handleNoteUpdate.bind(this)} />
                                          ):
                                          (
                                            <>
                                            {p.asset_note === null || p.asset_note === '' ? '...' : p.asset_note}
                                            </>
                                          )}
                                         
                                        </td>
                                        {/* <td>{p.asset_size}</td> */}
                                        {/* <td className='single-download-icon'><a target="_blank" href={(p.drive_location === 0 ? urls.storageUrl : urls.s3Url)+"storage/projects/brands/"+p.asset_file_location+"/"+p.asset_name} ><MaterialIcon icon="download" /></a></td>
                                        {AccessCheckBlock('delete_doc_files') &&
                                        (
                                          <td className="text-right"> <button onClick={this.deleteAssetAsk.bind(this, p.asset_id)} className="remove_btn"></button></td>)
                                        } */} 
                                        <td>
                                            <span className='d-flex attach-actions justify-content-between'>
                                              <a target="_blank" href={(p.drive_location === 0 ? urls.s3Url : urls.s3Url+"storage/")+"projects/brands/"+p.asset_file_location+"/"+p.asset_name} ><MaterialIcon icon="download" /></a>
                                              {AccessCheckBlock('delete_doc_files') &&
                                              (
                                                <button onClick={this.deleteAssetAsk.bind(this, p.asset_id)} className="remove_btn"></button>)
                                              }
                                            </span>
                                        </td>
                                        </tr>
                                      
                                          ))}
                                        </tbody>
                                      </Table>
                                      </div>
                                        </LightgalleryProvider>
                                      </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="5">
                        <Row>
                          {(this.state.subFolderId === 0 && this.props.match.params.fid != 7 && this.props.match.params.fid != 8) &&
                            (
                              <Col sm="12">
                                <div className="sub_assets_folder" onClick={this.toggle}>
                                    <div className='d-flex'>
                                      <h4 className="boxtitile">Folders 
                                          <Addsubfolders addfolder={this.addSubFolders} />
                                      </h4>
                                      {(this.state.subFolders && this.state.subFolders.length>4) &&
                                      (
                                        <>
                                            {this.state.showAllFolders ?
                                            (
                                              <a onClick={()=> this.setState({showAllFolders:false})}>Hide</a>
                                            ):
                                            (
                                              <a onClick={()=> this.setState({showAllFolders:true})}>View All({this.state.subFolders.length})</a>
                                            )}
                                        </>
                                        )}
                                    </div>
                                    {(this.state.subFolders && this.state.subFolders.length>0) &&
                                    (
                                      <div className="sub_folder_lists">
                                        {this.state.subFolders.map((subfolder, index)=>
                                        {
                                          return(
                                            <div className={`subfolder ${(!this.state.showAllFolders && index>3) && ' hide-folders' }`} key={index} >
                                              <Button className="sub_folder_delete" onClick={()=>this.deleteFolderAsk(subfolder.id)}>
                                                <MaterialIcon icon="remove_circle" />
                                              </Button>
                                              <span onClick={this.goToSubFolders.bind(this.setState,subfolder.id)}><MaterialIcon icon="folder"/></span>
                                              <span className="subfolder_name">
                                                {subfolder.folder_name}
                                                <Button className="sub_folder_add" onClick={this.ShowEditBox.bind(this, subfolder)}>
                                                  <MaterialIcon icon="edit" />
                                                </Button>
                                              </span>
                                            </div>
                                          )
                                        })}
                                        {this.state.folderEditActive &&
                                        (
                                          <>
                                         
                                          <EditSubFolder 
                                          editid={this.state.folderEditId} 
                                          editname={this.state.folderEditName}
                                          editfolder={this.editFolderName}
                                          />
                                          </>
                                        )}
                                      </div>
                                    )}
                                    
                                </div>
                              </Col>
                            )}



                            <Col sm="12">
                              <div className={`asset-link-wrapper ${this.state.subFolderId >0 && ' folder-empty'}`}>
                                <div className='d-flex'>
                                  <h4 className="boxtitile">Asset Links</h4>
                                  {(AccessCheckBlock('uplaod_doc_files') && !this.state.editActive ) &&
                                    (<span className="edit-links d-flex" onClick={this.activateEdit.bind(this)}>
                                      <MaterialIcon icon="edit"  />
                                    </span>)
                                  }
                                </div>

                                {AccessCheckBlock('uplaod_doc_files') &&
                                (
                                  <>
                                  
                                    {this.state.editActive &&
                                    (
                                      <>
                                        <ReactQuill value={this.state.assetLinks}
                                        onChange={this.handleChangeEditor}
                                        className={this.state.detailError? 'field-error' : ''}
                                        placeholder= "Enter your asset links here..." 
                                        modules={this.modules}
                                        theme="snow" />
                                        <Button className="btn btn-green btn-asset-link"
                                        onClick={this.updateAssetLinks.bind(this)}
                                        >Update</Button>
                                      </>
                                    )}
                                    </>
                                  )}
                                  <div className="assets-links-view">
                                      <Linkify className="cmd_editer_preview"  options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ ReactHtmlParser( this.stripslashes(this.state.assetLinks) +" ")}</Linkify>
                                  </div>
                                </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
          </LoadingWrapper>
       </div>
      </Row>
      {this.state.showDeleteConfirmation && (
      <ConfirmAction
        purpose="change"
        closeDialogue={this.closeDialogue}
        show={this.state.showDeleteConfirmation}
        note="*This action cannot be undone, are you sure?"
        content="You are about to delete this asset."
        handledeletion={this.deleteAsset}
        resolveid=""
        resolveStatus=""
        adminid=""
        confirmBoxAction="deleteasset"
      />
      )}
      {this.state.showFolderDeleteConfirmation && (
      <ConfirmAction
        purpose="change"
        closeDialogue={this.closeDialogue}
        show={this.state.showFolderDeleteConfirmation}
        note="*This action cannot be undone, are you sure?"
        content="You are about to delete this asset."
        handledeletion={this.deleteSubfolder}
        resolveid=""
        resolveStatus=""
        adminid=""
        confirmBoxAction="deletefolder"
      />
      )}
      {this.state.showDownloadConfirmation && (
      <ConfirmAction
        purpose="change"
        closeDialogue={this.closeDialogue}
        show={this.state.showDownloadConfirmation}
        content="Are you sure you want to download all assets"
        handledownload={this.downloadAllAssets}
        resolveid=""
        resolveStatus=""
        adminid=""
        confirmBoxAction="downloadassets"
      />
      )}
      </Container>
    </div>
    )
  }
}
