// BaseDrawer.jsx
import React,{useState} from 'react';

const BaseDrawer = ({ isOpen, toggle, title, children, isLoading, onSubmit }) => {
  return (
    <>
      <div className={`drawer-overlay ${isOpen ? 'show' : ''}`} onClick={toggle} />
      <div className={`drawer ${isOpen ? 'show' : ''}`}>
        <div className="drawer-header">
          <h5>{title}</h5>
          {/* <button className="close-button" onClick={toggle}>
            <i className="fas fa-times"></i>
          </button> */}
        </div>
        <form onSubmit={onSubmit}>
          <div className="drawer-body">
            {children}
          </div>
          <div className="drawer-footer">
            <button type="button" className="btn btn-secondary" onClick={toggle} disabled={isLoading}>
              Cancel
            </button>
            <button type="submit" className="btn btn-primary" disabled={isLoading}>
              {isLoading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" />
                  Processing...
                </>
              ) : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default BaseDrawer;