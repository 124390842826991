import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { urls } from "../../../services/UrlList";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

const getRepaymentSchedule = async() => {
   return await axios.get(urls.getRepaymentSchedule);
}

const updateRepaymentData = async(data) => {
   return await axios.post(urls.updateRepaymentData, data);
}   

export const useGetRepaymentSchedule = ()=>{
    return useQuery(['repayment-schedule'], () => getRepaymentSchedule(), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 3600000, 
    });
}

export const useUpdateRepaymentData = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(updateRepaymentData, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['repayment-schedule']);
        }
    });
}
 

