import React from "react";

const SERVER_SUPPORTED_FILE = new Map([
    ['jpg', 'image/jpg'],
    ['jpeg', 'image/jpeg'],
    ['png', 'image/png'],
    ['bmp', 'image/bmp']
    // Add other file types as needed
  ]);
  
  const thumbSize = (type) => {
    // Define your thumbnail sizes here
    const sizes = {
      small: { width: 122, height: 122 },
      // Add other sizes as needed
    };
    return sizes[type] || sizes.small;
  };
  
  const getFileNameFromUrl = (url) => {
    try {
      return url.split('/').pop() || '';
    } catch {
      return '';
    }
  };
  
  const getImageTypeFromUrl = (url) => {
    try {
      const extension = url.split('.').pop().toLowerCase();
      return extension || '';
    } catch {
      return '';
    }
  };
  
export const getThumbnail = (url, type = 'small', isKimpHubUrl) => {
    try {
      const THUMBNAIL_API_URL = process.env.REACT_APP_LAMBADA_ENDPOINT_THUMBNAIL;
      const fileName = getFileNameFromUrl(url) || '';
      const fileType = getImageTypeFromUrl(url);
      const isKimpAsset = url.includes(THUMBNAIL_API_URL) || isKimpHubUrl;
  
      if (!fileType || !isKimpAsset) {
        return url;
      }
  
      const fileTypeMimetype = SERVER_SUPPORTED_FILE.get(fileType) || '';
      
      if (!['image/jpg', 'image/jpeg', 'image/png', 'image/bmp'].includes(fileTypeMimetype)) {
        return url;
      }
  
      if (fileName.endsWith(type)) {
        return url;
      }
  
      return `${THUMBNAIL_API_URL}?url=${url}&width=${thumbSize(type).width}&height=${
        thumbSize(type).height
      }`;
    } catch (err) {
      return url;
    }
  };
