import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { urls } from "../../services/UrlList";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

const getProfileReportTasks = async(month, selectedMember) => {
   return await axios.post(urls.getProfileReportTasks, {'month':month, 'selected_member': selectedMember});
}

const getProfileReportTasksIssues = async(month, selectedMember) => {
   return await axios.post(urls.getProfileReportTasksIssues, {'month':month, 'selected_member': selectedMember});
}   

const getProfileReportTicketRespo = async(month, selectedMember) => {
    return await axios.post(urls.getProfileReportTicketRespo, {'month':month, 'selected_member': selectedMember});
 }   

const getProfileReportLeaves = async(month, selectedMember) => {
    return await axios.post(urls.getProfileReportLeaves, {'month':month, 'selected_member': selectedMember});
 }   

 const getUserFeedbackData = async(userID) => {
    return await axios.post(urls.getUserFeedbackData, {'user_id': userID});
 }   

 
const addProfileReport = async(data) =>
{
    return await axios.post(urls.addprofileReport, data);
}


const addUpdateSkipRating = async(data) =>
{
    return await axios.post(urls.addUpdateSkipRating, data);
}

    

const getProfileReportMembers =  async(userID) => {
    return await axios.post(urls.getProfileReportMembers,{'user_id': userID});
}

const getMyPerformanceData = async(month, selectedMember) =>
{
    return await axios.post(urls.getMyPerformanceData, {'month': month, 'selected_member': selectedMember});
}

const getMyIndividualPerformaceRating = async(month, questionType, selectedMember) =>
{
    return await axios.post(urls.getMyIndividualPerformaceRating, {'month': month, 'question_type': questionType, "selected_member": selectedMember});
}

const getMyProfileRealatedCount = async(month, selectedMember) =>
{
    return await axios.post(urls.getMyProfileRealatedCount, {'month': month, 'selected_member': selectedMember});
}

const getKimpMembers = async(user_type) =>
{
    return await axios.post(urls.getKimpMembers, {'user_type': user_type});
}


const getProfileTaskRatingsList = async(month, selectedMember) => {
    return await axios.post(urls.getProfileTaskRatingsList, {'month':month, 'selected_member': selectedMember});
 }


 const getAiTestReports = async(month, selectedMember) => {
    return await axios.post(urls.getAiTestReports, {'month':month, 'selected_member': selectedMember});
 }   


 const getRatingAssets = async() => {
    return await axios.get(urls.getRatingAssets);
 }   

 const addUpdateProfileRatingAuthor = async(data) =>
{
    return await axios.post(urls.addUpdateProfileRatingAuthor, data);
}

const getProfileTaskList = async(month, selectedMember,taskType) => {
    return await axios.post(urls.getProfileTaskList, {'month':month, 'selected_member': selectedMember,'type': taskType});
 }

export const useGetProfileTaskList = (month, selectedMember, taskType)=>{
    return useQuery(['profile-report-task-list', month + "-" + selectedMember+"-"+taskType], () => getProfileTaskList(month, selectedMember, taskType), {
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });
}



 export const useGetRatingAssets = ()=>{
    return useQuery(['rating-assets'], () => getRatingAssets(), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 360000, 
    });
}

export const useAddUpdateProfileRatingAuthor = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(addUpdateProfileRatingAuthor, {
        onSuccess: (response) => {
            if(response.data.success == 8)
            {
                toast('you are not allowed to perform this action.');
            }
            queryClient.invalidateQueries(['rating-assets']);
        }
    });
}
 

export const useGetProfileTaskRatingsList = (month, selectedMember)=>{
    return useQuery(['profile-report-task-ratings-list', month + "-" + selectedMember], () => getProfileTaskRatingsList(month, selectedMember), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 360000, 
    });
}


export const useGetKimpMembers = (user_type) => {
    return useQuery(['profile-kimp-members'], () => getKimpMembers(user_type), {
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });
}   

export const useGetMyProfileIssueCount=(month, selectedMember)=>{
    return useQuery(['profile-related-count-'+month+"-"+selectedMember], () => getMyProfileRealatedCount(month, selectedMember), {
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });
}   

export const useGetMyIndividualPerformaceRating= (month, questionType, selectedMember)=>{
    return useQuery(['profile-rating-individual-'+month+"-"+questionType+"-"+selectedMember ], () => getMyIndividualPerformaceRating(month, questionType,selectedMember), {
        refetchOnMount: true,
        refetchOnWindowFocus: true
    });
}

export const useGetMyPerformanceData= (month, selectedMember)=>{
    return useQuery(['profile-performance-data-', month+"-"+selectedMember], () => getMyPerformanceData(month, selectedMember), {
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });
}

export const useGetProfileReportMembers= (userId)=>{
    return useQuery(['profile-report-members-', userId], () => getProfileReportMembers(userId), {
        refetchOnMount: false,
        refetchOnWindowFocus: true
    });
}

export const useGetProfileReportTasks = (month, selectedMember)=>{
    return useQuery(['profile-report-task-', month + "-" + selectedMember], () => getProfileReportTasks(month, selectedMember), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 360000, 
    });
}

export const useGetProfileReportTasksIssues = (month, selectedMember)=>{
    return useQuery(['profile-report-task-issues-', month + "-" + selectedMember], () => getProfileReportTasksIssues(month, selectedMember), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 360000, 
    });
}

export const useGetProfileReportTicketRespo = (month, selectedMember)=>{
    return useQuery(['profile-report-ticket-respo-', month + "-" + selectedMember], () => getProfileReportTicketRespo(month, selectedMember), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 360000, 
    });
}

export const useGetProfileReportLeaves = (month, selectedMember)=>{
    return useQuery(['profile-report-leaves-'+month + "-" + selectedMember], () => getProfileReportLeaves(month, selectedMember), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 360000, 
    });
}

export const useGetUserFeedbackData = (userID)=>{
    return useQuery(['profile-feedback-data-', userID], () => getUserFeedbackData(userID), {
        refetchOnMount: true,
        refetchOnWindowFocus: false, 
    });
}

export const useAddProfileReport = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(addProfileReport, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['profile-report-members']);
            if(response.data.question_type && response.data.question_type == 6)
            {
                queryClient.invalidateQueries(['profile-report-members']);
            }
        }
    });
}


export const useAddUpdateSkipRating = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(addUpdateSkipRating, {
        onSuccess: (response) => {
           // queryClient.invalidateQueries(['profile-report-members']);
            if(response.data.success == 1)
            {
                queryClient.invalidateQueries(['profile-report-members']);
            }
        }
    });
}

export const useGetAiTestReports = (month, selectedMember)=>{
    return useQuery(['profile-ai-report-'+month + "-" + selectedMember], () => getAiTestReports(month, selectedMember), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 360000, 
    });
}