import React,{useState} from 'react';
import BaseDrawer from './BaseDrawer';

const TerminationDrawer = ({ isOpen, toggle, onSubmit, isLoading }) => {
  const [formData, setFormData] = useState({
    reason: '',
    improvementAttempts: '',
    recommendedDate: '',
    additionalNotes: ''
  });

  return (
    <BaseDrawer
      isOpen={isOpen}
      toggle={toggle}
      title="Initiate Termination Process"
      isLoading={isLoading}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(formData);
      }}
    >
      <div className="form-group">
        <label className="form-label">Reason for Termination</label>
        <textarea
          className="form-control"
          rows={4}
          value={formData.reason}
          onChange={(e) => setFormData({...formData, reason: e.target.value})}
          placeholder="Provide detailed reason for termination"
          required
        />
      </div>

      <div className="form-group">
        <label className="form-label">Improvement Attempts</label>
        <textarea
          className="form-control"
          rows={3}
          value={formData.improvementAttempts}
          onChange={(e) => setFormData({...formData, improvementAttempts: e.target.value})}
          placeholder="Document improvement attempts and support provided"
          required
        />
      </div>

      <div className="form-group">
        <label className="form-label">Recommended Termination Date</label>
        <input
          type="date"
          className="form-control"
          value={formData.recommendedDate}
          onChange={(e) => setFormData({...formData, recommendedDate: e.target.value})}
          required
        />
      </div>

      <div className="form-group">
        <label className="form-label">Additional Notes</label>
        <textarea
          className="form-control"
          rows={3}
          value={formData.additionalNotes}
          onChange={(e) => setFormData({...formData, additionalNotes: e.target.value})}
          placeholder="Any additional information"
        />
      </div>
    </BaseDrawer>
  );
};

export default TerminationDrawer;