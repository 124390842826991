import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import {Button, Input, Label, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Tooltip } from 'reactstrap';
import Linkify from 'linkifyjs/react';
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';
import {urls} from "../../services/UrlList";
import moment from "moment";
import LightboxPop from './LightboxPop';
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.min.css';
import KFileUpload from './kFileUpload';
import toast, { Toaster } from 'react-hot-toast';
import PeopleTags from "./PeopleTag";
import Dropicon from '../../images/drop_board_icon.png';
import { AccessCheck, AccessCheckBlock } from './AccessCheck';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import ImageUploader from "quill-image-uploader";

import ConfirmAction from '../admincomment/ConfirmAction';
import TextareaAutosize from 'react-autosize-textarea';
import MaterialIcon from "material-icons-react";
import ClipboardCopyCommentLink from "./ClipboardCopyCommentLink";
import { findMockups, excludeMockupFromOther } from './SourceManagement/HelperFunctions';
import { fixQuillColorPickerScroll } from './quillUtils';


Quill.register("modules/imageUploader", ImageUploader);
const notifyEditError = () => toast("Sorry, you can't edit this comment anymore.");
const notifyApproved = () => toast('Design has been marked as approved.');
const notifyRevision = () => toast('Design revision requested. Kindly post your comment.');
const notifyApproveRejectError = () => toast("Looks like you don't have persmission to perform this action.");

const toolbarOptions = [
  ['bold', 'italic', 'underline'],       
  [{ 'color': [] }, { 'background': [] }],           
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],     
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }] ,
  ['link','image'],
  ['clean']    
];

var atValues = [
  { id: 1, value: 'Fredrik Sundqvist' },
  { id: 2, value: 'Patrik Sjölin' }
];
var selectedMentions=[];

export default class TaskComments extends Component 
{
  constructor(props) {
      super(props);
      this.state = {
        approveDesign:false,
        revisionDesign:false,
        randomNum: Math.floor(Math.random() * 160),
        saveupdateProgress:false,
        editCommentText: this.props.edittext,
        commentImages:[],
        commentImagesUpdated:[],
        showEditOption:false,
        showAttachments:true,
        inspiration: this.props.commentfields && this.props.commentfields.inspiration, 
        resourcelinks:this.props.commentfields && this.props.commentfields.resourcelinks,
        approverAssginee:this.props.commentfields ? this.props.commentfields.approver : [],
        commentApprover:this.props.comment.task_approver,
        pmnote:this.props.commentfields && this.props.commentfields.pmnote,
        designApprovalNeeded:this.props.commentfields && this.props.commentfields.approval_required,
        spellCheck:(this.props.commentfields && this.props.commentfields.spell_check ) ? this.props.commentfields.spell_check : '',
        iPackaged:(this.props.commentfields && this.props.commentfields.packaged ) ? this.props.commentfields.packaged : '',
        taskApprovalNeeded: this.props.comment.task_approval_status,
        //stringifiedtextlinks: this.props.comment && this.props.comment.comment_attachments_textlinks && JSON.parse(this.props.comment.comment_attachments_textlinks),
        commentImagesTextArray: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.commentAttachmentFilesTextArray ) ? this.props.stringifiedtextlinks.commentAttachmentFilesTextArray:[],
        attachingFoldersOutput: (this.props.sourceFiles && this.props.sourceFiles.output_folders) ? this.props.sourceFiles.output_folders : [],
        attachingFoldersSource: (this.props.sourceFiles && this.props.sourceFiles.source_folders) ? this.props.sourceFiles.source_folders : [],
        attachingFoldersOthers: (this.props.sourceFiles && this.props.sourceFiles.other_folders) ? this.props.sourceFiles.other_folders : [],
        mockupFolders: [],
        otherFolders: [],
        verificationError:false,
        
        options: {
          attributes: null,
          className: 'linkified',
          defaultProtocol: 'http',
          events: null,
          format: function (value, type) {
            return value;
          },
          formatHref: function (href, type) {
            return href;
          },
          ignoreTags: [],
          nl2br: false,
          tagName: 'a',
          target: {
            url: '_blank'
          },
          validate: true
        },
        commentImagesTextOptions:(this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.commentAttachmentFilesTextArray && this.props.stringifiedtextlinks.commentAttachmentFilesTextArray.length>0 ) ? 2: 0,
        commentAttachmentsChange:false,
        collapseActive:true
      };
      atValues = this.props.atvalues;
      this.oldCommentRef = React.createRef();
    }

    modules = {
      toolbar: toolbarOptions,
      mention: {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ["@"],
        mentionContainerClass: 'ql-mention-list-container-bottom',
        listItemClass:'ql-mention-list-item',
        mentionListClass:'ql-mention-list',
        minChars: 2,
        source: function (searchTerm, renderList, mentionChar) {
          let values;
    
          if (mentionChar === "@") {
            values = atValues;
          }
    
          if(searchTerm.length >0) {
            const matches = [];
            for (var i = 0; i < values.length; i++)
              if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
            renderList(matches, searchTerm);
          }
        },
        onSelect: function (item, insertItem) {
          selectedMentions.push(item['id']);
          insertItem(item);
        }
      },
      imageUploader: {
        upload: file => {
          return new Promise((resolve, reject) => {
            const data = new FormData();
            data.append('file', file);
            data.append('filename', file.name);
            data.append('filesize', file.size);
            data.append('filetype', file.type);
            data.append('folder_path', 'editor')
            data.append('user_id', 1);
            axios.post(urls.uploadProjectMediaEditor, data, { 
            })
            .then(res => {
              resolve(res.data.file_name);
            })
            .catch(error => {
                reject("Upload failed");
                console.error("Error:", error);
              });
          });
        }
      }
    };

    nonemodules = {
      toolbar: [],
      mention: {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ["@"],
        mentionContainerClass: 'ql-mention-list-container-bottom',
        listItemClass:'ql-mention-list-item',
        mentionListClass:'ql-mention-list',
        minChars: 2,
        source: function (searchTerm, renderList, mentionChar) {
          let values;
    
          if (mentionChar === "@") {
            values = atValues;
          }
    
          if(searchTerm.length >0) {
            const matches = [];
            for (var i = 0; i < values.length; i++)
              if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
            renderList(matches, searchTerm);
          }
        },
        onSelect: function (item, insertItem) {
          selectedMentions.push(item['id']);
          insertItem(item);
        }
      },
      imageUploader: {
        upload: file => {
          return new Promise((resolve, reject) => {
            const data = new FormData();
            data.append('file', file);
            data.append('filename', file.name);
            data.append('filesize', file.size);
            data.append('filetype', file.type);
            data.append('folder_path', 'editor')
            data.append('user_id', 1);
            axios.post(urls.uploadProjectMediaEditor, data, { 
            })
            .then(res => {
              resolve(res.data.file_name);
            })
            .catch(error => {
                reject("Upload failed");
                console.error("Error:", error);
              });
          });
        }
      }
    };
    
    enableforEditing = ()=>
    {
      this.setState({
        commentImages: this.props.commentattachments != null? this.props.commentattachments : [],
        commentImagesUpdated: this.props.commentattachments != null? this.props.commentattachments : [],
        editCommentText:this.props.comment.comment
      })    
      
      if((moment((new Date()).getTime()).tz(this.props.mytimezone).format('X')) - (moment((new Date(this.props.comment.updated_on)).getTime()).tz(this.props.mytimezone).format('X'))<180)
      {
        this.setState({showEditOption:true}) 
        
      }
    }
  
    componentDidMount = () => {
      this.enableforEditing();
      this.findMockups()
      this.findothers();
      this.scrollFixCleanup = fixQuillColorPickerScroll('old-comment', this.oldCommentRef);
    };

    componentWillUnmount() {
      if (this.scrollFixCleanup) {
        this.scrollFixCleanup();
      }
    }


    findMockups = () =>
    {
      let Mockups = [];
      if(this.state.attachingFoldersOthers)
      {
      this.state.attachingFoldersOthers.forEach(folder => {
          if(folder.folder_name === 'Mockups')
          {
            Mockups.push(folder);
          }
      });
      this.setState({mockupFolders: Mockups});
      }
    }

    findothers = () =>
    {
      let Others = [];
      if(this.state.attachingFoldersOthers)
      {
      this.state.attachingFoldersOthers.forEach(folder => {
          if(folder.folder_name !== 'Mockups')
          {
            Others.push(folder);
          }
      });
      this.setState({otherFolders: Others});
      }
    }

    setApprover = (tags) =>
    {
      if(typeof(tags) === 'object' && tags.length>0)
      {
        this.setState({approverAssginee: tags, commentApprover:tags[0].id })
      }else
      {
        this.setState({approverAssginee: [], commentApprover:'' })
      }
    }

    showEditOption = (id, comment)=>
    {
      if(((moment((new Date()).getTime()).tz(this.props.mytimezone).format('X')) - (moment((new Date(this.props.comment.updated_on)).getTime()).tz(this.props.mytimezone).format('X'))<300) || AccessCheckBlock('i_am_admin'))
      {
        this.props.showcommentedit(id, comment); 
      }else
      {
        notifyEditError();
        this.setState({showEditOption:false});
      }
    }
    storeCommentFileNames = async(arrayname, item)=>
    {
      let temp =[...this.state.commentImagesUpdated];
      temp.push(item);
      this.setState({showAttachments:false});
      await this.setState({commentImagesUpdated: temp});
      this.setState({showAttachments:true});
    }

    deleteCommentFileNames = async(deletefile) =>
    {  
        let temp =[...this.state.commentImagesUpdated];
        var itemkey = temp.map(function (item) { return item.file_name; }).indexOf(deletefile); // (2D) finding the matching key providing value
        temp.splice(itemkey, 1);
        this.setState({showAttachments:false});
        await this.setState({commentImagesUpdated: temp});
        this.setState({showAttachments:true});
    }

    updatetaskComment = ()=>
    {
      this.setState({ saveupdateProgress:true});

      this.setState({
        comments:false,
        verificationError:false,
        detailError:false
      });

      const data = {
        comment_id: this.props.comment.id,
        task_id: this.props.comment.task_id,
        comments: this.state.editCommentText,
        
        comment_fields:{
          inspiration: this.state.inspiration,
          resourcelinks:this.state.resourcelinks,
          pmnote: this.state.pmnote,
          approver: this.state.needDesignApproval ? [] : this.state.approverAssginee,
          approval_required: this.state.designApprovalNeeded ? true : false,
          spell_check: this.state.spellCheck,
          packaged:  this.state.iPackaged
        },
        task_approval_needed: this.state.designApprovalNeeded ? 1 : 0,

        task_approver: this.state.commentApprover,
        comment_attachments: this.state.commentImagesUpdated,
        fieldDataLinkText:
        {
          commentAttachmentFilesText: this.state.commentImagesTextOptions == 1? this.state.commentImagesText : '',
          commentAttachmentFilesTextArray: this.state.commentImagesTextOptions == 2? this.state.commentImagesTextArray : [],
        },
        comment_by: this.props.myid,
        project_name: this.props.project_name,
        project_id : this.props.project_id,
        tid_cpt: this.props.tid_cpt,
        task_name: this.props.task_name,
        commentid: this.props.comment.comment_id
      };

     if(this.state.editCommentText ==='<p><br></p>')
      {
          this.setState({detailError:true, saveupdateProgress:false});
          return false;
      }
      // if(((this.state.inspiration !='' && this.state.inspiration != null) ||
      //  (this.state.resourcelinks != '' && this.state.resourcelinks != null) || 
      // (this.state.pmnote != '' && this.state.pmnote != null) ) && this.state.spellCheck=='')
      //  {
      //   this.setState({verificationError:true, saveupdateProgress:false});
      //   return false;
      //  }

      if(AccessCheckBlock('i_am_designer') && !this.state.iPackaged && this.state.stringifiedDescription && this.state.stringifiedDescription.Softwares && (this.state.stringifiedDescription.Softwares.includes('Ai') || this.state.stringifiedDescription.Softwares.includes('INDD')))
      {
        this.setState({packageError:true});
        return false;
      }

      if(AccessCheckBlock('i_am_designer') && !this.state.spellCheck)
      {
       this.setState({verificationError:true, saveupdateProgress:false});
       return false;
      }
     

      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
      }
      axios.post(urls.updateTaskComment, data, {
          headers: headers
        })
        .then((response) => {
          this.props.commentupdate(this.state.designApprovalNeeded ? 1 : 0);
          this.setState({ 
            saveupdateProgress:false,
            inspiration:'',
            resourcelinks:'',
            pmnote:'',
            approverAssginee:[]
          });
        })
        .catch((error) => {
          this.setState({ saveupdateProgress:false});
        })
    }

    handleChangeEditor = (value) =>
    {
      this.setState({
        editCommentText: value
      })
    }

    handleChange = ({ target }) => {
      this.setState({ [target.name]: target.value });
    };

    cancelCommentUpdate =()=>
    {
      this.props.cancelcommentupdate();
    }

    stripslashes = (str) => {
      str = str.replace(/\\'/g, '\'');
      str = str.replace(/\\"/g, '"');
      str = str.replace(/\\0/g, '\0');
      str = str.replace(/\\\\/g, '\\');
      return str;
   }

   replaceURLsResources = (message) => {
    if(!message) return;
    let newmessage= '';
    let string = message;
    string = string.replace(/,/g, ' ');
    const matches = string.match(/\bhttps?:\/\/\S+/gi);
    if(matches && matches.length>0)
    {
      for(let i = 0; i<matches.length; i++)
      {
          newmessage += '<a href="' + matches[i] + '" target="_blank" rel="noopener noreferrer"> Resource ' + (i+1) + '</a>, '
      }
      newmessage = newmessage.replace(/,\s*$/, "");
    }else
    {
      newmessage=message;
    }
    return newmessage;
   }

   replaceURLsInspiration = (message) => {
    if(!message) return;
    let newmessage= '';
    let string = message;

    string = string.replace(/,/g, ' ');
    const matches = string.match(/\bhttps?:\/\/\S+/gi);
    if(matches && matches.length>0)
    {
      for(let i = 0; i<matches.length; i++)
      {
          newmessage += '<a href="' + matches[i] + '" target="_blank" rel="noopener noreferrer"> Inspiration ' + (i+1) + '</a>, '
      }
      newmessage = newmessage.replace(/,\s*$/, "");
    }else
    {
      newmessage=message;
    }
    return newmessage;
   }

   approveAsk = () =>
   {
     this.setState({approveDesign:true});
   }

   markApproverAction = (status) =>
   {
    const data = {
      task_id:this.props.comment.task_id,
      comment_id: this.props.comment.id,
      status:status,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.approveDesign, data, {
        headers: headers
      })
      .then((response) => {
        if(response.data.success == 1 && status == 2)
         {
            notifyApproved();
            this.props.pullnewcomments(1);
         }else if(response.data.success == 1 && status == 3)
         {
            notifyRevision();
            this.props.pullnewcomments(1);
         }else if(response.data.success == 10)
         {
          notifyApproveRejectError();
         }
         
      })
      .catch((error) => {
        //notifyApproveRejectError();
      })

      this.setState({
        approveDesign:false,
        revisionDesign:false
      });
   }

   markAsRevised = () =>
   {
    this.setState({
      approveDesign:false,
      revisionDesign:false
    });
   }


   revisionAsk = () =>
   {
    this.setState({revisionDesign:true})
   }

   closeDialogue = () => {
    this.setState({
      approveDesign:false,
      revisionDesign:false
    });
   };

   needDesignApprovalClick = () =>
   {
     this.setState({designApprovalNeeded: !this.state.designApprovalNeeded});
   }

  iVerifiedClick =(e) =>
  {
   this.setState({spellCheck: e.target.value, verificationError:false});
  }

  iPackagedClick =(e) =>
  {
   this.setState({iPackaged: e.target.value, packageError:false});
  }

  updateFileText = (arrayname, values) =>
  {
    this.setState({commentImagesTextArray:values});
  }

  handClickUpdate = (e) => {
    if(e.target.checked === true)
      this.setState({ commentImagesTextOptions: e.target.value, commentAttachmentsChange:true });
    else
      this.setState({ commentImagesTextOptions: 0, commentAttachmentsChange:true });
    const _this = this;
    setTimeout(()=>
    {
      _this.setState({commentAttachmentsChange:false});
    }, 500);
  };

  toggleSidebar = () => {
    this.setState({ showMenu: !this.state.showMenu });
  }

  toggle = () => {
    this.setState({ dropdownopen: !this.state.dropdownopen });
  }
  
  linkcopyAction = ()=>
  {
    console.log("copied");
  }
  
  render()
    {
     return(
      <div className="ticket-posted-comments cmd_viewbox edit-comments-wrap" >
        {this.props.showeditoption && this.props.editid == this.props.comment.id?
        (
        <>
            <div className="new-ticket-details">
                <ReactQuill defaultValue={this.state.editCommentText}
                onChange={this.handleChangeEditor}
                className={this.state.detailError? 'field-error' : ''}
                placeholder= "Enter your comments here..." 
                modules={this.modules}
                ref={this.oldCommentRef}
                 id="old-comment"
                theme="snow" />
            </div>
            {AccessCheckBlock('i_am_designer') &&
            (
              <div className="comment-fields-wrap d-flex">
                <Row>
                  
                  <Col sm={12}>
                    <span className="area_label">Inspiration Links</span>
                    <TextareaAutosize
                        style={{ minHeight: 45, padding:10, }} 
                      value={this.state.inspiration} 
                      // placeholder="Inspiration - Add inspiration links only seperated by comma. Do not add images." 
                      onChange={(e) => this.setState({ inspiration: e.currentTarget.value })}/>

                  </Col> 
                  <Col sm={12}>
                  <span className="area_label">Resource Links</span>
                  <TextareaAutosize
                      style={{ minHeight: 45, padding:10, }} 
                    value={this.state.resourcelinks} 
                    // placeholder="Resource Links - Add resource links only seperated by comma.  Do not add images." 
                    onChange={(e) => this.setState({ resourcelinks: e.currentTarget.value })}/>

                  </Col>
                  <Col sm={12}>
                    <span className="area_label">Notes</span>
                    <ReactQuill value={this.state.pmnote} 
                    onChange={(value) => this.setState({ pmnote: value })}
                    className={this.state.detailError? 'Note-to-editor field-error' : 'Note-to-editor'}
                    // placeholder= "Note to PL / PM / TL / COM / Client - Do not add images here." 
                    modules={this.nonemodules}
                    theme="snow" />
                  </Col>
                  <Col sm="6" className="need_approval_box">
                  <span className="label_font">Need Design Approval</span>
                    <Input type="checkbox" id="need-approval" onClick={this.needDesignApprovalClick}
                    checked={this.state.designApprovalNeeded && 'checked'} />
                    <Label className="need-approval" for="need-approval">Yes</Label>
                  </Col>
                  <Col sm="6" style={{ margin:'12px 0px' }}>
                    {!this.state.designApprovalNeeded &&
                    ( 
                      <PeopleTags tagslist={this.props.tagslist} 
                      settags={this.setApprover.bind(this)}
                      tags={this.state.approverAssginee}
                      placeholder="Approved By"
                      />
                    )}
                  </Col>

                  {(this.state.stringifiedDescription && this.state.stringifiedDescription.Softwares && (this.state.stringifiedDescription.Softwares.includes('Ai') || this.state.stringifiedDescription.Softwares.includes('INDD'))) &&
                    (
                    <Col sm={12} className="confirm-box d-flex flex-column">
                      <Label className={`package-correct ${this.state.packageError && 'verification-required'}`} >I packaged files correctly: </Label>
                      <div className='d-flex verify-options'>
                      <div className="theme-radio">
                          <Input type="radio" name="package-correct" value="true"  id="package-yes" checked={this.state.iPackaged === 'true' && 'checked'}
                          onClick={(e)=>this.iPackagedClick(e)}   />
                          <Label for="package-yes">Yes</Label>
                        </div>
                        <div className="theme-radio">
                            <Input type="radio" name="package-correct" value="false" id="package-no" checked={this.state.iPackaged === 'false' && 'checked'}
                          onClick={(e)=>this.iPackagedClick(e)}  />
                            <Label for="package-no">No</Label>
                        </div>
                        <div className="theme-radio">
                            <Input type="radio" name="package-correct" value="NA"  id="package-na" checked={this.state.iPackaged === 'NA' && 'checked'}
                          onClick={(e)=>this.iPackagedClick(e)}   />
                            <Label for="package-na">Not Applicable</Label>
                        </div>
                      </div>
                    </Col>
                  )}

                  <Col sm={12} className="confirm-box d-flex flex-column">
                      <Label className={`spell-check ${this.state.verificationError && 'verification-required'}`} for="spell-check">I have completed the spell check: </Label>
                      <div className='d-flex verify-options'>
                        <div className="theme-radio">
                          <Input type="radio" name="spell-check" value="true"  id="spell-check-edit-yes" checked={(this.state.spellCheck == 'true' || this.state.spellCheck === true) && 'checked'}
                          onClick={(e)=>this.iVerifiedClick(e)}   />
                          <Label for="spell-check-edit-yes">Yes</Label>
                        </div>
                        <div className="theme-radio">
                            <Input type="radio" name="spell-check" value="false" id="spell-check-edit-no" checked={(this.state.spellCheck == 'false' ||  this.state.spellCheck === false) && 'checked'}
                            onClick={(e)=>this.iVerifiedClick(e)}  />
                            <Label for="spell-check-edit-no">No</Label>
                        </div>
                        <div className="theme-radio">
                            <Input type="radio" name="spell-check" value="NA"  id="spell-check-edit-na" checked={this.state.spellCheck == 'NA' && 'checked'}
                            onClick={(e)=>this.iVerifiedClick(e)}   />
                            <Label for="spell-check-edit-na">Not Applicable</Label>
                        </div>
                      </div>
                    </Col>
                </Row>
              </div>
            )}

            <KFileUpload 
              myid={this.props.myId} 
              folderpath="task/commentattachments" 
              arrayname="commentAttachments"  
              storefiles={this.storeCommentFileNames}
              deletefiles={this.deleteCommentFileNames}   />

            {(this.state.commentImagesUpdated && this.state.commentImagesUpdated.length>0 && this.state.showAttachments) &&
            (
              <>
                <div className='reminder-options custom_radio options-items-parent d-flex comment_image_text'>
                    <div className='d-flex options-items'>
                      <Input checked={this.state.commentImagesTextOptions == 2 && 'checked'} id="textInstructionsseparate" 
                      type="checkbox" name="commentImagesTextOptions" value="2" onClick={this.handClickUpdate}  />
                      <Label for="textInstructionsseparate">Want to add instructions for attachments</Label>
                    </div>
                </div>
                <LightboxPop 
                attachments={this.state.commentImagesUpdated} 
                deleteitem={'yes'}
                deletefiles={this.deleteCommentFileNames}
                
                listtype={this.state.commentImagesTextOptions == 2 ? this.state.commentImagesTextOptions : 1}
                taskAttachmenttextarray={this.state.commentImagesTextArray}
                updatefiletext={this.updateFileText}
                />
              </>
            )}

            {(this.state.detailError || this.state.verificationError) &&
            (
              <div className='required-field-text-error'>Please check the required field data</div>
            )}

            <div className="add-ticket update-comment d-flex justify-content-end">
              <Button className="edit-ticket-save btn btn-grey " onClick={this.cancelCommentUpdate}>Cancel</Button>
              <Button className="btn btn-green save-ticket add_cmd_btn" 
              disabled={this.state.saveupdateProgress && 'disabled'}
              onClick={this.updatetaskComment}>Update Comment</Button>
            </div>
        </>
        ):
        (
          <>
            <div className="d-flex justify-content-between comment-meta">
                <div className="comd_pop_head">
                <div className="posted-by user_detail">
                 
                    {/*<div className="user_pic"></div>*/}
                    <div className="user_avatar"><img src={urls.storageUrl+"storage/profile/image/"+this.props.comment.comment_by+".jpg?"+this.state.randomNum} alt="back" /></div>
                  <div className="post-date-time flex-box">
                  {this.props.comment.name}
                  <div className="daten_time">
                  <span className="posted-on">
                  {moment((new Date(this.props.comment.updated_on)).getTime()).tz(this.props.mytimezone).format('dddd, Do MMMM, YYYY')}
                  </span>
                  <span className="posted-time">
                      {moment((new Date(this.props.comment.updated_on)).getTime()).tz(this.props.mytimezone).format('hh:mm a')}
                  </span>
                  </div>
                 
                  </div>
                  <div className="post-date-time">
                   
                  <div className="dropdownboard view_cmd_actions">
                    <Dropdown className='ticket-module-selector'
                      isOpen={this.state.dropdownopen}
                      toggle={this.toggle} >
                      <DropdownToggle color='' caret>
                        <img src={Dropicon} alt="back" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {((AccessCheckBlock('i_am_admin') || (this.state.showEditOption == true && this.props.comment.comment_by == this.props.myid)  ) && this.props.comment.comment_type === 1) &&
                          (
                            <DropdownItem  onClick={this.showEditOption.bind(this, this.props.comment.id, this.props.comment)}>
                              Edit
                            </DropdownItem>
                          )}
                          <DropdownItem>
                          <ClipboardCopyCommentLink
                                copyText={urls.domainURL+"/task/detail/"+this.props.project_id+"/"+this.props.tid_cpt+"#"+this.props.comment.comment_id}
                                copyaction={this.linkcopyAction}
                               />
                          </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  
                  </div>
                 
                </div>
                </div>
                
                </div>
                
            </div>
            
            <div className={`comment-text comment-view-newstyle `}>
               
                <Linkify  options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ ReactHtmlParser( this.stripslashes(this.props.comment.comment) +" ")}</Linkify>
                
                {this.props.commentattachments.length>0 &&
                (  <>
                    <div className='d-flex comment-new-sec'>
                      <h6>Designs</h6>
                      {this.props.downloadmessage && this.props.downloaditem == 'Comment-Attachments-' ?
                        (
                          <div className='down-all-files'>
                              Please wait while we prepare the download file.
                          </div>
                        ):
                        (
                          <div className='down-all-files' onClick={this.props.downloadfiles.bind(this,'Comment-Attachments-', this.props.commentattachments)}>
                           Download all
                          </div>
                        )}
                      </div>
                    <div class="task-comment-attachements d-flex">
                      <div class="attachment-items d-flex justify-contents-between">
                        <LightboxPop attachments={this.props.commentattachments} 
                        attachmenttexts={this.state.commentImagesTextArray && this.state.commentImagesTextArray && this.state.commentImagesTextArray.length > 0 ? this.state.commentImagesTextArray : []}
                        />
                      </div>
                    </div>
                  </>
                )}
                
                <div className='source-output-other-folder-view'>
                {this.state.mockupFolders?.length>0 &&
                    (
                      <div className='folder-view-items'>
                          <h6>Mockups</h6>
                          
                          <div className='view-items-grid'>
                     
                            {this.state.mockupFolders.map((outputfolder, index)=>
                            {
                              return(
                                <>
                               
                                <span className='folder-view-name' href="#" key={outputfolder.folder_name}>
                                  <a href={outputfolder.path} target="_blank">
                                    {outputfolder.folder_name}
                                    <span className="copy_url_link" onClick={(e)=> e.preventDefault()}>
                                    <ClipboardCopyCommentLink
                                        copyText={`${outputfolder.folder_name} : ${outputfolder.path}`}
                                        copyaction={this.linkcopyAction}
                                    />
                                    </span>
                                  </a>
                                </span>
                             </>
                              )
                            })}
                          </div>
                      </div>
                    )}

                    {this.state.attachingFoldersOutput?.length>0 &&
                    (
                      <div className='folder-view-items'>
                          <h6>Output Folders</h6>
                          
                          <div className='view-items-grid'>
                     
                            {this.state.attachingFoldersOutput.map((outputfolder, index)=>
                            {
                              return(
                                <>
                               
                                <span className='folder-view-name' href="#" key={outputfolder.folder_name}>
                                  <a href={outputfolder.path} target="_blank">
                                    {outputfolder.folder_name}
                                    <span className="copy_url_link" onClick={(e)=> e.preventDefault()}>
                                    <ClipboardCopyCommentLink
                                        copyText={`${outputfolder.folder_name} : ${outputfolder.path}`}
                                        copyaction={this.linkcopyAction}
                                    />
                                    </span>
                                  </a>
                                </span>
                             </>
                              )
                            })}
                          </div>
                      </div>
                    )}
                    {this.state.attachingFoldersSource?.length>0 &&
                    (
                      <div className='folder-view-items'>
                          <h6>Source Folders</h6>
                          <div className='view-items-grid'>
                            {this.state.attachingFoldersSource.map((outputfolder, index)=>
                            {
                              return(
                                <span className='folder-view-name' key={outputfolder.folder_name}>
                                  <a href={outputfolder.path} target="_blank">{outputfolder.folder_name}
                                  <span className="copy_url_link" onClick={(e)=> e.preventDefault()}>
                                    <ClipboardCopyCommentLink
                                        copyText={`${outputfolder.folder_name} : ${outputfolder.path}`}
                                        copyaction={this.linkcopyAction}
                                    />
                                    </span>
                                  </a>
                                  
                                </span>
                              )
                            })}
                          </div>
                      </div>
                    )}
                    {this.state.otherFolders?.length>0 &&
                    (
                      <div className='folder-view-items'>
                          <h6>Other Folders</h6>
                          <div className='view-items-grid'>
                            {this.state.otherFolders.map((outputfolder, index)=>
                            {
                              return(
                                <span className='folder-view-name' key={outputfolder.folder_name}>
                                  <a href={outputfolder.path} target="_blank">{outputfolder.folder_name}
                                  <span className="copy_url_link" onClick={(e)=> e.preventDefault()}>
                                    <ClipboardCopyCommentLink
                                        copyText={`${outputfolder.folder_name} : ${outputfolder.path}`}
                                        copyaction={this.linkcopyAction}
                                    />
                                    </span>
                                  </a>
                                 
                                </span>
                              )
                            })}
                          </div>
                      </div>
                    )}
                </div>
                {(this.state.inspiration!='' && this.state.inspiration!= null)  &&
                (
                  <div className="comment-data"><Label>Inspiration:</Label> 
                    <span>
                      {/* <Linkify  options={this.state.options}>{ReactHtmlParser(this.state.inspiration)}</Linkify> */}
                      {ReactHtmlParser(this.replaceURLsInspiration(this.state.inspiration))}
                    </span> </div>
                )}
                {(this.state.resourcelinks!='' && this.state.resourcelinks!= null) &&
                (
                  <div className="comment-data"><Label>Resource Links:</Label> 
                    <span>
                    {/* <Linkify  options={this.state.options}>{ReactHtmlParser(this.state.resourcelinks)}</Linkify> */}
                    {ReactHtmlParser(this.replaceURLsResources(this.state.resourcelinks))}
                    </span> 
                  </div>
                )}
                {(this.state.designApprovalNeeded)&&
                (
                  <div className="comment-data approved-person"><Label>Design Approval Required:</Label> 
                   <span>Yes</span> 
                  </div>
                )}
                {(this.state.pmnote!='' && this.state.pmnote!= null ) &&
                (
                  <div className="comment-data red-data"><Label>Note to PL / PM / TL / COM / Client:</Label> 
                    <span className="red_text"> 
                    <Linkify  options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.state.pmnote)}</Linkify>
                    </span> 
                  </div>
                )}

                {(this.props.commentfields && 
                (this.props.commentfields.packaged == 'true' || this.props.commentfields.packaged === true || 
                this.props.commentfields.packaged == 'false' || this.props.commentfields.packaged === false ||
                this.props.commentfields.packaged == 'NA') ) &&
                (
                  <div className="comment-data approved-person">
                    <Label>Packaged Files Correctly: </Label> 
                    {(this.props.commentfields.packaged == 'true' || this.props.commentfields.packaged === true) ?
                    (
                      <span>Yes</span> 
                    ):
                    (
                      <>
                        {(this.props.commentfields.packaged == 'false' || this.props.commentfields.packaged === false)?
                        (
                          <span>No</span> 
                        ):
                        (
                          <span>Not Applicable</span> 
                        )}
                      </>
                    )}
                  </div>
                )}
                
                {(this.props.commentfields && 
                (this.props.commentfields.spell_check == 'true' || this.props.commentfields.spell_check === true || 
                this.props.commentfields.spell_check == 'false' || this.props.commentfields.spell_check === false ||
                this.props.commentfields.spell_check == 'NA') ) &&
                (
                  <div className="comment-data approved-person">
                    <Label>Completed Spell Check: </Label> 
                    {(this.props.commentfields.spell_check == 'true' || this.props.commentfields.spell_check === true) ?
                    (
                      <span>Yes</span> 
                    ):
                    (
                      <>
                        {(this.props.commentfields.spell_check == 'false' || this.props.commentfields.spell_check === false)?
                        (
                          <span>No</span> 
                        ):
                        (
                          <span>Not Applicable</span> 
                        )}
                      </>
                    )}
                  </div>
                )}

                {this.state.taskApprovalNeeded === 1 &&
                (
                  <div className='d-flex approval-wapper'>
                      <Button name="approve-task" className='btn btn-green' onClick={this.approveAsk}>Approve</Button>
                      <Button name="revise-task" className='btn btn-green' onClick={this.revisionAsk}>Revision Required</Button>
                  </div>
                )}

                {(this.state.approverAssginee && this.state.approverAssginee.length>0)&&
                (
                  <div className="comment-data approved-person" ><Label>Approved By:</Label> 
                   <span>{this.state.approverAssginee[0].name}</span> 
                  </div>
                )}

            </div>
            
          </>
        )}

        {this.state.approveDesign && (
          <ConfirmAction
            purpose="change"
            closeDialogue={this.closeDialogue}
            show={this.state.approveDesign}
            note="*Are you sure?"
            content="You are about to mark this design as approved"
            handledeletion={this.markApproverAction.bind(this, 2)}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="deletetask"
          />
        )}
        {this.state.revisionDesign && (
          <ConfirmAction
            purpose="change"
            closeDialogue={this.closeDialogue}
            show={this.state.revisionDesign}
            note="*Are you sure?"
            content="You are about to request a revision for this task. Make sure you post your revision comments."
            handledeletion={this.markApproverAction.bind(this, 3)}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="deletetask"
          />
        )}

    </div>
    )
    }
}

