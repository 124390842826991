import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const LoanVerificationDialog = ({ isOpen, toggle, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="loan-verification-dialog">
      <ModalHeader toggle={toggle}>
        Confirm Loan Deduction Verification
      </ModalHeader>
      <ModalBody>
        Are you sure you want to verify this loan deduction? This action cannot be undone.
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button color="primary" onClick={onConfirm}>
          Verify Deduction
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LoanVerificationDialog;