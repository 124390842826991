import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import Header from '../header/Header';
import { urls } from "../../services/UrlList";
import { Container, Row, Col, Input, Button, Label, Breadcrumb, FormGroup, 
  BreadcrumbItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import axios from 'axios';
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.min.css';
import moment from "moment";
import Homebackicon from '../../images/bc_homeicon.svg'
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import PeopleTags from "./PeopleTag";
import LightboxPop from "./LightboxPop";
import EditTask from "./EditTask";
import toast, { Toaster } from 'react-hot-toast';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import KFileUpload from './kFileUpload';
import TaskComments from './TaskComments';
import AccessDenied from "./AccessDenied";
import Dropicon from '../../images/drop_board_icon.png';
import Linkify from 'linkifyjs/react';
import ReactHtmlParser from 'react-html-parser';
import Timmer from './Timmer';
import ScrollTop from "./scrollTop"
import ConfirmAction from '../admincomment/ConfirmAction';
import { AccessCheck, AccessCheckBlock } from './AccessCheck';
import MaterialIcon from "material-icons-react";
import ProjectCurrentStatus from './ProjectCurrentStatus';
import ImageUploader from "quill-image-uploader";
import TextareaAutosize from 'react-autosize-textarea';
import TrelloCard from "./Tasks/task-single/TrelloCard";
import TrelloIcon from '../svgicon/TrelloIcon';
import TaskTickets from "./TaskTickets/TaskTicket";
import { Scrollbars } from 'react-custom-scrollbars';
import { saveAs } from "file-saver";
import TaskIssueReporting from "./TaskIssueReporting";
import SideMenu from "../NewSideMenu/NewSideMenu";
import TaskMoveDetail from "./TaskMoveDetail";
import TaskWorkLogs from "./TaskWorkLogs";
import EditTaskTitle from "./EditTaskTitle";
import MyBoards from "./MyBoards";
import SourceManagement from "./SourceManagement/SourceManagement";
import ConnectionWith360 from './ConnectionWith360';
import Kimp360Logo from '../../images/Kimp_360_Logo.svg'; 
import Quick360Access from './Quick360Access';
import CanvaAccess from './CanvaAccess';
import MailboxEmail from './MailboxEmail';
import BestDesignsApproval from './BestDesignsApproval';
import PrivateNotes from './PrivateNotes';
import { fixQuillColorPickerScroll } from './quillUtils';

Quill.register("modules/imageUploader", ImageUploader);

const notifySuccess = () => toast('Card updated successfully.');
const notifyCommentSuccess = () => toast('Comment added successfully.');
const notifyCommentUpdate = () => toast('Comment updated successfully.');
const notifyError = () => toast('Something went wrong, please try again.');
const notifyRunningError = () => toast('Looks like this card timer is currently running by the assigned person and you can not reassign a running card.');
const notifyErrorShift = () => toast('You must have an active shift to work on cards. Please start your shift.');
const notifyStatus = () => toast('Card status updated');
const notifyTaskEnded = () => toast('Card timer ended.');
const notifyTaskDueChange = () => toast('Card due date updated.');
const notifyCopied = () => toast('Color code copied to clipboard.');
const notifyPriority = () => toast('Card has been marked as priority card.');
const notifyDuplication = () => toast('Duplicate card created successfully.');
const notifyAssigneeUpdate = () => toast('Card assignee updated successfully.');
const notifyIssueClear = () => toast('You can not unmark an issues unless you remove all responsible team members');
const notifyFollow = () => toast('You are now following this card.');
const notifyUnFollow = () => toast('You unfollowed this card.');
const notifyTaskMove = () => toast('Card moved successfully.');

const yesterday = moment().subtract(1, 'day');
const disablePastDt = current => {
  return current.isAfter(yesterday);
}

var atValues = [
  { id: 1, value: 'Fredrik Sundqvist' },
  { id: 2, value: 'Patrik Sjölin' }
];
var selectedMentions = [];

const toolbarOptions = [
  ['bold', 'italic', 'underline'],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ['link', 'image'],
  ['clean']
];



export default class TaskDetails extends Component {
  fileObj = [];
  fileArray = [];
  fileNamesArray = [];
  constructor(props) {
    super(props);
    this.state = {
      mimimisedSourceView:false,
      newTicket: false,
      saveupdateProgress: false,
      duplicateCreated: false,
      myPrivileges: [],
      myId: "",
      kim360id:'',
      myBaseId: "",
      myName: "",
      myTimezone: '',
      showProgress: true,
      taskUpdateProgress: false,
      uploadingFiles: false,
      commentAddingProgress: false,
      taskRunningProgress: true,
      taskRunningDetails: [],
      industries:[],
      iCanRunTimmer: false,
      commentAttachmentsChange: false,
      reminderTime: '',
      taskDetails: '',
      taskStatus: '',
      oldtaskStatus: '',
      showTaskEdit: false,
      brandLogos: false,
      brandFiles: false,
      taskNewComment: '',
      showMenu: true,
      commentAttachments: [],
      taskComments: [],
      showTaskEditOption: false,
      editTaskCommentFlag: false,
      editTaskCommentPopupFlag: false,
      editTaskCommentId: '',
      editTaskComment: '',
      accessDenied: '',
      projectDetails: [],
      progressBar: true,
      progressBarDeletion: false,
      taskDeleted: false,
      dropdownopen: false,
      oldDueDate: '',
      needDueUpdate: false,
      showDeleteConfirmation: false,
      showDuplicateConfirmation: false,
      brand: 0,
      brandNotes: [],
      notesFetch: false,
      noNotesAdded: false,
      showPinText: false,
      animate: false,
      showAction: false,
      openNCount: 0,
      inspiration: '',
      resourcelinks: '',
      approverAssginee: [],
      commentApprover: '',
      pmnote: '',
      needDesignApproval: false,
      verificationError: false,
      packageError: false,
      iVerified: '',
      iPackaged: '',
      taskisActiveRunning: false,
      showAllComment: false,
      assigneeUpdate: false,
      cardDisplayNum: 0,
      CardDisplayTop: 30,
      taskCloseCount: 0,
      cardDetails1: {},
      cardComments1: [],
      cardAttachments1: [],
      displayTrelloCard1: false,
      trelloCard: '',
      taskIssueTypes: [],
      taskIssueLogs: [],
      issueLogLoader: false,
      showIssueLog: false,
      taskTickets: [],
      ticketSubject: '',
      ticketDesc: '',
      downloadMessage: false,
      downloadItem: '',
      scrollView: true,
      followers: [],
      showFollowers: true,
      showMoveModal:false,
      workLogsLoader:false,
      showWorkLogs:false,
      workLogs:[],
      showName:true,
      showTitleModal:false,
      pastDueError:false,
      showClientCardMove:false,
      showAssigneeBlock:true,
      sourceManagement:false,
      showPrivateNote:false,
      cardId360:'',
      cardIdUpdated:true,
      options: {
        attributes: null,
        className: 'linkified',
        defaultProtocol: 'http',
        events: null,
        format: function (value, type) {
          return value;
        },
        formatHref: function (href, type) {
          return href;
        },
        ignoreTags: [],
        nl2br: false,
        tagName: 'a',
        target: {
          url: '_blank'
        },
        rel: 'noopener',
        validate: true,
      },
      commentImagesTextOptions:1,
      commentImagesTextArray:[],
      commentImagesText:'',
      commentAttachmentsChange:false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.setAssignees = this.setAssignees.bind(this);
    this.setApprover = this.setApprover.bind(this);
    this.reactTags = React.createRef();
    this.commentRef = React.createRef();
  }

  modules = {
    toolbar: toolbarOptions,
    mention: {
      allowedChars: /^[A-Za-z\s]*$/,
      mentionDenotationChars: ["@"],
      mentionContainerClass: 'ql-mention-list-container-bottom',
      listItemClass: 'ql-mention-list-item',
      mentionListClass: 'ql-mention-list',
      minChars: 2,
      source: function (searchTerm, renderList, mentionChar) {
        let values;

        if (mentionChar === "@") {
          values = atValues;
        }

        if (searchTerm.length > 0) {
          const matches = [];
          for (var i = 0; i < values.length; i++)
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
          renderList(matches, searchTerm);
        }
      },
      onSelect: function (item, insertItem) {
        selectedMentions.push(item['id']);
        insertItem(item);
      }
    },
    imageUploader: {
      upload: file => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          data.append('file', file);
          data.append('filename', file.name);
          data.append('filesize', file.size);
          data.append('filetype', file.type);
          data.append('folder_path', 'editor')
          data.append('user_id', this.state.myId);
          axios.post(urls.uploadProjectMediaEditor, data, {
          })
            .then(res => {
              resolve(res.data.file_name);
            })
            .catch(error => {
              reject("Upload failed");
              console.error("Error:", error);
            });
        });
      }
    }
  };

  noneoptions = {
    toolbar: [],
    mention: {
      allowedChars: /^[A-Za-z\s]*$/,
      mentionDenotationChars: ["@"],
      mentionContainerClass: 'ql-mention-list-container-bottom',
      listItemClass: 'ql-mention-list-item',
      mentionListClass: 'ql-mention-list',
      minChars: 2,
      source: function (searchTerm, renderList, mentionChar) {
        let values;

        if (mentionChar === "@") {
          values = atValues;
        }

        if (searchTerm.length > 0) {
          const matches = [];
          for (var i = 0; i < values.length; i++)
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
          renderList(matches, searchTerm);
        }
      },
      onSelect: function (item, insertItem) {
        selectedMentions.push(item['id']);
        insertItem(item);
      }
    },
    imageUploader: {
      upload: file => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          data.append('file', file);
          data.append('filename', file.name);
          data.append('filesize', file.size);
          data.append('filetype', file.type);
          data.append('folder_path', 'editor')
          data.append('user_id', this.state.myId);
          axios.post(urls.uploadProjectMediaEditor, data, {
          })
            .then(res => {
              resolve(res.data.file_name);
            })
            .catch(error => {
              reject("Upload failed");
              console.error("Error:", error);
            });
        });
      }
    }
  };

  handleClick(e) {
    this.setState((prevState) => {
      return { animate: !prevState.animate }
    });
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  componentDidMount = () => {
    document.title = "KIMPHUB - Boards";
    AccessCheck('access_projects_management_module');
    this.checkUserPrivilege();
    const _this = this;
    setTimeout(() => {
      _this.getTaskAssets();
      _this.getTaskDetails();
      _this.getTaskTickets();
      _this.autoSavedComment();
    }, 500);
    window.addEventListener("storage", this.onRefreshMessage);

   this.scrollFixCleanup = fixQuillColorPickerScroll('new-comment', this.commentRef);
  };

  minimisedSourceUpdate = () =>
  {
    this.setState({mimimisedSourceView:true});
  }

  autoSavedComment = () => {
    if (localStorage.getItem("commentData") !== null) {
      var commentData = JSON.parse(localStorage.getItem("commentData"));
      if(commentData.taskID && commentData.taskID === this.props.match.params.id)
      {
        this.setState({
          taskNewComment: commentData.taskNewComment ? commentData.taskNewComment : '',
          approverAssginee: commentData.approverAssginee && commentData.approverAssginee.length>0 ? commentData.approverAssginee : [],
          commentApprover: commentData.commentApprover ? commentData.commentApprover:'',
          needDesignApproval: commentData.needDesignApproval && commentData.needDesignApproval,
          inspiration: commentData.inspiration && commentData.inspiration,
          resourcelinks: commentData.resourcelinks && commentData.resourcelinks,
          pmnote: commentData.pmnote ? commentData.pmnote:'',
          iPackaged: commentData.iPackaged && commentData.iPackaged,
          iVerified: commentData.iVerified && commentData.iVerified,
          commentAttachments:commentData.commentAttachments && commentData.commentAttachments.length>0  ? commentData.commentAttachments:[]
        });
      }
    }
  }

  onRefreshMessage = (ev) => {
    if (localStorage.getItem("enrefresh") == 'refreshplz') {
      localStorage.setItem('enrefresh', '');
      this.getTaskDetails();
    }
    if (localStorage.getItem("invalidshift") == '0') {
      this.setState({ showMessage: false });
    } else if (localStorage.getItem("invalidshift") == '1') {
      this.setState({ showMessage: true });
    } else if (localStorage.getItem("invalidshift") == '2') {
      this.setState({ showMessage: true });
    } else if (localStorage.getItem("invalidshift") == '3') {
      this.setState({ showMessage: true });
    }

  }

  componentWillUnmount = () => {
    this.checkUserPrivilege();
    this.getTaskAssets();
    this.getTaskDetails();
    this.getTaskTickets();
    window.removeEventListener("storage", this.onRefreshMessage);
    if (this.scrollFixCleanup) {
      this.scrollFixCleanup();
    }
  }

  componentDidUpdate(prevState) {
    if (prevState.fileTypeError !== this.state.fileTypeError && this.state.fileTypeError === true) {
      const _this = this;
      setTimeout(function () {
        _this.setState({ fileTypeError: false });
      }, 5000);
    }
    if (prevState.taskDeleted !== this.state.taskDeleted && this.state.taskDeleted === true) {
      const _this = this;
      setTimeout(function () {
        _this.setState({ taskDeleted: false });
        if (_this.state.projectDetails.board_type == 1)
          _this.props.history.push('/task-list/' + _this.props.match.params.bid+"?uuid="+_this.state.myId);
        else
          _this.props.history.push('/internal-task-list/' + _this.props.match.params.bid+"?uuid="+_this.state.myId);
      }, 2000);
    }
  }

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        kim360id: login.kimp360id,
        myBaseId: login.basecampid,
        myName: login.name,
        myTimezone: login.my_timezone
      });
    }
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });

      });
  };


  toggleSidebar = () => {
    this.setState({ showMenu: !this.state.showMenu });
  }

  toggle = () => {
    this.setState({ dropdownopen: !this.state.dropdownopen });
  }

  hideMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  }

  handleChangeEditor(value) {
    this.setState({ taskNewComment: value });
    this.setCommentStorage('taskNewComment', value);
  }
  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleChangePriority = ({ target }) => {
    let data = {};
    if (target.checked) {
      this.setState({ Priority: 1 });
      data = {
        project_id: this.props.match.params.bid,
        task_id: this.props.match.params.id,
        taskid: this.state.taskDetails.id,
        project_name: this.state.projectDetails.board_name,
        task_name: this.state.taskDetails.task_name,
        creator: this.state.myId,
        priority: 1
      };
    }
    else {
      this.setState({ Priority: 0 });
      data = {
        project_id: this.props.match.params.bid,
        task_id: this.props.match.params.id,
        taskid: this.state.taskDetails.id,
        project_name: this.state.projectDetails.board_name,
        task_name: this.state.taskDetails.task_name,
        creator: this.state.myId,
        priority: 0
      };
    }

    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.updateClientTaskPriority, data, {
      headers: headers
    })
      .then((response) => {
        if (target.checked) {
          notifyPriority();
        }
      })
      .catch((error) => {
        //notifyError();
      })
  };

  handleChangeBrand = ({ target }) => {
    this.setState({ brand: target.value });
    const data = {
      project_id: this.props.match.params.bid,
      task_id: this.props.match.params.id,
      creator: this.state.myId,
      brand_id: target.value,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.updateClientTaskBrand, data, {
      headers: headers
    })
      .then((response) => {

      })
      .catch((error) => {
        //notifyError();
      })
  };

  handleChangeIndustry = ({ target }) => {
    this.setState({ industry: target.value });
    const data = {
      project_id: this.props.match.params.bid,
      task_id: this.props.match.params.id,
      creator: this.state.myId,
      industry: target.value,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.updateClientTaskIndustry, data, {
      headers: headers
    })
      .then((response) => {
        if(response.data.success == 1)
        {
          toast('Industry updated successfully.');
        }else
        {
          toast('Something went wrong, please try again.');
        }
      })
      .catch((error) => {
        //notifyError();
      })
  };


  onTdateChange = (date) => {
    this.setState({ ticketDate: date });
  }



  handleOnChange(key, e) {
    let post = this.state.post;
    post[key] = e.target.value; // key variable can be a string
  }

  setDueDate = async (e) => {
    this.setState({ dueDate: e, needDueUpdate: true });
  }


  setDueDateUpdate = async () => {

    let currentmonent = moment();
    if(this.state.dueDate.diff(currentmonent) <= 0)
    {
      this.setState({pastDueError:true});
      return false;
    }else
    {
      this.setState({pastDueError:false, showAssigneeBlock:false});
    }

    const data = {
      project_id: this.props.match.params.bid,
      tid_cpt: this.props.match.params.id,
      task_id: this.state.taskDetails.id,
      comment_attachments: this.state.commentAttachments,
      creator: this.state.myId,
      oldDueDate: this.state.oldDueDate,
      dueDate: this.state.dueDate,
      project_name: this.state.projectDetails.board_name,
      task_name: this.state.taskDetails.task_name,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.updateClientTaskDueDate, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({ needDueUpdate: false, oldDueDate: this.state.dueDate, showAssigneeBlock:true });
        notifyTaskDueChange();
        this.getTaskComments();
      })
      .catch((error) => {
        notifyError();
        this.setState({showAssigneeBlock:true});
      })
  }

  taskStatusUpdate = (status) => {
    this.setState({ taskUpdateProgress: true })
    const data = {
      project_id: this.props.match.params.bid,
      task_id: this.props.match.params.id,
      tid: this.state.taskDetails.id,
      comment_attachments: [],
      creator: this.state.myId,
      status: status,
      oldtaskStatus: this.state.oldtaskStatus,
      project_name: this.state.projectDetails.board_name,
      task_name: this.state.taskDetails.task_name,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.updateClientTaskStatus, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({
          taskStatus: status,
          oldtaskStatus: status,
          taskUpdateProgress: false
        })
        notifyStatus();
        this.getTaskComments();
      })
      .catch((error) => {
        notifyError();
        this.setState({
          taskUpdateProgress: false
        })
      })
  }

  setReminderTimeOptions = (e) => {
    if (e.target.value > 0) {
      this.setState({
        reminderTime: new moment(new Date()).add(e.target.value, 'h').toDate()
      })
    }
  }
  getTaskAssets = () => {
    const data = {
      project_id: this.props.match.params.bid
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.taskAssets, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({
          availableAssignees: response.data.assignees,
          availableNotifiers: response.data.notifiers,
          availableBrands: response.data.brands,
          industries: response.data.industries,
          designTypes: response.data.design_types,
          labels: response.data.labels,
        })
        atValues = response.data.mentions;
      })
      .catch((error) => {
        //notifyError();
      })
  }

  getTaskIssueTypes = (taskid) => {
    const data = {
      task_id: taskid
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.taskIssueTypes, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({
          taskIssueTypes: response.data.success == 1 ? response.data.issue_items : [],
        })
      })
      .catch((error) => {
        //notifyError();
      })
  }

  getTaskIssueLogs = () => {
    this.setState({
      issueLogLoader: true
    })
    const data = {
      task_id: this.state.taskDetails.id
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.getTaskIssueLogs, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({
          taskIssueLogs: response.data.logs,
          issueLogLoader: false,
          showIssueLog: true
        })
      })
      .catch((error) => {
        this.setState({
          issueLogLoader: false
        })
      })
  }

  closeIssueLogs = () => {
    this.setState({
      showIssueLog: false
    })
  }
  getTaskDetails = async () => {
    this.setState({
      showProgress: true,
      progressBar: true
    })
    const data = {
      project_id: this.props.match.params.bid,
      task_id: this.props.match.params.id,
      myid: this.state.myId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    await axios.post(urls.getTaskDetails, data, {
      headers: headers
    })
      .then((response) => {
        if (response.data.success !== 10 && response.data.details[0] && response.data.details[0].task_name != '') {
          document.title = "HUB - " + response.data.details[0].task_name;
        }
        if (response.data.success !== 10) {
          this.findTrelloId(response.data.details[0].task_name);
          this.getTaskIssueTypes(response.data.details[0].id);
        }
        this.setState({
          accessDenied: response.data.success === 10 ? true : false,
          projectDetails: response.data.success !== 10 && response.data.project_details[0],
          taskStatus: response.data.success !== 10 && response.data.details[0].status,
          oldtaskStatus: response.data.success !== 10 && response.data.details[0].status,
          taskDetails: response.data.success !== 10 && response.data.details[0],
          brand: response.data.success !== 10 && response.data.details[0].brand_id,
          taskAssignees: response.data.success !== 10 && JSON.parse(response.data.details[0].task_assignee_list),
          taskNotifyies: response.data.success !== 10 && JSON.parse(response.data.details[0].task_notify_list),
          taskLabels: response.data.success !== 10 && JSON.parse(response.data.details[0].task_label_list),
          dueDate: response.data.success !== 10 && new Date(response.data.details[0].due_date),
          oldDueDate: response.data.success !== 10 && new Date(response.data.details[0].due_date),
          stringifiedDescription: response.data.success !== 10 && JSON.parse(response.data.details[0].task_description_stringified),
          stringifiedAttachments: response.data.success !== 10 && JSON.parse(response.data.details[0].task_attachments_stringified),
          stringifiedtextlinks: response.data.success !== 10 && JSON.parse(response.data.details[0].task_attachments_textlinks),
          taskAttachments: response.data.success !== 10 && JSON.parse(response.data.details[0].task_attachments),
          showProgress: false,
          progressBar: false,
          Priority: response.data.success !== 10 && JSON.parse(response.data.details[0].task_priority)
        })

        if (response.data.success !== 10 && response.data.details[0].brand_id > 0) {
          this.getTaskbrandAssets(response.data.details[0].brand_id);
        }

        if (response.data.success !== 10 && response.data.details[0].task_follow_list !== null) {
          this.setFollowers(response.data.details[0].task_follow_list);
        }

        this.getTaskComments();
        this.getTaskRunningLog();
        this.checkForAssignee();
        this.showBrandinfo();
      })
      .catch((error) => {
        //notifyError();
        this.setState({
          showProgress: false,
          progressBar: false,
          taskRunningProgress: false
        })
      })
  }

  getTaskbrandAssets = (brandid) => {
    const data = {
      brand_id: brandid
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.checkAssetExist, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({
          brandLogos: response.data.Logos > 0 ? true : false,
          brandFiles: response.data.others > 0 ? true : false
        });
      })
      .catch((error) => {
        //notifyError();
      })
  }

  checkIfPossibletoEdit = () => {
    if (((moment((new Date()).getTime()).tz(this.state.myTimezone).format('X')) - (moment((new Date(this.state.taskDetails.created_on)).getTime()).tz(this.state.myTimezone).format('X')) < 900) || this.state.taskStatus === 0) {
      this.setState({ showTaskEditOption: true })
    } else {
      this.setState({ showTaskEditOption: false })
    }
  }
  getTaskComments = async () => {
    this.setState({ taskComments: [] });
    const data = {
      tid: this.state.taskDetails.id,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.getTaskComments, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({
          taskComments: response.data.comments
        })
        const _this = this;
        setTimeout(function () {
          var type = window.location.hash.substr(1);
          if (type != '' && _this.state.scrollView) {
            const section = document.getElementById(type);
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }else if(response.data.comments && response.data.comments.length>1)
          {
              let commentidd= response.data.comments[0].comment_id;
              const section = document.getElementById(commentidd);
              section.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
          _this.setState({ scrollView: false });
        }, 1000);

      })
      .catch((error) => {
        //notifyError();
      })
  }

  arrayDiff = (a, b) => {
    return [
      ...a.filter(x => b.indexOf(x) === -1),
      ...b.filter(x => a.indexOf(x) === -1)
    ];
  }

  setApprover = (tags) => {
    if (typeof (tags) === 'object' && tags.length > 0) {
      this.setState({ approverAssginee: tags, commentApprover: tags[0].id })
      this.setCommentStorage('approverAssginee', tags);
      this.setCommentStorage('commentApprover', tags[0].id);
    }else
    {
      this.setState({ approverAssginee: [], commentApprover:'' })
      this.setCommentStorage('approverAssginee', []);
      this.setCommentStorage('commentApprover', '');
    }
  }


  setCommentStorage = (type, value) => {
    let commentData = {};
    if (localStorage.getItem("commentData") !== null) {
      let commentTemp = JSON.parse(localStorage.getItem("commentData"));
      if(commentTemp.taskID && commentTemp.taskID === this.props.match.params.id)
      {
        commentData = commentTemp;
      }
    }
    commentData.taskID = this.props.match.params.id;
    if (type == 'approverAssginee')
      commentData.approverAssginee = value;
    else if (type == 'commentApprover')
      commentData.commentApprover = value;
    else if (type == 'needDesignApproval')
      commentData.needDesignApproval = value;
    else if (type == 'taskNewComment')
      commentData.taskNewComment = value;
    else if (type == 'inspiration')
      commentData.inspiration = value;
    else if (type == 'resourcelinks')
      commentData.resourcelinks = value;
    else if (type == 'pmnote')
      commentData.pmnote = value;
    else if (type == 'iPackaged')
      commentData.iPackaged = value;
    else if (type == 'iVerified')
      commentData.iVerified = value;
    else if (type == 'commentAttachments')
      commentData.commentAttachments = value;

    localStorage.setItem("commentData", JSON.stringify(commentData));
  }

  setFollowers = (followers) => {
    if (followers.length > 0) {
      let fff = followers.split(",");
      this.setState({ followers: followers.split(",") });
    } else {
      this.setState({ followers: [] });
    }
  }


  // setAssignees = (tags) =>
  // {
  //   let newassigneedif = this.arrayDiff(this.state.taskAssignees, tags);
  //   if(newassigneedif.length>0 || this.state.taskAssignees.length<=0)
  //   {
  //       this.setState({tassignees: tags,assigneeUpdate:true})
  //   }
  // }

  setAssignees = (tags) => {
    let newassigneedif = this.arrayDiff(this.state.taskAssignees, tags);
    if (newassigneedif.length > 0) {
      this.setState({ tassignees: tags, assigneeUpdate: true })
    }
  }

  setAssigneesSave = () => {
    const data = {
      project_id: this.props.match.params.bid,
      task_id: this.props.match.params.id,
      tid: this.state.taskDetails.id,
      task_name: this.state.taskDetails.task_name,
      project_name: this.state.projectDetails.board_name,
      creator: this.state.myId,
      mname: this.state.myName,
      assignees: this.state.tassignees,
      dueDate: this.state.dueDate
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.updateClientTaskAssignees, data, {
      headers: headers
    })
      .then((response) => {

        this.setState({
          taskRunningProgress: false
        })

        if (response.data.msg == 10) {
          notifyRunningError();
          this.getTaskDetails();
        } else {
          notifyAssigneeUpdate();
          this.setState({
            taskRunningProgress: false,
            taskAssignees: this.state.tassignees,
            assigneeUpdate: false
          })
          const _this = this;
          setTimeout(function () {
            _this.checkForAssignee();
            _this.getTaskComments();
          }, 1000);
        }
      })
      .catch((error) => {
        this.setState({
          taskRunningProgress: false
        })
        this.checkForAssignee();
        //notifyError();
      })
  }

  setNotifiers = (tags) => {
    if(this.state.taskNotifyies.length != tags.length)
    {
      this.setState({ tnotifiers: tags })
      const data = {
        project_id: this.props.match.params.bid,
        task_id: this.props.match.params.id,
        tid: this.state.taskDetails.id,
        creator: this.state.myId,
        notifiers: tags,
      };
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin': '*',
      }
      axios.post(urls.updateClientTaskNotifiers, data, {
        headers: headers
      })
        .then((response) => {

        })
        .catch((error) => {
          //notifyError();
        })
    }
  }

  setLabels = (tags) => {

    let newassigneedif = this.arrayDiff(this.state.taskLabels, tags);
    if (newassigneedif.length <=0 ) {
      return false;
    }

    this.setState({ selectedLabels: tags })
    const data = {
      project_id: this.props.match.params.bid,
      task_id: this.props.match.params.id,
      tid: this.state.taskDetails.id,
      creator: this.state.myId,
      task_name: this.state.taskDetails.task_name,
      project_name: this.state.projectDetails.board_name,
      labels: tags,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.updateClientTaskLabels, data, {
      headers: headers
    })
      .then((response) => {

      })
      .catch((error) => {
        //notifyError();
      })
  }

  toggleTaskEdit = () => {
    this.setState({
      showTaskEdit: !this.state.showTaskEdit
    })
  }

  closeTaskEdit = async () => {
    await this.getTaskDetails();
    this.setState({
      showTaskEdit: false,
      showTitleModal:false
    });
  }

  
  closeTaskEditTitle = async (taskname) => {
    let taskdetails = this.state.taskDetails;
    taskdetails.task_name = taskname;
    this.setState({showTitleModal:false, taskDetails: taskdetails});
  }

  mentionModule = {
    allowedChars: /^[A-Za-z\s]*$/,
    mentionDenotationChars: ["@"],
    mentionContainerClass: 'ql-mention-list-container-bottom',
    listItemClass: 'ql-mention-list-item',
    mentionListClass: 'ql-mention-list',
    minChars: 2,
    source: function (searchTerm, renderList, mentionChar) {
      let values;

      if (mentionChar === "@") {
        values = atValues;
      }

      if (searchTerm.length > 0) {
        const matches = [];
        for (var i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
        renderList(matches, searchTerm);
      }
    },
    onSelect: function (item, insertItem) {
      selectedMentions.push(item['id']);
      insertItem(item);
    }
  }

  storeCommentFileNames = async (arrayname, item) => {

    let temp = [...this.state.commentAttachments];
    await this.setState({ commentAttachments: [] });
    temp.push(item);
    await this.setState({ commentAttachments: temp });

    this.setCommentStorage('commentAttachments', temp);
  }

  deleteCommentFileNames = async (filename, arrayname) => {
    this.setState({ commentAttachmentsChange: true });
    var assetupdate = [...this.state.commentAttachments];
    this.setState({ commentAttachments: [] });
    var itemkey = assetupdate.map(function (item) { return item.file_name; }).indexOf(filename);
    assetupdate.splice(itemkey, 1);
    const _this = this;
    setTimeout(() => {
      _this.setCommentStorage('commentAttachments', assetupdate);
      _this.setState({ commentAttachments: assetupdate, commentAttachmentsChange: false });
    }, 500);
  }

  createTaskComment = () => {
    this.setState({
      comments: false,
      verificationError: false,
      detailError: false
    });
    if (this.state.taskNewComment === '') {
      this.setState({ detailError: true });
      return false;
    }
    // if((this.state.inspiration !=='' || this.state.resourcelinks !== '' ||  this.state.pmnote !== '') && !this.state.iVerified)
    //  {
    //   this.setState({verificationError:true});
    //   return false;
    //  }


    if (AccessCheckBlock('i_am_designer') && !this.state.iPackaged && this.state.stringifiedDescription && this.state.stringifiedDescription.Softwares && (this.state.stringifiedDescription.Softwares.includes('Ai') || this.state.stringifiedDescription.Softwares.includes('INDD'))) {
      this.setState({ packageError: true });
      return false;
    }
    if (AccessCheckBlock('i_am_designer') && !this.state.iVerified) {
      this.setState({ verificationError: true });
      return false;
    }

    this.setState({ commentAddingLoader: true, saveupdateProgress: true });
    const data = {
      task_id: this.state.taskDetails.id,
      comment_by: this.state.myId,
      comments: this.state.taskNewComment,
      task_name: this.state.taskDetails.task_name,
      project_id: this.props.match.params.bid,
      tid_cpt: this.props.match.params.id,
      creator: this.state.myId,
      mname: this.state.myName,
      comment_fields: {
        inspiration: this.state.inspiration,
        resourcelinks: this.state.resourcelinks,
        pmnote: this.state.pmnote,
        approver: this.state.needDesignApproval ? [] : this.state.approverAssginee,
        approval_required: this.state.needDesignApproval ? true : false,
        spell_check: this.state.iVerified,
        packaged: this.state.iPackaged
      },
      task_approval_needed: this.state.needDesignApproval ? 1 : 0,
      task_approver: this.state.commentApprover,
      comment_attachments: this.state.commentAttachments,
      fieldDataLinkText:
      {
        commentAttachmentFilesText: this.state.commentImagesTextOptions == 1? this.state.commentImagesText : '',
        commentAttachmentFilesTextArray: this.state.commentImagesTextOptions == 2? this.state.commentImagesTextArray : [],
      },
      mentions: selectedMentions,
      assignees: this.state.taskAssignees,
      notifiers: this.state.taskNotifyies,
      project_name: this.state.projectDetails.board_name
    };

    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.addTaskComment, data, {
      headers: headers
    })
      .then((response) => {
        response.data.success === 1 ? notifyCommentSuccess() : notifyError();
        this.setState({
          taskNewComment: '',
          saveupdateProgress: false,
          commentAddingProgress: true,
          commentAttachments: [],
          inspiration: '',
          resourcelinks: '',
          pmnote: '',
          approverAssginee: [],
          commentAddingProgress: false,
          iVerified: '',
          iPackaged: '',
          verificationError: false,
          packageError: false,
          needDesignApproval: false
        });
        selectedMentions = [];
        this.getTaskComments();
        localStorage.removeItem("commentData");
      })
      .catch((error) => {
        this.setState({
          taskNewComment: '', saveupdateProgress: false,
          commentAddingProgress: true,
          commentAttachments: []
        });
        notifyError();
      })

  }


  showCommentEdit = (id, comment) => {
    this.setState({
      editTaskCommentFlag: comment.source_files != null ? false : true,
      editTaskCommentPopupFlag: comment.source_files != null ? true : false,
      editTaskCommentId: id,
      editTaskComment: comment
    })
  }

  cancelCommentUpdate = () => {
    this.setState({
      editTaskCommentFlag: false,
      editTaskCommentPopupFlag: false,
      editTaskCommentId: '',
      editTaskComment: ''
    })
  }

  updateTaskComment = (approval) => {
    this.setState({
      editTaskCommentFlag: false,
      editTaskCommentPopupFlag:false,
      editTaskCommentId: '',
      editTaskComment: ''
    })
    notifyCommentUpdate();
    if (approval == 0) {
      let tempDetails = { ...this.state.taskDetails };
      tempDetails.task_approval_waiting = 0;
      this.setState({ taskDetails: tempDetails });
    } else if (approval == 1) {
      let tempDetails = { ...this.state.taskDetails };
      tempDetails.task_approval_waiting = 1;
      this.setState({ taskDetails: tempDetails });
    }
    this.getTaskComments();
  }

  goToHome = () => {
    this.props.history.push("/home"+"?uuid="+this.state.myId);
  }

  breadNavigation = (murl) => {
    this.props.history.push(murl+"?uuid="+this.state.myId);
  }

  goToProjectsHome = () => {
    this.props.history.push("/boards"+"?uuid="+this.state.myId);
  }

  deleteTaskAsk = async () => {
    this.setState({
      showDeleteConfirmation: true
    })
  }

  deleteTask = async () => {
    this.setState({
      progressBarDeletion: true
    })
    const data = {
      project_id: this.props.match.params.bid,
      task_id: this.state.taskDetails.id,
      creator: this.state.myId,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    await axios.post(urls.deleteTask, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({
          progressBarDeletion: false,
          taskDeleted: response.data.success == 1 ? true : false,
        })
      })
      .catch((error) => {
        this.setState({
          progressBarDeletion: false,
        })
      })
  }

  startThisTask = async () => {
    this.setState({
      taskRunningProgress: true
    })
    const data = {
      task_id: this.state.taskDetails.id,
      tid_cpt: this.props.match.params.id,
      user_id: this.state.myId,
      mname: this.state.myName,
      project_id: this.props.match.params.bid,
      project_name: this.state.projectDetails.board_name,
      task_name: this.state.taskDetails.task_name
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.startProjectTask, data, {
      headers: headers
    })
      .then((response) => {
        if (response.data.success === 1) {
          this.getTaskRunningLog();
          localStorage.setItem('enrefresh', 'refreshplz');
        } else if (response.data.success === 2) {
          notifyErrorShift();
        }
        this.setState({
          taskRunningProgress: false
        });
      })
      .catch((error) => {
        notifyError();
        this.setState({
          taskRunningProgress: false
        });
      })
  }

  endThisTask = async () => {
    this.setState({
      taskRunningProgress: true
    });
    const data = {
      task_id: this.state.taskDetails.id,
      tid_cpt: this.props.match.params.id,
      user_id: this.state.myId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.endProjectTask, data, {
      headers: headers
    })
      .then((response) => {
        if (response.data.success === 1) {
          this.getTaskRunningLog();
          notifyTaskEnded();
          localStorage.setItem('enrefresh', 'refreshplz');
        } else {
          notifyError();
        }
        this.setState({
          taskRunningProgress: false
        });
      })
      .catch((error) => {
        notifyError();
        this.setState({
          taskRunningProgress: false
        });
      })
  }

  getTaskRunningLog = () => {
    const data = {
      task_id: this.state.taskDetails.id,
      user_id: this.state.myId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.getTaskRunningLog, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({
          taskRunningProgress: false,
          taskRunningDetails: response.data.success == 1 ? response.data.running : [],
          taskisActiveRunning: response.data.task_running == 1 ? true : false
        })
      })
      .catch((error) => {
        this.setState({
          taskRunningProgress: false
        })
        //notifyError();
      })
    this.checkIfPossibletoEdit();
  }


  checkForAssignee = () => {
    this.setState({ taskRunningProgress: true });
    var mid = this.state.taskAssignees.map(user => {
      return parseInt(user.id)
    }).includes(this.state.myId)

    const data = {
      task_id: this.state.taskDetails.id,
      user_id: this.state.myId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.checkOtherRunningTask, data, {
      headers: headers
    })
      .then((response) => {
        if (response.data.success === 1 && mid) {
          this.setState({
            taskRunningProgress: false,
            showAction: true
          })
        } else {
          this.setState({
            taskRunningProgress: false,
            showAction: false
          })
        }
      })
      .catch((error) => {
        this.setState({
          taskRunningProgress: false,
          showAction: false
        })
      })
  }
  closeDialogue = () => {
    this.setState({
      showDeleteConfirmation: false,
      showDuplicateConfirmation: false,
      hideshowbox: true
    });
  };

  handleDeleteConfirm = () => {
    this.setState({ showDeleteConfirmation: !this.state.showDeleteConfirmation });
    const _this = this;
    setTimeout(function () {
      //Start the timer
      _this.deleteTask();
    }, 1000);
  };

  showBrandinfo = () => {
    if (!this.state.showPinText) {
      this.setState({ notesFetch: true })
      const data = {
        project_id: this.props.match.params.bid,
        task_id: this.props.match.params.id,
        brand_id: this.state.brand
      };
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin': '*',
      }
      axios.post(urls.getBandNotes, data, {
        headers: headers
      })
        .then((response) => {
          this.setState({
            brandNotes: response.data.notes,
            showPinText: true,
            notesFetch: false,
            noNotesAdded: response.data.notes.lenth <= 0 ? true : false
          })
        })
        .catch((error) => {
          this.setState({
            showPinText: false,
            noNotesAdded: true
          })
        })
    }
    else {
      this.closePinText();
    }
  }

  closePinText = () => {
    this.setState({
      brandNotes: [],
      showPinText: false,
      notesFetch: false,
      noNotesAdded: false
    })
  }

  stripslashes = (str) => {
    if (str != null) {
      str = str.replace(/\\'/g, '\'');
      str = str.replace(/\\"/g, '"');
      str = str.replace(/\\0/g, '\0');
      str = str.replace(/\\\\/g, '\\');
    }
    return str;
  }

  copytoClipboard = (texttocopy) => {
    navigator.clipboard.writeText(texttocopy);
    notifyCopied();
  }

  needDesignApprovalClick = () => {
    this.setCommentStorage('needDesignApproval', !this.state.needDesignApproval);
    this.setState({ needDesignApproval: !this.state.needDesignApproval });
  }
  iVerifiedClick = (e) => {
    this.setState({ iVerified: e.target.value, verificationError: false });
    this.setCommentStorage('iVerified', e.target.value);
  }

  iPackagedClick = (e) => {
    this.setState({ iPackaged: e.target.value, packageError: false });
    this.setCommentStorage('iPackaged', e.target.value);
  }

  pullComments = () => {
    let tempDetails = { ...this.state.taskDetails };
    tempDetails.task_approval_waiting = 0;
    this.setState({ taskDetails: tempDetails });

    this.getTaskComments();
    this.commentRef.current.focus();
    this.commentRef.current.scrollIntoView();
  }

  duplicateAsk = async () => {
    this.setState({
      showDuplicateConfirmation: true
    })
  }

  duplictethisTask = async () => {
    this.setState({ progressBar: true, showDuplicateConfirmation: false });
    const data = {
      project_id: this.props.match.params.bid,
      task_id: this.props.match.params.id,
      fieldDataUploads:
      {
        brandingFiles: [],
        textInstructionFiles: [],
        imageryFiles: [],
        referenceFiles: [],
        approvedWorkFiles: []
      }
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.duplicateTask, data, {
      headers: headers
    })
      .then((response) => {
        if (response.data.success === 1) {
          this.setState({ duplicateCreated: true });
          notifyDuplication();
          window.location.href = urls.notificationUrl + "/task/detail/" + response.data.project_id + "/" + response.data.task_id+"?uuid="+this.state.myId;
        } else {
          notifyError();
        }

        this.setState({ progressBar: false });
      })
      .catch((error) => {
        notifyError();
        this.setState({ progressBar: false });
      })
  }

  showHideAllComments = () => {
    this.setState({ showAllComment: !this.state.showAllComment });
  }

  findTrelloId = (taskname) => {
    var cardid = taskname.match(new RegExp("## " + "(.*)" + ""));
    if (cardid === null) {
      var cardid = taskname.match(new RegExp("##" + "(.*)" + ""));
    }
    if (cardid != null) {
      this.setState({
        trelloCard: cardid[1]
      })
    }
  }

  showTrelloCard = () => {
    this.setState({
      cardId: this.state.trelloCard,
      cardDisplayNum: this.state.cardDisplayNum + 1,
      cardDisplayTop: this.state.cardDisplayTop + 10,
    });
    const _this = this;
    setTimeout(function () {
      _this.getCardDetailsById();
    }, 100);
  }

  getCardDetailsById = () => {
    fetch(urls.getTrelloBoardCardById + this.state.cardId)
      .then(response => response.json())
      .then(response => {
        response[0].showEditCardDescription = false;
        this.setState({
          ['cardDetails' + this.state.cardDisplayNum]: response[0],
          showProgress: false,
          ['displayTrelloCard' + this.state.cardDisplayNum]: true,
        });
        this.getCardComments();
      })
      .catch(error => {
        this.setState({
          showProgress: false,
          trelloWrong: true,
        });
      });
  };

  getCardComments = () => {
    fetch(urls.getTrelloBoardCardComments + this.state.cardId)
      .then(response => response.json())
      .then(response => {
        this.setState({
          ['cardComments' + this.state.cardDisplayNum]: response,
          showProgress: false
        });
        this.getCardAttachments();
      })
      .catch(error => {
        this.setState({
          showProgress: false
        });
      });
  };

  getCardAttachments = () => {
    fetch(urls.getTrelloBoardCardAttachments + this.state.cardId)
      .then(response => response.json())
      .then(response => {
        this.setState({
          ['cardAttachments' + this.state.cardDisplayNum]: response,
          showProgress: false
        });
      })
      .catch(error => {
        this.setState({
          showProgress: false
        });
      });
  };

  closeCard = () => {
    this.setState({
      ['displayTrelloCard' + this.state.cardDisplayNum]: false,
      cardDisplayNum: this.state.cardDisplayNum - 1,
      cardDisplayTop: this.state.cardDisplayTop - 10,
    });
  }

  getTaskTickets = () => {
    const data = {
      task_id: this.props.match.params.id,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.getTaskTickets, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({
          taskTickets: response.data.tickets
        })
      })
      .catch((error) => {
        //notifyError();
      })
  };

  createTicketPop = () => {
    this.setState({ newTicket: true });
  }
  closePopup = (value, subject, desc) => {
    if (value > 0) {
      const data = {
        task_id: this.props.match.params.id,
        ticket_id: value
      };
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin': '*',
      }
      axios.post(urls.addTaskTicket, data, {
        headers: headers
      })
        .then((response) => {
          this.setState({
            newTicket: false,
            ticketSubject: '',
            ticketDesc: ''
          })
          this.getTaskTickets();
        })
        .catch((error) => {
          //notifyError();
        })
    } else {
      this.setState({
        newTicket: false,
        ticketSubject: subject,
        ticketDesc: desc
      });
    }
  }

  handleIssueChange = (e, issueitem) => {
    let issueItems = [...this.state.taskIssueTypes];
    let difference = [];
    var itemkey = issueItems.map(function (item) { return item.id; }).indexOf(issueitem.id);
    if (e.target.checked) {
      issueItems[itemkey].issue_id = issueitem.id;
      this.setState({ taskIssueTypes: issueItems });
      this.updateTaskIssues(1, issueItems[itemkey].id, issueItems[itemkey].responsible_persons, difference, 0);
    } else {
      if (issueItems[itemkey].responsible_persons != null && issueItems[itemkey].responsible_persons.length == 0) {
        issueItems[itemkey].issue_id = null;
        this.setState({ taskIssueTypes: issueItems });
        this.updateTaskIssues(0, issueItems[itemkey].id, issueItems[itemkey].responsible_persons, difference, 0);
      } else {
        notifyIssueClear();
      }
    }
  }

  setIssueResponsibles = (tags, issueid) => {
    let issueItems = [...this.state.taskIssueTypes];
    let difference = [];
    let adding = 1;
    if (typeof (tags) === 'object' && tags.length > 0) {
      var itemkey = issueItems.map(function (item) { return item.id; }).indexOf(issueid);
      try {
        var arrr1 = JSON.parse(issueItems[itemkey].responsible_persons);
      } catch (e) {
        var arrr1 = issueItems[itemkey].responsible_persons;
      }
      difference = [
        ...this.getDifference(tags, arrr1),
        ...this.getDifference(arrr1, tags)
      ];
      if (issueItems[itemkey].responsible_persons.length > tags.length) {
        adding = 0;
      }
      issueItems[itemkey].responsible_persons = tags;
      this.setState({ taskIssueTypes: issueItems });
      this.updateTaskIssues(1, issueItems[itemkey].id, issueItems[itemkey].responsible_persons, difference, adding);
    } else {
      var itemkey = issueItems.map(function (item) { return item.id; }).indexOf(issueid);
      try {
        var arrr1 = JSON.parse(issueItems[itemkey].responsible_persons);
      } catch (e) {
        var arrr1 = issueItems[itemkey].responsible_persons;
      }
      if (arrr1 && arrr1 != null && arrr1.length > 0) {
        difference = [
          ...this.getDifference(tags, arrr1),
          ...this.getDifference(arrr1, tags)
        ];
      }
      issueItems[itemkey].responsible_persons = [];
      this.setState({ taskIssueTypes: issueItems });
      this.updateTaskIssues(1, issueItems[itemkey].id, issueItems[itemkey].responsible_persons, difference, 0);
    }
  }

  updateTaskIssues = (type, issueid, responsible, difference, mthd) => {
    const data = {
      task_id: this.state.taskDetails.id,
      issue_id: issueid,
      responsible_persons: (responsible === null || responsible == '') ? [] : responsible,
      difference: difference,
      type: type,
      mthd: mthd
    };

    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.addTaskIssues, data, {
      headers: headers
    })
      .then((response) => {
      })
      .catch((error) => {
        //notifyError();
      })
  };

  getDifference = (array1, array2) => {
    return array1.filter(object1 => {
      return !array2.some(object2 => {
        return object1.id === object2.id;
      });
    });
  }

  downloadAllFiles = (itemname, dfiles) => {
    this.setState({ showDownloadConfirmation: false, downloadMessage: true, downloadItem: itemname });
    const data = {
      user_id: this.state.myId,
      taskID: this.state.taskDetails.id,
      itemname: itemname,
      downfiles: dfiles
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.downloadAllTaskFiles, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({ downloadMessage: false, downloadItem: '' });
        if (response.data.filename !== '') {
          saveAs(urls.storageUrl + "storage/projects/downloads/" + response.data.filename, response.data.filename);
        } else {
          notifyError();
        }
      })
      .catch((error) => {
        this.setState({ downloadMessage: false });
      })
  }

  followUnfollowTask = (action) => {
    let taskFollowers = this.state.followers;
    this.setState({ followers: [], showFollowers: false });
    if (action == 1) {
      taskFollowers.push(this.state.myId);
      this.setState({ followers: taskFollowers, showFollowers: true });
      notifyFollow();
    } else {
      let findex = taskFollowers.findIndex(e => e == this.state.myId);
      if (findex !== -1) {
        taskFollowers.splice(findex, 1);
        const _this = this;
        setTimeout(() => {
          this.setState({ followers: taskFollowers, showFollowers: true });
          notifyUnFollow();
        }, 500);
      }
    }

    const data = {
      task_id: this.state.taskDetails.id,
      project_id: this.props.match.params.bid,
      tid_cpt: this.props.match.params.id,
      action: action,
      followers: taskFollowers
    };
    
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.followUnfollowTask, data, {
      headers: headers
    })
      .then((response) => {

      })
      .catch((error) => {

      })
  }

  toggleTaskTitleEdit = () =>
  {
    this.setState({showTitleModal:true});
  }

  moveTodo = () =>
  {
    this.setState({showMoveModal:true});
  }

  moveTaskUpdate = () =>
  {
    this.setState({showMoveModal:true});
    notifyTaskMove();
  }

  showWorkLogs = async () => {
    this.setState({workLogsLoader:true});
    const data = {
      taskid: this.state.taskDetails.id
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.getTaskLogs, data, {
      headers: headers
    })
    .then((response) => {
      this.setState({workLogs: response.data.logs, showWorkLogs:true,workLogsLoader:false});
      //let logs = this.groupArrayOfObjects(response.data.logs, 'name');
      // const _this = this;
      // setTimeout(() => {
      // _this.setState({workLogs: logs});
      // }, 2000);

    })
    .catch((error) => {
      this.setState({workLogs: [], showWorkLogs:false,workLogsLoader:false});
    })
  }


  groupArrayOfObjects = (list, key) => {
    let p =0;
    return list.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  convertTime = (time)=>
  {
    if(time!='')
    {
      let timeminutes = parseInt(time.split('.')[0]);
      let timetaken = '';
      var hours = Math.floor(timeminutes / 60);          
      var minutes = timeminutes % 60;
     
      if(parseInt(time.split('.')[1])>0)
      {
        minutes += Math.floor((time.split('.')[1]) / 60);    
      }
      timetaken  = hours>0? hours+" hr : ": '00 hr : ';
      timetaken  += minutes>0? minutes+" mins": '00 mins';
      return timetaken;
    }
  }
  
  hideWorkLog = () =>
  {
    this.setState({showWorkLogs:false});
  }

  updateFileText = (arrayname, values) =>
  {
    this.setState({commentImagesTextArray:values});
  }

  handClickUpdate = (e) => {
    if(e.target.checked === true)
      this.setState({ commentImagesTextOptions: e.target.value, commentAttachmentsChange:true });
    else
      this.setState({ commentImagesTextOptions: 0, commentAttachmentsChange:true });
    const _this = this;
    setTimeout(()=>
    {
      _this.setState({commentAttachmentsChange:false});
    }, 500);
  };

  moveClientCard = () =>
  {
    this.setState({showClientCardMove:true});
  }

  hideMoveClientCard = () =>
  {
    this.setState({showClientCardMove:false});
  }

  moveClientTask = (selectedboard) =>
  {
    const data = {
      from_board_id: this.state.projectDetails.board_id,
      from_board_name: this.state.projectDetails.board_name,
      to_board_id: selectedboard.id,
      to_board_name: selectedboard.name,
      tid_cpt: this.props.match.params.id,
      task_id: this.state.taskDetails.id,
      comment_attachments: this.state.commentAttachments,
      creator: this.state.myId,
      oldDueDate: this.state.oldDueDate,
      dueDate: this.state.dueDate,
    };
   // console.log(data);
    //return false;
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.moveClientTask, data, {
      headers: headers
    })
    .then((response) => {
      this.setState({showClientCardMove:false});
      window.location.href = urls.notificationUrl + '/task/detail/'+response.data.movedboardid+"/"+response.data.tidcpt+"?uuid="+this.state.myId;
      
    })
    .catch((error) => {
      
    })
  }
  closeSoucePopup = (status=0)=>
  {
    this.setState({sourceManagement:false, editTaskCommentFlag:false, editTaskCommentPopupFlag:false});
    console.log(status);
    if(status == 1)
    {
      this.setState({commentAddingProgress:true});
      this.setState({
        taskNewComment: '',
        approverAssginee: [],
        commentApprover: '',
        needDesignApproval: '',
        inspiration: '',
        resourcelinks: '',
        pmnote: '',
        iPackaged: '',
        iVerified: '',
        commentAttachments:[],
        commentAddingProgress:false
      });
    }
  }

  updateCardInfo = (cardid, cardinfo)=>
  {
    this.setState({cardIdUpdated:false});
    let temp = this.state.taskDetails;
    temp.task_360_id = cardid;
    temp.task_360_data = JSON.stringify(cardinfo);
    this.setState({taskDetails: temp,cardIdUpdated:true});
    this.getTaskDetails();
  }

  render() {
    let animationClasses = (this.state.animate ? ' arrow_active' : '');
    let logindex = -1;
    let logtitle = '';
    let catindex = 1;
    return (
      <div className="fullwidth_mobilemenu">

        <Container fluid className="top-bar-inactive bc_module_container">
          <div className="quick_access_box">
            {this.state.mimimisedSourceView ?
            (
              <Button  onClick={()=>this.setState({mimimisedSourceView:false})} className="folder_btn folder_btn_active" title="File Manager"><MaterialIcon icon="folder"/> </Button>
            ):
              <Button  onClick={()=>this.setState({sourceManagement:true})} className="folder_btn" title="File Manager"><MaterialIcon icon="folder"/> </Button>
            }
            {this.state.projectDetails.onboard_360 ?
            (
              <Quick360Access
              bid={this.props.match.params.bid}
              task_id ={this.props.match.params.id}
              tid = {this.state.taskDetails.id}
              taskDetails={this.state.taskDetails}
              comment_attachments = {[]}
              creator={this.state.myId}
              />
            ):''}
            
          </div>
          <Row>
            <div className="theme_header">
              <Header
                ncount={this.state.openNCount}
                {...this.props} className="tick-timer-header"
                logout={this.signOut.bind(this)}
                onmenuaction={this.toggleSidebar.bind(this)}
                quickactionblock={false} />
              <div className="SideNavbar">
                <SideMenu
                  activeitem="project"
                  showmenu={this.state.showMenu}
                  hidemenu={this.hideMenu.bind(this)} />
              </div>
            </div>
            <ScrollTop />
            <div className="themeContentBody themefullwidthContentBoday">
              <LoadingWrapper isLoading={this.state.progressBar || this.state.progressBarDeletion}>
                {this.state.accessDenied ?
                  (
                    <>
                      <AccessDenied gotohome={this.goToHome} gotoprojectshome={this.goToProjectsHome} />
                    </>
                  ) :
                  (
                    <>
                      {this.state.taskDeleted ?
                        (
                          <div className="task-deleted-wrapper d-flex justify-content-center">
                            <span>Card deleted successfully. Redirecting back to listing. </span>
                          </div>
                        ) :
                        (
                          <>
                            {this.state.duplicateCreated ?
                              (
                                <div className="task-deleted-wrapper d-flex justify-content-center">
                                  <span>Duplicate card created successfully. Please wait...</span>
                                </div>
                              ) :
                              (
                                <>
                                  <Row>
                                    <Col sm="12" className="bc_module">
                                      <div className="bc_Board_wrapper">
                                        <h2 className="section_title d-flex align-items-center justify-content-center">{this.state.projectDetails.board_name}
                                          <ProjectCurrentStatus
                                            graphics={this.state.projectDetails.graphics_pack}
                                            video={this.state.projectDetails.video_pack}
                                            graphics_video={this.state.projectDetails.graphics_video_pack}
                                            canva_graphics={this.state.projectDetails.canva_graphics}
                                            canva_plus={this.state.projectDetails.canva_plus}
                                            whitelabel={this.state.projectDetails.white_label}
                                            clientstatus={this.state.projectDetails.client_status} />

                                        </h2>
                                        <p className="board_discrption">{this.state.projectDetails.short_description}</p>
                                        <MailboxEmail canva_access={this.state.projectDetails.canva_access} />    
                                        <h2 className="section_title subtitle">{this.state.taskDetails.task_name}</h2>
                                        <div className="page_header_wrap new_task_head">
                                          <div className="search-box">
                                            <a className='follow-unfollow-task'>
                                              {(this.state.showFollowers && this.state.followers && this.state.followers.length > 0 && this.state.followers.includes(`${this.state.myId}`) ) ?
                                                (
                                                  <span title="Unfollow this card"  onClick={this.followUnfollowTask.bind(this, 2)}>F+</span>
                                                ) :
                                                (
                                                  <span title="Follow this card" onClick={this.followUnfollowTask.bind(this, 1)}>F</span>
                                                )}
                                            </a>
                                            <a className="edit_board_detail_icon" title="Back to Card List" onClick={this.breadNavigation.bind(this, '/boards/my-tasks')} > <MaterialIcon icon="checklist" /> </a>
                                            <a className="edit_board_detail_icon" title="Back to Boards" onClick={this.breadNavigation.bind(this, '/boards')} > <MaterialIcon icon="fact_check" /> </a>
                                            {/* {AccessCheckBlock('use_projects_search_addon') &&
                                            (<FilterSearch />)
                                            } */}
                                            <a className="home_button_box" onClick={this.goToHome}><img src={Homebackicon} alt="back" /></a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="breadcrumb_boards d-flex justify-content-between align-items-center">
                                        <Breadcrumb>
                                          <BreadcrumbItem><a href={"/boards"+"?uuid="+this.state.myId}>Boards</a></BreadcrumbItem>
                                          <BreadcrumbItem><a href={'/boardsdetails/' + this.props.match.params.bid+"?uuid="+this.state.myId} >Board Details</a></BreadcrumbItem>
                                          {this.state.projectDetails && this.state.projectDetails.board_type == 1 ?
                                            (
                                              <BreadcrumbItem><a href={'/task-list/' + this.props.match.params.bid+"?uuid="+this.state.myId} >Cards </a></BreadcrumbItem>
                                            ) :
                                            (
                                              <>
                                                {this.state.projectDetails && this.state.projectDetails.view_type==0 ?
                                                (
                                                  <BreadcrumbItem><a href={'/internal-task-list/' + this.props.match.params.bid+"?uuid="+this.state.myId} >Cards </a></BreadcrumbItem>
                                                ):
                                                (
                                                  <BreadcrumbItem><a href={'/internal-task-grid/' + this.props.match.params.bid+"?uuid="+this.state.myId} >Cards </a></BreadcrumbItem>
                                                )}
                                              </>
                                            )}

                                          <BreadcrumbItem active>{this.state.taskDetails.task_name}</BreadcrumbItem>
                                        </Breadcrumb>
                                        <div className='task-number'><span>Card Number: </span>{this.state.taskDetails.id}</div>
                                      </div>
                                      <div className="task_onwer_wrap">
                                        <div className="task_due_date">Due Date & Time :
                                          <span>
                                            {(this.state.dueDate && this.state.dueDate != 'Invalid Date') ?
                                              (
                                                moment((new Date(this.state.dueDate)).getTime()).tz(this.state.myTimezone).format('MMM, DD YYYY h:mm a')
                                              ) :
                                              (
                                                '---'
                                              )}
                                          </span>
                                          <span className="seperator">|</span>
                                          <span className="tast_status">
                                            {this.state.taskStatus == 1 ? 'Open' : (this.state.taskStatus == 0 ? 'Draft' : 'Completed')}
                                          </span>
                                        </div>
                                        {this.state.trelloCard != '' &&
                                          (
                                            <div className='detail-trello d-flex justify-content-center'>
                                              <span className='trello-seperator'></span>
                                              <TrelloIcon showtrellocard={this.showTrelloCard} />
                                            </div>
                                          )}
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row >
                                    <Toaster position="top-right" containerClassName="notifier"
                                      toastOptions={{
                                        // Define default options
                                        className: '',
                                        duration: 5000,
                                        style: {
                                          background: '#363636',
                                          color: '#fff',
                                        },
                                      }}
                                    />
                                    <Col sm="4" id="fixed-sidbar">
                                      <Scrollbars style={{ height: '95vh' }}
                                        autoHide
                                        autoHideTimeout={1000}
                                        autoHideDuration={200}
                                        className="ov-yonly"
                                      >
                                        {(this.state.taskStatus === 1 && this.state.showAction && this.state.projectDetails.board_id != 1001 && this.state.projectDetails.board_type != 2) &&
                                          (
                                            <LoadingWrapper isLoading={this.state.taskRunningProgress}>
                                              {(this.state.taskRunningDetails && this.state.taskRunningDetails.length > 0 && this.state.taskRunningDetails[0].timer_active === 1) ?
                                                (
                                                  <>
                                                    <div className="d-flex justify-content-center running-task-timmer">
                                                      <Timmer starttimer={this.state.taskRunningDetails[0].timmer} />
                                                    </div>
                                                    <div className="start_end_task">
                                                      <Button onClick={this.endThisTask.bind(this)}>End Timer</Button>
                                                    </div>
                                                  </>
                                                ) :
                                                (
                                                  <div className="start_start_task">
                                                    <Button onClick={this.startThisTask.bind(this)}>Start Timer</Button>
                                                  </div>
                                                )}

                                            </LoadingWrapper>
                                          )}
                                        {this.state.taskisActiveRunning &&
                                          (
                                            <div className='task-is-running'>This card timer is currently running</div>
                                          )}

                                        {(this.state.taskDetails.task_approval_waiting != 0) &&
                                          (
                                            <div className='task-waiting-approval'>Work Approval Required</div>
                                          )}

                                        <div className="card task_leftbox ">
                                          <div className="task_omwer_name d-flex">Card Created By:
                                            <span> {this.state.taskDetails.name}</span>
                                            {(this.state.Priority === 1) &&
                                              (
                                                <span className="priority-task">Priority</span>
                                              )}
                                          </div>

                                          <div className={`form-group ${((this.state.taskRunningDetails && this.state.taskRunningDetails.length > 0 && this.state.taskRunningDetails[0].timer_active === 1) || this.state.taskisActiveRunning || this.state.taskStatus != 1) && 'disabled-form-group'}`}>
                                            <label>Assigned To</label>
                                            {this.state.showAssigneeBlock && this.state.availableAssignees && this.state.availableAssignees.length > 0 && this.state.taskAssignees &&
                                              (
                                                <PeopleTags tagslist={this.state.availableAssignees}
                                                  settags={this.setAssignees.bind(this)}
                                                  tags={this.state.taskAssignees}
                                                  placeholder="Add Assignees"
                                                  boardtype={this.state.projectDetails.board_type}
                                                  duedate={this.state.dueDate}
                                                  currentmoment={moment()}
                                                  privileges={this.state.myPrivileges}
                                                  
                                                />
                                              )}
                                            {this.state.assigneeUpdate &&
                                              (
                                                <Button className='btn btn-green save-ticket add_cmd_btn btn btn-secondary update-assignee' onClick={this.setAssigneesSave.bind(this)}>Update</Button>
                                              )}
                                          </div>

                                          <div>
                                            <div className={`form-group ${(!AccessCheckBlock('i_am_admin') && !AccessCheckBlock('i_am_pl') && !AccessCheckBlock('i_am_pm') && this.state.myId !== this.state.taskDetails.task_owner) && 'disabled-form-group'}`}>
                                              <label>Notify when done</label>
                                              {this.state.availableNotifiers && this.state.availableNotifiers.length > 0 && this.state.taskNotifyies &&
                                                (
                                                  <PeopleTags tagslist={this.state.availableNotifiers}
                                                    settags={this.setNotifiers}
                                                    tags={this.state.taskNotifyies}
                                                    placeholder="Add Notifiers" />
                                                )}
                                            </div>

                                            {this.state.projectDetails && this.state.projectDetails.board_type == 1 &&
                                              (
                                                <div className={`form-group labels-group ${(!AccessCheckBlock('i_am_admin') && !AccessCheckBlock('i_am_com') && !AccessCheckBlock('i_am_pl') && !AccessCheckBlock('i_am_pm') && this.state.myId !== this.state.taskDetails.task_owner) && 'disabled-form-group'}`}>
                                                  <label>Label</label>
                                                  {this.state.labels && this.state.labels.length > 0 && this.state.taskLabels &&
                                                    (
                                                      <PeopleTags tagslist={this.state.labels}
                                                        settags={this.setLabels.bind(this)}
                                                        tags={this.state.taskLabels}
                                                        placeholder="Add Labels" />
                                                    )}
                                                </div>
                                              )}
                                            <div className={`form-group d-flex align-items-top taskpriority ${(!AccessCheckBlock('i_am_admin') && !AccessCheckBlock('i_am_pl') && !AccessCheckBlock('i_am_pm') && this.state.myId !== this.state.taskDetails.task_owner) && 'disabled-form-group'}`}>
                                              <Input type="checkbox" id="taskpriority" name='priority'
                                                onClick={(e) => this.handleChangePriority(e)}
                                                value="1"
                                                checked={this.state.Priority == 1 && 'checked'} />
                                              <label for="taskpriority">Priority Card</label>
                                            </div>
                                            {this.state.projectDetails && this.state.projectDetails.board_type == 1 &&
                                              (
                                                <div className={`form-group ${(!AccessCheckBlock('i_am_admin') && !AccessCheckBlock('i_am_pl') && !AccessCheckBlock('i_am_pm') && this.state.myId !== this.state.taskDetails.task_owner) && 'disabled-form-group'}`}>
                                                  <label>Brand</label>
                                                  <select className="choose_temp brand-disabled" name="brand" disabled onChange={this.handleChangeBrand}>
                                                    <option value="">Select</option>
                                                    {this.state.availableBrands && this.state.availableBrands.length > 0 &&
                                                      (
                                                        <>
                                                          {this.state.availableBrands.map((branditem) => {
                                                            return (
                                                              <option key={branditem.brand_id}
                                                                value={branditem.brand_id}
                                                                selected={this.state.taskDetails.brand_id === branditem.brand_id && 'selected'}
                                                              >{branditem.brand_name}</option>
                                                            )
                                                          })}
                                                        </>
                                                      )}
                                                  </select>
                                                </div>
                                              )}
                                
                                              <div className={`form-group ${(!AccessCheckBlock('i_am_admin') && !AccessCheckBlock('i_am_pl') && !AccessCheckBlock('i_am_pm') && this.state.myId !== this.state.taskDetails.task_owner) && 'disabled-form-group'}`}>
                                                  <label>Industry / Portfolio Category</label>
                                                  <select className="choose_temp " name="brand"  onChange={this.handleChangeIndustry}>
                                                    <option value="">Select</option>
                                                    {this.state.industries && this.state.industries.length > 0 && (
                                                    <>
                                                      <optgroup label="Graphics">
                                                        {this.state.industries
                                                          .filter(industry => industry.portfolio_type === 1)
                                                          .map((industry) => (
                                                            <option 
                                                              key={industry.id}
                                                              value={industry.id}
                                                              selected={this.state.taskDetails.industry === industry.id && 'selected'}
                                                            >
                                                              {industry.industry}
                                                            </option>
                                                          ))}
                                                      </optgroup>
                                                      <optgroup label="Video">
                                                        {this.state.industries
                                                          .filter(industry => industry.portfolio_type === 2)
                                                          .map((industry) => (
                                                            <option 
                                                              key={industry.id}
                                                              value={industry.id}
                                                              selected={this.state.taskDetails.industry === industry.id && 'selected'}
                                                            >
                                                              {industry.industry}
                                                            </option>
                                                          ))}
                                                      </optgroup>
                                                    </>
                                                  )}
                                                  </select>
                                                </div>

                                          </div>
                                        </div>

                                        {(AccessCheckBlock('add_update_due_date') && this.props.match.params.bid != 'gIiAZmVlZoCIgA==') &&
                                          (
                                            <div className="card task_leftbox">
                                              <div className="newtask_form_body">
                                                <div className="form-group duedate_placement">
                                                  <label>Due Date</label>
                                                  {this.state.pastDueError &&
                                                  (
                                                    <div className='past-date'>You can not set a past time.</div>
                                                  )}
                                                  <Datetime
                                                    dateFormat="DD-MM-YYYY"
                                                    value={this.state.dueDate}
                                                    onChange={this.setDueDate}
                                                    isValidDate={disablePastDt}
                                                    inputPops={{ disabled: true }}
                                                  />
                                                  {this.state.needDueUpdate &&
                                                    (
                                                      <Button className="update-due btn btn-green save-ticket add_cmd_btn" onClick={this.setDueDateUpdate.bind(this)}>Update</Button>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {(AccessCheckBlock('update_task_status') && this.state.cardIdUpdated) &&(
                                               <ConnectionWith360
                                               bid={this.props.match.params.bid}
                                               task_id ={this.props.match.params.id}
                                               tid = {this.state.taskDetails.id}
                                               taskDetails={this.state.taskDetails}
                                               comment_attachments = {[]}
                                               creator={this.state.myId}
                                               updateCardInfo={this.updateCardInfo}
                                               />
                                          )}

                                          {/* <div className="card task_leftbox">
                                            <div>
                                              <LoomRecording />
                                            </div>
                                          </div> */}

                                          {/* {this.state.taskDetails.task_360_id!=null &&
                                          ( */}
                                          {(AccessCheckBlock('i_am_pm') || AccessCheckBlock('i_am_pl') 
                                          || AccessCheckBlock('i_am_com') || AccessCheckBlock('i_am_admin') || AccessCheckBlock('i_am_superadmin') || AccessCheckBlock('i_am_ops')) &&(
                                            <div className="card task_leftbox">
                                                <div>
                                                  <LoadingWrapper isLoading={this.state.taskUpdateProgress}>
                                                    <h6>Private Note for PM's </h6>
                                                    <p>Add the private notes related to this task here.</p>
                                                    {this.state.showPrivateNote ?
                                                    (
                                                      <div className='private-note'>
                                                        <PrivateNotes taskID={this.state.taskDetails.id} myID={this.state.myId} />
                                                      </div>
                                                    ):
                                                    (
                                                      <Button className="btn btn-green action_source_management  btn btn-secondary" 
                                                      onClick={()=>this.setState({showPrivateNote:true})}
                                                      >View</Button>
                                                    )}
                                                  </LoadingWrapper>
                                                </div>
                                            </div>
                                            )}
                                            <div className="card task_leftbox">
                                                <div>
                                                  <LoadingWrapper isLoading={this.state.taskUpdateProgress}>
                                                    <h6>File Management</h6>
                                                    <p>Upload and manage your task source files here.</p>
                                                    {this.state.mimimisedSourceView ?
                                                    (
                                                      <Button className="btn btn-green action_source_management  btn btn-secondary" 
                                                      onClick={()=>this.setState({mimimisedSourceView:false})}
                                                      >File Manager</Button>
                                                    ):
                                                    <Button className="btn btn-green action_source_management  btn btn-secondary" 
                                                    onClick={()=>this.setState({sourceManagement:true})}
                                                    >File Manager</Button>
                                                    }

                                                  </LoadingWrapper>
                                                </div>
                                            </div>
                                          {/* )} */}
                                          {(AccessCheckBlock('update_task_status') && (this.state.taskRunningDetails && this.state.taskRunningDetails.length <= 0) && this.props.match.params.bid != 'gIiAZmVlZoCIgA==') &&
                                            (
                                              <div className="card task_leftbox">
                                                <div>
                                                  <LoadingWrapper isLoading={this.state.taskUpdateProgress}>
                                                    <Label>Card Status: {this.state.taskStatus === 1 ? 'Open' : (this.state.taskStatus === 0 ? 'Draft' : 'Completed')}</Label>
                                                    {this.state.taskStatus === 0 && (<Button className="btn btn-green task_status_change  btn btn-secondary" onClick={this.taskStatusUpdate.bind(this, 1)}>Publish Task</Button>)}
                                                    {this.state.taskStatus === 1 && (<Button className="btn btn-green task_status_change  btn btn-secondary" onClick={this.taskStatusUpdate.bind(this, 2)}>Mark as Completed</Button>)}
                                                    {this.state.taskStatus === 2 && (<Button className="btn btn-green  task_status_change  btn btn-secondary" onClick={this.taskStatusUpdate.bind(this, 1)}>Reopen</Button>)}
                                                  </LoadingWrapper>
                                                </div>
                                              </div>
                                            )}
                                          <div className="card task_leftbox worklogs">
                                            {!this.state.taskRunningProgress && this.state.taskDetails && this.state.taskDetails.id!='' &&
                                            (
                                              <TaskWorkLogs taskid={this.state.taskDetails.id} />
                                            )}
                                          </div>
                                          {this.state.projectDetails.canva_access !==null && this.state.projectDetails.canva_access!== '' &&
                                          (
                                            <div className="card task_leftbox worklogs">
                                              <CanvaAccess 
                                              email={this.state.projectDetails.canva_access}
                                              canvaaccesstype={this.state.projectDetails.canva_access_type}
                                               />
                                            </div>
                                          )}

                                          {(AccessCheckBlock('i_am_pm') || AccessCheckBlock('i_am_pl')) &&
                                          (
                                            <div className="card task_leftbox worklogs">
                                              <BestDesignsApproval 
                                              taskid={this.state.taskDetails.id}
                                               />
                                            </div>
                                          )}

                                          {((AccessCheckBlock('update_task_status')) || (AccessCheckBlock('i_am_qa'))) &&
                                          (
                                            <div className="card task_leftbox">
                                              <div>
                                                <LoadingWrapper isLoading={this.state.taskUpdateProgress}>
                                                  <Input type="checkbox" name="show-all-commnets" id="show-all-commnets" onClick={this.showHideAllComments.bind(this)} />
                                                  <Label for="show-all-commnets" className="show-all-commnets">Show All Comments</Label>
                                                </LoadingWrapper>
                                              </div>
                                            </div>
                                          )}

                                        {((AccessCheckBlock('i_am_admin')) || (AccessCheckBlock('i_am_qa'))
                                          || (AccessCheckBlock('add_new_task'))) &&
                                          (
                                            <div className="card task_leftbox">
                                              <div className="brand_cmd_sec">
                                                <LoadingWrapper isLoading={this.state.taskUpdateProgress}>
                                                  <div className='d-flex justify-content-between align-items-center'>
                                                    <h6>Tickets</h6>
                                                    <Button className='btn btn-task-ticket plus btn-green' onClick={this.createTicketPop}><MaterialIcon icon="add" /> </Button>
                                                  </div>
                                                  {this.state.taskTickets && this.state.taskTickets.length > 0 &&
                                                    (
                                                      <div className='task-tickets'>
                                                        {this.state.taskTickets.map((ticket, index) => {
                                                          return (
                                                            <div key={ticket.ticket_id} className="task-ticket-items d-flex justify-content-between">
                                                              <span className='ticket-subject'><a target="_blank" href={urls.notificationUrl + "/ticket/" + ticket.ticket_id+"?uuid="+this.state.myId}>#{ticket.ticket_id} - {ticket.subject}</a></span>
                                                              <span className='task-status'>{ticket.status}</span>
                                                            </div>
                                                          )
                                                        })}
                                                      </div>
                                                    )}
                                                </LoadingWrapper>
                                              </div>
                                            </div>
                                          )}
                                        {this.state.projectDetails && this.state.projectDetails.board_type == 1 &&
                                          (
                                            <TaskIssueReporting
                                              availableAssignees={this.state.availableAssignees}
                                              taskid={this.state.taskDetails.id}
                                              stringifiedDescription={this.state.stringifiedDescription}
                                              myid={this.state.myId}
                                            />
                                          )}
                                      </Scrollbars>
                                    </Col>
                                    <Col sm="8">
                                      <LoadingWrapper isLoading={this.state.showProgress}>
                                        {this.state.taskDetails && this.state.showTaskEdit ?
                                          (<div className="editblock comment_view_wraper">
                                            <EditTask taskdetails={this.state.taskDetails}
                                              availableBrands={this.state.availableBrands}
                                              stringifieddescription={this.state.stringifiedDescription}
                                              stringifiedattachments={this.state.stringifiedAttachments}
                                              stringifiedtextlinks={this.state.stringifiedtextlinks}
                                              taskattachments={this.state.taskAttachments}
                                              projectid={this.props.match.params.bid}
                                              taskid={this.props.match.params.id}
                                              closeedit={this.closeTaskEdit}
                                              projectDetails={this.state.projectDetails}
                                              myid={this.state.myId} />
                                          </div>
                                          ) :
                                          (
                                            <div className="task_right_box">
                                              {this.state.projectDetails.onboard_360 === 1 &&
                                              (
                                                <div className='bar-for-360'>
                                                  <div className="d-flex"><MaterialIcon icon="info"/> 360 onboarded client</div>
                                                  <img src={Kimp360Logo} className="kimp360logo" alt="360logo"/>
                                                </div>
                                              )}
                                              <div className="comment_view_wraper">
                                                <div className="user_strip">
                                                  <div className="user_avatar"><img src={urls.storageUrl + "storage/profile/image/" + this.state.taskDetails.task_owner + ".jpg"} alt="back" /></div>
                                                  <div className="user_bc_name">{this.state.taskDetails.name}
                                                    <span className="taskpost_time">
                                                      {this.state.taskDetails.created_on &&
                                                        (
                                                          moment((new Date(this.state.taskDetails.created_on)).getTime()).tz(this.state.myTimezone).format('dddd, Do MMMM, YYYY h:mm a')
                                                        )}
                                                    </span>
                                                  </div>
                                                  <div className="ml-auto p-2">
                                                    {((AccessCheckBlock('edit_task') || AccessCheckBlock('delete_task') || this.state.showTaskEditOption)
                                                      && (AccessCheckBlock('i_am_admin') || this.state.myId === this.state.taskDetails.task_owner)) &&
                                                      (
                                                        <div className="dropdownboard">
                                                          <Dropdown className='ticket-module-selector'
                                                            isOpen={this.state.dropdownopen}
                                                            toggle={this.toggle} >
                                                            <DropdownToggle color='' caret>
                                                              <img src={Dropicon} alt="back" />
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                              {/* {(AccessCheckBlock('edit_task') && this.state.showTaskEditOption) &&
                                                              ( */}
                                                              {this.state.taskDetails && this.state.taskDetails.block_edit === 0 ?
                                                              (
                                                                <DropdownItem onClick={this.toggleTaskEdit} dropDownValue="Allotments"><i className="fa fa-plane fa-fw"></i>Edit</DropdownItem>
                                                              ):
                                                              (
                                                                <DropdownItem onClick={this.toggleTaskTitleEdit} dropDownValue="Allotments"><i className="fa fa-plane fa-fw"></i>Edit title</DropdownItem>
                                                              )}
                                                              <DropdownItem onClick={this.duplicateAsk} dropDownValue="Allotments"><i className="fa fa-plane fa-fw"></i>Duplicate this card</DropdownItem>
                                                              {this.state.projectDetails && this.state.projectDetails.board_type === 2 ?
                                                              (
                                                                <DropdownItem onClick={this.moveTodo} dropDownValue="Allotments"><i className="fa fa-plane fa-fw"></i>Move this Card</DropdownItem>
                                                              ):
                                                              (
                                                                <DropdownItem onClick={this.moveClientCard} dropDownValue="Allotments"><i className="fa fa-plane fa-fw"></i>Move this card</DropdownItem>
                                                              )}
                                                              
                                                              {/* )} */}
                                                              {(AccessCheckBlock('delete_task') &&
                                                                this.state.taskRunningDetails &&
                                                                this.state.taskRunningDetails.length <= 0 &&
                                                                (AccessCheckBlock('i_am_admin') || this.state.myId === this.state.taskDetails.task_owner)) &&
                                                                (
                                                                  <DropdownItem onClick={this.deleteTaskAsk} dropDownValue="GeoAMS"><i className="fa fa-envelope fa-fw"></i>Delete</DropdownItem>
                                                                )}
                                                            </DropdownMenu>
                                                          </Dropdown>
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                                <div className="comments_bc comment-view-newstyle">
                                                  {(this.state.stringifiedDescription && this.state.taskDetails.template_id > 0) &&
                                                    (
                                                      <>
                                                        <div className="stringifiled-template-data">
                                                          {(this.state.stringifiedDescription && this.state.taskDetails.template_id > 0) &&
                                                            (
                                                              // <h5>{this.state.taskDetails.design_type_name}</h5>
                                                              <p>
                                                                Hi, <br />
                                                                <b>{"Please do " + this.state.taskDetails.design_type_name + " with the following details."}</b>
                                                              </p>
                                                          )}

                                                          {(this.state.stringifiedDescription.Other_Instructions && this.state.stringifiedDescription.Other_Instructions.length > 0) &&
                                                            (
                                                              <div className="client_cmd_box">
                                                                <h6>Client Comments</h6>
                                                                <div className="other_instruction">
                                                                  <div>
                                                                    {/* <span className="sec_sub_text">Other Instructions: </span> */}
                                                                    <Linkify className="cmd_editer_preview" options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.stripslashes(this.state.stringifiedDescription.Other_Instructions) + " ")}</Linkify>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )}

                                                          <Row>

                                                            {(this.state.stringifiedDescription.File_Formats && this.state.stringifiedDescription.File_Formats.length > 0) &&
                                                              (
                                                                <Col sm={12} md={4}>
                                                                  <div className="file_typen_soft">
                                                                    <h4>Output File Formats: </h4>
                                                                    <div className="d-flex flex-wrap">
                                                                      {this.state.stringifiedDescription.File_Formats.map((fformat, index) => {
                                                                        return (
                                                                          <div className="d-flex" key={index}>
                                                                            {this.state.stringifiedDescription.File_Formats[index] != 'other' ?
                                                                              (
                                                                                <>
                                                                                  <span>{this.state.stringifiedDescription.File_Formats[index]}</span>
                                                                                  <span className="item-or">{(index + 1) < this.state.stringifiedDescription.File_Formats.length && ' and '}</span>
                                                                                </>
                                                                              ) :
                                                                              (
                                                                                <>
                                                                                  {this.state.stringifiedDescription.File_Formats_other && this.state.stringifiedDescription.File_Formats_other != '' &&
                                                                                    (
                                                                                      <>
                                                                                        <span>{this.state.stringifiedDescription.File_Formats_other}</span>
                                                                                        <span className="item-or">{(index + 1) < this.state.stringifiedDescription.File_Formats.length && ' and '}</span>
                                                                                      </>
                                                                                    )}
                                                                                </>
                                                                              )}


                                                                          </div>
                                                                        )
                                                                      })}

                                                                    </div>
                                                                  </div>
                                                                </Col>
                                                              )}


                                                            {(this.state.stringifiedDescription.Softwares && this.state.stringifiedDescription.Softwares.length > 0) &&
                                                              (
                                                                <Col sm={12} md={4}>
                                                                  <div className="file_typen_soft">
                                                                    <h4>Software to Use: </h4>
                                                                    <div className="">
                                                                      {this.state.stringifiedDescription.Softwares.map((software, index) => {
                                                                        return (
                                                                          <div key={index}>
                                                                            {this.state.stringifiedDescription.Softwares[index] != 'other' ?
                                                                              (
                                                                                <>
                                                                                  <span>{this.state.stringifiedDescription.Softwares[index]}</span>
                                                                                  <span className="item-or">{(index + 1) < this.state.stringifiedDescription.Softwares.length && 'OR'}</span>
                                                                                </>
                                                                              ) :
                                                                              (
                                                                                <>
                                                                                  {this.state.stringifiedDescription.Software_other && this.state.stringifiedDescription.Software_other != '' &&
                                                                                    (
                                                                                      <>
                                                                                        <span>{this.state.stringifiedDescription.Software_other}</span>
                                                                                        <span className="item-or">{(index + 1) < this.state.stringifiedDescription.Softwares.length && ' OR '}</span>
                                                                                      </>
                                                                                    )}
                                                                                </>
                                                                              )}
                                                                          </div>
                                                                        )
                                                                      })}

                                                                      {((this.state.stringifiedDescription.Softwares.includes('Canva') || this.state.stringifiedDescription.Softwares.includes('Let My Designer Choose')) && this.state.projectDetails.canva_access_type!= null ) &&
                                                                      (
                                                                        <div className='displayBlock canva-access-info'>
                                                                          (<span className='canva-access-type'>Canva Access: </span> {this.state.projectDetails.canva_access_type === 1? 'Kimp Account' : 'Client Account'})
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                </Col>
                                                              )}

                                                            {(this.state.stringifiedDescription.outsoftInstructions && this.state.stringifiedDescription.outsoftInstructions != '' > 0) &&
                                                              (
                                                                <Col sm={12} >
                                                                  <div className="file_typen_soft">
                                                                    <h4>Instructions for Output file formats & Software to use </h4>
                                                                    <Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.stripslashes(this.state.stringifiedDescription.outsoftInstructions) + " ")}</Linkify>
                                                                  </div>
                                                                </Col>
                                                              )}


                                                            {(this.state.stringifiedDescription.Watermark && this.state.stringifiedDescription.Watermark.length > 0) &&
                                                              (
                                                                <Col sm={12} md={4}>
                                                                  <div className="file_typen_soft"><h4>Watermark Needed? </h4>{this.state.stringifiedDescription.Watermark}</div>
                                                                </Col>
                                                              )}
                                                            {(this.state.stringifiedDescription.Mockup && this.state.stringifiedDescription.Mockup.length > 0) &&
                                                              (
                                                                <Col sm={12} md={4}>
                                                                  <div className="file_typen_soft"><h4>Mockup Needed? </h4>{this.state.stringifiedDescription.Mockup}</div>
                                                                </Col>
                                                              )}
                                                          </Row>

                                                          {(this.state.stringifiedDescription.mokupInstructions && this.state.stringifiedDescription.mokupInstructions!='') &&
                                                              (
                                                                <Row>
                                                                  <Col sm={12} md={12}>
                                                                    <div className="file_typen_soft"><h4>Mockup Instruction </h4>
                                                                    <Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.stripslashes(this.state.stringifiedDescription.mokupInstructions) + " ")}</Linkify>
                                                                    </div>
                                                                  </Col>
                                                                </Row>
                                                          )}

                                                          <Row>
                                                            {((this.state.stringifiedDescription.Style_Colors && this.state.stringifiedDescription.Style_Colors.length > 0) || (this.state.stringifiedDescription.color_instructions && this.state.stringifiedDescription.color_instructions.length > 0)) &&
                                                            (
                                                              <Col sm={12} md={12}>
                                                                <div className="other_detail_fw">
                                                                {(this.state.stringifiedDescription.Style_Colors && this.state.stringifiedDescription.Style_Colors.length > 0) &&
                                                                  (

                                                                    <>
                                                                      <h4>Colors</h4>
                                                                      <div className="d-flex detail-colors">
                                                                        {typeof (this.state.stringifiedDescription.Style_Colors) === 'object' ?
                                                                          (
                                                                            <>
                                                                              {this.state.stringifiedDescription.Style_Colors.map((coloritem, index) => {
                                                                                return (
                                                                                  <div key={index} className="d-flex" onClick={this.copytoClipboard.bind(this, coloritem)}>
                                                                                    <span className="hexcodeview" style={{ background: coloritem && coloritem }} title={coloritem}></span>
                                                                                  </div>
                                                                                )
                                                                              }
                                                                              )}
                                                                            </>
                                                                          ) :
                                                                          (
                                                                            <>
                                                                              {this.state.stringifiedDescription.Style_Colors}
                                                                            </>
                                                                          )}

                                                                      </div>
                                                                    </>

                                                                  )}
                                                                {(this.state.stringifiedDescription.color_instructions && this.state.stringifiedDescription.color_instructions.length > 0) &&
                                                                  (

                                                                    <>
                                                                      <h4>Color Instructions: </h4>
                                                                      <Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.stripslashes(this.state.stringifiedDescription.color_instructions) + " ")}</Linkify>
                                                                    </>

                                                                  )}
                                                                </div>
                                                              </Col>
                                                            )}
                                                            {(this.state.stringifiedDescription.font_instructions && this.state.stringifiedDescription.font_instructions.length > 0 && this.state.stringifiedDescription.font_instructions && this.state.stringifiedDescription.font_instructions.length > 0) &&
                                                              (
                                                                <Col sm={12}>
                                                                  <div className="other_detail_fw">
                                                                    <h4>Font Instructions:</h4>
                                                                    <Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.stripslashes(this.state.stringifiedDescription.font_instructions) + " ")}</Linkify>
                                                                  </div>
                                                                </Col>
                                                              )}
                                                          </Row>
                                                          <Row>
                                                            {(this.state.stringifiedDescription.Target_Audience && this.state.stringifiedDescription.Target_Audience.length > 0) &&
                                                              (
                                                                <Col sm={12} md={12}>
                                                                  <div className="other_detail_fw"><h4>Target Audience </h4>{this.state.stringifiedDescription.Target_Audience}</div>
                                                                </Col>
                                                              )}
                                                          </Row>


                                                          {/* <Row>
                                {(this.state.stringifiedDescription.Style_Fonts && this.state.stringifiedDescription.Style_Fonts.length>0) &&
                                (
                                  <Col sm={12} md={4}>
                                    <div className="other_detail_fw"><h4>Fonts: </h4>{this.state.stringifiedDescription.Style_Fonts}</div>
                                  </Col>
                                )}
                              </Row> */}
                                                          <Row>
                                                            {(this.state.stringifiedDescription.Purpose && this.state.stringifiedDescription.Purpose.length > 0) &&
                                                              (
                                                                <Col sm={12} md={4}>
                                                                  <div className="other_detail_fw"><h4>Medium: </h4>{this.state.stringifiedDescription.Purpose}</div>
                                                                </Col>
                                                              )}

                                                            {(this.state.stringifiedDescription.resize_crop >= 0) &&
                                                              (
                                                                <Col sm={12} md={4}>
                                                                  <div className="other_detail_fw"><h4>Crop / Trim: </h4>
                                                                    {this.state.stringifiedDescription.resize_crop === 1 ? 'Yes' : 'No'}
                                                                  </div>
                                                                </Col>
                                                              )}
                                                            {(this.state.stringifiedDescription.bleedSize && this.state.stringifiedDescription.bleedSize.length > 0 &&
                                                              this.state.stringifiedDescription.bleedUnit && this.state.stringifiedDescription.bleedUnit.length > 0) &&
                                                              (
                                                                <Col sm={12} md={4}>
                                                                  <div className="other_detail_fw"><h4>Bleed Size & Unit: </h4>
                                                                    {this.state.stringifiedDescription.bleedSize}  {this.state.stringifiedDescription.bleedUnit}
                                                                  </div>
                                                                </Col>
                                                              )}

                                                            {(this.state.stringifiedDescription.FileSizeLimit && this.state.stringifiedDescription.FileSizeLimit.length > 0) &&
                                                              (
                                                                <Col sm={12} md={6}>
                                                                  <div className="other_detail_fw"><h4>File Size Limit: </h4>{this.state.stringifiedDescription.FileSizeLimit}</div>
                                                                </Col>
                                                              )}

                                                            {(this.state.stringifiedDescription.Size && this.state.stringifiedDescription.Size[0].width) &&
                                                              (
                                                                <Col sm={12} md={12}>
                                                                  <div className="other_detail_fw"><h4>Size: </h4>
                                                                    {this.state.stringifiedDescription.Size.map((sizeitem, index) => {
                                                                      return (
                                                                        <div key={index} className="d-flex flex-itmes">
                                                                          <span className="flex-itmes width_task_txt">Width: {sizeitem.width}{sizeitem.unit}</span>
                                                                          <span className="flex-itmes width_task_txt">Height: {sizeitem.height}{sizeitem.unit}</span>
                                                                          {sizeitem.note && sizeitem.note !== '' &&
                                                                            (
                                                                              <span className="flex-itmes width_task_txt">Note: {sizeitem.note}</span>
                                                                            )}

                                                                        </div>
                                                                      )
                                                                    })}
                                                                  </div>
                                                                </Col>
                                                              )}
                                                          </Row>
                                                          {((this.state.stringifiedDescription.Text_Instructions && this.state.stringifiedDescription.Text_Instructions.length > 0) ||
                                                            this.state.stringifiedAttachments.textInstructionFiles.length > 0) &&
                                                            (
                                                              <div className="text_cmd_edit_box">
                                                                {(this.state.stringifiedDescription.Text_Instructions && this.state.stringifiedDescription.Text_Instructions.length > 0) &&
                                                                  (
                                                                    <div>
                                                                      <span className="sub_texts">Text Copy</span>
                                                                      <Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.stripslashes(this.state.stringifiedDescription.Text_Instructions) + " ")}</Linkify>
                                                                    </div>
                                                                  )}
                                                                {(this.state.stringifiedAttachments.textInstructionFiles && this.state.stringifiedAttachments.textInstructionFiles.length > 0) &&
                                                                  (
                                                                    <>
                                                                      <div class="attachment-items ">
                                                                        {this.state.stringifiedAttachments.textInstructionFiles.length > 0 &&
                                                                          (
                                                                            <>
                                                                              <div class="attachment-items d-flex justify-contents-between">
                                                                                <LightboxPop attachments={this.state.stringifiedAttachments.textInstructionFiles}
                                                                                  attachmenttexts={this.state.stringifiedtextlinks && this.state.stringifiedtextlinks.textInstructionFilesTextArray && this.state.stringifiedtextlinks.textInstructionFilesTextArray.length > 0 ? this.state.stringifiedtextlinks.textInstructionFilesTextArray : []}
                                                                                />
                                                                              </div>
                                                                              {this.state.downloadMessage && this.state.downloadItem == 'Text-Instruction-Files-' ?
                                                                                (
                                                                                  <div className='down-all-files'>
                                                                                    Please wait while we prepare the download file.
                                                                                  </div>
                                                                                ) :
                                                                                (
                                                                                  <div className='down-all-files' onClick={this.downloadAllFiles.bind(this, 'Text-Instruction-Files-', this.state.stringifiedAttachments.textInstructionFiles)}>
                                                                                    <MaterialIcon icon="download" /> Download all files
                                                                                  </div>
                                                                                )}
                                                                            </>
                                                                          )}
                                                                      </div>
                                                                    </>
                                                                  )}

                                                                {(this.state.stringifiedtextlinks && this.state.stringifiedtextlinks.textInstructionFilesText && this.state.stringifiedtextlinks.textInstructionFilesText.length > 0) &&
                                                                  (
                                                                    <div>
                                                                      <Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.stripslashes(this.state.stringifiedtextlinks.textInstructionFilesText) + " ")}</Linkify>
                                                                    </div>
                                                                  )}
                                                              </div>
                                                            )}
                                                          {(this.state.stringifiedDescription.Guidelines ||
                                                            this.state.stringifiedAttachments.brandingFiles.length > 0 || this.state.brandLogos || this.state.brandFiles) &&
                                                            (
                                                              <div className="brand_cmd_sec client_cmd_box">
                                                                <h6>Brand Assets & Guidelines</h6>
                                                                {(this.state.stringifiedDescription.Guidelines && this.state.stringifiedDescription.Guidelines.length > 0) &&
                                                                  (
                                                                    <>
                                                                      <div>
                                                                        {/* <span  className="sec_sub_text">Brand Assets & Guidelines </span> */}
                                                                        <Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.stripslashes(this.state.stringifiedDescription.Guidelines) + " ")}</Linkify>
                                                                      </div>
                                                                    </>
                                                                  )}
                                                                {this.state.stringifiedAttachments.brandingFiles.length > 0 &&
                                                                  (
                                                                    <>
                                                                      <div class="attachment-items">
                                                                        {this.state.stringifiedAttachments.brandingFiles.length > 0 &&
                                                                          (
                                                                            <>
                                                                              <div class="attachment-items d-flex justify-contents-between">
                                                                                <LightboxPop attachments={this.state.stringifiedAttachments.brandingFiles} />
                                                                              </div>

                                                                              {this.state.downloadMessage && this.state.downloadItem == 'Branding-Files-' ?
                                                                                (
                                                                                  <div className='down-all-files'>
                                                                                    Please wait while we prepare the download file.
                                                                                  </div>
                                                                                ) :
                                                                                (
                                                                                  <div className='down-all-files' onClick={this.downloadAllFiles.bind(this, 'Branding-Files-', this.state.stringifiedAttachments.brandingFiles)}>
                                                                                    <MaterialIcon icon="download" /> Download all files
                                                                                  </div>
                                                                                )}
                                                                            </>
                                                                          )}
                                                                      </div>
                                                                    </>
                                                                  )}
                                                                {(this.state.taskDetails.brand_id > 0 || this.state.brand > 0) &&
                                                                  (
                                                                    <div className="brand-attahes d-flex">
                                                                      {this.state.brandLogos &&
                                                                        (
                                                                          <a target="_blank" href={"/brand/assets/" + this.props.match.params.bid + "/" + this.state.brand + "/1"}><span><MaterialIcon icon="folder" /> Logos</span></a>
                                                                        )}
                                                                      {this.state.brandFiles &&
                                                                        (
                                                                          <a target="_blank" href={"/brand/" + this.props.match.params.bid + "/" + this.state.brand}><span><MaterialIcon icon="folder" /> All Assets</span></a>
                                                                        )}
                                                                        <a target="_blank" href={"/board/approvedfiles/" + this.props.match.params.bid}><span><MaterialIcon icon="folder" /> Best Designs</span></a>
                                                                        <a target="_blank" href={"/brand/assets/" + this.props.match.params.bid + "/" + this.state.brand + "/9"}><span><MaterialIcon icon="folder" /> Client Reference Designs</span></a>
                                                                    </div>
                                                                  )}
                                                              </div>
                                                            )}
                                                          <Row>
                                                            {(this.state.stringifiedDescription.Website && this.state.stringifiedDescription.Website.length > 0) &&
                                                              (
                                                                <Col sm={12} md={6}><div className="other_detail_fw url_sec_box"><h4>Website </h4><Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{this.state.stringifiedDescription.Website}</Linkify></div></Col>
                                                              )}
                                                            {(this.state.stringifiedDescription.Facebook && this.state.stringifiedDescription.Facebook.length > 0) &&
                                                              (
                                                                <Col sm={12} md={6}><div className="other_detail_fw"><h4>Facebook </h4><Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{this.state.stringifiedDescription.Facebook}</Linkify></div></Col>
                                                              )}
                                                            {(this.state.stringifiedDescription.Twitter && this.state.stringifiedDescription.Twitter.length > 0) &&
                                                              (
                                                                <Col sm={12} md={6}><div className="other_detail_fw"><h4>Twitter </h4><Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{this.state.stringifiedDescription.Twitter}</Linkify></div></Col>
                                                              )}
                                                            {(this.state.stringifiedDescription.LinkedIn && this.state.stringifiedDescription.LinkedIn.length > 0) &&
                                                              (
                                                                <Col sm={12} md={6}><div className="other_detail_fw"><h4>LinkedIn </h4><Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{this.state.stringifiedDescription.LinkedIn}</Linkify></div></Col>
                                                              )}
                                                            {(this.state.stringifiedDescription.Instagram && this.state.stringifiedDescription.Instagram.length > 0) &&
                                                              (
                                                                <Col sm={12} md={6}><div className="other_detail_fw"><h4>Instagram </h4><Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{this.state.stringifiedDescription.Instagram}</Linkify></div></Col>
                                                              )}
                                                            {(this.state.stringifiedDescription.Pinterest && this.state.stringifiedDescription.Pinterest.length > 0) &&
                                                              (
                                                                <Col sm={12} md={6}><div className="other_detail_fw"><h4>Pinterest </h4><Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{this.state.stringifiedDescription.Pinterest}</Linkify></div></Col>
                                                              )}
                                                            {(this.state.stringifiedDescription.OtherMedia && this.state.stringifiedDescription.OtherMedia.length > 0) &&
                                                              (
                                                                <Col sm={12} md={6}><div className="other_detail_fw"><h4>Other Media </h4><Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{this.state.stringifiedDescription.OtherMedia}</Linkify></div></Col>
                                                              )}

                                                          </Row>
                                                          {((this.state.stringifiedAttachments.imageryFiles && this.state.stringifiedAttachments.imageryFiles.length > 0) || (this.state.stringifiedtextlinks && this.state.stringifiedtextlinks.imageryFilesText && this.state.stringifiedtextlinks.imageryFilesText.length > 0)) &&
                                                            (
                                                              <>
                                                                <div className="attch_box">
                                                                  <h6>Images</h6>
                                                                  {this.state.stringifiedAttachments.imageryFiles.length > 0 &&
                                                                    (
                                                                      <>
                                                                        <div class="attachment-items d-flex justify-contents-between">
                                                                          <LightboxPop attachments={this.state.stringifiedAttachments.imageryFiles}
                                                                            attachmenttexts={this.state.stringifiedtextlinks && this.state.stringifiedtextlinks.imageryFilesTextArray && this.state.stringifiedtextlinks.imageryFilesTextArray.length > 0 ? this.state.stringifiedtextlinks.imageryFilesTextArray : []}
                                                                          />
                                                                        </div>

                                                                        {this.state.downloadMessage && this.state.downloadItem == 'Imagery-Files-' ?
                                                                          (
                                                                            <div className='down-all-files'>
                                                                              Please wait while we prepare the download file.
                                                                            </div>
                                                                          ) :
                                                                          (
                                                                            <div className='down-all-files' onClick={this.downloadAllFiles.bind(this, 'Imagery-Files-', this.state.stringifiedAttachments.imageryFiles)}>
                                                                              <MaterialIcon icon="download" /> Download all files
                                                                            </div>
                                                                          )}
                                                                      </>
                                                                    )}

                                                                  {(this.state.stringifiedtextlinks && this.state.stringifiedtextlinks.imageryFilesText && this.state.stringifiedtextlinks.imageryFilesText.length > 0) &&
                                                                    (
                                                                      <div>
                                                                        <Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.stripslashes(this.state.stringifiedtextlinks.imageryFilesText) + " ")}</Linkify>
                                                                      </div>
                                                                    )}
                                                                </div>

                                                              </>
                                                            )}
                                                          {((this.state.stringifiedDescription.Provided_by_client && this.state.stringifiedDescription.Provided_by_client.length > 0) ||
                                                            (this.state.stringifiedDescription.Previous_Approved_work && this.state.stringifiedDescription.Previous_Approved_work.length > 0) ||
                                                            (this.state.stringifiedAttachments.referenceFiles.length > 0) ||
                                                            (this.state.stringifiedAttachments.approvedWorkFiles.length > 0) ||
                                                            (this.state.stringifiedtextlinks && this.state.stringifiedtextlinks.referenceFilesText && this.state.stringifiedtextlinks.referenceFilesText.length > 0) ||
                                                            (this.state.stringifiedtextlinks && this.state.stringifiedtextlinks.approvedWorkFilesText && this.state.stringifiedtextlinks.approvedWorkFilesText.length > 0)) &&
                                                            (
                                                              <div className="clientReferences_box">
                                                                {(this.state.stringifiedDescription.Provided_by_client != '' || this.state.stringifiedDescription.Previous_Approved_work != '') &&
                                                                  (
                                                                    <h6>Client References</h6>
                                                                  )
                                                                }
                                                                {(this.state.stringifiedDescription.Provided_by_client && this.state.stringifiedDescription.Provided_by_client.length > 0) &&
                                                                  (
                                                                    <div className="attach_subtext"><span className="sec_sub_text">{this.state.stringifiedDescription.Provided_by_client} using the references provided by client</span></div>
                                                                  )}
                                                                {this.state.stringifiedAttachments.referenceFiles.length > 0 &&
                                                                  (
                                                                    <>
                                                                      <div class="attachment-items d-flex justify-contents-between">
                                                                        <LightboxPop attachments={this.state.stringifiedAttachments.referenceFiles}
                                                                          attachmenttexts={this.state.stringifiedtextlinks && this.state.stringifiedtextlinks.referenceFilesTextArray && this.state.stringifiedtextlinks.referenceFilesTextArray.length > 0 ? this.state.stringifiedtextlinks.referenceFilesTextArray : []}
                                                                        />
                                                                      </div>

                                                                      {this.state.downloadMessage && this.state.downloadItem == 'Reference-Files-' ?
                                                                        (
                                                                          <div className='down-all-files'>
                                                                            Please wait while we prepare the download file.
                                                                          </div>
                                                                        ) :
                                                                        (
                                                                          <div className='down-all-files' onClick={this.downloadAllFiles.bind(this, 'Reference-Files-', this.state.stringifiedAttachments.referenceFiles)}>
                                                                            <MaterialIcon icon="download" /> Download all files
                                                                          </div>
                                                                        )}
                                                                    </>

                                                                  )}

                                                                {(this.state.stringifiedtextlinks && this.state.stringifiedtextlinks.referenceFilesText && this.state.stringifiedtextlinks.referenceFilesText.length > 0) &&
                                                                  (
                                                                    <div>
                                                                      <Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.stripslashes(this.state.stringifiedtextlinks.referenceFilesText) + " ")}</Linkify>
                                                                    </div>
                                                                  )}

                                                                {(this.state.stringifiedDescription.Previous_Approved_work && this.state.stringifiedDescription.Previous_Approved_work.length > 0) &&
                                                                  (
                                                                    <div className="attach_subtext"><span className="sec_sub_text">{this.state.stringifiedDescription.Previous_Approved_work} using previously approved client work</span></div>
                                                                  )}
                                                                {this.state.stringifiedAttachments.approvedWorkFiles.length > 0 &&
                                                                  (
                                                                    <>
                                                                      <div class="attachment-items d-flex justify-contents-between">
                                                                        <LightboxPop attachments={this.state.stringifiedAttachments.approvedWorkFiles}
                                                                          attachmenttexts={this.state.stringifiedtextlinks && this.state.stringifiedtextlinks.approvedWorkFilesTextArray && this.state.stringifiedtextlinks.approvedWorkFilesTextArray.length > 0 ? this.state.stringifiedtextlinks.approvedWorkFilesTextArray : []}
                                                                        />
                                                                      </div>
                                                                      {this.state.downloadMessage && this.state.downloadItem == 'Approved-Files-' ?
                                                                        (
                                                                          <div className='down-all-files'>
                                                                            Please wait while we prepare the download file.
                                                                          </div>
                                                                        ) :
                                                                        (
                                                                          <div className='down-all-files' onClick={this.downloadAllFiles.bind(this, 'Approved-Files-', this.state.stringifiedAttachments.approvedWorkFiles)}>
                                                                            <MaterialIcon icon="download" /> Download all files
                                                                          </div>
                                                                        )}

                                                                    </>
                                                                  )}
                                                                {(this.state.stringifiedtextlinks && this.state.stringifiedtextlinks.approvedWorkFilesText && this.state.stringifiedtextlinks.approvedWorkFilesText.length > 0) &&
                                                                  (
                                                                    <div>
                                                                      <Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.stripslashes(this.state.stringifiedtextlinks.approvedWorkFilesText) + " ")}</Linkify>
                                                                    </div>
                                                                  )}
                                                              </div>
                                                            )
                                                          }
                                                        </div>
                                                      </>
                                                    )}
                                                  <div className="project-manager-desc comments_bc ">
                                                    {this.state.taskDetails.task_description && this.state.taskDetails.task_description != '' && (
                                                      <>
                                                        <h6>Project Manager Comments</h6>
                                                        <Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.stripslashes(this.state.taskDetails.task_description) + " ")}</Linkify>
                                                      </>
                                                    )}
                                                    <div className="attach_images">
                                                      {(this.state.taskAttachments && this.state.taskAttachments.length > 0) &&
                                                        (
                                                          <>
                                                            <h6>Attachments</h6>
                                                            {this.state.taskAttachments && this.state.taskAttachments.length > 0 &&
                                                              (
                                                                <>
                                                                  <div class="attachment-items d-flex justify-contents-between">
                                                                    <LightboxPop attachments={this.state.taskAttachments}
                                                                      attachmenttexts={this.state.stringifiedtextlinks && this.state.stringifiedtextlinks.taskAttachmentTextArray && this.state.stringifiedtextlinks.taskAttachmentTextArray.length > 0 ? this.state.stringifiedtextlinks.taskAttachmentTextArray : []}
                                                                    />
                                                                  </div>
                                                                  {this.state.downloadMessage && this.state.downloadItem == 'Task-Files-' ?
                                                                    (
                                                                      <div className='down-all-files'>
                                                                        Please wait while we prepare the download file.
                                                                      </div>
                                                                    ) :
                                                                    (
                                                                      <div className='down-all-files' onClick={this.downloadAllFiles.bind(this, 'Task-Files-', this.state.taskAttachments)}>
                                                                        <MaterialIcon icon="download" /> Download all files
                                                                      </div>
                                                                    )}
                                                                </>
                                                              )}
                                                          </>
                                                        )}
                                                      {(this.state.stringifiedtextlinks && this.state.stringifiedtextlinks.taskAttachmentsText && this.state.stringifiedtextlinks.taskAttachmentsText.length > 0) &&
                                                        (
                                                          <div>
                                                            <Linkify options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.stripslashes(this.state.stringifiedtextlinks.taskAttachmentsText) + " ")}</Linkify>
                                                          </div>
                                                        )}
                                                    </div>
                                                    {((this.state.taskDetails.brand_id > 0 || this.state.brand > 0) && (this.state.brandNotes && this.state.brandNotes.length > 0)) &&
                                                      (
                                                        <div className="important-infobox">

                                                          <div className={`info-title${animationClasses}`} >
                                                            <div className="pin_note" >
                                                              <div><MaterialIcon icon="report_gmailerrorred" /> Important information about this task brand </div>
                                                              {/* <div className="arrow_icon"><MaterialIcon icon="expand_more"/></div> */}
                                                            </div>
                                                          </div>

                                                          <LoadingWrapper isLoading={this.state.notesFetch}>
                                                            {this.state.showPinText &&
                                                              (
                                                                <div className="box-area">
                                                                  {/* <div className="close-pintext" onClick={this.closePinText.bind(this)}><MaterialIcon icon="close"/> </div> */}
                                                                  {this.state.noNotesAdded &&
                                                                    (
                                                                      <span className="no-notes">Sorry, no notes added for this brand.</span>
                                                                    )}
                                                                  {(this.state.brandNotes && this.state.brandNotes.length > 0) &&
                                                                    (
                                                                      <ul>
                                                                        {this.state.brandNotes.map((note, index) => {
                                                                          return (
                                                                            <li key={note.note_id}>
                                                                              <Linkify className="cmd_editer_preview" options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.stripslashes(note.notes) + " ")}</Linkify>
                                                                            </li>
                                                                          )
                                                                        })}
                                                                      </ul>
                                                                    )}
                                                                </div>
                                                              )}
                                                          </LoadingWrapper>
                                                        </div>
                                                      )}
                                                  </div>
                                                  {(this.state.stringifiedDescription && this.state.taskDetails.template_id > 0) &&
                                                    (
                                                      // <h5>{this.state.taskDetails.design_type_name}</h5>
                                                      <p className="note_text_footer">
                                                        {"Let me know if you have any questions."}
                                                      </p>
                                                    )}
                                                </div>

                                              </div>
                                              <div className="d-flex">
                                                {AccessCheckBlock('add_edit_task_comments') &&
                                                  (
                                                    <LoadingWrapper isLoading={this.state.commentAddingProgress}>
                                                      <div className="editer_cmd_box note_add_wrapper">
                                                        <ReactQuill value={this.state.taskNewComment}
                                                          onChange={this.handleChangeEditor}
                                                          className={this.state.detailError ? 'field-error' : ''}
                                                          placeholder="Add Comments Here....."
                                                          theme="snow"
                                                          modules={this.modules}
                                                          id="new-comment"
                                                          ref={this.commentRef}
                                                          bounds={'.app'}
                                                         
                                                        />
                                                        {AccessCheckBlock('i_am_designer') &&
                                                          (
                                                            <div className="comment-fields-wrap d-flex ">
                                                              <Row>
                                                               
                                                                <Col sm={12}>
                                                                  <span className="area_label">Inspiration Links</span>
                                                                  <TextareaAutosize
                                                                    style={{ minHeight: 45, padding:10, }} 
                                                                    value={this.state.inspiration}
                                                                    placeholder="Inspiration - Add inspiration links only seperated by comma. Do not add images."
                                                                    onChange={(e) => { this.setState({ inspiration: e.currentTarget.value }); this.setCommentStorage('inspiration', e.currentTarget.value); }} />
                                                                </Col>
                                                                <Col sm={12}>
                                                                <span className="area_label">Resource Links</span>
                                                                  <TextareaAutosize
                                                                   style={{ minHeight: 45, padding:10, }} 
                                                                    value={this.state.resourcelinks}
                                                                    placeholder="Resource Links - Add resource links only seperated by comma. Do not add images."
                                                                    onChange={(e) => { this.setState({ resourcelinks: e.currentTarget.value }); this.setCommentStorage('resourcelinks', e.currentTarget.value); }} />
                                                                </Col>
                                                                <Col sm={12}>
                                                                  <span className="area_label">Notes</span>
                                                                  
                                                                  {/* <ReactQuill value={this.state.pmnote}
                                                                    onChange={(value) => { this.setState({ pmnote: value }); this.setCommentStorage('pmnote', value); }}
                                                                    className={this.state.detailError ? 'Note-to-editor field-error' : 'Note-to-editor'}
                                                                    placeholder="Do not add images here"
                                                                    modules={this.noneoptions}
                                                                    theme="snow" /> */}

                                                                <TextareaAutosize
                                                                  style={{ minHeight: 45, padding:10, }} 
                                                                  value={this.state.pmnote}
                                                                  placeholder="Note to PL / PM / TL / COM / Client. Do not add images."
                                                                  onChange={(e) => { this.setState({ pmnote: e.currentTarget.value }); this.setCommentStorage('pmnote', e.currentTarget.value);}} />

                                                                </Col>
                                                                <Col sm="6" className="need_approval_box">
                                                                  <span className="label_font">Need Design Approval</span>
                                                                  <Input type="checkbox" id="need-approval" onClick={this.needDesignApprovalClick}
                                                                    checked={this.state.needDesignApproval && 'checked'} />
                                                                  <Label className="need-approval" for="need-approval">Yes</Label>
                                                                </Col>
                                                                <Col sm="6" style={{ margin:'12px 0px' }}>
                                                                  {(!this.state.needDesignApproval && this.state.availableAssignees) &&
                                                                    (
                                                                      <PeopleTags tagslist={this.state.availableAssignees}
                                                                        settags={this.setApprover.bind(this)}
                                                                        tags={this.state.approverAssginee}
                                                                        placeholder="Approved By"
                                                                      />
                                                                    )}
                                                                </Col>
                                                                {(this.state.stringifiedDescription && this.state.stringifiedDescription.Softwares && (this.state.stringifiedDescription.Softwares.includes('Ai') || this.state.stringifiedDescription.Softwares.includes('INDD'))) &&
                                                                  (
                                                                    <Col sm={12} className="confirm-box d-flex flex-column">
                                                                      <Label className={`package-correct ${this.state.packageError && 'verification-required'}`} >I packaged files correctly: </Label>
                                                                      <div className='d-flex verify-options'>
                                                                        <div className="theme-radio">


                                                                          <Input type="radio" name="package-correct" value="true" id="package-yes" checked={this.state.iPackaged === 'true' && 'checked'}
                                                                            onClick={(e) => this.iPackagedClick(e)} />
                                                                          <Label for="package-yes">Yes</Label>
                                                                        </div>
                                                                        <div className="theme-radio">
                                                                          <Input type="radio" name="package-correct" value="false" id="package-no" checked={this.state.iPackaged === 'false' && 'checked'}
                                                                            onClick={(e) => this.iPackagedClick(e)} />
                                                                          <Label for="package-no">No</Label>
                                                                        </div>
                                                                        <div className="theme-radio">
                                                                          <Input type="radio" name="package-correct" value="NA" id="package-na" checked={this.state.iPackaged === 'NA' && 'checked'}
                                                                            onClick={(e) => this.iPackagedClick(e)} />
                                                                          <Label for="package-na">Not Applicable</Label>
                                                                        </div>
                                                                      </div>
                                                                    </Col>
                                                                  )}

                                                                <Col sm={12} className="confirm-box d-flex flex-column">
                                                                  <Label className={`spell-check ${this.state.verificationError && 'verification-required'}`} for="spell-check">I have completed the spell check: </Label>
                                                                  <div className='d-flex verify-options'>
                                                                    <div className="theme-radio">
                                                                      <Input type="radio" name="spell-check" value="true" id="spell-check-yes" checked={this.state.iVerified === 'true' && 'checked'}
                                                                        onClick={(e) => this.iVerifiedClick(e)} />
                                                                      <Label for="spell-check-yes">Yes</Label>
                                                                    </div>
                                                                    <div className="theme-radio">
                                                                      <Input type="radio" name="spell-check" value="false" id="spell-check-no" checked={this.state.iVerified === 'false' && 'checked'}
                                                                        onClick={(e) => this.iVerifiedClick(e)} />
                                                                      <Label for="spell-check-no">No</Label>
                                                                    </div>
                                                                    <div className="theme-radio">
                                                                      <Input type="radio" name="spell-check" value="NA" id="spell-check-na" checked={this.state.iVerified === 'NA' && 'checked'}
                                                                        onClick={(e) => this.iVerifiedClick(e)} />
                                                                      <Label for="spell-check-na">Not Applicable</Label>
                                                                    </div>
                                                                  </div>
                                                                  {this.state.projectDetails.onboard_360 === 1 &&
                                                                  (
                                                                    <div className='onborded-360client'>
                                                                    360 onboarded
                                                                    </div>
                                                                  )}
                                                                </Col>


                                                              </Row>
                                                            </div>
                                                          )}
                                                        <Row className={`uploader-text ${this.state.commentImagesTextOptions==2 && 'indv-instructions-wrap'}`}>
                                                        <Col sm="12">
                                                        <KFileUpload myid={this.state.myId}
                                                          folderpath="task/commentattachments"
                                                          arrayname="commentAttachments"
                                                          storefiles={this.storeCommentFileNames}
                                                        //deletefiles={this.deleteCommentFileNames} 
                                                        />
                                                        </Col>
                                                        <Col sm="12">
                                                        {(this.state.commentAttachments && this.state.commentAttachments.length > 0 && !this.state.commentAttachmentsChange) &&
                                                          (
                                                            <>
                                                              <div className='reminder-options custom_radio options-items-parent d-flex comment_image_text'>
                                                                  <div className='d-flex options-items'>
                                                                    <Input checked={this.state.commentImagesTextOptions == 2 && 'checked'} id="textInstructionsseparate" 
                                                                    type="checkbox" name="commentImagesTextOptions" value="2" onClick={this.handClickUpdate}  />
                                                                    <Label for="textInstructionsseparate">Want to add instructions for attachments</Label>
                                                                  </div>
                                                              </div>
                                                              <div class="attachment-items d-flex justify-contents-between">
                                                                <LightboxPop
                                                                  attachments={this.state.commentAttachments}
                                                                  deleteitem="yes"
                                                                  deletefiles={this.deleteCommentFileNames}
                                                                  arrayname={'commentAttachments'}
                                                                  listtype={this.state.commentImagesTextOptions == 2 ? this.state.commentImagesTextOptions : 1}
                                                                  taskAttachmenttextarray={this.state.commentImagesTextArray}
                                                                  updatefiletext={this.updateFileText}
                                                                />
                                                              </div>
                                                            </>
                                                          )}
                                                          </Col>
                                                          </Row>

                                                        <div id="" className="flex-fill equalHM ">
                                                          {(this.state.detailError || this.state.verificationError) &&
                                                            (
                                                              <div className='required-field-text-error'>Please check the required field data</div>
                                                            )}
                                                          <div className="file-wrapper upload-btn-wrapper task_detail_footer">
                                                            <div className="form-group btn_sec">
                                                              <Button type="submit" className="save_btn add_cmd_btn"
                                                                disabled={this.state.saveupdateProgress && 'disabled'}
                                                                onClick={this.createTaskComment}>Add Comment</Button>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      
                                                    </LoadingWrapper>
                                                  )}
                                              </div>
                                              {this.state.taskComments && this.state.taskComments.length > 0 &&
                                                (
                                                  <>
                                                    {this.state.taskComments.map((comment, index) => {
                                                      catindex++;
                                                      return (
                                                        <>
                                                          {((comment.comment_type != 2 && comment.comment_type != 3) || this.state.showAllComment) &&
                                                            (
                                                              <div className={`task-comments-wrap bc_module_cmd_preview ${comment.comment_by === 3 && ' bot-comments'}`} id={comment.comment_id}>
                                                                <TaskComments
                                                                  commentnum = {catindex}
                                                                  project_name = {this.state.projectDetails.board_name}
                                                                  project_id = {this.props.match.params.bid}
                                                                  tid_cpt = {this.props.match.params.id}
                                                                  task_name= {this.state.taskDetails.task_name}
                                                                  comment={comment}
                                                                  commentfields={JSON.parse(comment.comment_fields)}
                                                                  sourceFiles={JSON.parse(comment.source_files)}
                                                                  tagslist={this.state.availableAssignees}
                                                                  myid={this.state.myId}
                                                                  commentattachments={JSON.parse(comment.attachments)}
                                                                  mytimezone={this.state.myTimezone}
                                                                  showeditoption={this.state.editTaskCommentFlag}
                                                                  editid={this.state.editTaskCommentId}
                                                                  edittext={this.state.editTaskComment}
                                                                  showcommentedit={this.showCommentEdit}
                                                                  commentupdate={this.updateTaskComment}
                                                                  cancelcommentupdate={this.cancelCommentUpdate}
                                                                  pullnewcomments={this.pullComments}
                                                                  downloadfiles={this.downloadAllFiles}
                                                                  downloadmessage={this.state.downloadMessage}
                                                                  downloaditem={this.state.downloadItem}
                                                                  atvalues={atValues}
                                                                  stringifiedtextlinks = {comment && comment.comment_attachments_textlinks && comment.comment_attachments_textlinks!=null  && JSON.parse(comment.comment_attachments_textlinks)}
                                                                />
                                                              </div>
                                                            )}
                                                        </>
                                                      )
                                                    })}
                                                  </>
                                                )}
                                            </div>
                                          )}
                                      </LoadingWrapper>
                                    </Col>
                                  </Row>
                                </>
                              )}
                          </>


                        )}
                    </>
                  )}
              </LoadingWrapper>

            </div>
            {this.state.showTitleModal &&
            (
              <EditTaskTitle 
              taskdetails={this.state.taskDetails}
              projectid={this.props.match.params.bid}
              taskid={this.props.match.params.id}
              closeedit={this.closeTaskEditTitle}
              myid={this.state.myId}
              showTitleModal={this.state.showTitleModal}
              projectDetails={this.state.projectDetails}
              />
            )}
            {this.state.showMoveModal &&
            (
              <TaskMoveDetail 
              boardid={this.props.match.params.bid} 
              moveTaskId={this.state.taskDetails.id}
              onmoveupdate={this.moveTaskUpdate} />
            )}
            {this.state.newTicket &&
              (
                <TaskTickets
                  closepop={this.closePopup}
                  projectid={this.props.match.params.bid}
                  taskid={this.props.match.params.id}
                  taskname={this.state.taskDetails.task_name}
                  subject={this.state.ticketSubject}
                  description={this.state.ticketDesc}
                />
              )}
          </Row>
          {this.state.showDeleteConfirmation ? (
            <ConfirmAction
              purpose="change"
              closeDialogue={this.closeDialogue}
              show={this.state.showDeleteConfirmation}
              note="*This action cannot be undone, are you sure?"
              content="You are about to delete this task."
              handledeletion={this.handleDeleteConfirm}
              resolveid=""
              resolveStatus=""
              adminid=""
              confirmBoxAction="deletetask"
            />
          ) : null}
          {this.state.showDuplicateConfirmation ? (
            <ConfirmAction
              purpose="change"
              closeDialogue={this.closeDialogue}
              show={this.state.showDuplicateConfirmation}
              note="*Are you sure?"
              content="You are about to create a duplicate of this task"
              handleduplication={this.duplictethisTask}
              resolveid=""
              resolveStatus=""
              adminid=""
              confirmBoxAction="duplicatetask"
            />
          ) : null}
          {this.state.displayTrelloCard1 && (
            <TrelloCard
              carddetails={this.state.cardDetails1}
              cardcomments={this.state.cardComments1}
              cardattachments={this.state.cardAttachments1}
              closecard={this.closeCard}
              cardclass="cardone"
              showtrellocard={this.showTrelloCard}
              cardtop="30px"
            />
          )}
          {this.state.showClientCardMove &&
          (
            <MyBoards 
            hidemodal={this.hideMoveClientCard} 
            movemodal={this.state.showClientCardMove}
            moveboard={this.moveClientTask}
             />
          )}

          {(this.state.sourceManagement || this.state.editTaskCommentPopupFlag) && (
            <SourceManagement
            minimisedview={this.state.mimimisedSourceView}
            mimimisedupdate ={this.minimisedSourceUpdate}
            cardtop="30px"
            closecard={this.closeSoucePopup}
            boardname={this.state.projectDetails.board_name}
            boardtype={this.state.projectDetails.board_type}
            cardname={this.state.taskDetails.task_name}
            myid={this.state.myId}
            myname={this.state.myName}
            mytimezone={this.state.myTimezone}
            cardId={this.state.taskDetails.task_360_id}
            taskid={this.state.taskDetails.id}
            kim360id={this.state.kim360id}
            taskDetails={this.state.taskDetails}
            availableAssignees={this.state.availableAssignees}
            atValues = {atValues}
            assignees = {this.state.taskAssignees}
            notifiers = {this.state.taskNotifyies}
            project_id = {this.props.match.params.bid}
            tid_cpt = {this.props.match.params.id} 
            sourcecommentpostclose={ this.getTaskComments}
            stringifiedDescription={this.state.stringifiedDescription? this.state.stringifiedDescription : ''}
            myPrivileges={this.state.myPrivileges}
            taskcomments={this.state.taskComments.length > 0 ? this.state.taskComments : [] }
            
            editComment={this.state.editTaskCommentPopupFlag}
            editTaskCommentId = {this.state.editTaskCommentId}
            editTaskComment={this.state.editTaskComment}
            commentfields={(this.state.editTaskCommentPopupFlag && this.state.editTaskComment) && JSON.parse(this.state.editTaskComment.comment_fields)}
            sourceFiles={this.state.editTaskCommentPopupFlag && JSON.parse(this.state.editTaskComment.source_files)}
            commentattachments={this.state.editTaskCommentPopupFlag && JSON.parse(this.state.editTaskComment.attachments)}
            stringifiedtextlinks = {this.state.editTaskCommentPopupFlag && this.state.editTaskComment && this.state.editTaskComment.comment_attachments_textlinks && this.state.editTaskComment.comment_attachments_textlinks!=null  && JSON.parse(this.state.editTaskComment.comment_attachments_textlinks)}

            />
          )}

        </Container>
      </div>
    )
  }
}
