import React, { useState, useEffect } from 'react';
import { 
  Row, 
  Col,
  Input
} from 'reactstrap';
import { DollarSign } from 'lucide-react';

export default function CurrencySummary({ outstandingBalances, currency }){
    return (
      <Row className="g-2">
        {currency && currency!='' ?
        (
          <Col key={currency} xs="auto">
            <div className="px-3 py-2 rounded-3 bg-light border">
              {/* <DollarSign className="me-1" size={16} /> */}
              <span className="fw-semibold">{currency}:</span>{' '}
              {new Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: currency,
                maximumFractionDigits: 0,
              }).format(outstandingBalances)}
            </div>
          </Col>
        ):
        (
          <Col xs="auto">
            <div className="px-3 py-2 rounded-3 bg-light border">
              {'NA'}
            </div>
          </Col>
        )}
       
    </Row>
    );
  };
