import React,{useState} from 'react';
import BaseDrawer from './BaseDrawer';

const FeedbackRequestDrawer = ({ isOpen, toggle, onSubmit, isLoading }) => {
  const [formData, setFormData] = useState({
    requestTo: [],
    feedbackPoints: [''],
    deadline: ''
  });

  return (
    <BaseDrawer
      isOpen={isOpen}
      toggle={toggle}
      title="Request Performance Feedback"
      isLoading={isLoading}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(formData);
      }}
    >
      <div className="form-group">
        <label className="form-label">Feedback From</label>
        <div className="feedback-recipients">
          {['Project Lead', 'Creative Operations Lead'].map(recipient => (
            <div key={recipient} className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id={`recipient-${recipient}`}
                checked={formData.requestTo.includes(recipient)}
                onChange={(e) => {
                  const newRecipients = e.target.checked
                    ? [...formData.requestTo, recipient]
                    : formData.requestTo.filter(r => r !== recipient);
                  setFormData({...formData, requestTo: newRecipients});
                }}
              />
              <label className="form-check-label" htmlFor={`recipient-${recipient}`}>
                {recipient}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="form-group">
        <label className="form-label">Feedback Points</label>
        {formData.feedbackPoints.map((point, index) => (
          <div key={index} className="d-flex gap-2 mb-2">
            <input
              type="text"
              className="form-control"
              value={point}
              onChange={(e) => {
                const newPoints = [...formData.feedbackPoints];
                newPoints[index] = e.target.value;
                setFormData({...formData, feedbackPoints: newPoints});
              }}
              placeholder="Add feedback point"
              required
            />
            {index === formData.feedbackPoints.length - 1 ? (
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => setFormData({
                  ...formData,
                  feedbackPoints: [...formData.feedbackPoints, '']
                })}
              >
                <i className="fas fa-plus"></i>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-outline-danger"
                onClick={() => {
                  const newPoints = formData.feedbackPoints.filter((_, i) => i !== index);
                  setFormData({...formData, feedbackPoints: newPoints});
                }}
              >
                <i className="fas fa-trash"></i>
              </button>
            )}
          </div>
        ))}
      </div>

      <div className="form-group">
        <label className="form-label">Response Deadline</label>
        <input
          type="date"
          className="form-control"
          value={formData.deadline}
          onChange={(e) => setFormData({...formData, deadline: e.target.value})}
          required
        />
      </div>
    </BaseDrawer>
  );
};

export default FeedbackRequestDrawer;