import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { urls } from "../../../services/UrlList"
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

const getSupportTicketAssets = async() => {
   return await axios.get(urls.getSupportTicketAssets);
}

const createSupportTicket = async(data) => {
    return await axios.post(urls.createSupportTicket, data);
}

const getsupporttickets = async(data) => {
    return await axios.post(urls.getSupportTickets, data);
}

const getsupportticket = async(data) => {
    return await axios.post(urls.getSupportTicket, data);
}

const updateSupportAssignee = async(data) => {
    return await axios.post(urls.updateSupportAssignee, data);
}

const updateSupportTopic = async(data) => {
    return await axios.post(urls.updateSupportTopic, data);
}


const updateSupportPriority = async(data) => {
    return await axios.post(urls.updateSupportPriority, data);
}

const updateSupportDue = async(data) => {
    return await axios.post(urls.updateSupportDue, data);
}

const updateSupportStatus = async(data) => {
    return await axios.post(urls.updateSupportStatus, data);
}


const updateSupportParticipant = async(data) => {
    return await axios.post(urls.updateSupportParticipant, data);
}


const addSupportTicketComment = async(data) => {
    return await axios.post(urls.addSupportTicketComment, data);
}

const listSupportTicketComments = async(ticket_id) => {
    return await axios.post(urls.listSupportTicketComments, {ticket_id: ticket_id});
}

const handleSelfResolvingTicket = async(data) => {
    return await axios.post(urls.handleSelfResolvingTicket, data);
}


export const useSelfResolvingTicket = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(handleSelfResolvingTicket, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['support-ticket-', response.data.ticket_id]);
        }
    });
}


export const useListSupportTicketComments = (ticket_id) => {
    return useQuery(['support-tickets-comments-', ticket_id], () => listSupportTicketComments(ticket_id), {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        staleTime: 3600000,
    });
}

export const useAddSupportTicketComment = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(addSupportTicketComment, {
        onSuccess: async (response) => {
            const existingComments = queryClient.getQueryData(['support-tickets-comments-', response.data.ticket_id]);
            if (existingComments) {
                await queryClient.invalidateQueries(['support-tickets-comments-', response.data.ticket_id]);
            }
        }
    });
}

export const useSupportParticipantUpdate = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(updateSupportParticipant, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['support-ticket-', response.data.ticket_id]);
            queryClient.invalidateQueries(['support-tickets-comments-', response.data.ticket_id]);
        }
    });
}

export const useSupportStatusUpdate = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(updateSupportStatus, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['support-ticket-', response.data.ticket_id]);
            queryClient.invalidateQueries(['support-tickets-comments-', response.data.ticket_id]);
        }
    });
}



export const useSupportDueUpdate = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(updateSupportDue, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['support-ticket-', response.data.ticket_id]);
            queryClient.invalidateQueries(['support-tickets-comments-', response.data.ticket_id]);
        }
    });
}

export const useSupportPriorityUpdate = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(updateSupportPriority, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['support-ticket-', response.data.ticket_id]);
            queryClient.invalidateQueries(['support-tickets-comments-', response.data.ticket_id]);
        }
    });
}

export const useSupportTopicUpdate = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(updateSupportTopic, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['support-ticket-', response.data.ticket_id]);
        }
    });
}


export const useSupportAssigneeUpdate = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(updateSupportAssignee, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['support-ticket-', response.data.ticket_id]);
            queryClient.invalidateQueries(['support-tickets-comments-', response.data.ticket_id]);
        }
    });
}


export const useGetSupportTicket = (data) => {
    return useQuery(['support-ticket-', data.ticket_id], () => getsupportticket(data), {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        staleTime: 3600000, 
    });
}

export const useGetsupporttickets = (data)=>{
    return useQuery(['support-tickets'], () => getsupporttickets(data), {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        staleTime: 3600000, 
    });
}

export const useGetSupportTicketAssets = ()=>{
    return useQuery(['support-ticket-assets'], () => getSupportTicketAssets(), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 3600000, 
    });
}

export const useAddSupportTicket = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(createSupportTicket, {
        onSuccess: async (response) => {
            const existingTickets = queryClient.getQueryData(['support-tickets']);
            if (existingTickets) {
                await queryClient.invalidateQueries(['support-tickets']);
            }
        }
    });
}
