import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Input } from "reactstrap";
import Select from "react-select";
import { useGetKimpMembers } from "./useProfileReportHooks";
import "./ProfileReport.css";
import { AccessCheckBlock } from "../bcmodule/AccessCheck";

const ProfileReportFilters = ({
  month,
  userType,
  onMonthChange,
  updateSelectedMember,
}) => {
  const [member, setMember] = useState(0);
  const [viewMonth, setViewMonth] = useState(month);

  const { isLoading: usersLoading, data: usersData } =
    useGetKimpMembers(userType);

  const handleInputChange = (input, e) => {
    console.log("value", input);
  };

  const handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if (size > 0) {
      setMember(selectedOptions[0].id);
      updateSelectedMember(selectedOptions[0].id);
    }
  };

  useEffect(() => {
    setViewMonth(month);
  }, [month]);

  const changeViewMonth = (value) => {
    setViewMonth(value);
    onMonthChange(value);
  };

  return (
    <div className="row performance-report-head-filter">
      <div className="col-md-12 d-flex head-items">
        <h2>Performance Dashboard</h2>
        <div className="d-flex header-right-wrap">
          <Input
            type="select"
            name="time-period"
            className="graph-period head-filter-month monthsort_select"
            onChange={(e) => changeViewMonth(e.target.value)}
          >
           
            <option value="12" selected={month == 12 && "selected"}>
              12 Months
            </option>
            <option value="6" selected={month == 6 && "selected"}>
              6 Months
            </option>
            <option value="3" selected={month == 3 && "selected"}>
              3 Months
            </option>
            <option value="1" selected={month == 1 && "selected"}>
              Last Month
            </option>
            <option value="0" selected={month == 0 && "selected"}>
              Current Month
            </option>
          </Input>
          {(!usersLoading &&  (AccessCheckBlock('i_am_admin') || AccessCheckBlock('i_am_hr') || AccessCheckBlock('i_am_hradmin')
          || AccessCheckBlock('i_am_operations') || AccessCheckBlock('performance_viewer') )) && (
            <Typeahead
              id="user-picker"
              labelKey="name"
              onInputChange={handleInputChange}
              onChange={handleChange}
              options={usersData?.data.users}
              placeholder="Choose member"
              className="head-filter-member-fa"
              selected=""
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileReportFilters;
