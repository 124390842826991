import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { UnreadNotifyCount, UnreadNotifyMessages, ReadNotifyMessages } from '../redux/actions';
import { urls } from '../../services/UrlList';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken} from 'firebase/messaging';
import axios from 'axios';


export default function TokenGenerator (props)  {
  const dispatch = useDispatch();
  var firebaseConfig = {
    apiKey: "AIzaSyBiRRJbcmRQzg77ctxiungqfcnwaGNCnyg",
    authDomain: "kimp-251709.firebaseapp.com",
    projectId: "kimp-251709",
    storageBucket: "kimp-251709.firebasestorage.app",
    messagingSenderId: "706491100227",
    appId: "1:706491100227:web:37bfca2221fae9002b38f6"
 };
 initializeApp(firebaseConfig);

 const messaging = getMessaging();
  
  useEffect(() => {
    var login = JSON.parse( localStorage.getItem("user") );
    getToken(messaging, { vapidKey: 'BNa6GTMfzAs1DF09x37isikUqApbn12oq3MTnRRzoNNB9ESa3kEiU6BrORIHtCjqBYZO7oGWe7wz8aziTGCZirg' })
      .then((currentToken) => {
        if (currentToken) {
        // console.log('current token for client: ', currentToken);
          const data = {
            socket_id: currentToken,
            //tokenupdate: (localStorage.getItem("firstlogin") && localStorage.getItem("firstlogin") === 1) ? 1 : 0,
            tokenupdate: 1,
            sid: localStorage.getItem("superadmin"),
            lid:login.uid
          };

          const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-origin':'*',
          }
          axios.post(urls.updateMySocket, data, {
              headers: headers
            })
            .then((response) => {
                // if(response.data.notifications.length>0)
                // {
                  //console.log(response.data.notifications);
                  //return response.data;
                  dispatch(UnreadNotifyCount(response.data.notifications.length));
                  dispatch(UnreadNotifyMessages(response.data.notifications));
                  dispatch(ReadNotifyMessages(response.data.readnotifications))
                // }
             
              //console.log(BaseValues());
            })
            .catch((error) => {
              console.log(error);
            })
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  },[]);

  return (
      <></>
  );
};