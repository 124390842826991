import React, { useState, useEffect, useCallback } from 'react'
import { Input, Row, Col, Button } from 'reactstrap';
import { urls } from "../../services/UrlList";
import axios from 'axios';
import ProjectPicker from '../filter/ProjectPicker';

const DetailSearch = (props) => {
    const [textValue, setTextValue] = useState('');
    const [tasks, setTasks] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedProject, setSelectedProject] = useState(0);
    
    // Perform search with current values
    const performSearch = async (searchValue, projectId) => {
        if (searchValue.length > 4) {
            setIsLoading(true);
            const data = {
                search_term: searchValue,
                project_id: projectId
            };
            const headers = {
                'Content-Type': 'application/json',
                'Access-Control-Allow-origin': '*',
            }
            try {
                const response = await axios.post(urls.searchTasksUsingKey, data, {
                    headers: headers
                });
                setTasks(response.data.other_results);
            } catch (error) {
                console.error("Search error:", error);
            } finally {
                setIsLoading(false);
            }
        } else {
            setTasks([]);
        }
    };

    // Debounced search function using useCallback to maintain reference
    const debouncedSearch = useCallback(
        debounce((searchValue) => {
            performSearch(searchValue, selectedProject);
        }, 400), // 400ms debounce delay - adjust as needed
        [selectedProject] // Add selectedProject as a dependency
    );

    // Debounce function to limit API calls
    function debounce(func, wait) {
        let timeout;
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    // Handle input changes
    const handleInputChange = (e) => {
        const value = e.target.value;
        setTextValue(value);
        debouncedSearch(value);
    };

    const showTaskDetails = (bid, tid) => {
        window.open(urls.notificationUrl + "/task/detail/" + bid + "/" + tid, "_blank");
    }

    const resetSearch = () => {
        setTasks([]);
        setTextValue('');
    }
    
    const onProjectChange = (projectid) => {
        setSelectedProject(projectid);
        if (textValue.length > 4 && projectid !== 0) {
           performSearch(textValue, projectid);
        }
    }

    return (
        <>
            <button onClick={() => setToggle(!toggle)} className="btn-search"></button>
            {toggle && (
                <>
                    <div className="overlay_search" onClick={() => setToggle(!toggle)}></div>
                    <div className="search_box_bc_module">
                        <div className="search_drop_grp">
                            <Row>
                                <Col xs="12" md="4" style={{ marginTop: '21px' }}>
                                    <ProjectPicker onProjectChange={onProjectChange} />
                                </Col>
                                <Col xs="12" md="8" style={{ marginTop: '21px' }}>
                                    <div className="serach_input_bc">
                                        <Input 
                                            value={textValue} 
                                            type="text" 
                                            onChange={handleInputChange} 
                                            placeholder="Search for boards and tasks. Add '#' before task number to search a particular task."
                                        />
                                        {textValue ? <Button className="reset_btn" onClick={resetSearch}>reset</Button> : ''}
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="search_result_wrap">
                            {isLoading && (
                                <div className="search-loading">
                                    <p>Searching...</p>
                                </div>
                            )}
                            
                            {!isLoading && tasks && tasks.length > 0 ? (
                                <div className="task_result project_result">
                                    <h3> Results </h3>
                                    <ul>
                                        {tasks.map((task, index) => {
                                            return (
                                                <li key={task.id} className="seach_result">
                                                    <div className="sresult_task_detail_wrap" onClick={() => showTaskDetails(task.bid_cpt, task.tid_cpt)}>
                                                        <h5 className="task-name">{task.board_name}</h5>
                                                        <a className="task-link">{task.task_name}</a>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            ):
                            (
                                <>
                                     {(textValue.length > 4 && tasks.length === 0 && !isLoading) &&
                                     (
                                        <div className="task_result project_result">
                                            <p>No results found</p>
                                        </div>
                                     )}
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default DetailSearch;