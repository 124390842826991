import React, {Component, Fragment} from 'react';
import './ProjectSideMenu.css';
import Lottie  from 'react-lottie'
import {motion} from 'framer-motion'
import IconOutline from './382-radio-button-1-morph-edited.json'
import { Link} from "react-router-dom";
import MaterialIcon, {colorPalette} from 'material-icons-react';

export default class DaytoDaySideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStopped: true,
            isPaused: true,
            menuActive: false 
        }
    }
    
    animationStart = () => {
        this.setState({
            isStopped: false,
            isPaused: false
        });
    }
    
    animationStop = () => {
        this.setState({
            isStopped: true,
            isPaused: true
        });        
    }

    toggleMenu = () => {
        this.setState({menuActive: !this.state.menuActive})
    }

    render() {        

        let defaultOptions = {
            loop: false,
            autoplay: false,
            animationData: IconOutline,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
        }

        let menuClass = this.state.menuActive ? 'nsmi_submenu_open' : 'nsmi_submenu_close'

        const list = {
            visible: {
              opacity: 1,
              transition: {
                when: "beforeChildren",
                staggerChildren: 0.01,
                delay: 0.01
              } 
            },
            hidden: { 
              opacity: 0,
              transition: {
                when: "afterChildren",
              } 
            },
          }

          const item = {
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: -5 },
          }

        return(
            <div
                className={"nsmi_home menu-item-home menu-item "+(this.props.activeitem === 'project'? ' active' : '') }
                onMouseEnter={this.animationStart}
                onMouseLeave={this.animationStop}>
                <div className={"nsmi_menu_wrapper d-flex "+ menuClass} onClick={this.toggleMenu}>
                    <div className="nsmi_menu-icon" >
                    <MaterialIcon icon="assignment" />
                       
                    </div>                
                    <p className="nsmi_menu_name">Boards</p>
                    <div className="menu_icon_arrow ml-auto"></div>
                </div>
                {
                    this.state.menuActive &&
                        (
                            <motion.ul 
                                className="nsmi_submenu " 
                                initial="hidden"
                                animate="visible"
                                variants={list}>
                                <Fragment>
                                   <Link to={`/boards?uuid=${this.props.logger}`}><motion.li variants={item}>Boards</motion.li></Link>
                                   <Link to={`/reports/white-label-boards?uuid=${this.props.logger}`} ><motion.li variants={item}>White Label Boards</motion.li></Link>
                                   <Link to={`/boards/assignments?uuid=${this.props.logger}`} ><motion.li variants={item}>Assignments</motion.li></Link>
                                   <Link to={`/board/allactivities?uuid=${this.props.logger}`} ><motion.li variants={item}>Activities</motion.li></Link>
                                   <Link to={`/notifications?uuid=${this.props.logger}`} ><motion.li variants={item}>Notifications</motion.li></Link>
                                   {/* {(this.props.myprivileges.includes('projects_report_live_task_access')) &&
                                   (
                                    <Link to="/reports/active-running-tasks"><motion.li variants={item}>Task Running Log</motion.li></Link>
                                   )}
                                   {(this.props.myprivileges.includes('project_report_task_assigned_access')) &&
                                   (
                                    <Link to="/reports/task-assigned-summary"><motion.li variants={item}>Task Assigned Log</motion.li></Link>
                                   )} */}
                                </Fragment>
                            </motion.ul>
                        )
                }
            </div>
        )
    }
}