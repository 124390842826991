import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col
} from 'reactstrap';
import MaterialIcon from 'material-icons-react';
import ImageUpload from './ImageUpload';
import { useAddSupportTicket } from './useHrTicketsHooks';
import SupportLightboxPop from './SupportLightboxPop';

export default function CreateTicketDrawer({ isOpen, onClose, assets}){ 
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    title: '',
    topic_id: '',
    description: '',
    assignee_id: '',
    priority_id: '',
    slot_id: '',
    slot_value: ''
  });
  
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [topics, setTopics] = useState(assets?.topics || []);
  const [assignees, setAssignees] = useState(assets?.assignees || []);
  const [assignee, setAssignee] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(false);

  const handleImageUpload = (files, data) => {
    setUploadProgress(true);
    const dataArray = Array.isArray(data) ? data : [data];
    setUploadedImages(prev => [...prev, ...dataArray]);
    setFormData(prev => ({
      ...prev,
      files: [...(prev.files || []), ...dataArray]
    }));
    setTimeout(() => {
      setUploadProgress(false);
    }, 200);
    
  };

  // const deleteTicketFileNames = (filename, arrayname) => {
  //   setUploadProgress(true);
  //   var assetupdate = [...uploadedImages];
  //   var itemkey = assetupdate.map(function (item) { return item.file_name; }).indexOf(filename.file_name); 
  //   console.log(itemkey);
  //   assetupdate.splice(itemkey, 1);
  //   setUploadedImages(assetupdate);
  //   setFormData(prev => ({
  //     ...prev,
  //     files: assetupdate
  //   }));
  //   setUploadProgress(false);
  // };

  const deleteTicketFileNames = (filename, arrayname) => {
    setUploadProgress(true);
    setUploadedImages(uploadedImages => {
      const newImages = uploadedImages.filter(item => item.file_name !== filename.file_name);
      setFormData(prev => ({
        ...prev,
        files: newImages
      }));
      return newImages;
    });
    setTimeout(() => {
      setUploadProgress(false);
    }, 200);
  };

  const handleTopicChange = (e) => {
    setPriorities([]);
    setTimeSlots([]);
    const { value } = e.target;   
    setFormData(prev => ({
      ...prev,
      topic_id: value
    }));
    const priority = getPriorityForTopic(value, assets);
    setPriorities(priority);
    const assignee = getAssigneeForTopic(value, assets);
    setAssignee(assignee);
    if(assignee.length > 0){
      setFormData(prev => ({
        ...prev,
        assignee_id: assignee[0].id
      }));
    }
  };

  const handlePriorityChange = (e) => {
    const { value } = e.target;
    setFormData(prev => ({
      ...prev,
      priority_id: value
    }));
    const slots = getSlotsForTopic(value, assets);
    setTimeSlots(slots);
  };

  const getAssigneeForTopic = (topicId, data) => {
    if (!topicId || !data) return [];
    const topic = data.topics.find(t => t.id == topicId);
    if (!topic) return [];
    const assignPerson = data.assignees.find(a => a.id === topic.assignee_id);
    if (!assignPerson) return [];
    return [assignPerson];

};

  const getPriorityForTopic = (topicId, data) => {
    if (!topicId || !data) return [];
    const topic = data.topics.find(t => t.id == topicId);
    if (!topic) return [];
    const priority = data.priority.find(p => p.id === topic.priority_id);
    if (!priority) return [];
    return [priority];
};
  
  
  const getSlotsForTopic = (priorityId, data) => {
    const prioritySlots = data.priority_slots.filter(ps => ps.topic_id == priorityId);
    if (!prioritySlots.length) return [];
    const slots = prioritySlots.map(ps => {
      const slot = data.slots.find(s => s.id == ps.slot_id);
      return slot ? slot : null;
    }).filter(Boolean);
    console.log(slots);
    return slots;
  };


  const validateForm = () => {
    const newErrors = {};
    if (!formData.title.trim()) {
      newErrors.title = 1;
    }
    if (!formData.topic_id) {
      newErrors.topic_id = 1;
    }
    if (!formData.assignee_id) {
      newErrors.assignee_id = 1;
    }
    if (!formData.priority_id) {
      newErrors.priority_id = 1;
    }
    if (!formData.slot_id) {
      newErrors.slot_id = 1;
    }
    if (!formData.description || formData.description.trim() === '<p><br></p>') {
      newErrors.description = 1;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
   };



   const addTicketSupport = (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      if (validateForm()) {
        try {
          console.log(formData);
          addSupportTicket(formData, {
            onSuccess: (data) => {
              onClose();
              resetForm();
            },
          });
          
        } catch (error) {
          setErrors({
            submit: 'Failed to create ticket. Please try again.'
          });
        }
      }
      setIsSubmitting(false);  
    };
  
    const { mutate: addSupportTicket, isLoading: addSupportLoading } =
      useAddSupportTicket();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if(name === 'slot_id'){
      const slot = timeSlots.find(s => s.id == value);
      setFormData(prev => ({
        ...prev,
        slot_value: slot.time_slot
      }));
    }
  };

  const handleDescriptionChange = (content) => {
    setFormData(prev => ({
      ...prev,
      description: content
    }));
  };

  const resetForm = () => {
    setFormData({
      title: '',
      topic_id: '',
      description: '',
      assignee_id: '',
      priority_id: '',
      slot_id: ''
    });
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      ['clean']
    ],
    clipboard: {
      // Toggle to add and disable matching
      matchVisual: false,
      // Prevent pasting images
      matchers: []
    }
  };
  
  // Quill formats configuration
  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'script',
    'indent',
    'direction',
    'color', 'background',
    'align',
  ];

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const items = clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        e.preventDefault();
        return false;
      }
    }
    return true;
  };



  return (
    <>
      <div className={`drawer-overlay ${isOpen ? 'show' : ''}`} onClick={onClose}></div>
      <div className={`ticket-drawer ${isOpen ? 'open' : ''}`}>
        <div className="drawer-header">
          <h4 className="mb-0 drawer-title">Create New Support Ticket</h4>
          <Button close className="drawer-close" onClick={onClose}>
            <MaterialIcon icon="close" />
          </Button>
        </div>
        <div className="drawer-body">
          {/* Form layout with two columns */}
          <Form onSubmit={addTicketSupport} className='form-with-validation'>
            <Row>
              {/* Title field - full width */}
              <Col md={12}>
                <FormGroup>
                  <Label>Title</Label>
                  <Input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>

              {/* Topic field - half width */}
              <Col md={12} className='d-pl1'>
                <FormGroup>
                  <Label>Topic</Label>
                  <Input
                    type="select"
                    name="topic_id"
                    value={formData.topic_id}
                    onChange={handleTopicChange}
                    required
                  >
                    <option value="">Select Topic</option>
                    {topics?.map(topic => (
                      <option key={topic.id} value={topic.id}>
                        {topic.topic_label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup className="form-active-hidden-field">
                  <Label>Assignee</Label>
                  <Input
                    type="select"
                    name="assignee_id"
                    value={formData.assignee_id}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Assignee</option>
                    {assignee?.map(assign => (
                      <option key={assign.id} value={assign.id}>
                        {assign.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6} className='d-pl1'>
                <FormGroup>
                  <Label>Priority</Label>
                  <Input
                    type="select"
                    name="priority_id"
                    value={formData.priority_id}
                    onChange={handlePriorityChange}
                    required
                  >
                    <option value="">Select Priority</option>
                    {priorities?.map(priority => (
                      <option key={priority.id} value={priority.id}>
                        {priority.priority_label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>

              <Col md={6} className='d-pr1'>
                <FormGroup>
                  <Label>Response Time</Label>
                  <Input
                    type="select"
                    name="slot_id"
                    value={formData.slot_id}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Slot</option>
                    {timeSlots?.map(slot => (
                      <option key={slot.id} value={slot.id}>
                        {`${slot.time_slot} ${slot.time_slot === 1 ? 'hour' : 'hours'}`}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>

              
              <Col md={12}>
                <FormGroup>
                  <Label>Description</Label>
                  <div onPaste={handlePaste} className={`${errors?.description && errors.description == 1 ? 'is-error' : ''}`}>
                    <ReactQuill
                      value={formData.description}
                      onChange={handleDescriptionChange}
                      modules={modules}
                      formats={formats}
                      className="ticket-description-editor"
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <ImageUpload 
                    myid={1}
                    folderpath="tickets"
                    arrayname="ticketAttachments"
                    storefiles={handleImageUpload}
                  />
                </FormGroup>
                {uploadedImages?.length > 0 && !uploadProgress && (
                    <div className="attachment-items d-flex justify-contents-between">
                      <SupportLightboxPop
                        files={uploadedImages}
                        deleteitem="yes"
                        deletefiles={deleteTicketFileNames}
                        arrayname={'ticketAttachemnt'}
                        listtype={1}
                      />
                    </div>
                  )}
              </Col>
            </Row>
            
            {/* Action buttons */}
            <div className="d-flex justify-content-end mt-4">
              <Button color="secondary" onClick={onClose} className="mr-2">
                Cancel
              </Button>
              <Button color="primary btn-custom-primary" type="submit">
                Create Ticket
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
