import React, { useState, useEffect } from 'react';
import { Container, Row, Input, Col, Card, CardHeader, CardBody } from "reactstrap";
import { formatCurrency } from '../../Utils/HelperFunctions';

const LoanDetails = ({ loans, currency }) => {
   const parsedLoans = JSON.parse(loans);
    return (
      <div className="ps-2">
        {parsedLoans.map((loan, index) => (
          <div key={index} className="mb-3 p-3 rounded-3 bg-light ">
            <Row className="g-3">
              <Col xs={12} md={4}>
                <div className="text-muted small">Amount</div>
                <div className="fw-semibold">
                  {new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: currency,
                    maximumFractionDigits: 0,
                  }).format(loan.loan_allocated_amount)}
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="text-muted small">Date</div>
                <div className="fw-semibold">
                  {new Date(loan.loan_applied_on).toLocaleDateString()}
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="text-muted small">Currency</div>
                <div className="fw-semibold">{currency}</div>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    );
  };
  
  export default LoanDetails;