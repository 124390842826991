import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Table, Label, Input } from 'reactstrap';

function CrmTestimonials({ 
    projectManagers = [], 
    projectLeads = [], 
    comOptions = [], 
    opsOptions = [],
    data: initialData = [] 
  }) {
    const [data, setData] = useState(initialData);
    const [filters, setFilters] = useState({
      project_maanger_id: '',
      project_lead_id: '',
      com_id: '',
      ops_id: ''
    });
  
    useEffect(() => {
      setData(initialData);
    }, []);
  
    // Function to handle filter changes
    const handleFilterChange = (e) => {
      const { name, value } = e.target;
      setFilters(prevFilters => ({
        ...prevFilters,
        [name]: value
      }));
    };
  
    // Function to filter data
    const filterData = (dataToFilter) => {
      return dataToFilter.filter(item => {
        return (
          (!filters.project_maanger_id || item.project_maanger_id === parseInt(filters.project_maanger_id)) &&
          (!filters.project_lead_id || item.project_lead_id === parseInt(filters.project_lead_id)) &&
          (!filters.com_id || item.com_id === parseInt(filters.com_id)) &&
          (!filters.ops_id || item.ops_id === parseInt(filters.ops_id))
        );
      });
    };
  
    return (
      <Row>
          <div className="p-3">
                {/* Filter Section */}
                <div className="mb-4">
                  <Row>
                    <Col md={3}>
                      <Label>Requested By</Label>
                        <Input type="select" 
                          name="project_maanger_id"
                          value={filters.project_maanger_id}
                          onChange={handleFilterChange}
                        >
                          <option value="">Select</option>
                          {projectManagers.map(pm => (
                            <option key={pm.id} value={pm.id}>
                              {pm.name}
                            </option>
                          ))}
                        </Input>
                      
                    </Col>
                    <Col md={3}>
                     
                        <Label>Project Lead</Label>
                        <Input type='select'
                          name="project_lead_id"
                          value={filters.project_lead_id}
                          onChange={handleFilterChange}
                        >
                          <option value="">Select</option>
                          {projectLeads.map(pl => (
                            <option key={pl.id} value={pl.id}>
                              {pl.name}
                            </option>
                          ))}
                        </Input>
                      
                    </Col>
                    <Col md={3}>
                      
                        <Label>COM</Label>
                        <Input type='select'
                          name="com_id"
                          value={filters.com_id}
                          onChange={handleFilterChange}
                        >
                          <option value="">Select</option>
                          {comOptions.map(com => (
                            <option key={com.id} value={com.id}>
                              {com.name}
                            </option>
                          ))}
                        </Input>
                     
                    </Col>
                    <Col md={3}>
                      
                       <Label>OM</Label>
                       <Input type='select'
                          name="ops_id"
                          value={filters.ops_id}
                          onChange={handleFilterChange}
                        >
                          <option value="">Select</option>
                          {opsOptions.map(ops => (
                            <option key={ops.id} value={ops.id}>
                              {ops.name}
                            </option>
                          ))}
                        </Input>
                      
                    </Col>
                  </Row>
                </div>

              {/* Table Section */}
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Board</th>
                      <th>Platform</th>
                      <th>Requested By</th>
                      <th>Requested On</th>
                      <th>Received On</th>
                      <th>Status</th>
                      <th>Rating</th>
                      <th>Project Manager</th>
                      <th>Project Lead</th>
                      <th>COM</th>
                      <th>OM</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterData(data).map((item) => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.request_platform}</td>
                        <td>{item.requested_by}</td>
                        <td>{new Date(item.requested_on).toLocaleDateString()}</td>
                        <td>{new Date(item.received_on).toLocaleDateString()}</td>
                        <td>{item.status}</td>
                        <td>{item.rating_received}</td>
                        <td>{item.project_maanger_id}</td>
                        <td>{item.project_lead_id}</td>
                        <td>{item.com_id}</td>
                        <td>{item.ops_id}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
        </Row>
  );
};

export default CrmTestimonials;