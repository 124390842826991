import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ActionDialog = ({ isOpen, toggle, onConfirm, headMsg, descriptionMsg, ConfirmLabel }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="confirm-box-dialog">
      <ModalHeader toggle={toggle}>
        {headMsg}
      </ModalHeader>
      <ModalBody>
        {descriptionMsg}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button color="primary" onClick={onConfirm} className='modern-btn comment-btn'>
          {ConfirmLabel}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ActionDialog;