import React,{useState} from 'react';
import BaseDrawer from './BaseDrawer';

const FeedbackSubmissionDrawer = ({ isOpen, toggle, onSubmit, isLoading, feedbackPoints = [] }) => {
  const [formData, setFormData] = useState({
    overallComment: ''
  });

  return (
    <BaseDrawer
      isOpen={isOpen}
      toggle={toggle}
      title="Submit Performance Feedback"
      isLoading={isLoading}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(formData);
      }}
    >
      {/* {formData.responses.map((response, index) => (
        <div key={index} className="form-group">
          <label className="form-label">{response.point}</label>
          <div className="rating-group mb-2">
            {[1, 2, 3, 4, 5].map(rating => (
              <button
                key={rating}
                type="button"
                className={`btn btn-outline-primary me-2 ${response.rating === rating ? 'active' : ''}`}
                onClick={() => {
                  const newResponses = [...formData.responses];
                  newResponses[index].rating = rating;
                  setFormData({...formData, responses: newResponses});
                }}
              >
                {rating}
              </button>
            ))}
          </div>
          <textarea
            className="form-control"
            rows={2}
            value={response.comments}
            onChange={(e) => {
              const newResponses = [...formData.responses];
              newResponses[index].comments = e.target.value;
              setFormData({...formData, responses: newResponses});
            }}
            placeholder="Add comments"
            required
          />
        </div>
      ))} */}

      <div className="form-group">
        <label className="form-label">Comments</label>
        <textarea
          className="form-control"
          rows={4}
          value={formData.overallComment}
          onChange={(e) => setFormData({...formData, overallComment: e.target.value})}
          placeholder="Provide feedback"
          required
        />
      </div>
    </BaseDrawer>
  );
};

export default FeedbackSubmissionDrawer;