import React, { Component, Fragment } from 'react';
import ReactDOM from "react-dom";
import { Redirect } from 'react-router';
import './loginportal.css';
import Header from '../header/Header';
import { Container, Row, Col, Card, Button, Input} from 'reactstrap';
import Log from './log/Log';
import {urls} from "../../services/UrlList";
import "../../components/common/Routes";
import SideMenu from "../NewSideMenu/NewSideMenu";
import {isMobile} from 'react-device-detect';
import Alertlogmsg from "../messages/Alertlogmsg";
import AlertMsg from "../AlertMessage/AlertMessage";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import LunchBreakPopup from "./LunchBreakPopup";
import ExtendedBreakPopup from './ExtendedBreakPopup';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';
import TokenGenerator from '../header/TokenGenerator';
import ConfirmDialogue from '../admincomment/ConfirmAction';

import BreakRunningVoice from './BreakRunningVoice';

export default class LoginPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myPrivileges:[], 
      myId:'',  
      punch_type:'', 
      initialLoader:true,
      progressBarShift:false,
      ProgressBarBreak:false,
      ProgressBarExtBreak:false,
      punchStatus:0,
      breakStatus:0,
      lunchStatus:0,
      extStatus:0,
      lockStatus:'',
      logActivities:[], 
      logMsg:'',
      errorHealth:false,
      errorQr:false,
      dailyQuoteLoader: true,
      dailyQuote:[],
      healthErrorMsg: "Please click <a href='https://Kimphub.com/client-health'>here</a> to complete your client health report before ending your shift.",
      qrErrorMsg: "Please click <a href='https://Kimphub.com/report/questionnaire'>here</a> to complete your questionnaire report before ending your shift.",
      errorTicket:false,
      ticketErrorMsg:"You have some tickets pending to take action. Please review or set a due date for next working day.",
      loggedinTime:'0:00',
      breakTime:'0:00',
      lunchTime:'0:00',
      extBreakTime:'0:00',
      totalBreak:'0:00',
      totalBreakPercentage:0, 
      totalExtBreakPercentage:0,  
      startTimem:0, 
      startTimemx:0,
      showTimer:'0',
      showExtBreak:0,
      isMobileView:0,
      isLocked:0,
      showRequestPopup:false,
      requestComment:"",
      waitingStatus:false,
      shiftRestartRequest:'0',
      showShiftRequestPopup:false,
      ShiftUnblockWaitingStatus:false,
      intervalDelayNormal:55000,
      intervalDelayHigh:55000,
      redirectOn:false,
      isLoggedOut:false,
      errorMsg:0,
      myBaseId:'',
      workTime:"00:00:00",
      isrequired_field:false,
      rpeople:'',
      breakEnabled:false,
      myNotifications:[],
      notificationId:'',
      showingNotification:false,
      showMenu:true,
      hrLeaveApprovalPending:false,
      allowLeaveException:0,
      username:'',
      quickActionBlock:true,
      sessionNotExist:false,
      sessionMsg:'You login session timed out due to inactivity. Kimphub will auto logout now, and you have to login again',
      showExtentedBreakCommentBox:false,
      commentExtendedError:false,
      extendedBreakComment:'',
      extBreakTimemilli:0,
      breakTimemilli:0,
      oldShift:false,
      oldShiftMsg:'Looks like you are still doing a previous day shift. Kindly review.',
      socketId:'',
      openNCount:0,
      shiftExist:true,
      showShitEndConfirmation:false,
      speechOn:0,
      crossLimit:0
     
    };
  }
  componentDidMount =() =>
  {
    document.title = "HUB - My Timing";    
    this.checkUserPrivilege();  
    window.addEventListener('visibilitychange', this.handleFocus); // newly added 
    //this.notificationChecker(); 
    //window.addEventListener("blur", this.onBlurTimer);      
    const _this = this;
    setTimeout(function() { //Start the timer  
      _this.getDailyQuote();
      _this.renderContentDevice();
      _this.getUserBreakException();
      _this.breakEnabler();   
      _this.getStatusLogs();              
  }, 800);
    setTimeout(function() { //Start the timer        
      _this.getLoggedIn();
      if(_this.state.punchStatus)  
        _this.getMyNotifications();    
      }, 2500);    
  };

  componentDidUpdate(prevState) {  
    if (prevState.errorMsg !== this.state.errorMsg  && this.state.errorMsg === true) {      
      const _this = this;
      setTimeout(function() {           
        _this.setState({errorMsg:false})
      }, 20000);  
    } 

    if (prevState.hrLeaveApprovalPending !== this.state.hrLeaveApprovalPending  && this.state.hrLeaveApprovalPending === true) {      
      const _this = this;
      setTimeout(function() {           
        _this.setState({hrLeaveApprovalPending:false})
      }, 15000);  
    } 

    if (prevState.sessionNotExist !== this.state.sessionNotExist  && this.state.sessionNotExist === true) { 
      const _this = this;
      setTimeout(function() {   
      _this.setState({sessionNotExist:false});
      _this.signOut();
      }, 5000);
    } 

    if (prevState.errorHealth !== this.state.errorHealth  && this.state.errorHealth === true) { 
      const _this = this;
      setTimeout(function() {   
      _this.setState({errorHealth:false});
      }, 12000);
    } 


    if (prevState.errorQr !== this.state.errorQr  && this.state.errorQr === true) { 
      const _this = this;
      setTimeout(function() {   
      _this.setState({errorQr:false});
      }, 12000);
    } 

    if (prevState.errorTicket !== this.state.errorTicket  && this.state.errorTicket === true) { 
      const _this = this;
      setTimeout(function() {   
      _this.setState({errorTicket:false});
      }, 20000);
    }
    // if (prevState.showingNotification !== this.state.showingNotification  && this.state.showingNotification === true) {      
    //   const _this = this;
    //   setTimeout(function() {           
    //     _this.setState({showingNotification:false});
    //     _this.updateNotificationStatus();
    //   }, 15000);  
    // } 

  }

  componentWillUnmount() {         
    //window.removeEventListener("blur", this.onBlurTimer);   
    clearInterval(this.interval); 
    this.renderContentDevice();
    this.getUserBreakException();
    this.breakEnabler();   
    this.getStatusLogs(); 
    this.getLoggedIn();
    window.removeEventListener('visibilitychange', this.handleFocus);  // newly added 
    //this.notificationChecker(); 
  }


  handleFocus = async () => {
    this.setState({
      initialLoader:true
    });
    //await this.checkShiftEnded();
    await this.getLoggedIn();
 }


  onBlurTimer = () => {   
    const _this = this;
    setTimeout(function() { //Start the timer          
     // _this.props.history.push("/tick-report");       
      clearInterval(this.interval); 
     _this.setState({redirectOn:true});   
  }, 90000);    
  }

  
  updateMySocket = async (socketid)=>
  {
    const data = {
      socket_id: socketid,
      user_id: this.state.myId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    await axios.post(urls.updateMySocket, data, {
        headers: headers
      })
      .then((response) => {
        this.setState({
          socketId: socketid,
        })
      })
      .catch((error) => {
        //notifyError();
      })
  }

  notificationChecker = () =>
  {
    const {myProp, match: {params}} = this.props; 
    const _this = this;
    setTimeout(function() 
    {
      _this.updateMySocket(myProp.connection.socket_id);
    }, 2000);
    
    var channel = myProp.subscribe('Hub-Tasks');
    channel.bind('hub-event', function(data) {
      if(data.id === myProp.connection.socket_id)
      {
          _this.setState({
          openNCount: _this.state.openNCount +1
        })
      }
    });
    
  }
  intevalModifier =() =>
  {
    if((this.state.punchStatus == 0 && this.state.ShiftUnblockWaitingStatus === '1') || this.state.breakStatus == 1 ||  this.state.lunchStatus == 1 || this.state.extStatus == 1 || this.state.ShiftUnblockWaitingStatus == 1)
    {      
     clearInterval(this.interval);
     this.interval = setInterval(() => this.getLoggedIn(),15000);      
    }else
    {
      clearInterval(this.interval);
     this.interval = setInterval(() => this.getLoggedIn(),this.state.intervalDelayNormal);
    } 
  };

  getDailyQuote = () => {
    fetch(urls.getDailyQuote, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {           
        this.setState({
          dailyQuote: response[0].quote,
          dailyQuoteLoader: false
        });
      })
      .catch(err => {
        console.error("Get Daily Quotes: ", err)
      });
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  renderContentDevice = () => {
    //console.log('Window Screen Width:', navigator.maxTouchPoints);
    // if ((isMobile && window.screen.width <= 500)) {
    //     this.setState({isMobileView:1});
    // }  

    if (navigator.maxTouchPoints === 5) {
        this.setState({isMobileView:1});
    }  
  };

  getUserBreakException = () =>
  {
    fetch(urls.getUserBreakException+this.state.myId,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      }
       })
      .then(response => response.json())
      .then(response =>
      {                  
        this.setState({
          allowLeaveException:response[0].exception
        });
      })
      .catch(error =>
        {
          this.setState({progressBarShift:false});         
        });
  };

  updateBreakExceptionStatus = () =>
  {
      if(this.state.allowLeaveException === 1)
      {
          this.setState({allowLeaveException: 0});
      }else
      {
         this.setState({allowLeaveException: 1});
      }
  }

  allowUserBreakException = () => {
   this.setState({initialLoader:true});
    let opts = {
      user_id:this.state.myId,
      username: this.state.username,
      break_exception: this.state.allowLeaveException,
      approved_by:localStorage.getItem("superadmin")
    };    
    fetch(urls.allowUserBreakException,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
       })
      .then(response => response.json())
      .then(response =>
      { 
        this.setState({initialLoader:false});
      })
      .catch(error =>
      {
        this.setState({initialLoader:true});         
      });
  }
  
  updateNotificationStatus = () => {
    let opts = {
      user_id:this.state.myId,
      notification_id:this.state.notificationId  
    };    
    fetch(urls.updateNotificationStatus,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
       })
      .then(response => response.json())
      .then(response =>
      { 
        this.setState({notificationId:''});
        this.getMyNotifications();
      })
      .catch(error =>
      {
        this.setState({progressBarShift:false});         
      });
  }

  getMyNotifications = () =>
  {
    fetch(urls.getMyNotificationsLog+this.state.myId,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      }
       })
      .then(response => response.json())
      .then(response =>
      {                  
        this.setState({  
          initialLoader:false,        
          myNotifications: response.items,
          notificationId: response.items.length>0? response.items[0].id : '',
          showingNotification: response.items.length>0? true : false,
        });
      })
      .catch(error =>
        {
          this.setState({progressBarShift:false,initialLoader:false});         
        });
  };

  getWorkHoursDaily = () =>
  {   
    fetch(urls.getWorkHoursDaily+this.state.myId+"/"+this.state.myBaseId,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      }})
      .then(response => response.json())
      .then(response =>
      {                  
        this.getMyNotifications();
      })
      .catch(error =>
      {
        this.setState({progressBarShift:false});   
        this.getMyNotifications();        
      });
  }


  getLatetimeWeekly = () =>
  {
    fetch(urls.getLatetimeWeekly+this.state.myId,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      }
       })
      .then(response => response.json())
      .then(response =>
      {                  
        this.getMyNotifications();
      })
      .catch(error =>
        {
          this.setState({progressBarShift:false});         
        });
  };

  getLatetimeMonthly = () =>
  {
    fetch(urls.getLatetimeMonthly+this.state.myId,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      }
       })
      .then(response => response.json())
      .then(response =>
      {                  
        this.getMyNotifications();
      })
      .catch(error =>
        {
          this.setState({progressBarShift:false});         
        });
  };


  getOverquotaWeekly = () =>
  {
    fetch(urls.getOverquotaWeekly+this.state.myId,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      }
       })
      .then(response => response.json())
      .then(response =>
      {                  
        this.getMyNotifications();
      })
      .catch(error =>
        {
          this.setState({progressBarShift:false});         
        });
  };

  getOverquotaMonthly = () =>
  {
    fetch(urls.getOverquotaMonthly+this.state.myId,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      }
       })
      .then(response => response.json())
      .then(response =>
      {                  
        this.getMyNotifications();
      })
      .catch(error =>
        {
          this.setState({progressBarShift:false});         
        });
  };


  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") === null)
    {
      this.setState({isLoggedOut:true})
    }else
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, myId:login.uid,myBaseId:login.basecampid, showExtBreak:login.showext, username: login.name });
    }
   

  };

  checkShiftEnded = async () =>
  {   
    fetch(urls.checkShiftEnded+this.state.myId,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      }
       })
      .then(response => response.json())
      .then(response =>
      {          
        this.setState({          
          shiftRestartRequest: response[0].logout === 'ok' ? true : false
        });
      })
      .catch(error =>
        {
          this.setState({progressBarShift:false});         
        });  
  };

  getLoggedIn = async () =>
  {  
   this.waitingApprovalRequest();
   this.waitingShiftUnblockApprovalRequest();
   this.checkShiftEnded();    
    let opts ={
      user_id:this.state.myId,
      mybase_id:this.state.myBaseId,
      shift:this.state.punchStatus,
      break:this.state.breakStatus,
      lunch:this.state.lunchStatus,
      extbreak: this.state.extStatus
    };              
   await fetch(urls.myLoggedTime,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response =>response.json())
    .then(response =>
      {           
        this.setState({         
          isLocked: response[0].locked_out,
          breakTime: response[0].totalbreak,
          lunchTime:response[0].totalbreaktime,
          extBreakTime:response[0].totalextbreak,
          breakTimemilli: response[0].totalbreakmills,
          extBreakTimemilli: response[0].totalextbreakmills,
          loggedinTime:response[0].totaltime,
          totalBreak: response[0].totalbreaktime,
          totalBreakPercentage:response[0].total_break_percentage,
          totalExtBreakPercentage: response[0].total_extbreak_percentage,   
          workTime:response[0].total_work_hours,  
          oldShift: response[0].total_in_minutes >= 1320 ? true : false,   
          initialLoader:false,
          progressBar:false,
        });

        if(response[0].total_in_minutes >= 1320)
        {
          localStorage.setItem("oldShift", true);
        }else
        {
          localStorage.setItem("oldShift", false);
        }
      })

  };


  getStatusLogs =()=>
  {    
    var pagetite = "HUB - My Timing";
    var notification = "Break Time Running";
    fetch(urls.allMactivities+this.state.myId,{
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Content-Type':'application/json'
        }
      })
      .then(response =>response.json())
      .then(response =>
        {          
          if(response[0]['session_error'] === 1)
          {
               this.setState({
                 sessionNotExist:true,
                 initialLoader:false
               });
          }else
          {
            this.setState({
              progressBar:false,
              breakStatus: response[0]['status'] != null ? response[0]['status'].break_status:'',
              lunchStatus: response[0]['status'] != null ? response[0]['status'].lunch_status:'',
              extStatus: response[0]['status'] != null ? response[0]['status'].extbreak_status:'',
              punchStatus:response[0]['status'] != null ? response[0]['status'].punch_status:'',
              lockStatus: response[0]['status'] != null ? response[0]['status'].lock_down_status:'',
              logActivities: response[0]['activities'].length>0 ? response[0]['activities']:[],
              shiftExist: response[0]['shiftactive'] === 0? false : true
              });

              if(response[0]['status'].lunch_status == 1)
              {
                clearInterval(this.breakinterval);
                this.breakinterval = setInterval(() => this.breakVoiceCheck(),300000);
              }else
              {
                clearInterval(this.breakinterval);
              }

              if(response[0]['shiftactive'] === 0)
              {
                localStorage.setItem("invalidshift", '1');
              }else if(response[0]['shiftactive'] === 2)
              {
                localStorage.setItem("invalidshift", '2');
              }else if(response[0]['status'].break_status === 1 || response[0]['status'].lunch_status == 1 || response[0]['status'].extbreak_status === 1)
              {
               localStorage.setItem("invalidshift", '3');
              }else
              {
                localStorage.setItem("invalidshift", '0');
              }


            
            if(response[0]['status'] != null && (response[0]['status'].punch_status === 0 || response[0]['status'].break_status === 1 || response[0]['status'].lunch_status === 1 || response[0]['status'].extbreak_status === 1))
            {
              this.setState({
                quickActionBlock:false
              });
              let qopts ={
                quickActionBlock:0
              }
              localStorage.setItem("quickactions", JSON.stringify(qopts));
            }else
            {
              this.setState({
                quickActionBlock:true
              });
              let qopts ={
                quickActionBlock:1
              }
              localStorage.setItem("quickactions", JSON.stringify(qopts));
            }
            if(response[0]['status'] != null && (response[0]['status'].break_status == 1 || response[0]['status'].lunch_status == 1 || response[0]['status'].extbreak_status == 1) )
            {
              this.binterval = setInterval(function(){
                          if(pagetite == document.title)
                          {
                            document.getElementById("favicon").setAttribute("href", 'https://Kimphub.com/service/storage/tea-icon-new.png');
                            }else
                          {
                            document.getElementById("favicon").setAttribute("href", 'https://Kimphub.com/service/storage/favicon.ico');
                          }
                          document.title = (pagetite == document.title)
                                           ? notification
                                           : pagetite;
                      
                      
                  }, 1000);
            }
          }
        })
  };

  beakMessage = () =>
  {
    this.setState({speechOn:0, crossLimit:0}); 
  }

  breakVoiceCheck = () =>
  {
    let opts ={
      user_id:this.state.myId,
    };              
    fetch(urls.lunchactivetime,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response =>response.json())
    .then(response =>
    {
      if(response[0].breakactive ==1 )
      {
        if(response[0].totaltime>=80 && response[0].totaltime<= 90 )
        {
          this.setState({speechOn:1, crossLimit:0}); 
        }else if(response[0].totaltime > 90 )
        {
          this.setState({speechOn:1, crossLimit:1}); 
        }
      }else
      {
        clearInterval(this.breakinterval);
      }
    })

  }

  shiftActions = async (id) =>
  {   
    this.setState({initialLoader:true});
    let opts ={
      user_id: this.state.myId,
      punch_type: id,
      username: this.state.username
    };

    fetch(urls.startShift,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response =>response.json())
    .then(response =>
      {     
        this.setState({
          logMsg: response[0]['msg'],
          errorMsg:(response[0]['success'] === 2 || response[0]['success'] === 7) ? true : false,   
          errorHealth: response[0]['success'] === 5? true : false,
          errorQr: response[0]['success'] === 8? true : false,
          errorTicket: response[0]['success'] === 10? true : false,
          hrLeaveApprovalPending: response['leave_pending_approved'] === 1 ? true:false
        });
        this.checkShiftEnded();
        this.getStatusLogs();
        this.getMyNotifications();
        const _this = this;
        clearInterval(this.interval);    
        if(id == 2)
        {
          localStorage.setItem('enrefresh','refreshplz');
          this.getLoggedIn();
          this.getWorkHoursDaily();
        }   
      })
      .catch(error =>
      {
        this.setState({initialLoader:false});
        this.getStatusLogs();
        this.checkShiftEnded();
        this.getMyNotifications();
        const _this = this;
        clearInterval(this.interval);  
        if(id == 2)
        {
          this.getWorkHoursDaily();
        }      
      });
      
  };

  shiftActionsAsk = () =>
  {
    this.setState({showShitEndConfirmation:true})
  }

  closeDialogue = () =>
  {
    this.setState({showShitEndConfirmation:false})
  }

  shiftActionsEnd = async () =>
  {   
    this.setState({initialLoader:true, showShitEndConfirmation:false});
    let opts ={
      user_id: this.state.myId,
      punch_type: 2,
      username: this.state.username
    };
    fetch(urls.startShift,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response =>response.json())
    .then(response =>
      {     
        this.setState({
          logMsg: response[0]['msg'],
          errorMsg:(response[0]['success'] === 2 || response[0]['success'] === 7) ? true : false,   
          errorHealth: response[0]['success'] === 5? true : false,
          errorQr: response[0]['success'] === 8? true : false,
          errorTicket: response[0]['success'] === 10? true : false,
          hrLeaveApprovalPending: response['leave_pending_approved'] === 1 ? true:false
        });
        this.checkShiftEnded();
        this.getStatusLogs();
        this.getMyNotifications();
        const _this = this;
        clearInterval(this.interval);    
        localStorage.setItem('enrefresh','refreshplz');
        this.getLoggedIn();
        this.getWorkHoursDaily(); 
      })
      .catch(error =>
      {
        this.setState({initialLoader:false});
        this.getStatusLogs();
        this.checkShiftEnded();
        this.getMyNotifications();
        const _this = this;
        clearInterval(this.interval);  
        this.getWorkHoursDaily();    
      });
      
  };
  

  breakActions =(id) =>
  {    
    var pagetite = "KIMPHUB - My Timing";
    var notification = "Break Time Running";

    var favicon = this.state.favIcon;
    var breakicon = this.state.breakIcon;
    this.setState({
      initialLoader:true,      
      startTimem:0,
      quickActionBlock:0});
    let opts ={
      user_id: this.state.myId,
      punch_type: id,
      break:this.state.breakStatus,
      lunch:this.state.lunchStatus,
      extbreak: this.state.extStatus,
      username: this.state.username
    };
    if(id == 4)
    {
      document.title = pagetite;
      clearInterval(this.binterval);
      document.getElementById("favicon").setAttribute("href", 'https://Kimphub.com/service/storage/favicon.ico');                 
    }
    fetch(urls.startBreak,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response =>response.json())
    .then(response =>
      {                                                     
        const _this = this;
        setTimeout(function() { //Start the timer     
        _this.getStatusLogs();         
        }, 100);
        setTimeout(function() { //Start the timer        
        _this.getLoggedIn();      
        }, 1500);
        clearInterval(this.interval);
        if(id == 4)
        {         
          this.getLatetimeWeekly();
          //this.getLatetimeMonthly();
          this.getOverquotaWeekly();
          //this.getOverquotaMonthly();
        }else
        {
         // localStorage.setItem('enrefresh','refreshplz');
        }
        this.setState({   
          quickActionBlock:1, 
          logMsg: response[0]['msg'],
          errorMsg:(response[0]['success'] === 2 || response[0]['success'] === 7) ? true : false,
          initialLoader:false,
        }); 
      })
      .catch(error =>
      {
        this.setState({initialLoader:false});
        const _this = this;
        setTimeout(function() { //Start the timer     
        _this.getStatusLogs();         
        }, 100);
        setTimeout(function() { //Start the timer        
        _this.getLoggedIn();      
        }, 1500);
        clearInterval(this.interval);    
        if(id == 4)
        {         
          this.getLatetimeWeekly();
          //this.getLatetimeMonthly();
          this.getOverquotaWeekly();
          //this.getOverquotaMonthly();
        }       
      });
      
  }
  

  lunchActions =(id) =>
  {
   
    
    var pagetite = "KimpHub - My Timing";
    var notification = "Break Time Running";
    this.setState({initialLoader:true,startTimem:0});
    let opts ={
      user_id: this.state.myId,
      punch_type: id,
      break:this.state.breakStatus,
      lunch:this.state.lunchStatus,
      extbreak: this.state.extStatus,
      username: this.state.username
    };  

    if(id == 6)
    {
      document.title = pagetite;
      clearInterval(this.binterval);
      document.getElementById("favicon").setAttribute("href", 'https://Kimphub.com/service/storage/favicon.ico'); 
      clearInterval(this.breakinterval);
    }
    
    // else
    // {
    //   this.breakinterval = setInterval(() => this.breakVoiceCheck(),10000);
    // }

    fetch(urls.startLunch,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response =>response.json())
    .then(response =>
      {                  
        this.setState({
          initialLoader:false,             
          logMsg: response[0]['msg'],
          errorMsg:(response[0]['success'] === 2 || response[0]['success'] === 7) ? true : false,
        });
        window.scrollTo(0, 0)
        const _this = this;
        setTimeout(function() { //Start the timer     
        _this.getStatusLogs();         
        }, 100);
        setTimeout(function() { //Start the timer        
        _this.getLoggedIn();      
        }, 1500);
        clearInterval(this.interval);  
        if(id == 6)
        {         
          this.getLatetimeWeekly();
          //this.getLatetimeMonthly();
          this.getOverquotaWeekly();
          //this.getOverquotaMonthly();
        }else
        {
          localStorage.setItem('enrefresh','refreshplz');
        }       
      })
      .catch(error =>
      {
        this.setState({initialLoader:false});
        const _this = this;
        setTimeout(function() { //Start the timer     
        _this.getStatusLogs();         
        }, 100);
        setTimeout(function() { //Start the timer        
        _this.getLoggedIn();      
        }, 1500);
        clearInterval(this.interval);   
        if(id == 6)
        {         
          this.getLatetimeWeekly();
          //this.getLatetimeMonthly();
          this.getOverquotaWeekly();
          //this.getOverquotaMonthly();
        }     
      });      
  };

  extBreakActionsPopup = (id)=>
  {
    this.setState({showExtentedBreakCommentBox:true});
    ReactDOM.findDOMNode(this).scrollIntoView();
  }
 
  extBreakActionsPopupClose = (id)=>
  {
    this.setState({
      showExtentedBreakCommentBox:false,
      commentExtendedError:false,
      extendedBreakComment:''
    });
  }

  startExtendedBreakwithComment = ()=>
  {
      if(this.state.extendedBreakComment.trim() == '')
      {
          this.setState({commentExtendedError:true});
      }else
      {
        this.extBreakActions('7');
        this.setState({showExtentedBreakCommentBox:false});
      }
  }
  extBreakActions =(id) =>
  {    
    var pagetite = "KimpHub - My Timing";
    var notification = "Break Time Running";
    this.setState({
      initialLoader:true,      
      startTimemx:0});
      let opts ={
        user_id: this.state.myId,
        punch_type: id,
        break:this.state.breakStatus,
        lunch:this.state.lunchStatus,
        extbreak: this.state.extStatus,
        break_reason: this.state.extendedBreakComment
      };
    if(id == 8)
    {
      document.title = pagetite;
      clearInterval(this.binterval);
      document.getElementById("favicon").setAttribute("href", 'https://Kimphub.com/service/storage/favicon.ico'); 
    }
    fetch(urls.startExtBreak,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response =>response.json())
    .then(response =>
      {        
        this.setState({
          initialLoader:false,             
          logMsg: response[0]['msg'],
          extendedBreakComment:'',
          errorMsg:(response[0]['success'] === 2 || response[0]['success'] === 7) ? true : false,   
        });

        if(id==7)
        {
          localStorage.setItem('enrefresh','refreshplz');
        }
        const _this = this;
        setTimeout(function() { //Start the timer     
        _this.getStatusLogs();         
        }, 100);
        setTimeout(function() { //Start the timer        
        _this.getLoggedIn();      
        }, 1500);
        clearInterval(this.interval);  

        
      })
      .catch(error =>
      {
        this.setState({initialLoader:false,extendedBreakComment:''});
        this.getStatusLogs();  
        const _this = this;             
      });      
  }

  requestActionApproval = () =>
  {
    this.setState({
      showRequestPopup:true,
    });
  };

  hideRequestPopup = () =>
  {
    this.setState({
      showRequestPopup:false,
    });

  };

  updateComment = (e)=>
  {
    this.setState({requestComment:e.target.value, isrequired_field:false});
  }

  sendApprovalBreakRequest = (id) =>
  {
    if(this.state.requestComment!='')
    {
    let opts ={
      user_id:this.state.myId,
      puch_type:id,
      comment:this.state.requestComment,
      username:this.state.username
    };

    fetch(urls.sendBreakApproval,
      {
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Content-Type':'application/json'
        },
        method:'post',
        body:JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {
        this.setState({showRequestPopup:false});
        this.waitingApprovalRequest();
      })
      .catch(error =>
      {
        this.setState({showRequestPopup:false});
        this.waitingApprovalRequest();
      })
    }else
      {
        this.setState({isrequired_field:true});
        return false;
      }
  };

  breakEnabler = () =>
  {
    fetch(urls.breakenabler+this.state.myId,{
      headers:
      {
        'Access-Control-Allow-origin':'*',
        'Content-Type':'application/json'
      }
    })
    .then(response => response.json())
    .then(response =>
      {           
        this.setState({breakEnabled:response[0].breakenabler == 1? true : false});        
      })
    .catch(error =>
      {
        this.setState({ProgressBarBreak:false})
      })
  }

  waitingApprovalRequest = () =>
  {
    fetch(urls.waitingBreakApproval+this.state.myId,{
      headers:
      {
        'Access-Control-Allow-origin':'*',
        'Content-Type':'application/json'
      }
    })
    .then(response => response.json())
    .then(response =>
      {        
        this.setState({waitingStatus:response[0].waiting_status});
        if(response[0].waiting_status == '1')
        {
          const _this = this;
          setTimeout(function() { //Start the timer     
            _this.intevalModifier();      
          }, 1000);
        }
      })
    .catch(error =>
      {
        this.setState({ProgressBarBreak:false})
      })
  }

  waitingShiftUnblockApprovalRequest = () =>
  {
    fetch(urls.waitingShiftUnblockApproval+this.state.myId,{
      headers:
      {
        'Access-Control-Allow-origin':'*',
        'Content-Type':'application/json'
      }
    })
    .then(response => response.json())
    .then(response =>
      {        
        this.setState({ShiftUnblockWaitingStatus:response[0].waiting_status});
        if(response[0].waiting_status == '1')
        {
          const _this = this;
          setTimeout(function() { //Start the timer     
            _this.intevalModifier();      
          }, 1000);
        }
        else if(this.state.breakStatus == 0 && this.state.lunchStatus == 0 && this.state.extStatus == 0)
        {
          const _this = this;
          this.getStatusLogs();
          setTimeout(function() { //Start the timer     
            clearInterval(_this.interval);     
          }, 3000); 
        }               
      })
    .catch(error =>
      {
        this.setState({ProgressBarBreak:false});
        this.getStatusLogs();
        const _this = this;       
      })
  }

  shiftRequestActions =() =>
  {
    this.setState({showShiftRequestPopup:true});
  };
  hideShiftRequestPopup =() =>
  {
    this.setState({showShiftRequestPopup:false});
  };

  sendApprovalShiftRequest = (id) =>
  {
    if(this.state.requestComment!='')
    {
      let opts ={
        user_id:this.state.myId,
        puch_type:id,
        comment:this.state.requestComment,
        username: this.state.username
      };

      fetch(urls.sendShiftApproval,
        {
          headers:{
            'Access-Control-Allow-Origin':'*',
            'Content-Type':'application/json'
          },
          method:'post',
          body:JSON.stringify(opts)      
        })
        .then(response => response.json())
        .then(response =>
        {
          this.setState({showShiftRequestPopup:false});        
          this.waitingShiftUnblockApprovalRequest();
        })
        .catch(error =>
        {
          this.setState({showShiftRequestPopup:false});        
          this.waitingShiftUnblockApprovalRequest();
        })
      }else
      {
        this.setState({isrequired_field:true});
        return false;
      }
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  handleTextChange = ({ target }) =>
  {
    this.setState({[target.name] : target.value, commentExtendedError:false});
  }

   render() {
    if (this.state.isLoggedOut === true) {
      clearInterval(this.interval);
      return <Redirect to='/' />
    }

    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }

    return (

      <div className="fullwidth_mobilemenu mobileflex">
      
      <Container fluid className={this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive'}>
        <Row>
        <div className="theme_header">
        <Header {...this.props}  
            ncount={this.state.openNCount}
            classname="login-portal-page" 
            logout={this.signOut.bind(this)} 
            onmenuaction={this.toggleSidebar.bind(this)}
            quickactionblock={this.state.quickActionBlock}
            />
        <TokenGenerator />
        </div>
        <div className="SideNavbar">
          <SideMenu  
            activeitem="home" 
            showmenu={this.state.showMenu} 
            hidemenu={this.hideMenu.bind(this)}  /> 
       </div>
        <div className="themeContentBody login_portel_page">
        {this.state.errorMsg === true &&
        ( <Row>
            <Col>
              <Alertlogmsg 
                msgtext={this.state.logMsg} 
                boxclass="task-running shift-error" />
            </Col>
        </Row>)} 

        {/* {this.state.oldShift === true &&
          ( <Row>
              <Col>
                <Alertlogmsg 
                  msgtext={this.state.oldShiftMsg} 
                  boxclass="task-running shift-error" />
              </Col>
          </Row>)}  */}

          {this.state.errorHealth &&
          ( <Row>
              <Col>
                <Alertlogmsg 
                  msgtext={this.state.healthErrorMsg} 
                  boxclass="task-running warning-color" />
              </Col>
          </Row>)} 

          {this.state.errorQr &&
          ( <Row>
              <Col>
                <Alertlogmsg 
                  msgtext={this.state.qrErrorMsg} 
                  boxclass="task-running warning-color" />
              </Col>
          </Row>)} 
          {this.state.errorTicket &&
          ( <Row>
              <Col>
                <Alertlogmsg 
                  msgtext={this.state.ticketErrorMsg} 
                  boxclass="task-running warning-color" />
              </Col>
          </Row>)}

          {this.state.hrLeaveApprovalPending === true &&
          ( <Row>
              <Col>
                <Alertlogmsg 
                  msgtext="Looks like there are leaves waiting for your approval. Kindly approve the same before ending your shift." 
                  boxclass="task-running" />
              </Col>
          </Row>)} 

          {(this.state.myNotifications.length>0 && this.state.showingNotification ) &&
          (
            <Row>
              {this.state.myNotifications.map((item, index) =>
              {
                return(
                  <AlertMsg 
                    key={index} 
                    action={this.updateNotificationStatus} 
                    msg={item.notification_message_individuals} 
                    type={item.activity_type} severity={item.notification_severity} />
                )
              })}
            </Row>
          )}

        {(this.state.sessionNotExist ) && 
          (
            <Row>
              <Alertlogmsg 
                    msgtext={this.state.sessionMsg} 
                    boxclass="task-running" />
            </Row>
          )}
         <Row>
          <Col sm={12} lg={12}>
            <div className="main_banner">
              <LoadingWrapper isLoading={this.state.dailyQuoteLoader}>
                {(this.state.dailyQuote && this.state.dailyQuote.length>0) &&
                  ( 
                    <div className="quote-box-inner">
                      <h3>Quote of the day ✨</h3>
                      <p>{this.state.dailyQuote[0].quote_text}</p>
                      {this.state.dailyQuote[0].quote_author && this.state.dailyQuote[0].quote_author!='' &&
                      (
                        <span className="quote_onwer"> - {this.state.dailyQuote[0].quote_author}</span> 
                      )}
                    </div>
                    )}
                </LoadingWrapper>
                <LoadingWrapper isLoading={this.state.initialLoader}>
                {this.state.isMobileView == 0  ?(
                  <>
                    {this.state.breakStatus == 0 && this.state.lunchStatus == 0 && this.state.extStatus == 0 && this.state.isLocked == 0?
                    (
                      <>
                          {this.state.shiftRestartRequest?
                          (
                            <>
                              {this.state.ShiftUnblockWaitingStatus === '1'?
                                (<span className="unlock-shift-text">Waiting Restart Approval</span>)
                                :
                              (<Button 
                                className={
                                  this.state.punchStatus == 0? "start-shift btn-green login-page-btn restart_btn" : "start-shift btn-gray-outline login-page-btn disabled" } 
                                  onClick={this.shiftRequestActions.bind(this)}>Restart</Button>)
                              
                              }
                            </>
                          )
                          :
                          (
                            <>
                            {this.state.punchStatus == 0?
                            (
                              <Button className="white_bg_btn" onClick={this.shiftActions.bind(this, '1')}>Start Shift</Button>
                            )
                            :
                            (
                              // <Button className="white_bg_btn end_shift" onClick={this.shiftActions.bind(this, '2')}>End Shift</Button>
                              <Button className="white_bg_btn end_shift" onClick={this.shiftActionsAsk.bind(this)}>End Shift</Button>
                            )}
                            </>
                          )}
                      </>
                      
                    ):
                    (
                      <Button className="white_bg_btn inactive_shift_buttons">End Shift</Button>
                    )}
                  </>
                ):
                (
                  <span className='mobile-block-shift-msg'>You can not start or end shift via mobile.</span>
                )}
               <BreakRunningVoice speech={this.state.speechOn} crosslimit={this.state.crossLimit} beakMessage={this.beakMessage}/>
                {/* {localStorage.getItem("superadmin") !== null &&
                (<>
                  <div className="override-policy d-flex">
                  <div className="d-flex">
                  <Input id="override-policy" type="checkbox" checked={this.state.allowLeaveException === 1? 'checked' :'' } name="allow-break" className="flex-grow-1" onClick={this.updateBreakExceptionStatus.bind(this)} />
                  <Label for="override-policy">Override Leave Policy</Label>
                  </div>
                  <Button className="login_update_btn" onClick={this.allowUserBreakException.bind(this)}>Update</Button>
                  </div>
                  </>
                )} */}
                </LoadingWrapper>
                <div className="total_log">
                  <p><span>In Time:</span>  {this.state.loggedinTime}</p> 
                  <p><span>Work Time:</span>  {this.state.workTime}</p>
                  <p><span>Out Time: </span>  {this.state.totalBreak}</p>
                </div>
            </div>
          </Col>
          <div className="total_log_mob">
                 <p><span>In Time:</span>  {this.state.loggedinTime}</p> 
                  <p><span>Work Time:</span>  {this.state.workTime}</p>
                  <p><span>Out Time: </span>  {this.state.totalBreak}</p>
          </div>
          <Col sm={12} lg={4}>
            <Card className="break_commen_box">
            <div className="card_header">
              <div className="reg_br_icon"></div>
              <span className="time_alert">{this.state.lunchTime}</span>
            </div>
            <div className="card_content">
              <h4 variant="h4">Regular Break</h4>
              <p className="small_discr">Use this break to track each one of your tea breaks and lunch breaks.</p>
            </div>
            <LoadingWrapper isLoading={this.state.initialLoader}>
            {this.state.punchStatus === 1 && this.state.breakStatus === 0 && this.state.extStatus === 0 && this.state.isLocked === 0 && this.state.breakEnabled?
            (
              <>
                {this.state.lunchStatus == 0?
                  (<Button className="start_b" onClick={this.lunchActions.bind(this, '5')}>Start Break</Button>)
                  :
                  (<Button className="start_b active_break_buttons" onClick={this.lunchActions.bind(this, '6')}>End Break</Button>)
                }                      
              </>
            )
            :
            (
              <>
                 {this.state.lunchStatus === 1 && this.state.isLocked === 1?
                    ( 
                      <>
                        {this.state.waitingStatus === '1'?
                        (
                          <span>Waiting for Approval</span>
                        )
                        :
                        (
                          <Button className="start_b inactive_break_buttons" onClick={this.requestActionApproval.bind(this)}>End Break Request</Button>
                        )}
                      </>
                    )
                    :
                    ( <Button className="start_b inactive_break_buttons" >Start Break</Button>)
                  }
              </>
            )
            }
            </LoadingWrapper>
          </Card>
          </Col>
        
          <Col sm={12} lg={4}>
          <Card className="break_commen_box">
            <div className="card_header">
               <div className="emer_br_icon"></div>
              <span className="time_alert">{this.state.extBreakTime}</span>
            </div>
            <div className="card_content">
              <h4 variant="h4">Emergency Break</h4>
              <p className="small_discr">Use this break only if you need to take a break due to an emergency.</p>
            </div>
            <LoadingWrapper isLoading={this.state.initialLoader}>
            {this.state.breakStatus === 0 && this.state.lunchStatus === 0 && this.state.punchStatus === 1 && this.state.isLocked === 0 && this.state.breakEnabled?
            ( 
              <div className="login-btns d-flex">
                {this.state.extStatus === 0 &&
                (
                  <Button className={`start_b ${this.state.extStatus === 1 && " inactive_break_buttons" } `} onClick={this.extBreakActionsPopup.bind(this, '7')}>Start Break</Button>
                )}

                {this.state.extStatus === 1 &&
                (
                  <Button className="start_b active_break_buttons" onClick={this.extBreakActions.bind(this, '8')}>End Break</Button>
                )}
              </div>
            )
            :
            (
              <div className="login-btns d-flex">
                <Button className="start_b inactive_break_buttons">Start Break</Button>
              </div>
            )
            } 
            </LoadingWrapper>
          </Card>
          </Col>
          <Col sm={12} lg={4}>
          <Card className="break_commen_box today_activity_box">
          
             <div className="card_content">
              <h4 variant="h4">Activity Log</h4>
              <Scrollbars style={{ height: '170px' }} className="xhide" >
              {this.state.logActivities.length>0?
                (
                  <ul>
                    {this.state.logActivities.map((item, ikey) =>
                      {
                        return(
                          <li key={ikey}>{item.comment} {item.time}</li>
                          )
                      })}
                  </ul>
                ):
                null
                }
              </Scrollbars> 
            </div>
            
             </Card>
          </Col>
         </Row>
         
        </div>
        </Row>
        {this.state.showShiftRequestPopup &&
        (
        <div className="popup-overlay">
          <div className="request-approval-shift">
          
                <p className="text-center sub-title">Shift Restart Request</p>
                <div className="request-form-comment">
                  <Input className={this.state.isrequired_field? 'is_required_field':''} required="required" placeholder="Enter the reason, why you want to restart shift " type="textarea" value={this.state.comment} onChange={this.updateComment.bind(this)}></Input>
                </div>
                <div className="submit-request">
                <Button className="btn btn-secondary cancel_btn_pop" onClick={this.hideShiftRequestPopup.bind(this)} >Cancel</Button>
                <Button className="btn btn-secondary send_btn_pop" onClick={this.sendApprovalShiftRequest.bind(this, '2')}>Send</Button>
                </div>
              
            </div>
          </div>
        )}
        {this.state.showExtentedBreakCommentBox &&
          (
            <>
              <div className="internal-comment container extendedbreakcomment">
                <div className="internal-comment-overlay" onClick={this.extBreakActionsPopupClose}></div>
                  <div className="internal-comment-box">
                  <label className="internal-comment-label">Please enter the details, why you want to take an emergency break. Take prior approval from your reporting officer before taking this break. This will be notified to the HR Department.</label>
                      <Input type="textarea" placeholder="Enter you comment" 
                      name="extendedBreakComment" onChange={this.handleTextChange} 
                      className={this.state.commentExtendedError && 'field-error'} />
                      <div class="col-sm-12">
                        <button type="button" class="project-assignee-submit ext_br_brtn btn-green btn btn-secondary" onClick={this.startExtendedBreakwithComment} >Submit and Start Break</button>
                      </div>
                  </div>
              </div>
            </>
        )}
        {this.state.lunchStatus == 1 &&
        (
          <div className="popup-overlay">
            <div className="tea-break-popup extended-break-popup-wrap">
              <LunchBreakPopup 
              lunchTime ={this.state.lunchTime}
              breakStatus={this.state.breakStatus}
              startTimem={this.state.breakTimemilli}
              punchStatus={this.state.punchStatus}
              lunchStatus={this.state.lunchStatus}
              extStatus={this.state.extStatus}
              isLocked={this.state.isLocked}
              username={this.state.username}
              breakEnabled={this.state.breakEnabled}
              waitingStatus={this.state.waitingStatus}
              totalBreakPercentage={this.state.totalBreakPercentage}
              lunchActions={this.lunchActions}
              myId={this.state.myId}
              logMsg = {this.state.logMsg}
              errorMsg = {this.state.errorMsg}
              intevalModifier={this.intevalModifier}
              initialLoader={this.state.initialLoader}
              mobileview={this.state.isMobileView}
              />
            </div>
          </div>
        )}
        {this.state.extStatus == 1 &&
        (
          <div className="popup-overlay">
            <div className="tea-break-popup extended-break-popup-wrap">
              <ExtendedBreakPopup 
              extTime ={this.state.extBreakTime}
              breakStatus={this.state.breakStatus}
              startTimem={this.state.extBreakTimemilli}
              punchStatus={this.state.punchStatus}
              lunchStatus={this.state.lunchStatus}
              extStatus={this.state.extStatus}
              isLocked={this.state.isLocked}
              username={this.state.username}
              breakEnabled={this.state.breakEnabled}
              waitingStatus={this.state.waitingStatus}
              totalBreakPercentage={this.state.totalBreakPercentage}
              extActions={this.extBreakActions}
              myId={this.state.myId}
              logMsg = {this.state.logMsg}
              errorMsg = {this.state.errorMsg}
              intevalModifier={this.intevalModifier}
              initialLoader={this.state.initialLoader}
              mobileview={this.state.isMobileView}
              />
            </div>
          </div>
        )}
        {this.state.showShitEndConfirmation ? (
            <ConfirmDialogue
              purpose="change"
              closeDialogue={this.closeDialogue}
              show={this.state.showShitEndConfirmation}
              note=""
              content="Are you sure you want to end shift?"
              handleendshift={this.shiftActionsEnd}
              resolveid=""
              resolveStatus=""
              adminid=""
              confirmBoxAction="endshift"
            />
          ) : null}
      </Container>
      </div>
    )
  }
}
