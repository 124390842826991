import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { urls } from "../../services/UrlList";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

const getIssueTrackerSteps = async() => {
   return await axios.get(urls.getIssueTrackerSteps);
}

const getIssueTimeline = async(issueID) => {
    return await axios.post(urls.getIssueTimeline, {'issue_id': issueID});
 }   


 export const useGetIssueTrackerSteps = ()=>{
    return useQuery(['issue-tracking-steps'], () => getIssueTrackerSteps(), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 3600000, 
    });
}

export const useGetIssueTimeline = (issueID)=>{
    return useQuery(['issue-timeline-', issueID], () => getIssueTimeline(issueID), {
        refetchOnMount: true,
        refetchOnWindowFocus: false, 
    });
}
