import React, { Component, Fragment } from 'react';
import './NewSideMenu.css'
import { Redirect } from 'react-router';
import { BrowserRouter as Router,Route,Link} from "react-router-dom";
import Lottie from 'lottie-web'
import Sidebar from "react-sidebar";
import LogoImg from '../../images/brand-logo.png';
import HomeSideMenu from './NewSideMenuItems/HomeSideMenu/HomeSideMenu'
import TaskSideMenu from './NewSideMenuItems/TaskSideMenu/TaskSideMenu'
import ReportsSideMenu from './NewSideMenuItems/ReportsSideMenu/ReportsSideMenu'
import FeedSideMenu from './NewSideMenuItems/FeedSideMenu/FeedSideMenu'
import TicketSideMenu from './NewSideMenuItems/TicketSideMenu/TicketSideMenu'
import ApproveSideMenu from './NewSideMenuItems/ApproveSideMenu/ApproveSideMenu'
import LiveUpdateSideMenu from './NewSideMenuItems/LiveUpdatesSideMenu/LiveUpdatesSideMenu'
import LeaveSideMenu from './NewSideMenuItems/LeaveSideMenu/LeaveSideMenu'
import LoanSideMenu from './NewSideMenuItems/LoanSideMenu/LoanSideMenu'
import SwitchUserSideMenu from './NewSideMenuItems/SwitchUserSideMenu/SwitchUserSideMenu'
import AdminUserSideMenu from './NewSideMenuItems/AdminUserSideMenu/AdminUserSideMenu'
import DaytoDay from "./NewSideMenuItems/DaytoDaySideMenu/DaytoDaySideMenu";
import Colleagues from "./NewSideMenuItems/ColleaguesSideMenu/ColleaguesSideMenu";
import ProjectSideMenu from "./NewSideMenuItems/Projects/ProjectSideMenu"
import MomSideMenu from "./NewSideMenuItems/MinutesofMeeting/MomSideMenu"
import { Scrollbars } from 'react-custom-scrollbars';
import MaterialIcon from "material-icons-react";

const styles = {
    contentHeaderMenuLink: {
      textDecoration: "none",
      color: "white",
      padding: 8
    },
    content: {
      padding: "16px"
    }
  };
  

const mql = window.matchMedia(`(min-width: 800px)`);

export default class NewSideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
           myPrivileges:[],

        };
  
    }
   

    componentDidMount =() =>
    {    
    this.checkUserPrivilege();    
    };

    checkUserPrivilege = () =>
    {
    if(localStorage.getItem("user") !== null)
    {      
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, myId:login.uid, showExtBreak:login.showext });
    }            
    };

    hideMenu = () =>
      {
            this.props.hidemenu();
      }


    menuAnimation = ( element ) => {
        let start = null;

        function step( timestamp ) {
            if ( !start ) start = timestamp;
            let progress = timestamp - start;
            element.style.height = `${progress}px`;
            if( progress < 2000 ) window.requestAnimationFrame(step);
        }

        window.requestAnimationFrame(step);
        
    }



    render() {


        return (
         <>
       <Scrollbars style={{ height: '100vh' }} className="xhide">
       <div  className={this.props.showmenu ? 'mobile-none' : 'mobile-active'} >
                
       <div  className="overlay_menu" onClick={this.toggleSidebar}></div> 
                {/* className={this.props.showmenu ? 'mobile-active': ''}  */}
            <nav id="new-side-menu" >
          
                <div className="side-menu-wrapper">
                    <div className="menu_items">
                        <HomeSideMenu activeitem={this.props.activeitem} logger={this.state.myId} />
                        <TaskSideMenu  activeitem={this.props.activeitem} logger={this.state.myId} />
                        <ProjectSideMenu  activeitem={this.props.activeitem} myprivileges={this.state.myPrivileges} logger={this.state.myId}  /><FeedSideMenu activeitem={this.props.activeitem} logger={this.state.myId} />
                        {/* <MomSideMenu  activeitem={this.props.activeitem} /> */}
                        {(this.state.myPrivileges.includes('i_am_com') || this.state.myPrivileges.includes('i_am_portfolio_viewer') || this.state.myPrivileges.includes('i_am_mgd_tl') || this.state.myPrivileges.includes('i_am_portfolio_admin'))  &&
                        (
                            <DaytoDay activeitem={this.props.activeitem} myprivileges={this.state.myPrivileges} logger={this.state.myId} />
                        )}
                        <ReportsSideMenu 
                            animation={this.menuAnimation.bind(this)} 
                            activeitem={this.props.activeitem} 
                            myprivileges={this.state.myPrivileges}
                            logger={this.state.myId}  />
                        {(this.state.myPrivileges.includes('all_tickets') || this.state.myPrivileges.includes('my_tickets')) &&
                        (
                            <TicketSideMenu activeitem={this.props.activeitem} logger={this.state.myId} />
                        )
                        }
                        {this.state.myPrivileges.includes('kimp_team') &&
                        (
                            <Colleagues activeitem={this.props.activeitem} myprivileges={this.state.myPrivileges} logger={this.state.myId} />
                        )}
                        
                        {(this.state.myPrivileges.includes('access_monthly_overview') && this.state.myPrivileges.includes('approve_user_requests') ) ?
                            <ApproveSideMenu activeitem={this.props.activeitem} myprivileges={this.state.myPrivileges} logger={this.state.myId} />
                        :
                           <Fragment>
                              {this.state.myPrivileges.includes('approve_user_requests')?
                                <ApproveSideMenu activeitem={this.props.activeitem} myprivileges={this.state.myPrivileges} logger={this.state.myId} />
                              :null
                              }             
                            </Fragment>
                        }
                        {/* {this.state.myPrivileges.includes('live_data') ?
                            <LiveUpdateSideMenu  
                            animation={this.menuAnimation.bind(this)} 
                            activeitem={this.props.activeitem} 
                            myprivileges={this.state.myPrivileges}  />
                        :
                            null
                        } */}
                        
                        <LeaveSideMenu activeitem={this.props.activeitem} logger={this.state.myId} />
                        <LoanSideMenu animation={this.menuAnimation.bind(this)} 
                            activeitem={this.props.activeitem} 
                            myprivileges={this.state.myPrivileges} 
                            logger={this.state.myId} /> 
                        {(this.state.myPrivileges.includes('am_admin') || localStorage.getItem("superadmin") !== null) ?
                            <AdminUserSideMenu animation={this.menuAnimation.bind(this)} 
                            activeitem={this.props.activeitem} 
                            myprivileges={this.state.myPrivileges} 
                            logger={this.state.myId} /> 
                        : null
                        }
                                               
                    </div>
                </div>
            </nav>
           </div>
            </Scrollbars>
            </>
        )
    }
}
