import React, { useState, useEffect } from 'react';
import { Container, Row, Input, Col, Card, CardHeader, CardBody, Label, Button} from "reactstrap";
import MaterialIcon from "material-icons-react";
import { ArrowDown, ArrowUp } from 'lucide-react';
import LoanDetails from './LoanDetails';
import CurrencySummary from './CurrencySummary'; 
import { use } from 'react';
import LoanVerificationDialog from './LoanVerificationDialog';
import {useUpdateRepaymentData} from './useLoanRepaymentHooks';
import { toast } from 'react-hot-toast';

export default function EmployeeLoanCard({ employee }){
    const [isExpanded, setIsExpanded] = useState(false);
    const [monthlyAmount, setMonthlyAmount] = useState(employee.defaultMonthlyAmount || 0);
    const [isDeductionEnabled, setIsDeductionEnabled] = useState(employee.deduction_active === 1);
    const [selectedCurrency, setSelectedCurrency] = useState('INR');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    //const toggleExpand = () => setIsExpanded(!isExpanded);
    
    const toggleDialog = () => {
      setIsDialogOpen(!isDialogOpen);
    };
  
    const handleVerifyDeduction = () => {
      setIsDialogOpen(true);
    };

    const handleConfirmVerification = () => {
      setIsDialogOpen(false);

      const data2={
        user_id: employee.id,
        monthly_deduction_verified: 1,
        deduction_amount: monthlyAmount
      }
      updateScheduleData(data2,
        {
          onSuccess: (data) => {
            toast('Verified successfully');
          },
        }
      );
    };

  
    const handleDeductionStauts = (e) => {
      setIsDeductionEnabled(e.target.checked)
      const data1 = {
        user_id: employee.id,
        deduction_active: e.target.checked ? 1 : 0,
      };
      updateScheduleData(data1,
        {
          onSuccess: (data) => {
            toast('Deduction status updated successfully');
          },
        }
      );
    };

    const { mutate: updateScheduleData, isLoading: updateLoading } =
    useUpdateRepaymentData();

  const handleMonthlyAmountChange = (e) => {
      const value = e.target.value;
      setMonthlyAmount(value);
  };
  
  const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
          const data1 = {
              user_id: employee.id,
              deduction_amount: monthlyAmount,
          };
          updateScheduleData(data1,
            {
              onSuccess: (data) => {
                toast('Updated successfully');
              },
            }
          );
      }
  };
    const isButtonVisible = () => {
      const today = new Date();
      const day = today.getDate();
      return day >= 1 && day <= 20;
    };

    return (
      <Card className="mb-3 shadow-sm border-0">
      <CardHeader className="bg-white">
      <Row className="align-items-center">
        <Col className='col col-md-2'>
          <div className="d-flex align-items-center gap-2">
            <h6 className="cursor-on h6 mb-0" onClick={() => setIsExpanded(!isExpanded)}>{employee.name}</h6>
          </div>
        </Col>
        <Col>
          <div className="d-flex align-items-center kimp-gap-2">
            <div className="text-muted medium mb-2">Outstanding: </div>
            <CurrencySummary outstandingBalances={employee.outstandingBalances} currency={employee.currency} />
          </div>
        </Col>
        <Col xs="auto" className='gap-2-mob'>
            <Row className="align-items-center g-3">
              <Col xs="auto">
                <div className="enable-deduction-wrap d-flex align-items-center kimp-gap-2">
                <Input
                      type="checkbox"
                      className="form-check-input"
                      checked={employee.deduction_active == 1 ? true : false}  
                      onChange={(e) => handleDeductionStauts(e)}
                      id={`deduction-${employee.id}`}
                  />
                  <Label 
                  check 
                  for={`deduction-${employee.id}`}
                  className="form-check-label mb-0"
                  >
                  Enable Monthly Deduction
                  </Label>
                </div>
              </Col>
              <Col xs="auto" className='gap-2-mob'>
                <Row className="align-items-center g-2">
                  <Col xs="auto">
                  <Input
                    type="text"
                    value={monthlyAmount}
                    onChange={(e) => handleMonthlyAmountChange(e)}
                    onKeyDown={handleKeyPress}
                    placeholder="Monthly amount"
                    className="form-control-sm"
                    style={{ width: '120px' }}
                  />
                  </Col>
                  <Col xs="auto">
                  <div className="text-muted medium currency-text-wrap">
                    {employee.currency}
                  </div>
                  </Col>
                </Row>
              </Col>
            </Row>
        </Col>
        <Col xs="auto">
          {isButtonVisible() && <Button color="primary" size="sm" 
          className="btn-sm btn-kimp-green gap-2-mob"
          onClick={handleVerifyDeduction}
          >
          {employee.monthly_deduction_verified === 1 ? 'Verified' : 'Verify'}
          </Button>}
          <LoanVerificationDialog 
          isOpen={isDialogOpen}
          toggle={toggleDialog}
          onConfirm={handleConfirmVerification}
          />
        </Col>
      </Row>
      </CardHeader>

      {isExpanded && (
      <CardBody>
        <h4 className="h6 text-muted mb-3 mt-3 text-center">Loan Taken History</h4>
        <LoanDetails loans={employee.loans} currency={employee.currency} />
      </CardBody>
      )}
    </Card>
    );
  };
  