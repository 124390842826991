import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { urls } from "../../services/UrlList";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import Hashids from 'hashids';
import { ta } from "date-fns/locale";

const hashids = new Hashids('',6,'abcdefghijklmopqrstuvwxyz0123456789','', );


// fetchings

const getPortfolioAssetsReview = async(month, comid) => {
    return await axios.post(urls.portfolioAssetsReview, {'month':month, 'comid': comid});
}

const getBoardApprovedFiles = async(boardID) => {
    return await axios.post(urls.boardApprovedFiles, {'boardID': boardID});
}

const getTaskClientRating = async(taskID) => {
    return await axios.post(urls.taskClientRating, {'taskID': taskID});
}

const updateBestDesigns = async(data) =>
{
    return await axios.post(urls.updateBestTaskDesigns, data);
}

const getBestDesignsLog = async(taskID) =>
{
    return await axios.post(urls.getTaskbestdesignlog, {'taskID': taskID});
}

const portfolioAssetsAction = async(data) =>
{
    return await axios.post(urls.updatePortfolioStatus, data);
}

const getPrivateNotes = async(taskID) =>
{
    return await axios.post(urls.getTaskPrivateNotes, {'taskID': taskID});
}

const addPrivateNote = async(data) =>
{
    return await axios.post(urls.addTaskNote, data);
}

const deletePrivateNote = async(data) =>
{
    return await axios.post(urls.deletePrivateNote, data);
}

const updatePrivateNote = async(data) =>
{
    return await axios.post(urls.updatePrivateNote, data);
}

const getVideoPortfolioAssetsReview = async(month) => {
    return await axios.post(urls.videoPortfolioAssetsReview, {'month':month});
}

const videoPortfolioAssetsAction = async(data) =>
{
    return await axios.post(urls.updateVideoPortfolioStatus, data);
}


export const useVideoPortfolioAssetsAction = (data) => {
    const queryClient = useQueryClient();
    return useMutation(videoPortfolioAssetsAction, {
        onSuccess: (response) => {
            console.log(response);
            if(response.data.success == '1')
            {
                toast('Assets marked as approved successfully');
            }else if(response.data.success == '2')
            {
                toast('Assets marked as unapproved successfully');
            }else if(response.data.success == '3')
            {
                toast('Assets marked as rejected successfully');
            }else if(response.data.success == '8')
            {
                toast('Something went wrong, pleas try again');
            }
            queryClient.invalidateQueries(['video-portfolio-assets-review-',response.data.month]);
        }
    });
}


export const useGetVideoPortfolioAssetsReview = (month) => {
    return useQuery(
      ['video-portfolio-assets-review-',month],
      () => getVideoPortfolioAssetsReview(month),
      {
        refetchOnMount: true,
        refetchOnWindowFocus: true,
      }
    );
}

    
export const useGetBoardApprovedFiles = (boardID)=>{
    return useQuery(['board-approved-files-', boardID], () =>  getBoardApprovedFiles(boardID),
    {
        refetchOnMount:true,
        refetchOnWindowFocus:false,
    })
}


export const useGetTaskClientRating = (taskID)=>{
    return useQuery(['task-rating-', taskID],  ()=>getTaskClientRating(taskID),
    {
        refetchOnMount:true,
        refetchOnWindowFocus:false,
    })
}



export const useUpdateBestDesigns = (data) => {
    const queryClient = useQueryClient();
    return useMutation(updateBestDesigns, {
        onSuccess: (response) => {
            if(response.data.success == '1')
            {
                toast('Added to best designs successfully.');
            }else if(response.data.success == '2')
            {
                toast('Removed from best designs successfully.');
            }else if(response.data.success == '3')
            {
                toast('sorry, you don\'t have privilege to perfom this action.');
            }
            queryClient.invalidateQueries(['task-rating-', response.data.taskID]);
        }
    });
}


export const useGetBestDesignsLog = (taskID)=>{
    return useQuery(['best-designs-log-',taskID], ()=>getBestDesignsLog(taskID),
    {
        refetchOnMount:true,
        refetchOnWindowFocus:false,
    })
}


export const useGetPortfolioAssetsReview = (month, comid) => {
    return useQuery(
      // Add userID and comid to the query key so the query re-runs when any of them change
      ['portfolio-assets-review-',month, comid],
      () => getPortfolioAssetsReview(month, comid),
      {
        // Enable refetching on mount and window focus
        refetchOnMount: true,
        refetchOnWindowFocus: true,
      }
    );
}

export const usePortfolioAssetsAction = (data) => {
    const queryClient = useQueryClient();
    return useMutation(portfolioAssetsAction, {
        onSuccess: (response) => {
            console.log(response);
            if(response.data.success == '1')
            {
                toast('Assets marked as approved successfully');
            }else if(response.data.success == '2')
            {
                toast('Assets marked as unapproved successfully');
            }else if(response.data.success == '3')
            {
                toast('Assets marked as rejected successfully');
            }else if(response.data.success == '8')
            {
                toast('Something went wrong, pleas try again');
            }
            queryClient.invalidateQueries(['portfolio-assets-review-',response.data.month, response.data.comid]);
        }
    });
}

export const useGetPrivateNotes = (taskID)=>{
    return useQuery(['task-private-note-',taskID], ()=>getPrivateNotes(taskID),
    {
        refetchOnMount:true,
        refetchOnWindowFocus:false,
    })
}

export const useAddPrivateNote = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(addPrivateNote, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['task-private-note-',response.data.taskID]);
            if(response.data.success == '1')
            {
                toast('Note added successfully.');
            }else if(response.data.success == '2')
            {
                toast('Something went wrong, please try again.');
            }
        }
    });
}

export const useDeletePrivateNote = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(deletePrivateNote, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['task-private-note-',response.data.taskID]);
            if(response.data.success == '1')
            {
                toast('Note deleted successfully.');
            }else if(response.data.success == '2')
            {
                toast('Something went wrong, please try again.');
            }
        }
    });
}

export const useUpdatePrivateNote = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(updatePrivateNote, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['task-private-note-',response.data.taskID]);
            if(response.data.success == '1')
            {
                toast('Note updated successfully.');
            }else if(response.data.success == '2')
            {
                toast('Something went wrong, please try again.');
            }
        }
    });
}