// Currency formatter utility
export const formatCurrency = (amount, currency) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(amount);
};

export const stripslashes = (str) => {
  if (str && str.length > 0) {
    str = str.replace(/\\'/g, "'");
    str = str.replace(/\\"/g, '"');
    str = str.replace(/\\0/g, "\0");
    str = str.replace(/\\\\/g, "\\");
  }
  return str;
};

export const linkifyOptions = {
  attributes: null,
  className: "linkified",
  defaultProtocol: "http",
  events: null,
  format: function (value, type) {
    return value;
  },
  formatHref: function (href, type) {
    return href;
  },
  ignoreTags: [],
  nl2br: false,
  tagName: "a",
  target: {
    url: "_blank",
  },
  validate: true,
};

export const PageAccessCheck = (access) =>
  {
      if (localStorage.getItem("user") !== null) {
          var login = JSON.parse(localStorage.getItem("user"));
          if (login.privileges.includes(access)) {
            return true;
          }else
          {
              //return false;
              window.location.href = "/maintenance";
          }
      }else
      {
          window.location.href = "/maintenance";
      }
  }

  export const getAttachmentUrl = (attachitem, type, urls) =>
  {
    let baseUrl = '';
    if (attachitem.path.includes('amazonaws.com')) {
      let modifiedPath = attachitem.path;
      if (attachitem.path.includes('accelerate.amazonaws.com')) {
        modifiedPath = modifiedPath.replace(urls.s3UrlWsl, urls.cdnURLWsl);
      } else if (attachitem.path.includes('-1.amazonaws.com')) {
        modifiedPath = modifiedPath.replace(urls.s3OldUrlWsl, urls.cdnURLWsl);
      }
      if (attachitem.path.includes(attachitem.file_name.split('.').pop())) {
        baseUrl = modifiedPath;
      } else {
        baseUrl = modifiedPath + "/" + attachitem.file_name;
      }
    } else {
      baseUrl = urls.cdnURL + 'projects/' + attachitem.path + "/" + attachitem.file_name;
    }
    if (type.toLowerCase() === 'src' && attachitem.path.includes('amazonaws.com')) {
      return baseUrl + "?class=small";
    }
    return baseUrl;
  }