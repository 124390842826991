import React from 'react';
import { LockIcon } from 'lucide-react';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '256px',
    padding: '16px'
  },
  alert: {
    maxWidth: 'auto',
    padding: '16px',
    backgroundColor: '#f8d7da',
    border: '1px solid #f5c2c7',
    borderRadius: '4px',
    color: '#842029'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '8px'
  },
  title: {
    fontSize: '18px',
    fontWeight: '600',
    margin: 0
  },
  message: {
    margin: 0,
    fontSize: '14px'
  }
};

const AccessDenied = () => {
  return (
    <div style={styles.container}>
      <div style={styles.alert}>
        <div style={styles.header}>
          <LockIcon size={20} />
          <h2 style={styles.title}>Access Denied</h2>
        </div>
        <p style={styles.message}>
          You don't have permission to access this content. Please contact your administrator if you believe this is a mistake.
        </p>
      </div>
    </div>
  );
};

export default AccessDenied;