import React, { useEffect, useRef } from 'react';
import ScriptTag from 'react-script-tag';
import './EwebinarPage.css';

const EWebinarPage = () => {
   return (
          <div className=''>
           <ScriptTag isHydrating={true} type="text/javascript" 
            src="https://kimp.co/ewebinnar.js" />

<div id="w1742299463060" class="ewebinar__Widget ewebinar__RegForm_Root" >
  <form class="ewebinar__RegForm ewebinar--ltr">
    <div class="ewebinar__RegForm__Content">
      <div>
        <div id="session" class="ewebinar__RegForm__Field ewebinar__RegForm__Field--Sessions">
          <div class="ewebinar__Session__Dropdown" >
            <select name="session" id="session" class="ewebinar__Session__Dropdown__Select">
              <option class="ewebinar__FixedBar__Content__Session__Dropdown__Select" value="" disabled="disabled" selected="selected" hidden="">Getting sessions...</option>
            </select>
          </div>
          <div class="ewebinar__RegForm__Field__Error"></div>
        </div>
        <div class="ewebinar__RegForm__Field">
          <div class="ewebinar__RegForm__Field__Input">
            <input id="name" name="name" autocomplete="name" placeholder="Name" />
          </div>
          <div class="ewebinar__RegForm__Field__Error"></div>
        </div>
        <div class="ewebinar__RegForm__Field">
          <div class="ewebinar__RegForm__Field__Input">
            <input id="email" name="email" autocomplete="email" placeholder="Email" />
          </div>
          <div class="ewebinar__RegForm__Field__Error"></div>
        </div>
      </div>
      <div class="ewebinar__RegForm__Captcha"></div>
      <div class="ewebinar__RegForm__Error"></div>
    </div>
    <div class="ewebinar__RegForm__Footer">
      <a class="ewebinar__RegisterButton__Wrap ewebinar--ltr" href="javascript:;" >
        <button class="ewebinar__Widget ewebinar__RegisterButton" type="submit" >
          <div class="ewebinar__Dots">
            <span class="ewebinar__LoadingDot" ></span>
            <span class="ewebinar__LoadingDot" ></span>
            <span class="ewebinar__LoadingDot" ></span>
          </div>
          <span class="ewebinar__ButtonText">Sign up now!</span>
        </button>
      </a>
        <div >
          <a  href="https://buy.ewebinar.com/webinar/buyca-down-payment-boost-18424">Sign up now!</a>
        </div>
      
    </div>
  </form>
</div>
          </div>
        );
};

export default EWebinarPage;