// export const AccessCheck = (access) =>
// {
//     if (localStorage.getItem("user") !== null) {
//         var login = JSON.parse(localStorage.getItem("user"));
//         if (login.privileges.includes(access)) {
//           return true;
//         }else
//         {
//             window.location.href = "/maintenance";
//         }
//     }else
//     {
//         window.location.href = "/maintenance";
//     }
// }

export const AccessCheck = (access) => {
    if (localStorage.getItem("user") !== null) {
        var login = JSON.parse(localStorage.getItem("user"));
        const accessArray = Array.isArray(access) ? access : access.split(",").map(a => a.trim());
        if (accessArray.some(acc => login.privileges.includes(acc))) {
            return true;
        } else {
            window.location.href = "/maintenance";
        }
    } else {
        window.location.href = "/maintenance";
    }
};

export const AccessCheckBlock = (access) =>
{
    if (localStorage.getItem("user") !== null) {
        var login = JSON.parse(localStorage.getItem("user"));
        if (login.privileges.includes(access)) {
          return true;
        }else
        {
            return false;
        }
    }else
    {
        return false;
    }
}