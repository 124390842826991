import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Badge,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  CardTitle
} from 'reactstrap';
import MaterialIcon from 'material-icons-react';
import { useGetSupportTicketAssets, useGetSupportTicket, 
  useSupportAssigneeUpdate, useSupportPriorityUpdate, useSupportTopicUpdate,
  useSupportDueUpdate, useSupportStatusUpdate, useSupportParticipantUpdate, useAddSupportTicketComment,
  useListSupportTicketComments, useSelfResolvingTicket } from './useHrTicketsHooks';
import ImageUpload from './ImageUpload';
import{formatDate} from './HelperFunctions';
import SupportLightboxPop from './SupportLightboxPop';
import Linkify from "linkifyjs/react";
import ReactHtmlParser from "react-html-parser";
import { stripslashes, linkifyOptions } from '../../Utils/HelperFunctions';
import ActionDialog from './ActionDialog';
import { AccessCheckBlock } from '../../bcmodule/AccessCheck';
import AccessDenied from '../../Utils/AccessDenied';
import { useHistory } from "react-router";

export default function TicketDetail(props){
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [comment, setComment] = useState('');
  const [supportTicket, setSupportTicket] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [assignee, setAssignee] = useState([]);
  const { data: supportAssets, isLoading: assetsLoading, error } = useGetSupportTicketAssets();
  const { data: ticket, isLoading } = useGetSupportTicket({ticket_id: id});
  const [uploadProgress, setUploadProgress] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [originalDueDate, setOriginalDueDate] = useState(0);
  const [noAccess, setNoAccess] = useState(false);
  const history = useHistory();

    const toggleDialog = () => {
      setIsDialogOpen(!isDialogOpen);
    };
  
    const handleConfirm = () => {
      setIsDialogOpen(true);
    };

  useEffect(() => {
    if (ticket) {
      setSupportTicket(ticket.data.ticket[0]);
      let assignedPerson = getAssigneeForTopic(ticket.data.ticket[0].topic_id, supportAssets.data);
      setAssignee(assignedPerson);
    }

    if(AccessCheckBlock('all_tickets') || 
    (ticket?.data?.ticket && (ticket.data.ticket[0].assignee_id === props.myId || ticket.data.ticket[0].created_by === props.myId 
    || ticket.data.ticket[0].participant_id === props.myId)) ){
        setNoAccess(false);   
     }else
     {
        setNoAccess(true);
     }
  }, [ticket]);

  const { data: ticketComments, isLoading:commentsLoading } = useListSupportTicketComments(id);

  useEffect(() => {
    if (ticketComments) {
    }
  }, [ticketComments?.data]);

  const handleImageUpload = (files, data) => {
    setUploadProgress(true);
    const dataArray = Array.isArray(data) ? data : [data];
    setUploadedImages(prev => [...prev, ...dataArray]);
    setUploadProgress(false);
  };

  const deleteTicketFileNames = async (filename, arrayname) => {
    setUploadProgress(true);
    var assetupdate = [...uploadedImages];
    var itemkey = assetupdate.map(function (item) { return item.file_name; }).indexOf(filename.file_name); 
    assetupdate.splice(itemkey, 1);
    setUploadedImages(assetupdate);
    setTimeout(() => {
      setUploadProgress(false);
    }, 200);
  };

  const getAssigneeForTopic = (topicId, data) => {
    if (!topicId || !data) return [];
    const topic = data.topics.find(t => t.id == topicId);
    if (!topic) return [];
    const assignPerson = data.assignees.find(a => a.id === topic.assignee_id);
    if (!assignPerson) return [];
    return [assignPerson];

};

  
  const handleAssigneeChange = (assignee) => {
        let data={
          ticket_id: id,
          assignee_id: assignee.id,
          current_assignee: supportTicket.assignee_id
        }
        updateAssigneeSupport(data);
  };
    
const { mutate: updateAssigneeSupport, isLoading: assigneeLoading } =
useSupportAssigneeUpdate();



const handleParticipantChange = (participant) => {
  let data={
    ticket_id: id,
    participant_id: participant
  }
  updateParticipantSupport(data);
};

const { mutate: updateParticipantSupport, isLoading: participantLoading } =
useSupportParticipantUpdate();


const handleTopicChange = (e) => {
  e.preventDefault();
  let temp = { ...supportTicket };
  const newTopicId = e.target.value;
  temp.topic_id = newTopicId;
  setSupportTicket(temp);
  let data={
    ticket_id: id,
    topic_id: e.target.value,
    current_topic: supportTicket.topic_id
  }
  updateTopicSupport(data,
    {
      onSuccess: (data) => {
        let assignedPerson = getAssigneeForTopic(newTopicId, supportAssets.data);
        setAssignee(assignedPerson);
        handleAssigneeChange(assignedPerson[0]);
        handleParticipantChange(0);
      },
    }
  );
};

const { mutate: updateTopicSupport, isLoading: topicLoading } =
useSupportTopicUpdate();


const handlePriorityChange = (e) => {
  e.preventDefault();
  let temp = { ...supportTicket };
  temp.priority_id = e.target.value;
  setSupportTicket(temp);
  let data={
    ticket_id: id,
    priority_id: e.target.value
  }
  updatePrioritySupport(data);
};

const { mutate: updatePrioritySupport, isLoading: priorityLoading } =
useSupportPriorityUpdate();


useEffect(() => {
  if (supportTicket?.due_date && originalDueDate === 0){
    setOriginalDueDate(supportTicket.due_date);
  }
}, [supportTicket?.due_date]); 


const handleDueDateChange = (e) => {
  e.preventDefault();
  let temp = { ...supportTicket };
  temp.due_date = e.target.value;
  setSupportTicket(temp);
};


const handleDueDateBlur = () => {
    const data = {
      ticket_id: id,
      due_date: supportTicket.due_date,
      current_due_date: originalDueDate
    };
    updateDueSupport(data);
    setOriginalDueDate(0);
};

const { mutate: updateDueSupport, isLoading: dueLoading } =
useSupportDueUpdate();

const handleStatusChange = (e) => {
  e.preventDefault();
  let temp = { ...supportTicket };
  temp.status = e.target.value;
  setSupportTicket(temp);
  let data={
    ticket_id: id,
    status: e.target.value
  }
  updateStatusSupport(data);
};

const { mutate: updateStatusSupport, isLoading: statusLoading } =
useSupportStatusUpdate();
  
const addSupportComment =() => {
    let data={
      ticket_id: id,
      comment: comment,
      files: uploadedImages
    }
    addSupportTicketComment(data,
      {
        onSuccess: (data) => {
          setComment('');
          setUploadedImages([]);
        },
      }
    );
}

const { mutate: addSupportTicketComment, isLoading: commentAddLoading } = useAddSupportTicketComment();


const handleConfirmAction = () => {
  setIsDialogOpen(false);
  const datac={
    user_id: supportTicket.created_by,
    ticket_id: id
  }
  handleResolvingConfirmation(datac);
};

const { mutate: handleResolvingConfirmation, isLoading: selfResolvigLoading } =
useSelfResolvingTicket();


  if (isLoading) {
    return <div>Loading ticket details...</div>;
  }

  if (noAccess) {
    return <AccessDenied />;
  }
 
return (
  <Row>
        <Col sm={12} lg={3}>
          <div className="modern-ticket-container">
            <div className="ticket-management-sidebar">
            <div className="sidebar-section list-back-button">
              <CardTitle tag="h4">
                <Button onClick={() => history.push(`/tickets/support-tickets`)} 
                className="balck_btn"> <MaterialIcon icon="arrow_back"/></Button>Support Tickets
              </CardTitle>
            </div>
            <div className="sidebar-section">
                <h4 className="sidebar-title">Topic</h4>
                <FormGroup>
                  <Input
                    type="select"
                    value={supportTicket?.topic_id}
                    onChange={(e) => handleTopicChange(e)}
                  >
                    {supportAssets?.data.topics?.map(topic => (
                      <option key={topic.id} value={topic.id}>
                        {topic.topic_label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </div>
              <div className="sidebar-section">
                <h4 className="sidebar-title">Assignee</h4>
                <FormGroup>
                  <Input 
                    type="select" 
                    value={supportTicket?.assignee_id}
                  >
                    {assignee?.map(assign => (
                      <option key={assign.id} value={assign.id}>
                        {assign.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </div>
              <div className="sidebar-section">
                <h4 className="sidebar-title">Participant</h4>
                <FormGroup>
                  <Input 
                    type="select" 
                    value={supportTicket?.participant_id}
                    onChange={(e) => handleParticipantChange(e.target.value)}
                  >
                    <option value="">Select Participant</option>
                    {supportAssets?.data.assignees.map(assign => (
                      <option key={assign.id} value={assign.id}>
                        {assign.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </div>
              <div className="sidebar-section">
                <h4 className="sidebar-title">Priority</h4>
                <FormGroup>
                  <Input
                    type="select"
                    value={supportTicket?.priority_id}
                    onChange={(e) => handlePriorityChange(e)}
                  >
                    {supportAssets?.data.priority?.map(priority => (
                      <option key={priority.id} value={priority.id}>
                        {priority.priority_label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </div>

              <div className="sidebar-section">
                <h4 className="sidebar-title">Due Date</h4>
                <FormGroup>
                  <Input
                    type="datetime-local"
                    value={supportTicket?.due_date}
                    onChange={(e) => handleDueDateChange(e)}
                    onBlur={handleDueDateBlur}
                  />
                  {/* <div className='dummy-input'>
                   {supportTicket.due_date && formatDate(supportTicket.due_date)}
                   </div> */}
                </FormGroup>
              </div>

              <div className="sidebar-section">
                <h4 className="sidebar-title">Status</h4>
                <FormGroup>
                  <Input
                    type="select"
                    value={supportTicket?.status}
                    onChange={(e) => handleStatusChange(e)}
                  >
                    <option value="1">Open</option>
                    <option value="2">Resolved</option>
                  </Input>
                </FormGroup>
              </div>

            </div>
           </div>
        </Col>
        <Col sm={12} lg={9}>
          <div className="modern-ticket-container">
            <div className="ticket-header">
              <div className="ticket-header-content">
               <div className="d-flex align-items-center gap-3 justify-content-between w-100">
                  <h2 className="support-ticket-title">{supportTicket?.ticket_title}</h2>
                  {/* <span className={`ticket-status ${supportTicket.status === 1 ? 'status-active' : 'status-closed'}`}>
                    {supportTicket.status === 1 ? 'Open' : 'Closed'}
                  </span> */}
                </div>
              </div>

              <div className="ticket-meta">
                <div className="meta-item">
                  {/* <MaterialIcon icon="person" /> */}
                  <div>
                    <span className="meta-label">Created by</span>
                    <span className="meta-value">{supportTicket?.created_name}</span>
                  </div>
                </div>
                <div className="meta-item">
                  {/* <MaterialIcon icon="schedule" /> */}
                  <div>
                    <span className="meta-label">Due Date</span>
                    <span className="meta-value">
                      {supportTicket.due_date && formatDate(supportTicket.due_date)}
                    </span>
                  </div>
                </div>
                <div className="meta-item">
                  {/* <MaterialIcon icon="label" /> */}
                  <div>
                    <span className="meta-label">Topic</span>
                    <span className="meta-value">
                      {supportAssets?.data.topics?.find(t => t.id === supportTicket?.topic_id)?.topic_label}
                    </span>
                  </div>
                </div>
                <div className="meta-item">
                  {/* <MaterialIcon icon="priority_high" /> */}
                  <div>
                    <span className="meta-label">Priority</span>
                    <span className={`priority-badge priority-${supportTicket?.priority_id === 1 ? 'high' : 
                      supportTicket?.priority_id === 2 ? 'medium' : 'low'}`}>
                      {supportAssets?.data.priority?.find(p => p.id === supportTicket?.priority_id)?.priority_label}
                    </span>
                  </div>
                </div>
                <div className="meta-item">
                  {/* <MaterialIcon icon="radar" /> */}
                  <div>
                    <span className="meta-label">Status</span>
                    <span className={`ticket-status ${supportTicket.status === 1 ? 'status-active' : 'status-closed'}`}>
                      {supportTicket.status === 1 ? 'Open' : 'Closed'}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="ticket-body">
              <div className="description-section">
                <h3 className="section-title">Description</h3>
                    <div 
                      className="description-content">
                      <Linkify tagName="p" options={linkifyOptions}>
                        {ReactHtmlParser(
                          stripslashes(supportTicket?.description)
                        )}
                      </Linkify>
                    </div>
                    {(supportTicket?.attachments && Object.keys(supportTicket.attachments).length > 0) &&
                        (() => {
                          const attachmentsArray = typeof supportTicket.attachments === 'string' 
                            ? JSON.parse(supportTicket.attachments)
                            : supportTicket.attachments;
                          return (
                            <div className="attachment-items d-flex justify-contents-between">
                              {attachmentsArray && attachmentsArray.length > 0 && attachmentsArray[0].file_name!=null &&
                              (
                                  <SupportLightboxPop
                                  files={attachmentsArray}
                                  deleteitem="no"
                                  deletefiles={''}
                                  arrayname={'commentAttachments'}
                                  listtype={1}
                                />
                              )}
                            </div>
                          );
                    })()}
              </div>
              {!selfResolvigLoading &&
              (
                <>
                    {supportTicket?.topic_id === 1 && supportTicket?.self_healing == 0 ? 
                    (
                      <div className="self-healing-section">
                        <h3 className="section-title">Self Resolve</h3>
                        <div className="self-healing-content">
                          <p>You may try to resolve this issue yourself. If not resolved then we will look into this ticket.</p>
                        </div>
                        <div className="self-healing-action">
                          <Button color="primary" className="modern-btn comment-btn" 
                            onClick={handleConfirm}
                          >
                            <MaterialIcon icon="check_circle" />
                            Resolve
                          </Button>
                        </div>
                      </div>
                    ):
                    (
                      <>
                        {supportTicket?.self_healing === 1 &&
                        (
                          <div className="self-healing-section">
                            <h3 className="section-title">Self Resolve</h3>
                            <div className="self-healing-content">
                              <div className="self-healing-time">
                                <p>
                                  {ReactHtmlParser(
                                  stripslashes(supportTicket?.self_healing_comment)
                                  )}
                                </p>
                                <span>
                                  Time: {formatDate(supportTicket?.self_healing_date)} 
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                </>
              )}
              <div className="comments-section">
                <h3 className="section-title">Comments</h3>
                {(supportTicket?.topic_id != 1 && supportTicket?.status == 1)  &&
                //|| (supportTicket?.topic_id === 1 && supportTicket?.self_healing != 1 && supportTicket?.status != 1) 
                (
                  <div className="comment-form">
                    <ReactQuill
                      value={comment}
                      onChange={setComment}
                      placeholder="Add a comment..."
                      className="modern-editor"
                    />
                    <ImageUpload 
                      myid={1}
                      folderpath="tickets"
                      arrayname="ticketAttachments"
                      storefiles={handleImageUpload}
                    />
                      {(uploadedImages && uploadedImages.length > 0 && !uploadProgress) &&
                      (
                        <div className="attachment-items d-flex justify-contents-between">
                          <SupportLightboxPop
                            files={uploadedImages}
                            deleteitem="yes"
                            deletefiles={deleteTicketFileNames}
                            arrayname={'commentAttachment'}
                            listtype={1}
                          />
                        </div>
                      )}
                      <Button 
                        color="primary" 
                        className="modern-btn comment-btn "
                        onClick={(e) => {
                          e.preventDefault();
                          addSupportComment();
                        }}
                      >
                        <MaterialIcon icon="send" />
                        Add Comment
                        </Button>
                  </div>
                )}
                {commentsLoading ? (<div>Loading comments...</div>) :
                (
                  <>
                  {ticketComments?.data.comments.length>0 ?
                    (
                      <div className="comments-list">
                        {ticketComments?.data.comments.map((comment) => (
                          <div key={comment.comment_id} className="comment-item">
                            <div className="comment-header">
                              <div className="comment-author">
                                <div className="author-avatar">
                                {(() => {
                                      const nameParts = comment.added_by_name.split(' ');
                                      return nameParts
                                          .slice(0, Math.min(2, nameParts.length))  // Take up to 2 words, or less if only 1 exists
                                          .map(word => word.charAt(0))
                                          .join('')
                                          .toUpperCase();
                                })()}
                                </div>
                                <span>{comment.added_by_name}</span>
                              </div>
                              <span className="comment-date">{formatDate(comment.added_on)}</span>
                            </div>
                            <div 
                              className="comment-content"
                              dangerouslySetInnerHTML={{ __html: comment.comment }} 
                            />
                            {(comment?.attachments && Object.keys(comment.attachments).length > 0) &&
                            (() => {
                              const attachmentsArray = typeof comment.attachments === 'string' 
                                ? JSON.parse(comment.attachments)
                                : comment.attachments;
                              return (
                                <div className="attachment-items d-flex justify-contents-between">
                                  {attachmentsArray && attachmentsArray.length > 0 && attachmentsArray[0].file_name!=null &&
                                  (
                                      <SupportLightboxPop
                                      files={attachmentsArray}
                                      deleteitem="no"
                                      deletefiles={''}
                                      arrayname={'commentAttachments'}
                                      listtype={1}
                                    />
                                  )}
                                </div>
                              );
                            })()}
                          </div>
                        ))}
                      </div>
                    ):
                    (
                      <div className="no-comments">No comments found!</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <ActionDialog 
          isOpen={isDialogOpen}
          toggle={toggleDialog}
          onConfirm={handleConfirmAction}
          headMsg={'Resolve unable to login issue'}
          descriptionMsg={'System will try to figure out the log time issue now. If the minimum time required for a day shift or a half day shift is very close to your actual logged time, then the system will attempt to resolve it.'}
          ConfirmLabel={'Continue'}
          />
        </Col>
  </Row>
);
};
