import React,{useState} from 'react';
import BaseDrawer from './BaseDrawer';

const WarningIssueDrawer = ({ isOpen, toggle, onSubmit, isLoading, designer }) => {
    const [formData, setFormData] = useState({
        warningType: '',
        warningTo: designer?.id, // Set this by default
        ccList: [], // CC recipients
        details: '',
        deadline: '30',
        improvementPoints: ['']
      });
    
      const deadlineOptions = [
        { value: '10', label: '10 Days' },
        { value: '15', label: '15 Days' },
        { value: '20', label: '20 Days' },
        { value: '30', label: '30 Days' }
      ];

      const ccOptions = [
        { id: 'TL', name: 'TL' },
        { id: 'PM', name: 'PM' },
        { id: 'PL', name: 'PL' },
        { id: 'COM', name: 'COM' },
        { id: 'OM', name: 'OM' },
        { id: 'COL', name: 'COL' },
        { id: 'Prem', name: 'Prem' },
        { id: 'Senthu', name: 'Senthu' }
      ];
  
    return (
      <BaseDrawer
        isOpen={isOpen}
        toggle={toggle}
        title="Issue Performance Warning"
        isLoading={isLoading}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(formData);
        }}
      >
        <div className="form-group">
          <label className="form-label">Warning Type</label>
          <select 
            className="form-control"
            value={formData.warningType}
            onChange={(e) => setFormData({...formData, warningType: e.target.value})}
            required
          >
            <option value="">Select Warning Type</option>
            <option value="FIRST_WARNING">First Warning</option>
            <option value="SECOND_WARNING">Second Warning</option>
            <option value="FINAL_WARNING">Final Warning</option>
          </select>
        </div>

        <div className="form-group">
            <label className="form-label">Warning To</label>
            <div className="warning-recipient-card">
            <div className="recipient-info">
                <span className="recipient-name">{designer?.name}</span>
                <span className="recipient-designation">{designer?.designation}</span>
            </div>
            </div>
        </div>

        <div className="form-group">
            <label className="form-label">CC Recipients</label>
            <div className="cc-options">
            {ccOptions.map(cc => (
                <div key={cc.id} className="form-check">
                <input
                    type="checkbox"
                    className="form-check-input"
                    id={`cc-${cc.id}`}
                    checked={formData.ccList.includes(cc.id)}
                    onChange={(e) => {
                    const newCCList = e.target.checked
                        ? [...formData.ccList, cc.id]
                        : formData.ccList.filter(id => id !== cc.id);
                    setFormData({...formData, ccList: newCCList});
                    }}
                />
                <label className="form-check-label" htmlFor={`cc-${cc.id}`}>
                    {cc.name}
                </label>
                </div>
            ))}
            </div>
        </div>

  
        <div className="form-group warning-message">
          <label className="form-label">Warning Message</label>
          <textarea
            className="form-control"
            rows={2}
            value={formData.details}
            onChange={(e) => setFormData({...formData, details: e.target.value})}
            placeholder="Provide detailed reason for the warning"
            required
          />
        </div>
  
        <div className="form-group">
            <label className="form-label">Resolution Deadline</label>
            <div className="deadline-options">
            {deadlineOptions.map((option) => (
               
                <div 
                    className="form-check"
                    onClick={() => setFormData({...formData, deadline: option.value})}
                >
                    <input
                        type="radio"
                        className="form-check-input"
                        id={`deadline-${option.value}`}
                        name="deadline"
                        value={option.value}
                        checked={formData.deadline === option.value}
                        onChange={(e) => setFormData({...formData, deadline: e.target.value})}
                        required
                    />
                    <label className="form-check-label" htmlFor={`deadline-${option.value}`}>
                        {option.label}
                    </label>
                </div>
            ))}
            </div>
        </div>
  
        {/* <div className="form-group">
          <label className="form-label">Improvement Points</label>
          {formData.improvementPoints.map((point, index) => (
            <div key={index} className="d-flex gap-2 mb-2">
              <input
                type="text"
                className="form-control"
                value={point}
                onChange={(e) => {
                  const newPoints = [...formData.improvementPoints];
                  newPoints[index] = e.target.value;
                  setFormData({...formData, improvementPoints: newPoints});
                }}
                placeholder="Add improvement point"
                required
              />
              {index === formData.improvementPoints.length - 1 ? (
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={() => setFormData({
                    ...formData,
                    improvementPoints: [...formData.improvementPoints, '']
                  })}
                >
                  <i className="fa fa-plus"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={() => {
                    const newPoints = formData.improvementPoints.filter((_, i) => i !== index);
                    setFormData({...formData, improvementPoints: newPoints});
                  }}
                >
                  <i className="fas fa-trash"></i>
                </button>
              )}
            </div>
          ))}
        </div> */}
      </BaseDrawer>
    );
  };
  export default WarningIssueDrawer;