import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { 
  Container, 
  Row, 
  Col, 
  Button, 
  Form, 
  FormGroup, 
  Label, 
  Input,
  CustomInput,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import "./RatingAuthors.css";
import { useGetRatingAssets, useAddUpdateProfileRatingAuthor } from './useProfileReportHooks';
import { use } from 'react';

const EmployeeReportingManagement = () => {
    const { isLoading: assetLoading, data: assetsData } = useGetRatingAssets();
    const [rolesData, setRolesData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [fullEmployees, setFullEmployees] = useState(initialData);
    const [employees, setEmployees] = useState(initialData);

    useEffect(() => {
        if (!assetLoading) {
            setRolesData(assetsData?.data.roles);
            setUsersData(assetsData?.data.members);
            //setEmployees(assetsData?.data.rating_members);
            //setInitialData(assetsData?.data.rating_members);

            const transformedData = assetsData?.data.rating_members.map(employee => ({
                ...employee,
                ratings: employee.ratings === '[]' ? [] : 
                         employee.ratings ? JSON.parse(employee.ratings.replace(/"/g, '')) : []
            }));
            
            setEmployees(transformedData);
            setFullEmployees(transformedData);
        }
    }, [assetsData]);

  
  const [currentEmployeeIndex, setCurrentEmployeeIndex] = useState(0);
  const [showDrawer, setShowDrawer] = useState(false);
  const [reportingType, setReportingType] = useState('role');
  const [selectedReportings, setSelectedReportings] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showNavigationWarning, setShowNavigationWarning] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  
    // Helper function to get name by ID
  const getNameById = (id, type) => {
    if (!id) return '';
    if (type === 'user') {
      const user = usersData.find(u => u.id === id);
      return user ? user.name : '';
    } else if (type === 'role') {
      const role = rolesData.find(r => r.id === id);
      return role ? role.name : '';
    }
    return '';
  };

  // Helper function to get dropdown options based on type
  const getDropdownOptions = (type) => {
    if (type === 'role') {
      return rolesData.map(role => ({
        id: role.id,
        name: role.name
      }));
    } else {
      return usersData.map(user => ({
        id: user.id,
        name: user.name + " - "+ getNameById(user.role_id, 'role')
      }));
    }
  };

    // Modified to properly load employee data
    const loadEmployeeData = (index) => {
        const employee = employees[index];
        setReportingType(employee.ratingType);
        setSelectedReportings(employee.ratings || []);
      };
    
  
    const handleNavigate = (action) => {
      if (hasUnsavedChanges) {
        setPendingAction(action);
        setShowNavigationWarning(true);
      } else {
        executeNavigation(action);
      }
    };
  
    const executeNavigation = (action) => {
      if (action === 'next') {
        handleNext();
      } else if (action === 'previous') {
        handlePrevious();
      } else if (action === 'close') {
        toggleDrawer();
      }
    };

    const formatName = (name) => {
        if (name.split(' ').filter(w => w.includes('.')).length >= 2) {
          return name;
        }
        if (name.includes('-')) {
          return name.split(' ')[0];
        }
        return name.split(' ').slice(0, 2).join(' ');
       };

    useEffect(() => {
        if (showDrawer) {
          loadEmployeeData(currentEmployeeIndex);
        }
    }, [currentEmployeeIndex, showDrawer]);

  
    const toggleDrawer = () => {
        setShowDrawer(!showDrawer);
        setHasUnsavedChanges(false);
    };

    const handleViewClick = (index) => {
        setCurrentEmployeeIndex(index);
        setShowDrawer(true);
    };
  
    const handleReportingTypeChange = (type) => {
      setReportingType(type);
      setSelectedReportings([]);
      setHasUnsavedChanges(true);
    };
  
    const addReporting = () => {
      setSelectedReportings([...selectedReportings, '']);
      setHasUnsavedChanges(true);
    };
  
    const removeReporting = (index) => {
      const newReportings = [...selectedReportings];
      newReportings.splice(index, 1);
      setSelectedReportings(newReportings);
      setHasUnsavedChanges(true);
    };
  
    const updateReporting = (index, value) => {
      const newReportings = [...selectedReportings];
      newReportings[index] = value;
      setSelectedReportings(newReportings);
      setHasUnsavedChanges(true);
    };
  
    const handleNext = () => {
      if (currentEmployeeIndex < employees.length - 1) {
        setCurrentEmployeeIndex(currentEmployeeIndex + 1);
        loadEmployeeData(currentEmployeeIndex + 1);
        setHasUnsavedChanges(false);
      }
    };
  
    const handlePrevious = () => {
      if (currentEmployeeIndex > 0) {
        setCurrentEmployeeIndex(currentEmployeeIndex - 1);
        loadEmployeeData(currentEmployeeIndex - 1);
        setHasUnsavedChanges(false);
      }
    };
  
    const saveReportings = () => {
        const updatedEmployees = [...employees];
        updatedEmployees[currentEmployeeIndex] = {
          ...updatedEmployees[currentEmployeeIndex],
          ratingType: reportingType,
          ratings: selectedReportings
        };
        setEmployees(updatedEmployees);
        setFullEmployees(updatedEmployees);
        const data1 = updatedEmployees[currentEmployeeIndex];
        addRatingAuthor(data1);
        setHasUnsavedChanges(false);
        if(searchQuery!='')
        {
          setShowDrawer(false);
        }
      };

  const { mutate: addRatingAuthor, isLoading: addLoading } =
  useAddUpdateProfileRatingAuthor();

  const renderTableRow = (employee, index) => (  // Added index parameter
        <tr key={employee.userId}>
          <td>{formatName(employee.name)}</td>
          <td>{getNameById(employee.role_id, 'role')}</td>
          <td>{employee.ratingType!='none'? employee.ratingType : '-'}</td>
          <td>{formatName(getNameById(employee.teamLead, 'user'))}</td>
          <td>{formatName(getNameById(employee.projectManager, 'user'))}</td>
          <td>{formatName(getNameById(employee.projectLead, 'user'))}</td>
          <td>{formatName(getNameById(employee.com, 'user'))}</td>
          <td>{formatName(getNameById(employee.ops, 'user'))}</td>
          <td>
            <Button 
              color="link" 
              className="p-0"
              onClick={() => handleViewClick(index)}
            >
              View
            </Button>
          </td>
        </tr>
      );


    //   const renderSelectOptions = () => {
    //     const options = getDropdownOptions(reportingType);
    //     return options.map(option => (
    //       <option key={option.id} value={option.id}>
    //         {option.name}
    //       </option>
    //     ));
    //   };
    
      // Update the reporting selections display
      const renderReportingSelections = () => {
        return selectedReportings.map((reportingId, index) => {
          // Find the selected item to properly display it
          const selectedItem = (reportingType === 'role' ? 
            rolesData : usersData).find(item => item.id === reportingId);
      
          // Create the select options
          const options = (reportingType === 'role' ? rolesData : usersData).map(item => ({
            value: item.id,
            label: `${item.name}${item.role_id ? ` - ${getNameById(item.role_id, 'role')}` : ''}`,
            id: item.id
          }));
      
          return (
            <FormGroup key={index} className="mb-2 d-flex align-items-center">
              <Select
                value={selectedItem ? {
                  value: selectedItem.id,
                  label: `${selectedItem.name}${selectedItem.role_id ? ` - ${getNameById(selectedItem.role_id, 'role')}` : ''}`,
                  id: selectedItem.id
                } : null}
                onChange={(selected) => updateReporting(index, selected ? selected.id : '')}
                options={options}
                placeholder={`Select ${reportingType === 'role' ? 'Role' : 'Individual'}`}
                className="flex-grow-1 me-2"
                classNamePrefix="select"
                isClearable
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: '42px',
                    borderColor: '#dee2e6',
                    '&:hover': {
                      borderColor: '#ced4da'
                    }
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    padding: '2px 12px'
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: '#6c757d'
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: '#333'
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 1100
                  }),
                  option: (base, state) => ({
                    ...base,
                    padding: '8px 12px',
                    backgroundColor: state.isSelected 
                      ? '#007bff' 
                      : state.isFocused 
                        ? '#f8f9fa' 
                        : 'white',
                    color: state.isSelected ? 'white' : '#333',
                    '&:active': {
                      backgroundColor: state.isSelected ? '#007bff' : '#f8f9fa'
                    }
                  })
                }}
              />
              <Button 
                className="btn-remove"
                onClick={() => removeReporting(index)}
              >
                ×
              </Button>
            </FormGroup>
          );
        });
      };


      const [searchQuery, setSearchQuery] = useState('');

      // Add this function to filter employees
     

      useEffect(() => {
        if (searchQuery === '') {
                setEmployees(fullEmployees);
            }
        else {
             const filteringEmployees = [...fullEmployees];
             const filteredEmployees =  filteringEmployees.filter(employee => 
                employee.name.toLowerCase().includes(searchQuery.toLowerCase())
             );
             setEmployees(filteredEmployees);
        }   
     }, [searchQuery]);
  return (
        <div className="p-6 bg-gray-50 min-h-screen">
            <div className="d-flex justify-content-between mb-3">
                <h2 className="">Manage Rating Authors</h2>
                <Input
                    type="search"
                    placeholder="Search by employee name..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="form-control"
                    style={{ maxWidth: '300px' }}
                />
            </div>
            <div className="mt-4">
            <div className="card">
                <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                        <thead className='bg-light'>
                            <tr>
                            <th>Name</th>
                            <th>Role</th>
                            <th>Rating</th>
                            <th>TL</th>
                            <th>PM</th>
                            <th>PL</th>
                            <th>COM</th>
                            <th>OPs</th>
                            <th>Actions</th>
                            </tr>
                        </thead>
                        {employees && employees.length>0?
                        (
                            <tbody>
                                {employees.map((employee, index) => renderTableRow(employee, index))}
                            </tbody>
                        ):
                        (
                            <></>
                        )}
                        
                        </table>
                    </div>
                    </div>
                </div>


            {/* Drawer Component */}
            <div className={`reporting-drawer ${showDrawer ? 'open' : ''}`}>
                <div className="drawer-header">
                <h4 className="mb-0">Details for {employees[currentEmployeeIndex]?.name}</h4>
                <Button 
                    close
                    onClick={() => handleNavigate('close')}
                />
                </div>
                <div className="drawer-data-info ml-4 mt-4">
                    <div className='mb-2 cs-md cs-xs'>
                        <span><Label>TL: </Label>{formatName(getNameById(employees[currentEmployeeIndex]?.teamLead, 'user'))}</span>
                        <span><Label>PM: </Label>{formatName(getNameById(employees[currentEmployeeIndex]?.projectManager, 'user'))}</span>
                        <span><Label>PL: </Label>{formatName(getNameById(employees[currentEmployeeIndex]?.projectLead, 'user'))}</span>
                    </div>
                    <div className='mb-2 cs-md cs-xs'>
                        <span><Label>COM: </Label>{formatName(getNameById(employees[currentEmployeeIndex]?.com, 'user'))}</span>
                        <span><Label>OP: </Label>{formatName(getNameById(employees[currentEmployeeIndex]?.ops, 'user'))}</span>
                    </div>
                </div>

                <Form className="drawer-body">
                <FormGroup className="mb-3">
                    <Label className="fw-bold">Rating Type</Label>
                    <div>
                    <CustomInput
                        type="radio"
                        id="roleRadio"
                        label="Role Based"
                        name="reportingType"
                        checked={reportingType === 'role'}
                        onChange={() => handleReportingTypeChange('role')}
                        inline
                    />
                    <CustomInput
                        type="radio"
                        id="individualRadio"
                        label="Individual Based"
                        name="reportingType"
                        checked={reportingType === 'individual'}
                        onChange={() => handleReportingTypeChange('individual')}
                        inline
                    />
                    </div>
                </FormGroup>

                {renderReportingSelections()}

                <Button 
                    color="secondary" 
                    onClick={addReporting}
                    className="mt-2"
                >
                    Add {reportingType === 'role' ? 'Role' : 'Individual'}
                </Button>
                </Form>

                <div className="drawer-footer">
                <div className="mt-4 d-flex justify-content-between w-100">
                    <Button 
                    outline
                    color="secondary" 
                    onClick={() => handleNavigate('previous')}
                    disabled={currentEmployeeIndex === 0}
                    >
                    Previous
                    </Button>
                    <Button 
                    color="primary" 
                    onClick={saveReportings}
                    >
                    Save
                    </Button>
                    <Button 
                    outline
                    color="secondary" 
                    onClick={() => handleNavigate('next')}
                    disabled={currentEmployeeIndex === employees.length - 1}
                    >
                    Next
                    </Button>
                </div>
                </div>
            </div>

            {/* Navigation Warning Modal */}
            <Modal isOpen={showNavigationWarning} toggle={() => setShowNavigationWarning(false)}>
                <ModalHeader toggle={() => setShowNavigationWarning(false)}>
                Unsaved Changes
                </ModalHeader>
                <ModalBody>
                You have unsaved changes. Are you sure you want to continue?
                </ModalBody>
                <ModalFooter>
                <Button color="secondary" onClick={() => setShowNavigationWarning(false)}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                    executeNavigation(pendingAction);
                    setShowNavigationWarning(false);
                    setPendingAction(null);
                    }}
                >
                    Continue
                </Button>
                </ModalFooter>
            </Modal>
        </div>
    </div>
    
  );
};

export default EmployeeReportingManagement;