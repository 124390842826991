import React, { useState, useEffect, Suspense, lazy } from "react";
import { useHistory } from "react-router";
import Header from "../header/Header";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card } from "reactstrap";
import "./RatingAuthors.css";
import { urls } from "../../services/UrlList";
import EmployeeReportingManagement from "./EmployeeReportingManagement";

const EmployeeRatingAuthors = (props) => {
  const [opencount, setOpenCount] = useState(0);
  const [showMenu, setshowMenu] = useState(true);
  const [myId, setmyId] = useState(0);
  const [month, setMonth] = useState(48);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const history = useHistory();

  useEffect(() => {
    checkUserPrivilege();
  }, []);

  const checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      setmyId(login.uid);
    }
  };

  function toggleSidebar() {
    setshowMenu(!showMenu);
  }

  function hideMenu() {
    setshowMenu(!showMenu);
  }

  return (
    <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className="">
        <Row>
          <div className="theme_header">
            <Header
              ncount={opencount}
              {...props}
              className="tick-timer-header"
              quickactionblock={false}
            />
          </div>
          <div className="SideNavbar">
            <SideMenu
              activeitem="report"
              showmenu={showMenu}
              hidemenu={hideMenu}
            />
          </div>
          <div className="themeContentBody rating-authors">
            <EmployeeReportingManagement />
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default EmployeeRatingAuthors;
