/**
 * Fixes the scroll position issue in Quill editors when applying text formatting
 * @param {string} editorId - The ID of the Quill editor container element
 * @param {object} quillRef - React ref object pointing to the Quill component
 * @param {number} maxWaitTime - Maximum time to wait for editor in milliseconds (default: 10000ms)
 */
export const fixQuillColorPickerScroll = (editorId, quillRef, maxWaitTime = 10000) => {
  if (!editorId) {
    console.error("fixQuillColorPickerScroll: editorId parameter is required");
    return;
  }
  
  if (!quillRef) {
    console.error("fixQuillColorPickerScroll: quillRef parameter is required");
    return;
  }
  
  let isSetup = false;
  let editorCheckInterval = null;
  let startTime = Date.now();
  const setupScrollFix = () => {
    if (isSetup) return;
    const quillContainer = document.getElementById(editorId);
    if (!quillContainer) return;
    const toolbar = quillContainer.querySelector('.ql-toolbar');
    if (!toolbar) return;
    if (editorCheckInterval) {
      clearInterval(editorCheckInterval);
      editorCheckInterval = null;
    }
    isSetup = true;
    let scrollElements = [
      quillContainer,                           
      quillContainer.querySelector('.ql-editor'),   
      quillContainer.querySelector('.ql-container'), 
      quillContainer.parentElement              
    ].filter(Boolean);
    const colorPickers = toolbar.querySelectorAll('.ql-color, .ql-background');
    const getQuillInstance = () => {
      try {
        return quillRef.current ? quillRef.current.getEditor() : null;
      } catch (error) {
        return null;
      }
    };
    const quill = getQuillInstance();
    const getScrollPosition = () => {
      let positions = {};
      scrollElements.forEach((el, index) => {
        positions[`element${index}`] = el.scrollTop;
      });
      for (const key in positions) {
        if (positions[key] > 0) {
          return { element: key, position: positions[key] };
        }
      }
      return { element: 'element0', position: positions.element0 || 0 };
    };
    const handlePickerClick = (e) => {
      const scrollInfo = getScrollPosition();
      setTimeout(() => {
        const pickerOptions = document.querySelectorAll('.ql-picker-options .ql-picker-item');
        pickerOptions.forEach(option => {
          option.addEventListener('click', () => {
            setTimeout(() => {
              scrollElements.forEach((el, index) => {
                if (`element${index}` === scrollInfo.element) {
                  el.scrollTop = scrollInfo.position;
                }
              });
              
              if (quill) {
                const range = quill.getSelection();
                if (range) {
                  quill.setSelection(range.index, 0);
                }
              }
            }, 50);
          }, { once: true }); 
        });
      }, 50);
    };
    colorPickers.forEach(picker => {
      picker.addEventListener('click', handlePickerClick);
    });
  };
  const observer = new MutationObserver((mutations) => {
    setupScrollFix();
  });
  observer.observe(document.body, { 
    childList: true, 
    subtree: true 
  });
  editorCheckInterval = setInterval(() => {
    setupScrollFix();
    if (Date.now() - startTime > maxWaitTime) {
      clearInterval(editorCheckInterval);
      observer.disconnect();
    }
  }, 500);
  return () => {
    if (editorCheckInterval) {
      clearInterval(editorCheckInterval);
    }
    observer.disconnect();
  };
};