// MeetingScheduleDrawer.jsx
import React, { useState } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';
import BaseDrawer from './BaseDrawer';

// MeetingScheduleDrawer.jsx
const MeetingScheduleDrawer = ({ isOpen, toggle, onSubmit, isLoading }) => {
  const [formData, setFormData] = useState({
    meetingDate: '',
    participants: {
      Designer: { selected: false, memberId: '' },
      'COM/TL': { selected: false, memberId: '' },
      'OM': { selected: false, memberId: '' },
      'QA': { selected: false, memberId: '' }
    },
    agenda: ''
  });

  // Sample member data - replace with your actual data structure
  const memberOptions = {
    Designer: [
      { id: 'D1', name: 'John Doe' },
      { id: 'D2', name: 'Jane Smith' },
      { id: 'D3', name: 'Mike Johnson' }
    ],
    'COM/TL': [
      { id: 'C1', name: 'Alice Brown' },
      { id: 'C2', name: 'Bob Wilson' },
      { id: 'C3', name: 'Carol White' }
    ],
    'OM': [
      { id: 'O1', name: 'David Miller' },
      { id: 'O2', name: 'Eva Garcia' },
      { id: 'O3', name: 'Frank Lopez' }
    ],
    'QA': [
      { id: 'Q1', name: 'Grace Lee' },
      { id: 'Q2', name: 'Henry Chen' },
      { id: 'Q3', name: 'Iris Park' }
    ]
  };

  const handleParticipantChange = (role, checked) => {
    setFormData(prev => ({
      ...prev,
      participants: {
        ...prev.participants,
        [role]: {
          ...prev.participants[role],
          selected: checked,
          memberId: checked ? prev.participants[role].memberId : ''
        }
      }
    }));
  };

  const handleMemberSelection = (role, memberId) => {
    setFormData(prev => ({
      ...prev,
      participants: {
        ...prev.participants,
        [role]: {
          ...prev.participants[role],
          memberId
        }
      }
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Convert the participants data into a more usable format
    const selectedParticipants = Object.entries(formData.participants)
      .filter(([_, data]) => data.selected && data.memberId)
      .map(([role, data]) => ({
        role,
        memberId: data.memberId,
        memberName: memberOptions[role].find(m => m.id === data.memberId)?.name
      }));

    onSubmit({
      ...formData,
      participants: selectedParticipants
    });
  };

  return (
    <BaseDrawer
        isOpen={isOpen}
        toggle={toggle}
        title="Schedule Performance Discussion Meeting"
        isLoading={isLoading}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(formData);
        }}
      >
          <div className="form-group">
            <label className="form-label">Meeting Date & Time</label>
            <input
              type="datetime-local"
              className="form-control"
              value={formData.meetingDate}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                meetingDate: e.target.value
              }))}
              required
            />
          </div>

          <div className="form-group">
            <label className="form-label">Required Participants</label>
            {Object.entries(formData.participants).map(([role, { selected, memberId }]) => (
              <div className="form-check participant-row" key={role}>
                  
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`participant-${role}`}
                    checked={selected}
                    onChange={(e) => handleParticipantChange(role, e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor={`participant-${role}`}>
                    {role}
                  </label>
                  {selected && (
                  <div className="member-select">
                    <select
                      className="form-control"
                      value={memberId}
                      onChange={(e) => handleMemberSelection(role, e.target.value)}
                      required={selected}
                    >
                      <option value="">Select {role}</option>
                      {memberOptions[role].map(member => (
                        <option key={member.id} value={member.id}>
                          {member.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                </div>
            ))}
          </div>

          <div className="form-group">
            <label className="form-label">Agenda</label>
            <textarea
              className="form-control"
              rows={4}
              value={formData.agenda}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                agenda: e.target.value
              }))}
              placeholder="Enter meeting agenda"
              required
            />
          </div>
        </BaseDrawer>
  );
};

export default MeetingScheduleDrawer;