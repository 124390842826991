import React, { useState, useEffect} from "react";
import { useHistory } from "react-router";
import { Typeahead } from "react-bootstrap-typeahead";
import Header from "../header/Header";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card } from "reactstrap";
import "./ProfileReport.css";
import { useGetProfileReportMembers,useGetKimpMembers } from "./useProfileReportHooks";
import { urls } from "../../services/UrlList";
import PerformanceCard from "./PerformanceCard";
import { AccessCheckBlock } from "../bcmodule/AccessCheck";

const PerformanceDashboard = (props) => {
  
  const [opencount, setOpenCount] = useState(0);
  const [showMenu, setshowMenu] = useState(true);
  const [myId, setmyId] = useState(0);
  const [month, setMonth] = useState(48);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const history = useHistory();
  const [myMembers, setMyMembers] = useState([]);
  const [member, setMember] = useState(0);

  useEffect(() => {
    checkUserPrivilege();
  }, []);

  const checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      setmyId(login.uid);
      setMember(login.uid);
    }
  };

  const { isLoading: membersLoading, data: teamMembers } =
    useGetProfileReportMembers(member);

  const { isLoading: usersLoading, data: usersData } =
      useGetKimpMembers(1);


  function toggleSidebar() {
    setshowMenu(!showMenu);
  }

  function hideMenu() {
    setshowMenu(!showMenu);
  }

  const toggleFeedbackFormTrigger = () => {
    setShowFeedbackForm(!showFeedbackForm);
  };

  const isDateInRange = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    return currentDay > 22;
  };

  const handleInputChange = (input, e) => {
    console.log("value", input);
  };

  const handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if (size > 0) {
      setMember(selectedOptions[0].id);
    }else
    {
      setMember(myId);
    }
  };

  useEffect(() => {
    if (teamMembers?.data?.members) {
      const sortedData = [...teamMembers.data.members].sort((a, b) => {
        if (a.currentRatingCount === 6 && b.currentRatingCount !== 6) return 1;
        if (a.currentRatingCount !== 6 && b.currentRatingCount === 6) return -1;
        return 0;
      });
      setMyMembers(sortedData);
    }
  }, [teamMembers?.data?.members]);

  const renderContent = () => {
    // if (!isDateInRange()) {
    //   return (
    //     <div className="no-data" style={{marginLeft:"20px"}}>
    //       <p>Performance review cards are only available after 22nd of this month.</p>
    //     </div>
    //   );
    // }

    return myMembers && myMembers.length > 0 ? (
      <>
        {myMembers.map((member) => (
          <PerformanceCard
            key={member.id}
            member={member}
            storageUrl={urls.storageUrl}
            toggleFeedbackFormTrigger={toggleFeedbackFormTrigger}
            availableReport={!isDateInRange() ? false : true}
          />
        ))}
      </>
    ) : (
      <div className="no-data" style={{marginLeft:"20px"}}>
        <p>Nothing on your plate for ratings this month.</p>
      </div>
    );
  };

  return (
    <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className="">
        <Row>
          <div className="theme_header">
            <Header
              ncount={opencount}
              {...props}
              className="tick-timer-header"
              quickactionblock={false}
            />
          </div>
          <div className="SideNavbar">
            <SideMenu
              activeitem="report"
              showmenu={showMenu}
              hidemenu={hideMenu}
            />
          </div>
          <div className="themeContentBody performance-dashboard">
            <div className="p-6 bg-gray-50 min-h-screen">
              <div className="d-flex justify-content-between align-items-center performace-report-ratings">
                <h1 className="page-title text-left">Performance Report</h1>
                <div className="d-flex header-right-wrap">
                  {(!usersLoading &&  (AccessCheckBlock('i_am_admin') )) && (
                      <Typeahead
                        id="user-picker"
                        labelKey="name"
                        onInputChange={handleInputChange}
                        onChange={handleChange}
                        options={usersData?.data.users}
                        placeholder="Choose member"
                        className="head-filter-member-fa"
                        selected=""
                      />
                    )}
                </div>
              </div>
              {/* <h1 className="page-title text-left">Performance Report</h1> */}
              
              {!isDateInRange() && (
                <div className="no-data">
                  <p>Performance review cards are available for rating only after 22nd of this month.</p>
                </div>
              )}
              <Row>
                {renderContent()}
              </Row>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default PerformanceDashboard;