import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {QueryClient,QueryClientProvider} from '@tanstack/react-query'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/Typography.css';
import Login from './components/login/Login';
import LoginPortal from './components/loginportal/LoginPortal';
import TickTimer from './components/ticktimer/TickTimer';
import LogTimer from './components/Reports/LogTimer';
import ProjectWorkLog from './components/Reports/ProjectWorkLog';
import ProjectReport from './components/Reports/ProjectReport';
import MonthlyReport from './components/Reports/MonthlyReport';
import ApprovalContainer from './components/Approval/approvalContainer/ApprovalContainer';
import MyTasks from './components/task/Task';
import LiveActivity from "./components/LiveActivity/LiveActivity";
import LiveActivityDesigners from "./components/LiveActivity/LiveActivityDesigners";
import Profile from "./components/Profile/Profile";
import Leave from "./components/Leave/LeaveApply/LeaveApply";
import LeaveBalance from "./components/Leave/LeaveBalance/LeaveBalance";
import LeaveCalender from "./components/Leave/LeaveCalender/LeaveCalender";
import LeaveStatus from "./components/Leave/LeaveStatus/LeaveStatus";
import LoanSummary from "./components/Loan/LoanContainer/LoanContainer";
import LoanApproval from "./components/LoanApproval/LoanContainer/LoanContainer";
import AdminNotifications from './components/AdminNotifications/approvalContainer/ApprovalContainer';
import Feeds from "./components/Feeds/Feeds";
import SwitchUser from "./components/Switchuser/SwitchUser";
import Countdown from "./components/countdown/Countdown";
import UserManagement from "./components/UserManagement/UserManagement";
import ManageUsers from "./components/UserManagement/UsersList";
import Maintenance from "./components/Maintenance/Maintenance";
import TrelloBoards from "./components/Reports/TrelloBoards";
import TeamHolidays from "./components/Admin/TeamHolidays";
import EmployeeLeaveBalance from "./components/Admin/EmployeeLeaveBalance";
import EmployeeLeaveBalanceDetails from "./components/Admin/EmoployeeLeaveBalance/EmployeeLeaveBalanceDetails";
import NewTicket from "./components/Tickets/NewTicket";
import TicketDetails from "./components/Tickets/TicketDetails";
import TicketList from "./components/Tickets/TicketList";
import ClientHealth from "./components/Reports/HealthReport";
import ClientHealthReport from "./components/Reports/ClientHealthReport";
import CheckOnline from "./components/Reports/CheckOnline";
import CheckOnlineReport from "./components/Reports/OnlineAvailabilityReport";
import Questionnaire from "./components/Questionnaire/Qa-board";
import QuestionnaireReport from "./components/Questionnaire/QaBoardReport";
import ProjectAccess from "./components/task/ProjectAccess";
import TeamView from "./components/TeamView/TeamView";
import ColleagueTask from "./components/Colleagues/ColleagueTask";
import LogEditor from "./components/Switchuser/LogEditor";
import SpeedTest from "./components/Switchuser/SpeedTest";
import ConfirmHoliday from "./components/Admin/ConfirmHoliday";
import ClosedTrelloBoards from "./components/Reports/ClosedTrelloBoards";
import ClientWorkReport from './components/Reports/ClientWorkReport';
import OverLimitReport from './components/Reports/OverLimitReport';
import TeamControl from "./components/Teamcontrol/TeamControl";
import Bcboards from './components/bcmodule/Boards';
import BcboardsDetail from './components/bcmodule/Boardsdetails';
import Docandfile from './components/bcmodule/Docandfile';
import Brand from './components/bcmodule/Brand';
import Attachfiles from './components/bcmodule/Attachfiles'
import Tasklistbc from './components/bcmodule/Tasklistbc'
import NewTask from './components/bcmodule/Newtask'
import TaskDetails from './components/bcmodule/Taskdetail'
import ProjectTasks from "./components/bcmodule/Tasks/Tasks"
import ClientRatio from "./components/Reports/ClientRatio"
import WhiteLabelBoards from './components/Reports/WhiteLabelBoards';
import './components/Darktheme/Darkmode.css';
import './responsive.css';
import {useDispatch} from 'react-redux';
import {AddNotifyCount, UnreadNotifyCount} from "./components/redux/actions";
import { AppendNotifyMessage } from './components/redux/actions';
import { AppendNotifyMessages,UnreadNotifyMessages } from './components/redux/actions';
import {urls} from "./services/UrlList";
import axios from 'axios';
import ActiveRunningTasks from './components/Reports/ActiveRunningTasks';
import TaskAssignedSummary from './components/Reports/TaskAssignedSummary';
import TokenGenerator from './components/header/TokenGenerator';
import BrandPreset from './components/bcmodule/BrandPreset';
import Notificationdetail from './components/header/NotificationDetail'
import GeoLocation from './components/loginportal/GeoLocation';

import internalActivities from './components/bcmodule/InternalTimers/Activities'
import AssignmentsandActivities from './components/bcmodule/InternalTimers/AllassignmentsandActivities'
import Yourplate from './components/bcmodule/InternalTimers/Yourplate'
import TasklistbcGroup from './components/bcmodule/TasklistbcGroup';
import CloneInternalBoard from './components/bcmodule/CloneInternalBoard';
import NonBrandedBoards from './components/Reports/NonBrandedBoards';
import CreateMeeting from './components/Momeeting/CreateMeeting';
import Meetinglist from './components/Momeeting/MeetingList';
import MinutesofMeeting from './components/Momeeting/MinutesofMeeting';
import SalaryTable from './components/salaryTable/salaryTable';
import SourceView from './components/bcmodule/SourceManagement/SourceView';
import SourceViewFolder from './components/bcmodule/SourceManagement/SourceViewFolder';


import DDTest from './components/bcmodule/DDtest';
import AssignmentsandActivitiesDemo from './components/bcmodule/InternalTimers/AllassignmentsandActivitiesDemo'
import ClientprofileList from './components/ClientProfile/Clientlist'
import ClientprofileDetails from './components/ClientProfile/Clientdetails'
import LateReport from './components/Reports/LateReport';
import IssueReportingLog from './components/Reports/IssueReportingLog';
import AllBoardActivities from './components/bcmodule/AllBoardActivities';
import TasklistbcGroupDefault from './components/bcmodule/TasklistbcGroupDefault';
import { MediaProvider } from './MediaContext';
import BoardMailBox from './components/bcmodule/BoardMailBox';
import BoardCollections from './components/Admin/BoardCollections/BoardCollections';
import CanvaAccounts from './components/Admin/CanvaAccounts/CanvaAccounts';

import PredefinedMessages from './components/bcmodule/PredefinedMessages'
import BoardApprovedFiles from './components/bcmodule/BoardApprovedFiles';
import PortfolioAssetsReview from './components/bcmodule/PortfolioAssetsReview';
import PortfolioAssets from './components/bcmodule/PortfolioAssets';
import ProfileReport from './components/ProfileReport/ProfileReport';
import PerformanceDashboard from './components/ProfileReport/PerformanceDashboard';
import AiTestTracker from './components/AiTest/AiTestTracker';
import TestWpLogin from './components/AiTest/TestWpLogin';
import CrmTestimonailsReport from './components/Reports/CrmTestimonailsReport';
import EmployeeRatingAuthors from './components/ProfileReport/EmployeeRatingAuthors';
import PerformanceTimeline from './components/PerformanceIssues/PerformanceIssues';

import LoanRepayment from './components/Loan/LoanRepayment/LoanRepayment';

import HrTicketList from './components/Tickets/HrTickets/HrTicketList';
import HrTicketDetails from './components/Tickets/HrTickets/HrTicketDetails';
import VideoPortfolioAssetsReview from './components/bcmodule/VideoPortfolioAssetsReview';
import VideoPortfolioAssets from './components/bcmodule/VideoPortfolioAssets';
import EWebinarPage from './components/common/EWebinarPage';
const queryClient = new QueryClient()

function App() {       
    return (
      <MediaProvider>
      <QueryClientProvider client={queryClient}>
      <div className="App">
        <TokenGenerator />  
        <BrowserRouter>
        
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/home" component={LoginPortal} />
            <Route exact path="/tick-report" component={TickTimer} />
            <Route exact path="/approve-request" component={ApprovalContainer} />
            <Route exact path="/mytasks" component={MyTasks} />
            <Route exact path="/liveactivity" component={LiveActivity} />
            <Route exact path="/reports/liveactivity-designers" component={LiveActivityDesigners} />
            <Route exact path="/my-profile" component={Profile} />
            <Route exact path="/reports/log-timer" component={LogTimer} />  
            <Route exact path="/reports/monthly-overview" component={MonthlyReport} />   
            <Route exact path="/reports/project-work-log" component={ClientWorkReport} /> 
            <Route exact path="/reports/white-label-boards" component={WhiteLabelBoards} />     
            <Route exact path="/reports/employee-leave-balance" component={EmployeeLeaveBalance} />   
            <Route exact path="/reports/employee-leave-balance-details/:id" component={EmployeeLeaveBalanceDetails} />        
            <Route exact path="/reports/latecoming" component={LateReport} />
            <Route exact path="/reports/taskissues" component={IssueReportingLog} />
            
            <Route exact path="/leave/apply" component={Leave} />
            <Route exact path="/leave/balance" component={LeaveBalance} />
            <Route exact path="/leave/calendar" component={LeaveCalender} />
            <Route exact path="/leave/status" component={LeaveStatus} />
            
           {/*  <Route exact path="/leave/apply" component={Maintenance} />
            <Route exact path="/leave/balance" component={Maintenance} />
            <Route exact path="/leave/calendar" component={Maintenance} />
            <Route exact path="/leave/status" component={Maintenance} />
              */}
            <Route exact path="/loan/summary" component={LoanSummary} />
            <Route exact path="/loan/approval" component={LoanApproval} />
            <Route exact path="/admin-notifications" component={AdminNotifications} />
            <Route exact path="/news-feed" component={Feeds} />
            <Route exact path="/switch-user" component={SwitchUser} />
            <Route exact path="/countdown" component={Countdown} />
            <Route exact path="/reports/projectwise" component={ProjectReport} />
            <Route exact path="/usermanagement/:id" component={UserManagement} />
            <Route exact path="/manage-users" component={ManageUsers} />
            <Route exact path="/maintenance" component={Maintenance} />
            <Route exact path="/reports/active-trello-boards" component={TrelloBoards} />
            <Route exact path="/admin/team-holidays-apply" component={TeamHolidays} />
            <Route exact path="/admin/employee-leave-balance" component={EmployeeLeaveBalance} />
            <Route exact path="/admin/employee-leave-balance-details/:id" component={EmployeeLeaveBalanceDetails} />
            <Route exact path="/new-ticket" component={NewTicket} />
            <Route exact path="/ticket/:id" component={TicketDetails} />
            <Route exact path="/tickets" component={TicketList} />
            <Route exact path="/client-health" component={ClientHealth} />
            <Route exact path="/client-health-report" component={ClientHealthReport} />
            <Route exact path="/verify-online/:id/:uid" component={CheckOnline} />
            <Route exact path="/report/online-availability" component={CheckOnlineReport} />
            <Route exact path="/report/questionnaire" component={Questionnaire} />
            <Route exact path="/report/qrreport" component={QuestionnaireReport} />
            <Route exact path="/task/project-access" component={ProjectAccess} />
            <Route exact path="/users/team-view" component={TeamView} />
            <Route exact path="/colleagues/task" component={ColleagueTask} />
            <Route exact path="/logeditor" component={LogEditor} />
            <Route exact path="/speedtest" component={SpeedTest} />
            <Route exact path="/confirmholiday/:id" component={ConfirmHoliday} />
            <Route exact path="/reports/closed-trello-boards" component={ClosedTrelloBoards} />
            <Route exact path="/reports/over-limit" component={OverLimitReport} />
            <Route exact path="/team-control" component={TeamControl} />
            
            <Route exact path="/boards" component={Bcboards} />
            <Route exact path="/boardsdetails/:id" component={BcboardsDetail} />
            <Route exact path="/boardsdetails/:id/:uid" component={BcboardsDetail} />
            <Route exact path="/mailbox/:id" component={BoardMailBox} />
            <Route exact path="/docandfiles/:id" component={Docandfile} />
            <Route exact path="/brand/:bid/:id" component={Brand} />
            <Route exact path="/brand/assets/:pid/:bid/:fid" component={Attachfiles} />
            <Route exact path="/brand/assets/:pid/:bid/:fid/:sfid" component={Attachfiles} />
            <Route exact path="/task-list/:bid" component={Tasklistbc} />
            <Route exact path="/new-task/:bid" component={NewTask} />
            <Route exact path="/task/detail/:bid/:id" component={TaskDetails} />
            <Route exact path="/boards/my-tasks" component={ProjectTasks} />
            <Route exact path='/reports/active-running-tasks' component={ActiveRunningTasks} />
            <Route exact path="/reports/client-ratio" component={ClientRatio} />
            <Route exact path='/reports/task-assigned-summary' component={TaskAssignedSummary} />
            <Route exact path='/brand/preset/:pid/:bid/:fid' component={BrandPreset} />
            
            <Route exact path='/board/approvedfiles/:pid' component={BoardApprovedFiles} />

            <Route exact path='/notifications' component={Notificationdetail} />
            <Route exact path="/geolocation" component={GeoLocation} />

            <Route exact path='/boards/activities' component={internalActivities} />
            
            <Route exact path='/board/allactivities' component={AllBoardActivities} />
            <Route exact path="/board/allactivities/:uid" component={AllBoardActivities} />

            <Route exact path='/boards/assignments' component={AssignmentsandActivities} />
            <Route exact path='/boards/assignments/:team' component={AssignmentsandActivities} />
            <Route exact path='/plate' component={Yourplate} />
            <Route exact path='/internal-task-grid/:bid' component={TasklistbcGroup} />
            <Route exact path='/internal-task-list/:bid' component={TasklistbcGroupDefault} />
            <Route exact path='/clone-internal-board' component={CloneInternalBoard} />
            <Route exact path='/reports/non-branded-boards' component={NonBrandedBoards} />
            <Route exact path='/ddtest' component={DDTest} />
            <Route exact path='/create-meeting' component={CreateMeeting} />
            <Route exact path='/manage-meeting' component={Meetinglist} />
            <Route exact path='/meeting-logs' component={MinutesofMeeting} />
            <Route exact path='/boards/assignmentsdemo' component={AssignmentsandActivitiesDemo} />
            <Route exact path='/salary-calculator' component={SalaryTable} />
            <Route exact path='/client-profiles' component={ClientprofileList} />
            <Route exact path='/client-details' component={ClientprofileDetails} />
            <Route exact path="/predefined-messages" component={PredefinedMessages}/>
            <Route  path='/source/r/:taskid/:revisionid/:ukey/:keyexp' component={SourceView} />
            <Route path='/source/f/:taskid/:revisionid/:folderid/:ukey/:keyexp' component={SourceViewFolder} />
            <Route path="/admin/board-collections" component={BoardCollections} />
            <Route path="/admin/canva-accounts" component={CanvaAccounts} />
            <Route exact path='/portfolio-assets-review' component={PortfolioAssetsReview} />
            <Route exact path='/video-portfolio-assets-review' component={VideoPortfolioAssetsReview} />

            <Route exact path='/portfolio-assets' component={PortfolioAssets} />
            <Route exact path='/video-portfolio-assets' component={VideoPortfolioAssets} />

            <Route exact path='/performance-dashboard' component={ProfileReport} />
            <Route exact path='/performance-report' component={PerformanceDashboard} />
            <Route exact path='/ai-test-tracker' component={AiTestTracker} />
            <Route exact path='/client-testimonials/:type' component={CrmTestimonailsReport} />
            <Route exact path='/employee-rating-authors' component={EmployeeRatingAuthors} />
            <Route exact path="/loan/repayment-scheduler" component={LoanRepayment} />

            <Route exact path='/performance-issues' component={PerformanceTimeline  } />
            <Route exact path='/tickets/support-tickets' component={HrTicketList} />
            <Route exact path='/tickets/support-ticket/:id' component={HrTicketDetails} />
            <Route exact path='/webinnar' component={EWebinarPage} />

            {/* <Route exact path='/test-notification' component={Notification} /> */}
            {/* <Route exact path='/test-wp-login' component={TestWpLogin} /> */}
          </Switch>
        </BrowserRouter>
      </div>
      </QueryClientProvider> 
      </MediaProvider>
    );
}
export default App;
