import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { withRouter } from 'react-router-dom';
import {
CardBody,
Table,
Badge,
Button,
Input,
FormGroup,
Label,
Row,
Col,
UncontrolledDropdown,
DropdownToggle,
DropdownMenu,
DropdownItem,
Spinner,
Alert
} from 'reactstrap';
import { Plus, Inbox } from 'lucide-react';
import CreateTicketDrawer from './CreateTicketDrawer';
import { useGetsupporttickets } from './useHrTicketsHooks';
import { formatDate, getPriorityBadgeColor, getStatusBadgeColor } from './HelperFunctions';

const TicketList = ({ history, assets }) => {
const queryClient = useQueryClient();
const [isDrawerOpen, setIsDrawerOpen] = useState(false);
const [topics, setTopics] = useState(assets?.topics || []);
const [priorities, setPriorities] = useState(assets?.priority || []);
const [users, setUsers] = useState(assets?.assignees || []);
const [asignedMe, setAsignedMe] = useState(true);

const containerRef = useRef(null);
const [filters, setFilters] = useState({
  status: 'active',
  topic: '',
  priority: '',
  assignee: '',
  search: '',
  dateRange: '7days',
  page: 1,
  assignedMe: asignedMe
});

// Modified to include pagination
const { 
  data: ticketsResponse, 
  isLoading, 
  error,
  isFetching,
  refetch
} = useGetsupporttickets(filters);

// Effect to trigger refetch when filters change
useEffect(() => {
  if(!isFetching)
  {
    refetch();
  }
}, [filters, refetch]);

// Store all loaded tickets
const [allTickets, setAllTickets] = useState([]);

// Update allTickets when new data arrives
useEffect(() => {
  if (ticketsResponse?.data?.tickets) {
    if (filters.page === 1) {
      setAllTickets(ticketsResponse.data.tickets);
    } else {
      console.log(ticketsResponse.data.tickets);
      setAllTickets(prev => [...prev, ...ticketsResponse.data.tickets]);
    }
  }
}, [ticketsResponse]);

// Handle scroll
const handleScroll = useCallback((event) => {
  const container = event.target;
  if (!container || isFetching || isLoading || !ticketsResponse?.data?.hasMore) {
    return;
  }
  const { scrollTop, scrollHeight, clientHeight } = container;
  const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
  
  if (scrollPercentage > 0.9 && !isFetching) {
    setFilters(prev => {
      const newFilters = {
        ...prev,
        page: ticketsResponse?.data?.nextPage
      };
      return newFilters;
    });
  }
}, [isFetching, ticketsResponse, filters.page]); // Added filters.page as dependency

// Add scroll listener
useEffect(() => {
  const currentContainer = containerRef.current;
  if (currentContainer) {
    currentContainer.addEventListener('scroll', handleScroll);
    return () => {
      currentContainer.removeEventListener('scroll', handleScroll);
    };
  }
}, [handleScroll]);

// Reset pagination when filters change (except page)
useEffect(() => {
  const resetFilters = {
    ...filters,
    page: 1
  };
  setFilters(resetFilters);
  setAllTickets([]);
}, [
  filters.status,
  filters.topic,
  filters.priority,
  filters.assignee,
  filters.search,
  filters.dateRange
]);

return (
  <div className="ticket-list-container">
    <div className="ticket-list-header">
      <Row className="align-items-center mb-3">
        <Col>
          <h3 className="mb-0 custom-page-title">Support Tickets</h3>
        </Col>
        <Col xs="auto">
          <Button color="primary btn-custom-primary btn-new-support" onClick={() => setIsDrawerOpen(true)}>
            <Plus className="new-ticket-plus" size={18} /> New Ticket
          </Button>
        </Col>
      </Row>

      {/* Filters Section */}
      {/* ... your existing filters section ... */}
    </div>

    <div 
      className='support-ticket-list-body hide-scrollbar' 
      ref={containerRef}
      style={{ 
        height: '74vh', 
        overflowY: 'auto',
        position: 'relative',
      }}
    >
      {error ? (
        <Alert color="danger">
          {error.message || 'Error loading tickets. Please try again later.'}
        </Alert>
      ) : !allTickets.length && !isLoading ? (
        <div className="text-center py-5">
          <Inbox size={48} />
          <h5 className="mt-2">No tickets found</h5>
          <p className="text-muted">
            Create a new ticket
          </p>
        </div>
      ) : (
        <>
          <div className="table-responsive support-tickets-table">
            <Table className="ticket-table" hover>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Topic</th>
                  <th>Priority</th>
                  <th>Assignee</th>
                  <th>Created</th>
                  <th>Due Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {allTickets.map((ticket) => (
                  <tr key={ticket.id} onClick={() => history.push(`/tickets/support-ticket/${ticket.id}`)}>
                    <td>
                      <div className="d-flex align-items-center">
                        {ticket.ticket_title}
                      </div>
                    </td>
                    <td>
                      {topics?.find(t => t.id === ticket.topic_id)?.topic_label}
                    </td>
                    <td>
                      <Badge color={getPriorityBadgeColor(ticket.priority_id)} className="priority-badge">
                        {priorities?.find(p => p.id === ticket.priority_id)?.priority_label}
                      </Badge>
                    </td>
                    <td>
                      {ticket.assignee}
                    </td>
                    <td>{formatDate(ticket.created_at)}</td>
                    <td>{formatDate(ticket.due_date)}</td>
                    <td>
                      <Badge color={getStatusBadgeColor(ticket.status)}>
                        {ticket.status === 1 ? 'Active' : 'Closed'}
                      </Badge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          
          {/* Loading indicator */}
          <div className="text-center py-3">
            {isFetching && (
              <Spinner color="primary" size="sm" />
            )}
            {!ticketsResponse?.data?.hasMore && allTickets.length > 0 && (
              <p className="text-muted mb-0">No more tickets to load</p>
            )}
          </div>
        </>
      )}
    </div>
    {assets && <CreateTicketDrawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} assets={assets} />}
  </div>
);
};

export default withRouter(TicketList);